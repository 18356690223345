import {
    FScrollBarStyle,
    FDragAndDrop,
} from '@fantaskticedtechlimited/fui-complib'
import { useNavigate } from 'react-router-dom'
import { useQBAssetLibraryContext } from '../../../../../contexts/qbAssetLibraryContext'
import { QBDirectoryViewMode } from '../../../../../contexts/qbAssetLibraryContext/types'
import { useChapterPopUpWrapperContext } from '../../../../QuestionBankContent/components/popUp/WithChapterPopUpWrapper'
import { QBAssetDropdownMenu } from '../../../components/QBAssetDropdownMenu'
import QBAssetLibraryURLGenerator from '../../../components/QBAssetLibraryURLGenerator'
import { QBAssetListCardChaptersContent } from '../../../components/QBAssetListCardContent/Chapters'
import QBAssetSwitchButton from '../../../components/QBAssetSwitchButton'
import { styles } from '../styles'

export const QBDirectoryViewChaptersBody = () => {
    const { folder, onRefresh, handleRearrangeChapters } =
        useQBAssetLibraryContext()
    const navigate = useNavigate()
    const {
        onChapterToggleEnablePopUpOpen,
        onChapterDeletePopUpOpen,
        onChapterMoveToFolderPopUpOpen,
        onChapterUpdatePopUpOpen,
    } = useChapterPopUpWrapperContext()

    return (
        <div
            className={
                styles.QBDirectoryView_ListWrapper + ' ' + FScrollBarStyle()
            }
        >
            <FDragAndDrop
                data={[...folder!.chapters]}
                onUpdateData={async (newDatas) =>
                    await handleRearrangeChapters(newDatas)
                }
                children={(chapter) => (
                    <>
                        <QBAssetListCardChaptersContent
                            data={chapter}
                            onClick={() => {
                                navigate(
                                    QBAssetLibraryURLGenerator({
                                        viewMode:
                                            QBDirectoryViewMode.QUESTION_TYPES,
                                        chapterId: chapter.id,
                                        questionBankId: chapter.questionBankId,
                                    })
                                )
                            }}
                        />
                        <QBAssetSwitchButton
                            isEnable={chapter.isEnable}
                            onClick={() => {
                                onChapterToggleEnablePopUpOpen(
                                    chapter,
                                    async () => {
                                        await onRefresh()
                                    }
                                )
                            }}
                        />
                        <QBAssetDropdownMenu 
                            onEditInfo={() => {
                                onChapterUpdatePopUpOpen(chapter, async () => {
                                    await onRefresh()
                                })
                            }}
                            onMoveTo={() => {
                                onChapterMoveToFolderPopUpOpen(
                                    chapter,
                                    async () => {
                                        await onRefresh()
                                    }
                                )
                            }}
                            onDelete={() => {
                                onChapterDeletePopUpOpen(chapter, async () => {
                                    await onRefresh()
                                })
                            }}
                        />
                    </>
                )}
            />
        </div>
    )
}
