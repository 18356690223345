import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { FConfirmPopUp } from '@fantaskticedtechlimited/fui-complib'
import { FOnSubmitFunction } from '@fantaskticedtechlimited/fui-complib'
import { FFontTypes } from '@fantaskticedtechlimited/fui-fontlib'
import {
    createContext,
    ElementType,
    Fragment,
    useContext,
    useState,
} from 'react'
import { Chapter, Folder } from '../../../../../assets/type/backend.type'

import { ChapterCreateForm } from '../../form/ChapterCreateForm'
import { ChapterMoveToFolderForm } from '../../form/ChapterMoveToFolderForm'
import { ChapterUpdateForm } from '../../form/ChapterUpdateForm'
import { ChapterDeletePopUp } from '../ChapterDeletePopUp'
import ChapterMoreActionPopUp from '../ChapterMoreActionPopUp'
import { ChapterToggleEnablePopUp } from '../ChapterToggleEnablePopUp'
import { ChapterPopUpWrapperProps } from './types'

const ChapterPopUpWrapperContext = createContext<ChapterPopUpWrapperProps>({
    isChapterMoreActionPopUpOpen: false,
    onChapterMoreActionPopUpOpen: async () => {},

    isChapterToggleEnablePopUpOpen: false,
    onChapterToggleEnablePopUpOpen: async () => {},

    isChapterCreatePopUpOpen: false,
    onChapterCreatePopUpOpen: async () => {},

    isChapterUpdatePopUpOpen: false,
    onChapterUpdatePopUpOpen: async () => {},

    isChapterMoveToFolderPopUpOpen: false,
    onChapterMoveToFolderPopUpOpen: async () => {},

    isChapterDeletePopUpOpen: false,
    onChapterDeletePopUpOpen: async () => {},
})
export const useChapterPopUpWrapperContext = () =>
    useContext(ChapterPopUpWrapperContext)
export const WithChapterPopUpWrapper = (Component: ElementType) => {
    return function WithChapterPopUpWrapper(props: any) {
        // More Action PopUp
        const [moreActionData, setMoreActionData] = useState<{
            chapter: Chapter
            callback: FOnSubmitFunction<Chapter | Folder>
        } | null>(null)
        const [isChapterMoreActionPopUpOpen, setIsChapterMoreActionPopUpOpen] =
            useState(false)
        const handleChapterMoreActionPopUpOpen = async (
            _chapter: Chapter,
            _callbackFunction: FOnSubmitFunction<Chapter | Folder>
        ) => {
            setIsChapterMoreActionPopUpOpen(true)
            setMoreActionData({
                chapter: _chapter,
                callback: _callbackFunction,
            })
        }
        const handleChapterMoreActionPopUpClose = () => {
            setIsChapterMoreActionPopUpOpen(false)
            setMoreActionData(null)
        }

        // Toggle Enable PopUp
        const [toggleEnableData, setToggleEnableData] = useState<{
            chapter: Chapter
            callback: FOnSubmitFunction<Chapter>
        } | null>(null)
        const [
            isChapterToggleEnablePopUpOpen,
            setIsChapterToggleEnablePopUpOpen,
        ] = useState(false)
        const handleChapterToggleEnablePopUpOpen = async (
            _chapter: Chapter,
            _callbackFunction: FOnSubmitFunction<Chapter>
        ) => {
            setIsChapterToggleEnablePopUpOpen(true)
            setToggleEnableData({
                chapter: _chapter,
                callback: _callbackFunction,
            })
        }
        const handleChapterToggleEnablePopUpClose = () => {
            setIsChapterToggleEnablePopUpOpen(false)
            setToggleEnableData(null)
        }

        // Chapter Create
        const [chapterCreateData, setChapterCreateData] = useState<{
            questionBankId: string
            presetFolderId?: string
            callback: FOnSubmitFunction<Chapter>
        } | null>(null)
        const [isChapterCreatePopUpOpen, setIsChapterCreatePopUpOpen] =
            useState(false)
        const handleChapterCreatePopUpOpen = async (
            // _questionBankId: string,
            data: { questionBankId: string; presetFolderId?: string },
            _callbackFunction: FOnSubmitFunction<Chapter>
        ) => {
            setChapterCreateData({
                ...data,
                callback: _callbackFunction,
            })
            setIsChapterCreatePopUpOpen(true)
        }
        const handleChapterCreatePopUpSubmit = async (newChapter: Chapter) => {
            chapterCreateData && (await chapterCreateData.callback(newChapter))
        }
        const handleChapterCreatePopUpClose = () => {
            setIsChapterCreatePopUpOpen(false)
            setChapterCreateData(null)
        }

        // Chapter Update
        const [chapterUpdateData, setChapterUpdateData] = useState<{
            chapter: Chapter
            callback: FOnSubmitFunction<Chapter>
        } | null>(null)
        const [isChapterUpdatePopUpOpen, setIsChapterUpdatePopUpOpen] =
            useState(false)
        const handleChapterUpdatePopUpOpen = async (
            _chapter: Chapter,
            _callbackFunction: FOnSubmitFunction<Chapter>
        ) => {
            setChapterUpdateData({
                chapter: _chapter,
                callback: _callbackFunction,
            })
            setIsChapterUpdatePopUpOpen(true)
        }
        const handleChapterUpdatePopUpSubmit = async (
            updatedChapter: Chapter
        ) => {
            chapterUpdateData &&
                (await chapterUpdateData.callback(updatedChapter))
        }
        const handleChapterUpdatePopUpClose = () => {
            setIsChapterUpdatePopUpOpen(false)
            setChapterUpdateData(null)
        }

        // Chapter Move to Folder
        const [chapterMoveToFolderData, setChapterMoveToFolderData] = useState<{
            chapter: Chapter
            callback: FOnSubmitFunction<Folder>
        } | null>(null)
        const [
            isChapterMoveToFolderPopUpOpen,
            setIsChapterMoveToFolderPopUpOpen,
        ] = useState(false)
        const handleChapterMoveToFolderPopUpOpen = async (
            _chapter: Chapter,
            _callbackFunction: FOnSubmitFunction<Folder>
        ) => {
            setChapterMoveToFolderData({
                chapter: _chapter,
                callback: _callbackFunction,
            })
            setIsChapterMoveToFolderPopUpOpen(true)
        }
        const handleChapterMoveToFolderPopUpSubmit = async (
            newFolder: Folder
        ) => {
            chapterMoveToFolderData &&
                (await chapterMoveToFolderData.callback(newFolder))
        }
        const handleChapterMoveToFolderPopUpClose = () => {
            setIsChapterMoveToFolderPopUpOpen(false)
            setChapterMoveToFolderData(null)
        }

        // Chapter Delete
        const [chapterDeleteData, setChapterDeleteData] = useState<{
            chapter: Chapter
            callback: FOnSubmitFunction<Chapter>
        } | null>(null)
        const [isChapterDeletePopUpOpen, setIsChapterDeletePopUpOpen] =
            useState(false)
        const handleChapterDeletePopUpOpen = async (
            _chapter: Chapter,
            _callbackFunction: FOnSubmitFunction<Chapter>
        ) => {
            setIsChapterDeletePopUpOpen(true)
            setChapterDeleteData({
                chapter: _chapter,
                callback: _callbackFunction,
            })
        }
        const handleChapterDeletePopUpClose = () => {
            setIsChapterDeletePopUpOpen(false)
            setChapterDeleteData(null)
        }

        // Context
        const defaultContextValue: ChapterPopUpWrapperProps = {
            isChapterMoreActionPopUpOpen,
            onChapterMoreActionPopUpOpen: handleChapterMoreActionPopUpOpen,

            isChapterToggleEnablePopUpOpen,
            onChapterToggleEnablePopUpOpen: handleChapterToggleEnablePopUpOpen,

            isChapterCreatePopUpOpen,
            onChapterCreatePopUpOpen: handleChapterCreatePopUpOpen,

            isChapterUpdatePopUpOpen,
            onChapterUpdatePopUpOpen: handleChapterUpdatePopUpOpen,

            isChapterMoveToFolderPopUpOpen,
            onChapterMoveToFolderPopUpOpen: handleChapterMoveToFolderPopUpOpen,

            isChapterDeletePopUpOpen,
            onChapterDeletePopUpOpen: handleChapterDeletePopUpOpen,
        }

        return (
            <ChapterPopUpWrapperContext.Provider value={defaultContextValue}>
                <Fragment>
                    <Component {...props} />

                    {/* More Action PopUp */}
                    {/* {isChapterMoreActionPopUpOpen &&
                        moreActionData !== null && (
                            <ChapterMoreActionPopUp 
                                onClose={() =>
                                    handleChapterMoreActionPopUpClose()
                                }
                                chapterData={moreActionData.chapter}
                                onCallback={async (data) =>
                                    await moreActionData.callback(data)
                                }
                            />
                        )} */}

                    {/* Toggle Enable PopUp */}
                    {isChapterToggleEnablePopUpOpen &&
                        toggleEnableData !== null && (
                            <ChapterToggleEnablePopUp
                                onClose={handleChapterToggleEnablePopUpClose}
                                chapterData={toggleEnableData?.chapter}
                                onCallback={async (data) =>
                                    await toggleEnableData.callback(data)
                                }
                            />
                        )}

                    {/* Chapter Create PopUp */}
                    {isChapterCreatePopUpOpen && chapterCreateData && (
                        <FConfirmPopUp
                            title="Create Chapter"
                            subtitle="Input new chapter name, order sequence and/or description below."
                            subtitleProps={{ style: { fontWeight: 400 } }}
                            disableBottomBar
                            disableCloseWhenClickOutside
                            style={{ width: '30%', height: 'fit-content' }}
                        >
                            <ChapterCreateForm
                                questionBankId={
                                    chapterCreateData.questionBankId
                                }
                                presetFolderId={
                                    chapterCreateData.presetFolderId
                                }
                                onSubmit={async (data) =>
                                    await handleChapterCreatePopUpSubmit(data)
                                }
                                onClose={handleChapterCreatePopUpClose}
                            />
                        </FConfirmPopUp>
                    )}

                    {/* Update Chapter PopUp*/}
                    {isChapterUpdatePopUpOpen && chapterUpdateData && (
                        <FConfirmPopUp
                            title="Edit Chapter"
                            disableBottomBar
                            disableCloseWhenClickOutside
                            style={{ width: '30%', height: 'fit-content' }}
                        >
                            <ChapterUpdateForm
                                chapter={chapterUpdateData.chapter}
                                onClose={handleChapterUpdatePopUpClose}
                                onSubmit={async (data) =>
                                    await handleChapterUpdatePopUpSubmit(data)
                                }
                            />
                        </FConfirmPopUp>
                    )}

                    {/* Move Chapter PopUp */}
                    {isChapterMoveToFolderPopUpOpen &&
                        chapterMoveToFolderData !== null && (
                            <FConfirmPopUp
                                title="Move Chapter:"
                                subtitle={chapterMoveToFolderData.chapter.name}
                                subtitleProps={{
                                    font: FFontTypes.Large_Text(),
                                    color: FLegacyColorTypes.PRIMARY_BLACK,
                                    style: { fontStyle: 'italic' },
                                }}
                                disableBottomBar
                                disableCloseWhenClickOutside
                                style={{ width: '30%', height: 'fit-content' }}
                            >
                                <ChapterMoveToFolderForm
                                    chapter={chapterMoveToFolderData.chapter}
                                    onClose={
                                        handleChapterMoveToFolderPopUpClose
                                    }
                                    onSubmit={async (data) =>
                                        await handleChapterMoveToFolderPopUpSubmit(
                                            data
                                        )
                                    }
                                />
                            </FConfirmPopUp>
                        )}

                    {/* Chapter Delete PopUp */}
                    {isChapterDeletePopUpOpen && chapterDeleteData !== null && (
                        <ChapterDeletePopUp
                            onClose={handleChapterDeletePopUpClose}
                            chapter={chapterDeleteData?.chapter}
                            onCallback={async (data) =>
                                await chapterDeleteData.callback(data)
                            }
                        />
                    )}
                </Fragment>
            </ChapterPopUpWrapperContext.Provider>
        )
    }
}
