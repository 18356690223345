import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { style } from 'typestyle'

export const toolbarContainer = style({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    columnGap: '0.75rem',
})

export const chapterListWrapper = style({
    display: 'flex',
    flexDirection: 'column',
    rowGap: '2rem',
})

export const emptyDataWarningDiv = style({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '1.5rem',
})

export const chapterListDiv = style({
    display: 'flex',
    flexDirection: 'column',
    rowGap: '1rem',
})

export const divider = style({
    margin: '0 1rem',
    height: '0.075rem',
    backgroundColor: FLegacyColorTypes.PRIMARY_GREY,
})

export const chapterFloatingButtonContentDiv = style({
    display: 'flex',
    flexDirection: 'column',
    rowGap: '0.75rem',
    alignItems: 'flex-end',
})
