import { style } from 'typestyle'

export const switchButton_Circle = (checked: boolean) =>
    style({
        $nest: {
            '&>svg': {
                transform: checked
                    ? 'translateX(10px) !important'
                    : 'translateX(0)',
            },
        },
    })
