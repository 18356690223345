import { FolderDetailHeaderProps } from './type'
import FolderRow from '../../components/Folder/FolderRow'
import { useFolderPopUpWrapperContext } from '../../components/popUp/WithFolderPopUpWrapper'
import * as styles from './styles'
import { FHeaderButton } from '@fantaskticedtechlimited/fui-complib'
import { useNavigate } from 'react-router-dom'

function FolderDetailHeader(props: FolderDetailHeaderProps) {
    const navigate = useNavigate()
    const { folder } = props
    const { onFolderMoreActionPopUpOpen, onFolderToggleEnablePopUpOpen } =
        useFolderPopUpWrapperContext()

    return (
        <div className={styles.FolderDetailHeader_Container}>
            <FHeaderButton
                type="Back"
                onClick={() => navigate('/questionBankContent')}
            />
            <div style={{ flex: 1 }}>
                <FolderRow
                    folder={folder}
                    disableCardOnClick
                    onMoreActionButtonClick={() =>
                        onFolderMoreActionPopUpOpen(folder, props.onRefresh)
                    }
                    onToggleEnableButtonClick={() =>
                        onFolderToggleEnablePopUpOpen(folder, props.onRefresh)
                    }
                />
            </div>
        </div>
    )
}

export default FolderDetailHeader
