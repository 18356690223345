import { useEffect, useState } from 'react'
import useQBAPIService from '../../../../../apiService/questionBank'
import {
    ConceptCard,
    MCContentCreateInput,
    QuestionBank,
    QuestionUpdateInput,
    QuestionInputTypes,
    QuestionType,
    Tag,
    Question,
    MCContent,
    MCAnswerCreateInput,
    Chapter,
    QuestionGroup,
} from '../../../../../assets/type/backend.type'
import { FForm, Nullable } from '@fantaskticedtechlimited/fui-complib'
import { styles } from './styles'
import { QuestionUpdateFormProps } from './types'
import FCreateSelect from '../../../../../Components/FCreateSelect'
import ConceptCardRow from '../../ConceptCard/ConceptCardRow'
import QuestionPreview from '../../Question/QuestionPreview'
import { MCContentInputPanel } from '../../Question/MCContentInputPanel'
import { useStateSafe } from '../../../../../utils/useStateSafe'
import TagCreateSelector from '../../TagCreateSelector'
import { LoadingAnimation } from '../../../../../layout/WithLoadingPageWrapper'
import removeTypename from '../../../../../utils/removeTypename'

export const QuestionUpdateForm = (props: QuestionUpdateFormProps) => {
    const qbAPIService = useQBAPIService()
    const [selectedQuestion, setSelectedQuestion] =
        useState<Nullable<Question>>(null)
    const [selectedQuestionBank, setSelectedQuestionBank] =
        useState<QuestionBank | null>(null)
    const [questionTypes, setQuestionTypes] = useState<QuestionType[]>([])
    const [selectedQuestionType, setSelectedQuestionType] =
        useState<QuestionType | null>(null)
    const [selectedQuestionGroup, setSelectedQuestionGroup] =
        useState<QuestionGroup | null>(null)
    const [selectedConceptCards, setSelectedConceptCards] = useStateSafe<
        ConceptCard[]
    >([])
    const [tags, setTags] = useStateSafe<Tag[]>([])
    const [selectedTags, setSelectedTags] = useStateSafe<Tag[]>([])
    const [questionInputType] = useStateSafe<QuestionInputTypes>(
        QuestionInputTypes.MULTIPLE_CHOICE
    )
    const [mcContentCreateInput, setMcContentCreateInput] =
        useStateSafe<MCContentCreateInput | null>(null)

    const handleGetQuestionDetailById = async (questionId: string) => {
        try {
            const result =
                await qbAPIService.getQuestionUpdate_QuestionDetailById({
                    questionId,
                })
            const { questionGet } = result.data
            if (questionGet.isSuccess) {
                // console.log('questionGet', questionGet)
                setSelectedQuestion(questionGet.data! || null)
                // set content
                saveQuestionToContentInput(questionGet.data!)
                setSelectedConceptCards(questionGet.data?.conceptCards || [])
                setSelectedQuestionType(questionGet.data?.questionType || null)
                setSelectedQuestionGroup(
                    questionGet.data?.questionGroup || null
                )
                setSelectedTags(questionGet.data?.tags || [])
            }
        } catch (error) {
            console.log('error', error)
            setSelectedQuestion(null)
        }
    }

    const handleQuestionBankContentDetailById = async (id: string) => {
        try {
            const result =
                await qbAPIService.getQuestionCreate_QuestionBankContentDetailById(
                    {
                        id,
                    }
                )
            const { questionBankGet } = result.data
            if (questionBankGet.isSuccess) {
                setSelectedQuestionBank(questionBankGet.data! || null)
                setTags(questionBankGet.data?.tags ?? [])
            }
        } catch (error) {
            console.log('error', error)
            setSelectedQuestionBank(null)
        }
    }

    const handleConceptCardSelect = (newData: ConceptCard) => {
        const found = selectedConceptCards.find((e) => e.id === newData.id)
        if (!found) {
            setSelectedConceptCards([...selectedConceptCards, newData])
        }
    }

    const handleConceptCardRemove = (newData: ConceptCard) => {
        const newSelectedConceptCards = selectedConceptCards.filter(
            (e) => e.id !== newData.id
        )
        setSelectedConceptCards(newSelectedConceptCards)
    }

    const saveQuestionToContentInput = (question: Question) => {
        switch (question.inputType) {
            case QuestionInputTypes.MULTIPLE_CHOICE:
                const mcContent = question.content as MCContent
                const mcAnswers: MCAnswerCreateInput[] = []
                mcContent.answers.forEach((e) => {
                    mcAnswers.push({
                        isCorrect: mcContent.correctAnswerIds.includes(e.id)
                            ? true
                            : false,
                        body: e.body,
                    })
                })
                setMcContentCreateInput({
                    body: mcContent.body,
                    solution: mcContent.solution,
                    answers: mcAnswers,
                })
                break
            default:
            // return DefaultQuestionContentPreview(question);
        }
    }

    const handleQuestionUpdate = async () => {
        try {
            let _questionUpdateInput: QuestionUpdateInput = {
                questionId: props.questionId,
                questionTypeId: selectedQuestionType?.id!,
                questionGroupId: selectedQuestionGroup?.id ?? null,
                isFlaged: true,
                contentMC: mcContentCreateInput,
                tagIds: selectedTags.map((e) => e.id) || [],
                conceptCardIds: selectedConceptCards.map((e) => e.id) || [],
                photoAttachments: [],
            }
            let questionUpdateInput: QuestionUpdateInput =
                removeTypename(_questionUpdateInput)
            const result = await qbAPIService.qbQuestionUpdateByIdMutation({
                variables: {
                    questionUpdateInput,
                },
            })
            if (
                result.data?.qbQuestionUpdateById.isSuccess &&
                result.data.qbQuestionUpdateById.data
            ) {
                props.onSubmitCallback &&
                    (await props.onSubmitCallback(
                        result.data.qbQuestionUpdateById.data
                    ))
                props.onClose && (await props.onClose())
            } else {
                console.error(
                    `Question Update failed, Reason. ${JSON.stringify(
                        result.data?.qbQuestionUpdateById.errorMessage
                    )}`
                )
            }
        } catch (error) {
            console.log('error', error)
        }
    }

    useEffect(() => {
        if (props.questionBankId && props.questionId) {
            handleQuestionBankContentDetailById(props.questionBankId)
            handleGetQuestionDetailById(props.questionId)
        }
    }, [props.questionBankId, props.questionId])

    useEffect(() => {
        if (selectedQuestion && selectedQuestionBank) {
            let foundChapterIndex = (
                selectedQuestionBank.chapters || []
            ).findIndex((e) => selectedQuestion.chapterId?.includes(e.id))
            if (foundChapterIndex !== -1) {
                let _chapter = selectedQuestionBank.chapters[
                    foundChapterIndex
                ] as Chapter
                setQuestionTypes(_chapter.questionTypes)
            }
        }
    }, [selectedQuestion, selectedQuestionBank])

    if (selectedQuestionBank === null || selectedQuestion === null)
        return <LoadingAnimation />

    return (
        <FForm onClose={props.onClose} onSubmit={handleQuestionUpdate}>
            <div className={styles.FormContent_Container}>
                <div className={styles.FormContent_Input_Container}>
                    {/* Question Types */}
                    <FCreateSelect
                        isDisableCreate
                        isClearable={false}
                        options={questionTypes || []}
                        value={selectedQuestionType}
                        placeholder="Select Question Types"
                        getOptionLabel={(data) => data?.name}
                        getOptionKey={(data) => data?.id!}
                        onSelect={(data) => {
                            setSelectedQuestionType(data)
                            setSelectedQuestionGroup(null)
                        }}
                        onCompareInputValue={(data, input) => {
                            return data!.name
                                .toLowerCase()
                                .includes(input.toLowerCase())
                        }}
                    />
                    {/* QuestionGroups */}
                    <FCreateSelect
                        isDisableCreate
                        isClearable={false}
                        options={selectedQuestionType?.questionGroups || []}
                        value={selectedQuestionGroup}
                        placeholder="Select Question Groups"
                        getOptionLabel={(data) => data?.name}
                        getOptionKey={(data) => data?.id!}
                        onSelect={(data) => setSelectedQuestionGroup(data)}
                        onCompareInputValue={(data, input) =>
                            data?.name.includes(input) ?? false
                        }
                    />
                    {/* Concept Cards */}
                    <FCreateSelect
                        isDisableCreate
                        options={selectedQuestionBank.conceptCards}
                        value={selectedConceptCards}
                        placeholder="Select Concept Cards..."
                        isMulti
                        getOptionLabel={(data) => (
                            <ConceptCardRow
                                conceptCard={data}
                                isDisableToolbar
                            />
                        )}
                        getOptionKey={(data) => data.id}
                        onClear={() => setSelectedConceptCards([])}
                        onSelect={(data) => handleConceptCardSelect(data)}
                        onRemove={(data) => handleConceptCardRemove(data)}
                        onCompareInputValue={(data, input) => {
                            return data!.name
                                .toLowerCase()
                                .includes(input.toLowerCase())
                        }}
                    />
                    {/* TagInput */}
                    <TagCreateSelector
                        questionBankId={props.questionBankId}
                        tags={tags}
                        onTagsChange={setTags}
                        selectedTags={selectedTags}
                        onSelectedTagsChange={setSelectedTags}
                    />
                    {/* MC Content Input */}
                    {mcContentCreateInput !== null && (
                        <MCContentInputPanel
                            content={mcContentCreateInput}
                            onContentChange={(newData) =>
                                setMcContentCreateInput(newData)
                            }
                        />
                    )}
                </div>
                <div className={styles.FormContent_Preview_Container}>
                    <QuestionPreview
                        question={{
                            ...selectedQuestion,
                            questionType: selectedQuestionType ?? null,
                            questionGroup: selectedQuestionGroup ?? null,
                            content: {
                                ...mcContentCreateInput,
                                inputType: questionInputType,
                            },
                            tags: selectedTags,
                            tagIds: [],
                            conceptCards: selectedConceptCards,
                            conceptCardIds: [],
                        }}
                        isEdit
                    />
                </div>
            </div>
        </FForm>
    )
}
