import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { QBDirectoryViewHeader } from '..'
import { useQBAssetLibraryContext } from '../../../../../contexts/qbAssetLibraryContext'
import { QBDirectoryViewMode } from '../../../../../contexts/qbAssetLibraryContext/types'
import { useLoadingPageWrapperContext } from '../../../../../layout/WithLoadingPageWrapper'
import { useQuestionTypePopUpWrapperContext } from '../../../../QuestionBankContent/components/popUp/WithQuestionTypePopUpWrapper'
import QBAssetLibraryURLGenerator from '../../../components/QBAssetLibraryURLGenerator'

export const QBDirectoryViewQuestionTypesHeader = () => {
    const { folder, chapter, onRefresh } = useQBAssetLibraryContext()
    const { onQuestionTypeCreatePopUpOpen } =
        useQuestionTypePopUpWrapperContext()
    const { setLoadingStart, setLoadingEnd } = useLoadingPageWrapperContext()
    const navigate = useNavigate()

    useEffect(() => {
        if (chapter === null) setLoadingStart()
        else setLoadingEnd()
    }, [chapter])

    return (
        <QBDirectoryViewHeader
            onBackButtonClick={() =>
                navigate(
                    QBAssetLibraryURLGenerator({
                        viewMode: QBDirectoryViewMode.CHAPTERS,
                        questionBankId: chapter?.questionBankId!,
                        folderId: folder !== null ? folder.id : '',
                    })
                )
            }
            title={chapter?.name!}
            CTAButtonLabel="+  Add Question Type"
            onCTAButtonClick={() =>
                onQuestionTypeCreatePopUpOpen(chapter!, () => onRefresh())
            }
        />
    )
}
