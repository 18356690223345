import useQBAPIService from '../../../../../apiService/questionBank'
import { FolderUpdateInput } from '../../../../../assets/type/backend.type'
import { FolderToggleEnablePopUpProps } from './types'
import { TogglePopUp } from '../../../../../Components/TogglePopUp'

export const FolderToggleEnablePopUp = (
    props: FolderToggleEnablePopUpProps
) => {
    const qbAPIService = useQBAPIService()
    const targetEnableStatus = !props.folderData.isEnable

    const handlePopUpClose = async () => {
        props.onClose && (await props.onClose())
    }

    const handleFolderToggle = async (
        folderId: string,
        _targetEnableStatus: boolean
    ) => {
        let data: FolderUpdateInput = {
            folderId: folderId,
            isEnable: _targetEnableStatus,
        }
        const statusStr = data.isEnable ? 'Enable' : 'Disable'

        try {
            const result = await qbAPIService.qbFolderUpdateByIdMutation({
                variables: {
                    folderUpdateInput: data,
                },
            })
            if (result.data?.qbFolderUpdateById.isSuccess) {
                props.onCallback &&
                    (await props.onCallback(
                        result.data?.qbFolderUpdateById.data!
                    ))
                handlePopUpClose()
            } else {
                alert(
                    `Folder ${statusStr} failed, Reason. ${JSON.stringify(
                        result
                    )}`
                )
            }
        } catch (error) {
            console.log('error', error)
            alert(`Folder ${statusStr} failed, Error: ${JSON.stringify(error)}`)
        }
    }

    return (
        <TogglePopUp
            isEnable={targetEnableStatus}
            targetType="Folder"
            targetName={props.folderData.name}
            onClose={handlePopUpClose}
            onActionButtonClick={() =>
                handleFolderToggle(props.folderData.id, targetEnableStatus)
            }
        />
    )
}
