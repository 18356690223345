import { ElementType } from 'react'
import { useStateSafe } from '../../../utils/useStateSafe'
import { UploadRuleCheck } from '../utils/DefaultRuleChecker'
import { UploaderComponentProps } from '../types'
import * as styles from './styles'
import { FUseTheme } from '@fantaskticedtechlimited/fui-complib'

export interface DNDUploaderProps
    extends Omit<UploaderComponentProps, 'innerRef'> {
    buttonUploader?: ElementType<UploaderComponentProps>
    innerRef?: React.RefObject<HTMLDivElement>
}
export const DefaultDNDUploader = (props: DNDUploaderProps) => {
    const {
        // set default value
        uploadStatusDisplayer,
        innerRef,
        style,
        className,
        iconUploaded,
        ...restProps
    } = props
    const { theme } = FUseTheme()
    // handle upload
    const [isLoading, setIsLoading] = useStateSafe(false)
    const localDisableDND = props.uploadStatus
        ? props.uploadStatus.isUploading
        : isLoading
    const handleUpload = async (files: File[], fileNamePrefix: string) => {
        if (props.uploadStatus) {
            props.onBeforeUpload && props.onBeforeUpload(files)
            const finalList = await props.uploadHandler(files, fileNamePrefix)
            props.onUploadedFiles && (await props.onUploadedFiles(finalList))
            console.log('Files: ', finalList.length)
        } else {
            setIsLoading(true)
            props.onBeforeUpload && props.onBeforeUpload(files)
            const finalList = await props.uploadHandler(files, fileNamePrefix)
            props.onUploadedFiles && (await props.onUploadedFiles(finalList))
            console.log('Files: ', finalList.length)
            setIsLoading(false)
        }
    }
    // Drag and Drop
    const [isDropEnter, setIsDropEnter] = useStateSafe(false)
    const handleDivOnDrop = async (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        try {
            const files: File[] = []
            if (e.dataTransfer.items) {
                for (let i = 0; i < e.dataTransfer.items.length; i++) {
                    if (e.dataTransfer.items[i].kind === 'file') {
                        const file = e.dataTransfer.items[i].getAsFile()
                        file !== null && files.push(file)
                    }
                }
            } else {
                for (let i = 0; i < e.dataTransfer.files.length; i++) {
                    files.push(e.dataTransfer.files[i])
                }
            }
            const { isPass, checkedFiles, errorMessage } = UploadRuleCheck(
                files,
                restProps
            )
            if (isPass) {
                await handleUpload(checkedFiles, props.fileNamePrefix)
            } else {
                return alert(errorMessage)
            }
        } catch (error) {
            console.error('error', error)
        }
        setIsDropEnter(false)
    }

    const handleDivOnDropEnter = async (e: React.DragEvent<HTMLDivElement>) => {
        // console.log("onEnter")
        setIsDropEnter(true)
    }

    const handleDivOnDropLeave = async (e: React.DragEvent<HTMLDivElement>) => {
        // console.log("onLeave")
        setIsDropEnter(false)
    }

    return (
        <div
            ref={innerRef}
            style={style}
            className={
                styles.DefaultDNDUploader_Container(
                    isDropEnter,
                    iconUploaded!
                ) +
                ' ' +
                className
            }
            onDrop={!localDisableDND ? handleDivOnDrop : undefined}
            onDragOver={(e) => {
                e.preventDefault()
            }}
            onDragEnter={handleDivOnDropEnter}
            onDragLeave={handleDivOnDropLeave}
        >
            {props.buttonUploader && <props.buttonUploader {...restProps} />}
            {uploadStatusDisplayer &&
                props.uploadStatus &&
                uploadStatusDisplayer(props.uploadStatus)}
        </div>
    )
}
