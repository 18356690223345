import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { FOverrideStyle } from '@fantaskticedtechlimited/fui-complib'
import { style, stylesheet } from 'typestyle'

export const styles = stylesheet({ 
    rowDiv: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: 8,
        alignItems: 'center',
    },
    iconRow: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '4px',
        alignItems: 'flex-end',
    },
    icon: {
        width: '16px',
        height: '16px',
    },
    IconsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        columnGap: '16px',
    },
    questionBankList_Wrapper: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        rowGap: '16px',
        overflowY: 'auto',
    },
})