import { QBAssetListCardIconBarProps } from '../QBAssetListCardIconBar/types'
import { QBAssetListCardQuestionTypesContentProps } from './types'
import { styles } from './styles'
import { FText, FUseColor } from '@fantaskticedtechlimited/fui-complib'
import { FFontTypes } from '@fantaskticedtechlimited/fui-fontlib'
import QBAssetListCardIconBar from '../QBAssetListCardIconBar'

export const QBAssetListCardQuestionTypesContent = (
    props: QBAssetListCardQuestionTypesContentProps
) => {
    const data = props.data
    const chapterData = data.questionGroups
    const iconBarProps: Partial<QBAssetListCardIconBarProps> = {
        questionGroupsNumber: chapterData.length,
    }

    return (
        <div className={styles.CardContent_Container} onClick={props.onClick}>
            <FText
                font={FFontTypes.Large_Text()}
                color={FUseColor({ colorName: 'Grey' })}
            >
                {data.orderSequence}
            </FText>
            <FText
                font={FFontTypes.Large_Text()}
                maxRows={2}
                overflowHidden
                style={{ flex: 1 }}
            >
                {data.name}
            </FText>
            <QBAssetListCardIconBar {...iconBarProps} />
        </div>
    )
}
