import { gql } from '@apollo/client'
import { PHOTO_DETAIL_FIELDS } from '../fragments/photoDetail.gql'

export const GET_QUESTION_DETAIL_BY_ID_QUERY = gql`
    query questionDetailById($questionId: String!) {
        questionGet(id: $questionId) {
            isSuccess
            code
            errorMessage
            data {
                id
                questionBankId
                level
                questionGroupId

                isFlaged
                inputType
                chapter {
                    id
                    name
                }
                questionTypeId
                questionType {
                    id
                    name
                }
                questionGroup {
                    id
                    name
                }
                conceptCards {
                    id
                    name
                    tags {
                        id
                        name
                    }
                }
                content {
                    inputType
                    ... on MCContent {
                        body
                        answers {
                            id
                            body
                        }
                        correctAnswerIds
                        solution
                    }
                    ... on ShortQuestionContent {
                        body
                        answer
                        solution
                    }
                }
                tags {
                    id
                    name
                }
                photoAttachments {
                    ...photoDetail
                }
                createAt
                updateAt
            }
        }
    }

    ${PHOTO_DETAIL_FIELDS}
`
