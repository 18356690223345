import { style } from 'typestyle'

export const QuestionListView_Container = style({
    display: 'flex',
    flexDirection: 'column',
    rowGap: '1rem',
    flexGrow: 0.5,
    overflowX: 'hidden',
    overflowY: 'auto',
    // maxHeight: 600, // should be fixed later
})
