import { FLinkButton } from '@fantaskticedtechlimited/fui-complib'
import { Fragment, memo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import * as styles from './styles'
import { MenuButtonListProps } from './types'
import { useRouteService } from '../../../utils/useRouteService'
import { FRouteConfig } from '../../../utils/useRouteService/types'
import { FFontFamily, FFontTypes } from '@fantaskticedtechlimited/fui-fontlib'

const checkIsSelected = (
    locationPath: string,
    itemPath: string | string[] | undefined
) => {
    if (!itemPath) {
        return false
    }
    if (Array.isArray(itemPath)) {
        return itemPath.includes(locationPath)
    } else {
        return locationPath === itemPath
    }
}

const checkIsIncluded = (
    locationPath: string,
    itemPath: string | string[] | undefined
) => {
    if (!itemPath) {
        return false
    }
    if (Array.isArray(itemPath)) {
        let isInclude = false
        itemPath.forEach((e) => {
            if (locationPath.includes(e)) {
                isInclude = true
                return
            }
        })
        return isInclude
    } else {
        return locationPath.includes(itemPath)
    }
}

interface LinkButtonProps {
    route: FRouteConfig
    key: number
    isSelected: boolean
    isParentPathIncluded: boolean
    depth: number
    haveSubSubMenus: boolean
    newParentPath?: string
    isFooter?: boolean
}
const LinkButton = memo((props: LinkButtonProps) => {
    const {
        route,
        key,
        isSelected,
        isParentPathIncluded,
        depth,
        haveSubSubMenus,
        newParentPath,
        isFooter,
    } = props
    const routeService = useRouteService()
    const [isHover, setIsHover] = useState(false)
    return (
        <div
            className={styles.SubMenuList(isParentPathIncluded, depth)}
            onMouseEnter={() => {
                setIsHover(true)
            }}
            onMouseLeave={() => {
                setIsHover(false)
            }}
        >
            <FLinkButton
                key={`${depth}_${key}`}
                className={() => styles.useMenuButtonStyle(isSelected)}
                containsRouteChildren={haveSubSubMenus}
                leadingIcon={{
                    name: route.iconName! as any,
                    size: isFooter ? 'small' : undefined,
                    color: () => styles.iconButton(isSelected, isHover),
                }}
                actionIcon={{ style: () => ({ display: 'none' }) }}
                pathIsSelected={isSelected}
                label={route.menuName}
                pathLink={
                    route.defaultRedirect ??
                    routeService.extractPathLink(newParentPath)
                }
                labelClassName={() => styles.labelButton}
                labelStyle={() => ({
                    font: isFooter
                        ? FFontTypes.Text()
                        : FFontTypes.Large_Text({
                              fontFamily: FFontFamily.Poppins,
                          }),
                    color: styles.iconButton(isSelected, isHover),
                })}
                style={() => ({ width: '100%' })}
            />
        </div>
    )
})

interface RenderMenuButtonListProps {
    pathName: string
    routes: FRouteConfig[]
    depth: number
    isParentPathIncluded: boolean
    parentPath: string
    isFooter?: boolean
}

const RenderMenuButtonList = memo(
    (props: RenderMenuButtonListProps) => {
        const {
            pathName,
            routes,
            depth,
            isParentPathIncluded,
            parentPath,
            isFooter,
        } = props
        return (
            <Fragment>
                {routes.map((r, i) => {
                    const haveSubSubMenus = Array.isArray(r.routes)
                    const newParentPath =
                        depth === 0 ? r.path : `${parentPath}/${r.path}`
                    const isSelected = checkIsSelected(pathName, newParentPath)
                    const isIncluded = checkIsIncluded(pathName, newParentPath)

                    // console.log(
                    // 	`Depth:${depth} Path: ${parentPath}${r.path} isSelected:${isSelected}, isIncluded:${isIncluded}`
                    // );
                    if (r.hideInMenu) return null
                    return (
                        <Fragment key={i}>
                            <LinkButton
                                route={r}
                                key={i}
                                isSelected={isSelected}
                                isParentPathIncluded={isParentPathIncluded}
                                depth={depth}
                                haveSubSubMenus
                                newParentPath={newParentPath}
                                isFooter={isFooter}
                            />

                            {haveSubSubMenus && (
                                <RenderMenuButtonList
                                    pathName={pathName}
                                    routes={r.routes ?? []}
                                    depth={depth + 1}
                                    isParentPathIncluded={isIncluded}
                                    parentPath={newParentPath || ''}
                                />
                            )}
                        </Fragment>
                    )
                })}
            </Fragment>
        )
    },
    (prev, now) => {
        return false
        // if (prev.pathName !== now.pathName) {
        // 	return true;
        // }
        // return false;
    }
)

export const MenuButtonList = (props: MenuButtonListProps) => {
    const location = useLocation()
    return (
        <div className={styles.MenuListDiv(props.autoExpand!)}>
            <RenderMenuButtonList
                pathName={location.pathname}
                routes={props.routes}
                depth={0}
                isParentPathIncluded={false}
                parentPath={''}
            />
        </div>
    )
}
