import { FForm, FInputField } from '@fantaskticedtechlimited/fui-complib'
import { Fragment, useState } from 'react'
import useQBAPIService from '../../../../../apiService/questionBank'
import { QuestionTypeCreateInput } from '../../../../../assets/type/backend.type'

import { QuestionTypeCreateFormProps } from './types'

export const QuestionTypeCreateForm = (props: QuestionTypeCreateFormProps) => {
    const qbAPIService = useQBAPIService()
    let defaultValue: QuestionTypeCreateInput = {
        chapterId: props.chapter.id,
        name: 'Name',
        questionIds: [],
        orderSequence: 0,
    }
    const [questionTypeCreateInput, setQuestionTypeCreateInput] =
        useState<QuestionTypeCreateInput>({
            ...defaultValue,
        })

    const handleQuestionTypeCreate = async (
        questionTypeCreateInput: QuestionTypeCreateInput
    ) => {
        try {
            const result = await qbAPIService.qbQuestionTypeCreateMutation({
                variables: {
                    questionTypeCreateInput,
                },
            })
            if (
                result.data?.qbQuestionTypeCreate.isSuccess &&
                result.data.qbQuestionTypeCreate.data
            ) {
                props.onSubmitCallback &&
                    (await props.onSubmitCallback(
                        result.data.qbQuestionTypeCreate.data
                    ))
                props.onClose && (await props.onClose())
            } else {
                console.error(
                    `QuestionType Create failed, Reason. ${JSON.stringify(
                        result
                    )}`
                )
                alert(
                    `QuestionType Create failed, Reason.  ${result.data?.qbQuestionTypeCreate.errorMessage}`
                )
            }
        } catch (error) {
            console.log('error', error)
            alert(`QuestionType Create failed, Error: ${JSON.stringify(error)}`)
        }
    }

    return (
        <FForm
            onClose={props.onClose}
            onSubmit={() =>
                handleQuestionTypeCreate({
                    ...questionTypeCreateInput,
                })
            }
        >
            <FInputField
                autoFocus
                label="QuestionType Name"
                value={questionTypeCreateInput.name}
                wordCount={100}
                onInput={(v) =>
                    setQuestionTypeCreateInput({
                        ...questionTypeCreateInput,
                        name: v,
                    })
                }
            />
            <FInputField
                label="Order Sequence"
                value={String(questionTypeCreateInput.orderSequence)}
                onInput={(v) =>
                    setQuestionTypeCreateInput({
                        ...questionTypeCreateInput,
                        orderSequence: Number(v),
                    })
                }
            />
        </FForm>
    )
}
