import { Fragment, useEffect, useState } from 'react'
import useQBAPIService from '../../../../apiService/questionBank'
import {
    QuestionGroup,
    QuestionGroupFilterInput,
    Question,
    QuestionFilterInput,
    QuestionType,
    QuestionTypeFilterInput,
} from '../../../../assets/type/backend.type'
import { QuestionsContainerProps } from './types'
import * as styles from './styles'
import { QuestionListView } from '../../components/Question/QuestionListView'
import QuestionPreview from '../../components/Question/QuestionPreview'
import { useLoadingPageWrapperContext } from '../../../../layout/WithLoadingPageWrapper'
import { PageContainer } from '../../../../layout/PageContainer'
import {
    FButton,
    FNoData,
    FSelect,
    FText,
} from '@fantaskticedtechlimited/fui-complib'

import { useQuestionPopUpWrapperContext } from '../../components/popUp/WithQuestionPopUpWrapper'

function QuestionsContainer(props: QuestionsContainerProps) {
    const { onQuestionCreatePopUpOpen } = useQuestionPopUpWrapperContext()
    const { setLoadingStart, setLoadingEnd } = useLoadingPageWrapperContext()
    //
    const qbAPIService = useQBAPIService()

    const [questionTypes, setQuestionTypes] = useState<QuestionType[]>([])
    const [selectedQuestionType, setSelectedQuestionType] =
        useState<QuestionType | null>(null)
    const handleGetQuestionTypesAndQuestionGroupsByFilter = async (
        questionTypeFilterInput: QuestionTypeFilterInput,
        withLoadingScreen?: boolean
    ) => {
        withLoadingScreen && setLoadingStart()
        try {
            const result = await qbAPIService.getQuestionTypesDetailByFilter({
                questionTypeFilterInput,
            })
            const { questionTypesGet } = result.data

            setQuestionTypes(
                questionTypesGet.isSuccess ? questionTypesGet.data || [] : []
            )
        } catch (error) {
            console.log('error', error)
            setQuestionTypes([])
        }
        withLoadingScreen && setLoadingEnd()
    }
    const [selectedQuestionGroup, setSelectedQuestionGroup] =
        useState<QuestionGroup | null>(null)
    const [isFilterLoading, setIsFilterLoading] = useState(false)

    const [selectedQuestion, setSelectedQuestion] = useState<Question | null>(
        null
    )
    const [questions, setQuestions] = useState<Question[]>([])
    const totalQuestions = questions !== null ? questions.length : 0
    const handleGetQuestionsByFilter = async (
        questionFilterInput: QuestionFilterInput,
        withLoadingScreen?: boolean
    ) => {
        withLoadingScreen && setLoadingStart()
        setSelectedQuestion(null)
        try {
            const result = await qbAPIService.getQuestionsDetailByFilter({
                questionFilterInput,
            })
            const { questionsGet } = result.data
            setQuestions(questionsGet.isSuccess ? questionsGet.data || [] : [])
        } catch (error) {
            console.log('error', error)
            setQuestions([])
        }
        withLoadingScreen && setLoadingEnd()
    }
    const handleGetQuestionDetailById = async (
        questionId: string,
        withLoadingScreen?: boolean
    ) => {
        withLoadingScreen && setLoadingStart()
        try {
            const result = await qbAPIService.getQuestionDetailById({
                questionId,
            })
            const { questionGet } = result.data
            setSelectedQuestion(
                questionGet.isSuccess ? questionGet.data || null : null
            )
        } catch (error) {
            console.log('error', error)
            setSelectedQuestion(null)
        }
        withLoadingScreen && setLoadingEnd()
    }

    useEffect(() => {
        if (props.questionBankId || (props.questionBankId && props.chapterId)) {
            handleGetQuestionTypesAndQuestionGroupsByFilter({
                questionBankId: props.questionBankId,
                chapterId: props.chapterId ?? undefined,
            })
        } else {
            setQuestions([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.questionBankId, props.chapterId])

    return (
        <PageContainer
            header={
                <div className={styles.toolbarContainer}>
                    {/* Questions Filters */}
                    {/* Question Type Filter */}
                    <FSelect
                        wrapperStyle={{
                            width: '200px',
                        }}
                        label={`Question Types`}
                        showLabelOnly
                        options={questionTypes || []}
                        onSelect={(data) => setSelectedQuestionType(data)}
                        selectedOptions={selectedQuestionType}
                        renderOptionNameOnly={(qb) =>
                            // ls.getLocaleString(qb.name)
                            qb.name
                        }
                        onClear={() => {
                            setSelectedQuestionType(null)
                            setSelectedQuestionGroup(null)
                        }}
                    />

                    {/* QuestionGroup Filter */}
                    <FSelect
                        wrapperStyle={{
                            width: '200px',
                        }}
                        label={`Question Groups`}
                        showLabelOnly
                        options={
                            selectedQuestionType
                                ? selectedQuestionType.questionGroups || []
                                : []
                        }
                        onSelect={(data) => setSelectedQuestionGroup(data)}
                        selectedOptions={selectedQuestionGroup}
                        renderOptionNameOnly={(data) => data.name}
                        onClear={() => setSelectedQuestionGroup(null)}
                    />
                    {/* Search button */}
                    <FButton
                        type="Primary"
                        onClick={async () => {
                            try {
                                setIsFilterLoading(true)
                                await handleGetQuestionsByFilter({
                                    questionBankId: props.questionBankId,
                                    chapterId: props.chapterId ?? undefined,
                                    questionTypeId: selectedQuestionType
                                        ? selectedQuestionType.id
                                        : undefined,
                                    questionGroupId: selectedQuestionGroup
                                        ? selectedQuestionGroup.id
                                        : undefined,
                                    pagination: {
                                        limit: 9999999,
                                    },
                                })
                            } catch (error) {
                                alert(`Error ${JSON.stringify(error)}`)
                            }
                            setIsFilterLoading(false)
                        }}
                        label="Search"
                    />
                    {isFilterLoading && <FText>Searching...</FText>}

                    <div
                        style={{
                            flexGrow: 1,
                        }}
                    />

                    {/* New Questions button */}
                    <FButton
                        type="Primary"
                        onClick={
                            // () => console.log("New Question")
                            () =>
                                onQuestionCreatePopUpOpen(
                                    {
                                        questionBankId: props.questionBankId,
                                        presetChapterId: props.chapterId,
                                        presetQuestionTypeId:
                                            selectedQuestionType?.id,
                                        presetQuestionGroupId:
                                            selectedQuestionGroup?.id,
                                    },
                                    (newQuestion: Question) => {
                                        handleGetQuestionsByFilter({
                                            questionBankId:
                                                props.questionBankId,
                                            chapterId:
                                                props.chapterId ?? undefined,
                                            questionTypeId:
                                                newQuestion.questionTypeId ??
                                                null,
                                            questionGroupId:
                                                newQuestion.questionGroupId ??
                                                null,
                                            pagination: {
                                                limit: 9999999,
                                            },
                                        })
                                        console.log('newQuestion', newQuestion)
                                        console.log(
                                            'questionTypes',
                                            questionTypes
                                        )
                                        const foundQuestionType =
                                            questionTypes.find(
                                                (e) =>
                                                    e.id ===
                                                    newQuestion.questionTypeId
                                            )
                                        console.log(
                                            'foundQuestionType',
                                            foundQuestionType
                                        )
                                        if (foundQuestionType) {
                                            setSelectedQuestionType(
                                                foundQuestionType
                                            )
                                            const foundQuestionGroup =
                                                foundQuestionType.questionGroups.find(
                                                    (e) =>
                                                        e.id ===
                                                        newQuestion.questionGroupId
                                                )
                                            if (foundQuestionGroup) {
                                                setSelectedQuestionGroup(
                                                    foundQuestionGroup
                                                )
                                            }
                                        } else {
                                            setSelectedQuestionType(null)
                                            setSelectedQuestionGroup(null)
                                        }
                                    }
                                )
                        }
                        label="New Question"
                    />

                    {/* refresh button */}
                    <FButton
                        type="Primary"
                        onClick={async () => {
                            await handleGetQuestionTypesAndQuestionGroupsByFilter(
                                {
                                    questionBankId: props.questionBankId,
                                    chapterId: props.chapterId ?? undefined,
                                },
                                true
                            )
                            setSelectedQuestionType(null)
                            setSelectedQuestionGroup(null)
                            setQuestions([])
                            setSelectedQuestion(null)
                        }}
                        label="Refresh"
                    />
                </div>
            }
            body={
                // <div className={styles.listWrapper}>

                // </div>
                <Fragment>
                    {totalQuestions === 0 ? (
                        <FNoData description="Use filter to search questions" />
                    ) : (
                        <Fragment>
                            <div
                                className={
                                    styles.questionSelectAndPreviewContainer
                                }
                            >
                                <div
                                    style={{
                                        height: '100%',
                                        width: '50%',
                                    }}
                                >
                                    <QuestionListView
                                        questions={questions || []}
                                        onQuestionClicked={(q) =>
                                            handleGetQuestionDetailById(q.id)
                                        }
                                        containerStyle={{
                                            width: '100%',
                                            height: '100%',
                                        }}
                                        onRefresh={() => {
                                            handleGetQuestionsByFilter({
                                                questionBankId:
                                                    props.questionBankId,
                                                chapterId:
                                                    props.chapterId ??
                                                    undefined,
                                                questionTypeId:
                                                    selectedQuestionType
                                                        ? selectedQuestionType.id
                                                        : undefined,
                                                questionGroupId:
                                                    selectedQuestionGroup
                                                        ? selectedQuestionGroup.id
                                                        : undefined,
                                            })
                                        }}
                                    />
                                </div>
                                {selectedQuestion ? (
                                    <QuestionPreview
                                        question={selectedQuestion}
                                    />
                                ) : (
                                    <FNoData description="No selected Question" />
                                )}
                            </div>
                        </Fragment>
                    )}
                </Fragment>
            }
        />
    )
}

export default QuestionsContainer
