import { gql } from '@apollo/client'
import { PHOTO_DETAIL_FIELDS } from '../fragments/photoDetail.gql'

export const QB_CHAPTER_DELETE_BY_ID_MUTATION = gql`
    mutation qbChapterDeleteById($chapterId: String!) {
        qbChapterDeleteById(chapterId: $chapterId) {
            isSuccess
            code
            errorMessage
            data {
                id
                name
                description
                orderSequence
                icon {
                    ...photoDetail
                }
            }
        }
    }

    ${PHOTO_DETAIL_FIELDS}
`
