import { stylesheet } from 'typestyle'

export const styles = stylesheet({
    IconBar_RowDiv: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '15px',
        alignItems: 'center',
    },
    IconBar_ItemDiv: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '4px',
        alignItems: 'center',
    },
})
