import { gql } from '@apollo/client'

import { PHOTO_DETAIL_FIELDS } from '../fragments/photoDetail.gql'

export const QB_CHAPTER_UPDATE_BY_ID_MUTATION = gql`
    mutation qbChapterUpdateById($chapterUpdateInput: ChapterUpdateInput!) {
        qbChapterUpdateById(chapterUpdateInput: $chapterUpdateInput) {
            isSuccess
            code
            data {
                id
                name
                description
                orderSequence
                icon {
                    ...photoDetail
                }
                isEnable
                isFlagged
            }
        }
    }

    ${PHOTO_DETAIL_FIELDS}
`
