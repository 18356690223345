import { style } from 'typestyle'

export const adminUserManagement_toolbarContainer = style({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    columnGap: '6px',
    // minHeight:"5rem"
})
