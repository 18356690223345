
/*
 * -------------------------------------------------------
 * THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
 * -------------------------------------------------------
 */

/* tslint:disable */
/* eslint-disable */
export enum QuestionInputTypes {
    SHORT_QUESTION = "SHORT_QUESTION",
    LONG_QUESTION = "LONG_QUESTION",
    MULTIPLE_CHOICE = "MULTIPLE_CHOICE",
    GRAPH = "GRAPH"
}

export enum AttachmentTypes {
    PHOTO = "PHOTO",
    VIDEO = "VIDEO",
    AUDIO = "AUDIO",
    LINK = "LINK",
    ICON = "ICON"
}

export enum Locale {
    EN = "EN",
    ZH = "ZH"
}

export enum UserRole {
    ADMIN = "ADMIN",
    STUDENT = "STUDENT",
    TEACHER = "TEACHER",
    PUBLISHER = "PUBLISHER"
}

export enum UserStatus {
    INVITED = "INVITED",
    ACTIVE = "ACTIVE",
    DEACTIVE = "DEACTIVE"
}

export enum EventTokenTypes {
    OTP = "OTP",
    SOCIETY_INVITE_CODE = "SOCIETY_INVITE_CODE"
}

export enum EventTokenStatus {
    ACTIVE = "ACTIVE",
    USED = "USED",
    DEACTIVE = "DEACTIVE"
}

export enum Subject {
    CHINESE = "CHINESE",
    ENGLISH = "ENGLISH",
    MATHEMATICS = "MATHEMATICS",
    CHEMISTRY = "CHEMISTRY",
    PHYSICS = "PHYSICS",
    BIOLOGY = "BIOLOGY"
}

export enum CourseMemberStatus {
    INVITED = "INVITED",
    ACTIVE = "ACTIVE",
    REJECTED = "REJECTED",
    DEACTIVE = "DEACTIVE"
}

export enum CourseMemberRole {
    TEACHER = "TEACHER",
    STUDENT = "STUDENT"
}

export enum ExamSystem {
    HKDSE = "HKDSE",
    GCE = "GCE"
}

export enum DifficultyInputTypes {
    EASY = "EASY",
    NORMAL = "NORMAL",
    HARD = "HARD"
}

export enum HomeworkInputTypes {
    ADAPTIVE = "ADAPTIVE",
    STATIC = "STATIC",
    CUSTOM = "CUSTOM",
    MISTAKEREVISION = "MISTAKEREVISION"
}

export enum DayOfWeekEnum {
    SUN = "SUN",
    MON = "MON",
    TUE = "TUE",
    WED = "WED",
    THU = "THU",
    FRI = "FRI",
    SAT = "SAT"
}

export enum SorterTypes {
    ASC = "ASC",
    DESC = "DESC"
}

export enum CMHomeoworkResponsePairStatus {
    ALL = "ALL",
    NOT_START = "NOT_START",
    STARTED_NOT_DONE = "STARTED_NOT_DONE",
    NOT_DONE = "NOT_DONE",
    DONE = "DONE"
}

export interface CTMDateInputGQL {
    weekofday?: Nullable<DayOfWeekEnum>;
    month?: Nullable<number>;
    day?: Nullable<number>;
    hour?: Nullable<number>;
    minute?: Nullable<number>;
    second?: Nullable<number>;
}

export interface UserFilterInput {
    userID?: Nullable<string>;
    email?: Nullable<string>;
    firebaseUID?: Nullable<string>;
}

export interface UserArrayFilterInput {
    userIDs?: Nullable<string[]>;
    email?: Nullable<string>;
    roles?: Nullable<UserRole[]>;
    statuses?: Nullable<UserStatus[]>;
    isEnableMFA?: Nullable<boolean>;
}

export interface UserProfileFilterInput {
    userIds?: Nullable<string[]>;
    darkMode?: Nullable<boolean>;
    courseIdList?: Nullable<string[]>;
    matchAllCourseIdList?: Nullable<string[]>;
    language?: Nullable<Locale>;
}

export interface EventTokenArrayFilterInput {
    eventTokenIds?: Nullable<string[]>;
    tokenOwnerId?: Nullable<string>;
    event?: Nullable<EventTokenTypes[]>;
    status?: Nullable<EventTokenStatus[]>;
    expireFrom?: Nullable<DateTime>;
    expireTo?: Nullable<DateTime>;
    isValid?: Nullable<boolean>;
    pagination?: Nullable<PaginationInput>;
}

export interface PaginationInput {
    offset?: Nullable<number>;
    page?: Nullable<number>;
    limit?: Nullable<number>;
}

export interface CourseMemberFilterInput {
    courseMemberIds?: Nullable<string[]>;
    courseId?: Nullable<string>;
    courseIds?: Nullable<string[]>;
    userId?: Nullable<string>;
    userEmail?: Nullable<string>;
    role?: Nullable<CourseMemberRole>;
    status?: Nullable<CourseMemberStatus>;
    pagination?: Nullable<PaginationInput>;
}

export interface CMMyHomeworksFilterInput {
    courseIds?: Nullable<string[]>;
    sortByEndDate?: Nullable<SorterTypes>;
    pagination?: Nullable<PaginationInput>;
    status?: Nullable<CMHomeoworkResponsePairStatus>;
    dateBeforeEndDate?: Nullable<DateTime>;
}

export interface CMCoursesHomeworksFilterInput {
    homeworkIds?: Nullable<string[]>;
    studentId?: Nullable<string>;
    sortByEndDate?: Nullable<SorterTypes>;
    pagination?: Nullable<PaginationInput>;
    beforeEndDate?: Nullable<DateTime>;
    afterEndDate?: Nullable<DateTime>;
}

export interface CMCourseHomeworkResponsesDetailInput {
    studentId?: Nullable<string>;
    pagination?: Nullable<PaginationInput>;
    homeworkId: string;
    courseId: string;
    completed?: Nullable<boolean>;
}

export interface BookmarkQuestionFilterInput {
    bookmarkQuestionIDs?: Nullable<string[]>;
    studentId?: Nullable<string>;
    studentIds?: Nullable<string[]>;
    courseId?: Nullable<string>;
    chapterId?: Nullable<string>;
    chapterIds?: Nullable<string[]>;
    questionId?: Nullable<string>;
    questionIds?: Nullable<string[]>;
    pagination?: Nullable<PaginationInput>;
}

export interface HomeworkResponseFilterInput {
    homeworkResponseIds?: Nullable<string[]>;
    homeworkIds?: Nullable<string[]>;
    homeworkId?: Nullable<string>;
    courseId?: Nullable<string>;
    studentId?: Nullable<string>;
    isFinished?: Nullable<boolean>;
    pagination?: Nullable<PaginationInput>;
}

export interface QuestionHistoryFilterInput {
    questionHistoryIDs?: Nullable<string[]>;
    homeworkId?: Nullable<string>;
    homeworkIds?: Nullable<string[]>;
    studentId?: Nullable<string>;
    studentIds?: Nullable<string[]>;
    typeId?: Nullable<string>;
    typeIds?: Nullable<string[]>;
    chapterIds?: Nullable<string[]>;
    groupIds?: Nullable<string[]>;
    courseId?: Nullable<string>;
    courseIds?: Nullable<string[]>;
    homeworkResponseId?: Nullable<string>;
    homeworkResponseIds?: Nullable<string[]>;
    sessionSequence?: Nullable<number>;
    questionId?: Nullable<string>;
    questionIds?: Nullable<string[]>;
    studentAnswer?: Nullable<string>;
    timeUsed?: Nullable<number>;
    isCorrect?: Nullable<boolean>;
    archived?: Nullable<boolean>;
    sortByDate?: Nullable<SorterTypes>;
    sortByChapter?: Nullable<SorterTypes>;
    pagination?: Nullable<PaginationInput>;
    beforeCreateDate?: Nullable<DateTime>;
    afterCreateDate?: Nullable<DateTime>;
}

export interface CourseFilterInput {
    courseIds?: Nullable<string[]>;
    questionBankId?: Nullable<string>;
    pagination?: Nullable<PaginationInput>;
}

export interface HomeworkFilterInput {
    homeworkIds?: Nullable<string[]>;
    courseId?: Nullable<string>;
    courseIds?: Nullable<string[]>;
    studentId?: Nullable<string>;
    sortByEndDate?: Nullable<SorterTypes>;
    excludeHomeworkIds?: Nullable<string[]>;
    pagination?: Nullable<PaginationInput>;
    beforeEndDate?: Nullable<DateTime>;
    afterEndDate?: Nullable<DateTime>;
}

export interface ChapterFilterInput {
    chapterIds?: Nullable<string[]>;
    questionBankId?: Nullable<string>;
    sortByOrderSequence?: Nullable<SorterTypes>;
    pagination?: Nullable<PaginationInput>;
}

export interface ConceptCardFilterInput {
    conceptCardIds?: Nullable<string[]>;
    questionBankId?: Nullable<string>;
    chapterId?: Nullable<string>;
    pagination?: Nullable<PaginationInput>;
}

export interface FolderFilterInput {
    folderIds?: Nullable<string[]>;
    questionBankId?: Nullable<string>;
    sortByOrderSequence?: Nullable<SorterTypes>;
    pagination?: Nullable<PaginationInput>;
}

export interface QuestionBankFilterInput {
    questionBankIds?: Nullable<string[]>;
    examSystem?: Nullable<ExamSystem>;
    pagination?: Nullable<PaginationInput>;
}

export interface QuestionGroupFilterInput {
    questionGroupIds?: Nullable<string[]>;
    questionBankId?: Nullable<string>;
    chapterId?: Nullable<string>;
    questionTypeId?: Nullable<string>;
    sortByOrderSequence?: Nullable<SorterTypes>;
    pagination?: Nullable<PaginationInput>;
}

export interface QuestionTypeFilterInput {
    questionTypeIds?: Nullable<string[]>;
    chapterId?: Nullable<string>;
    chapterIds?: Nullable<string[]>;
    questionBankId?: Nullable<string>;
    sortByOrderSequence?: Nullable<SorterTypes>;
    pagination?: Nullable<PaginationInput>;
}

export interface QuestionFilterInput {
    questionIds?: Nullable<string[]>;
    excludeQuestionIds?: Nullable<string[]>;
    questionBankId?: Nullable<string>;
    chapterId?: Nullable<string>;
    chapterIds?: Nullable<string[]>;
    questionGroupId?: Nullable<string>;
    questionGroupIds?: Nullable<string[]>;
    minLevel?: Nullable<number>;
    maxLevel?: Nullable<number>;
    questionTypeId?: Nullable<string>;
    inputType?: Nullable<QuestionInputTypes>;
    isFlaged?: Nullable<boolean>;
    conceptCardId?: Nullable<string>;
    conceptCardIds?: Nullable<string[]>;
    matchAllConceptCardIds?: Nullable<string[]>;
    tagId?: Nullable<string>;
    tagIds?: Nullable<string[]>;
    matchAllTagIds?: Nullable<string[]>;
    sortByLevel?: Nullable<SorterTypes>;
    sortBychapterId?: Nullable<SorterTypes>;
    pagination?: Nullable<PaginationInput>;
}

export interface TagFilterInput {
    subject?: Nullable<Subject>;
    questionBankId?: Nullable<string>;
    pagination?: Nullable<PaginationInput>;
}

export interface CronJobRecordFilterInput {
    cronJobRecordIDs: string[];
    cronJobId: string;
    cronJobIds: string[];
    startTime: DateTime;
    functionName: string;
    endTime?: Nullable<DateTime>;
    isCompleted: boolean;
    sortByDate?: Nullable<SorterTypes>;
    pagination?: Nullable<PaginationInput>;
}

export interface CronjobScheduleFilterInput {
    cronjobScheduleIDs?: Nullable<string[]>;
    name: string;
    functionName: string;
    cronDateTime?: Nullable<CTMDateInputGQL>;
    enable?: Nullable<boolean>;
    pagination?: Nullable<PaginationInput>;
}

export interface StudentCronJobTypeScoreFilterInput {
    studentCronJobTypeScoreIDs?: Nullable<string[]>;
    studentId?: Nullable<string>;
    studentIds?: Nullable<string[]>;
    typeId?: Nullable<string>;
    typeIds?: Nullable<string[]>;
    score?: Nullable<number>;
    totalDoneNum?: Nullable<number>;
    correctNum?: Nullable<number>;
    correctPercentage?: Nullable<number>;
    averageTimeSpent?: Nullable<number>;
    totalTimeSpent?: Nullable<number>;
    pagination?: Nullable<PaginationInput>;
}

export interface StudentCronJobGroupScoreFilterInput {
    studentCronJobGroupScoreIDs?: Nullable<string[]>;
    studentId?: Nullable<string>;
    studentIds?: Nullable<string[]>;
    groupId?: Nullable<string>;
    groupIds?: Nullable<string[]>;
    score?: Nullable<number>;
    totalDoneNum?: Nullable<number>;
    correctNum?: Nullable<number>;
    correctPercentage?: Nullable<number>;
    averageTimeSpent?: Nullable<number>;
    totalTimeSpent?: Nullable<number>;
    pagination?: Nullable<PaginationInput>;
}

export interface FirebaseUserRegisterInput {
    firebaseToken: string;
}

export interface FirebaseUserLoginInput {
    firebaseToken: string;
}

export interface MFAOTPCreateInput {
    email: string;
}

export interface UMFirebaseEmailPasswordUserCreateInput {
    email: string;
    password: string;
    emailVerified?: Nullable<boolean>;
    roles: UserRole[];
    status: UserStatus;
}

export interface UserProfileUpdateInput {
    userId: string;
    nickname?: Nullable<string>;
    profilePic?: Nullable<PhotoAttachmentCreateInput>;
    isClearProfilePic?: Nullable<boolean>;
    darkMode?: Nullable<boolean>;
    courseIdList?: Nullable<string[]>;
    language?: Nullable<Locale>;
}

export interface PhotoAttachmentCreateInput {
    attachmentFileType: AttachmentTypes;
    size?: Nullable<number>;
    fileURL: string;
    fileName: string;
    filePath?: Nullable<string>;
    contentType?: Nullable<string>;
    originalFileName: string;
}

export interface QuestionBankCreateInput {
    name: string;
    subject: Subject;
    examSystem: ExamSystem;
}

export interface FolderCreateInput {
    questionBankId: string;
    name: string;
    icon?: Nullable<PhotoAttachmentCreateInput>;
    orderSequence?: Nullable<number>;
}

export interface FolderUpdateInput {
    folderId: string;
    name?: Nullable<string>;
    icon?: Nullable<PhotoAttachmentCreateInput>;
    isClearIcon?: Nullable<boolean>;
    isEnable?: Nullable<boolean>;
    isFlagged?: Nullable<boolean>;
}

export interface FolderAddOrRemoveChapterInput {
    folderId: string;
    chapterId: string;
    isRemove?: Nullable<boolean>;
}

export interface FoldersRearrangeInput {
    folderOrderSequences: FolderOrderSequenceInput[];
}

export interface FolderOrderSequenceInput {
    folderId: string;
    orderSequence: number;
}

export interface QuestionTypesRearrangeInput {
    questionTypesOrderSequences: QuestionTypesOrderSequenceInput[];
}

export interface QuestionTypesOrderSequenceInput {
    questionTypeId: string;
    orderSequence: number;
}

export interface QuestionGroupsRearrangeInput {
    questionGroupsOrderSequences: QuestionGroupOrderSequenceInput[];
}

export interface QuestionGroupOrderSequenceInput {
    questionGroupId: string;
    orderSequence: number;
}

export interface FolderChapterCreateInput {
    questionBankId: string;
    name: string;
    description?: Nullable<string>;
    orderSequence?: Nullable<number>;
    icon?: Nullable<PhotoAttachmentCreateInput>;
    folderId?: Nullable<string>;
}

export interface ChapterUpdateInput {
    chapterId: string;
    name?: Nullable<string>;
    description?: Nullable<string>;
    isClearDescription?: Nullable<boolean>;
    icon?: Nullable<PhotoAttachmentCreateInput>;
    isClearIcon?: Nullable<boolean>;
    isEnable?: Nullable<boolean>;
    isFlagged?: Nullable<boolean>;
}

export interface ChaptersRearrangeInput {
    chapterOrderSequences: ChapterOrderSequenceInput[];
}

export interface ChapterOrderSequenceInput {
    chapterId: string;
    orderSequence: number;
}

export interface QBChapterMoveToFolderInput {
    fromFolderId?: Nullable<string>;
    chapterId: string;
    toFolderId: string;
}

export interface ConceptCardCreateInput {
    chapterId: string;
    name: string;
    orderSequence?: Nullable<number>;
    photo?: Nullable<PhotoAttachmentCreateInput>;
    tagIds?: Nullable<string[]>;
}

export interface ConceptCardUpdateInput {
    conceptCardId: string;
    name?: Nullable<string>;
    photo?: Nullable<PhotoAttachmentCreateInput>;
    isClearPhoto?: Nullable<boolean>;
    tagIds?: Nullable<string[]>;
}

export interface ConceptCardsRearrangeInput {
    conceptCardOrderSequences: ConceptCardOrderSequenceInput[];
}

export interface ConceptCardOrderSequenceInput {
    conceptCardId: string;
    orderSequence: number;
}

export interface QuestionCreateInput {
    level: number;
    questionBankId: string;
    questionGroupId?: Nullable<string>;
    questionTypeId: string;
    chapterId: string;
    isFlaged?: Nullable<boolean>;
    inputType: QuestionInputTypes;
    contentMC?: Nullable<MCContentCreateInput>;
    contentShortQuestion?: Nullable<ShortQuestionContentCreateInput>;
    contentLongQuestion?: Nullable<LongQuestionContentCreateInput>;
    contentGraph?: Nullable<GraphContentCreateInput>;
    tagIds?: Nullable<string[]>;
    conceptCardIds?: Nullable<string[]>;
    photoAttachments?: Nullable<PhotoAttachmentCreateInput[]>;
}

export interface MCContentCreateInput {
    body: string;
    answers: MCAnswerCreateInput[];
    solution: string;
}

export interface MCAnswerCreateInput {
    body: string;
    isCorrect: boolean;
}

export interface ShortQuestionContentCreateInput {
    body: string;
    answer: string;
    solution: string;
}

export interface LongQuestionContentCreateInput {
    body: string;
    answer: string;
    solution: string;
}

export interface GraphContentCreateInput {
    body: string;
    answer: string;
    solution: string;
}

export interface QuestionUpdateInput {
    questionId: string;
    level?: Nullable<number>;
    questionTypeId?: Nullable<string>;
    questionGroupId?: Nullable<string>;
    chapterId?: Nullable<string>;
    isFlaged?: Nullable<boolean>;
    contentMC?: Nullable<MCContentCreateInput>;
    contentShortQuestion?: Nullable<ShortQuestionContentCreateInput>;
    contentLongQuestion?: Nullable<LongQuestionContentCreateInput>;
    contentGraph?: Nullable<GraphContentCreateInput>;
    tagIds?: Nullable<string[]>;
    conceptCardIds?: Nullable<string[]>;
    photoAttachments?: Nullable<PhotoAttachmentCreateInput[]>;
}

export interface TagCreateInput {
    questionBankId: string;
    name: string;
}

export interface TagPerformanceUpsertBulkInput {
    userTagPerformances: TagPerformanceCreateInput[];
}

export interface TagPerformanceCreateInput {
    userId: string;
    tagId: string;
    performance: number;
}

export interface TagDifficultyUpdateBulkInput {
    tagDifficulties: TagDifficultyUpdateInput[];
}

export interface TagDifficultyUpdateInput {
    tagId: string;
    difficulty: number;
}

export interface QuestionGroupCreateInput {
    name: string;
    questionTypeId: string;
    orderSequence?: Nullable<number>;
}

export interface QuestionGroupUpdateInput {
    questionGroupId: string;
    name?: Nullable<string>;
}

export interface QuestionGroupSetDefaultQuestionInput {
    questionGroupId: string;
    defaultQuestionId: string;
    isClearDefaultQuestion?: Nullable<boolean>;
}

export interface QuestionTypeCreateInput {
    name: string;
    chapterId: string;
    questionIds?: Nullable<string[]>;
    orderSequence?: Nullable<number>;
}

export interface QuestionTypeUpdateInput {
    questionTypeId: string;
    name: string;
}

export interface CMCourseAdminAddEmailsInput {
    emails?: Nullable<string[]>;
    courseId: string;
}

export interface CourseCreateInput {
    name: string;
    questionBankId: string;
    ownerId?: Nullable<string>;
}

export interface CourseInviteStudentInput {
    inviteeUserEmail: string;
    courseId: string;
}

export interface CourseInviteeAcceptInvitationInput {
    courseMemberId: string;
    isAccept: boolean;
}

export interface HomeworkCreateInput {
    courseId: string;
    subject: Subject;
    name: string;
    studentIds?: Nullable<string[]>;
    inputType: HomeworkInputTypes;
    chapterIds?: Nullable<string[]>;
    questionIds?: Nullable<string[]>;
    timeLimit: number;
    totalSessionQuestions: number;
    offsetQuestions: number;
    totalSessions: number;
    startDate: DateTime;
    endDate: DateTime;
}

export interface HomeworkResponseCreateInput {
    homeworkId: string;
    studentId?: Nullable<string>;
    totalSessions?: Nullable<number>;
}

export interface CMHomeworkResponseSessionRequestNextInput {
    homeworkId: string;
    homeworkResponseId: string;
    difficultyInputTypes: DifficultyInputTypes;
}

export interface CMHomeworkResponseSessionCompletedInput {
    homeworkId: string;
    homeworkResponseId: string;
    sessionSequence: number;
    submitAnswers: CMSubmitAnswerInput[];
}

export interface CMSubmitAnswerInput {
    questionId: string;
    studentAnswer: string[];
    timeUsed: number;
    isOpenConceptCard: boolean;
}

export interface CMBookmarkQuestionInput {
    questionId: string;
    courseId: string;
    save: boolean;
}

export interface CMArchiveQuestionHistoryInput {
    questionHistoryId: string;
    archived: boolean;
}

export interface CTMCronTaskCreateInput {
    name: string;
    functionName: string;
    enable?: Nullable<boolean>;
    cronDateTime?: Nullable<CTMDateInputGQL>;
}

export interface CTMCronTaskUpdateInput {
    name: string;
    enable?: Nullable<boolean>;
    cronDateTime?: Nullable<CTMDateInputGQL>;
}

export interface CTMCronTaskDeleteInput {
    name: string;
    scheduleId: string;
}

export interface QuestionBulkUpdateInput {
    questionFilterInput: QuestionFilterInput;
    questionGroupId?: Nullable<string>;
    isResetGroup?: Nullable<boolean>;
}

export interface CronJobRecordCreateInput {
    cronJobId: string;
    functionName: string;
    startTime: DateTime;
    endTime?: Nullable<DateTime>;
    isCompleted?: Nullable<boolean>;
}

export interface CronJobRecordUpdateInput {
    cronJobRecordID: string;
    cronJobId: string;
    functionName: string;
    startTime: DateTime;
    endTime?: Nullable<DateTime>;
    isCompleted?: Nullable<boolean>;
}

export interface CronjobScheduleCreateInput {
    name: string;
    functionName: string;
    NextTaskStartTime?: Nullable<DateTime>;
    cronDateTime?: Nullable<CTMDateInputGQL>;
    enable?: Nullable<boolean>;
}

export interface CronjobScheduleUpdateInput {
    cronjobScheduleID: string;
    name?: Nullable<string>;
    functionName?: Nullable<string>;
    cronDateTime?: Nullable<CTMDateInputGQL>;
    NextTaskStartTime?: Nullable<DateTime>;
    enable?: Nullable<boolean>;
}

export interface StudentCronJobTypeScoreCreateInput {
    studentId: string;
    typeId: string;
    score?: Nullable<number>;
    totalDoneNum?: Nullable<number>;
    correctNum?: Nullable<number>;
    correctPercentage?: Nullable<number>;
    averageTimeSpent?: Nullable<number>;
    totalTimeSpent?: Nullable<number>;
}

export interface StudentCronJobTypeScoreUpdateInput {
    studentCronJobTypeScoreID: string;
    studentId: string;
    typeId: string;
    score: number;
    totalDoneNum: number;
    correctNum: number;
    correctPercentage: number;
    averageTimeSpent: number;
    totalTimeSpent: number;
}

export interface StudentCronJobGroupScoreCreateInput {
    studentId: string;
    groupId: string;
    score?: Nullable<number>;
    totalDoneNum?: Nullable<number>;
    correctNum?: Nullable<number>;
    correctPercentage?: Nullable<number>;
    averageTimeSpent?: Nullable<number>;
    totalTimeSpent?: Nullable<number>;
}

export interface StudentCronJobGroupScoreUpdateInput {
    studentCronJobGroupScoreID: string;
    studentId: string;
    groupId: string;
    score: number;
    totalDoneNum: number;
    correctNum: number;
    correctPercentage: number;
    averageTimeSpent: number;
    totalTimeSpent: number;
}

export interface QuestionContentGQLType {
    inputType: QuestionInputTypes;
}

export interface ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
}

export interface AttachmentGQLType {
    attachmentFileType: AttachmentTypes;
}

export interface Node {
    id: string;
    createAt?: Nullable<DateTime>;
    updateAt?: Nullable<DateTime>;
}

export interface PaginationOutput {
    count: number;
    page: number;
    totalPages: number;
    offset: number;
    limit: number;
}

export interface DefaultResponseFormatOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
}

export interface PhotoAttachment extends AttachmentGQLType {
    attachmentFileType: AttachmentTypes;
    size?: Nullable<number>;
    fileURL: string;
    fileName: string;
    filePath?: Nullable<string>;
    contentType?: Nullable<string>;
    originalFileName: string;
}

export interface UserProfile {
    userId?: Nullable<string>;
    nickname?: Nullable<string>;
    profilePic?: Nullable<PhotoAttachment>;
    darkMode: boolean;
    courseIdList?: Nullable<string[]>;
    language?: Nullable<Locale>;
    emailVerified: boolean;
    createAt?: Nullable<DateTime>;
    updateAt?: Nullable<DateTime>;
}

export interface UserProfileOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<UserProfile>;
}

export interface UserProfileArrayOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<UserProfile[]>;
}

export interface AuthFirebaseLoginOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<string>;
}

export interface User {
    id: string;
    email: string;
    firebaseUID: string;
    roles: UserRole[];
    userProfile?: Nullable<UserProfile>;
    mfaSecret?: Nullable<string>;
    createAt?: Nullable<DateTime>;
    updateAt?: Nullable<DateTime>;
}

export interface AuthFirebaseRegisterOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<User>;
}

export interface UserSafe {
    id: string;
    email: string;
    firebaseUID: string;
    roles: UserRole[];
    status: UserStatus;
    userProfile?: Nullable<UserProfile>;
    createAt: DateTime;
    updateAt: DateTime;
}

export interface EventToken {
    id: string;
    token: string;
    tokenOwnerId: string;
    event: EventTokenTypes;
    status: EventTokenStatus;
    createAt: DateTime;
    updateAt: DateTime;
    expireAt?: Nullable<DateTime>;
}

export interface EventTokenOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<EventToken>;
}

export interface EventTokenArrayOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<EventToken[]>;
}

export interface AuthTokenRefreshOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<string>;
}

export interface UserSafeOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<UserSafe>;
}

export interface UserSafeArrayOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<UserSafe[]>;
}

export interface Tag extends Node {
    id: string;
    createAt?: Nullable<DateTime>;
    updateAt?: Nullable<DateTime>;
    name: string;
    subject: Subject;
    difficulty: number;
    questionBankId?: Nullable<string>;
}

export interface TagPerformance {
    userId: string;
    user: User;
    tagId: string;
    tag: Tag;
    performance: number;
    createAt?: Nullable<DateTime>;
    updateAt?: Nullable<DateTime>;
}

export interface CourseMember extends Node {
    id: string;
    createAt?: Nullable<DateTime>;
    updateAt?: Nullable<DateTime>;
    userEmail: string;
    userId?: Nullable<string>;
    userProfile?: Nullable<UserProfile>;
    tagsPerformance: TagPerformance[];
    courseId: string;
    course?: Nullable<Course>;
    status: CourseMemberStatus;
    role: CourseMemberRole;
}

export interface QuestionGroup {
    id: string;
    chapterId: string;
    chapter: Chapter;
    name: string;
    questionTypeId: string;
    orderSequence: number;
    defaultQuestionId?: Nullable<string>;
    defaultQuestion?: Nullable<Question>;
    questions: Question[];
    questionBankId?: Nullable<string>;
    createAt?: Nullable<DateTime>;
    updateAt?: Nullable<DateTime>;
}

export interface QuestionType {
    id: string;
    chapterId: string;
    chapter: Chapter;
    name: string;
    orderSequence: number;
    questionGroups: QuestionGroup[];
    questions: Question[];
    questionIds: string[];
    questionBankId?: Nullable<string>;
    createAt?: Nullable<DateTime>;
    updateAt?: Nullable<DateTime>;
}

export interface Chapter extends Node {
    id: string;
    createAt?: Nullable<DateTime>;
    updateAt?: Nullable<DateTime>;
    name: string;
    description?: Nullable<string>;
    isEnable: boolean;
    isFlagged: boolean;
    subject: Subject;
    questionGroups: QuestionGroup[];
    questionTypes: QuestionType[];
    questions: Question[];
    conceptCards: ConceptCard[];
    questionBankId: string;
    orderSequence: number;
    icon?: Nullable<PhotoAttachment>;
}

export interface Folder extends Node {
    id: string;
    createAt?: Nullable<DateTime>;
    updateAt?: Nullable<DateTime>;
    questionBankId: string;
    chapters: Chapter[];
    name: string;
    icon?: Nullable<PhotoAttachment>;
    subject: Subject;
    isEnable: boolean;
    isFlagged: boolean;
    orderSequence: number;
}

export interface QuestionBank extends Node {
    id: string;
    createAt?: Nullable<DateTime>;
    updateAt?: Nullable<DateTime>;
    name: string;
    subject: Subject;
    examSystem: ExamSystem;
    folders: Folder[];
    chapters: Chapter[];
    conceptCards: ConceptCard[];
    tags: Tag[];
}

export interface ConceptCard extends Node {
    id: string;
    createAt?: Nullable<DateTime>;
    updateAt?: Nullable<DateTime>;
    name: string;
    chapter?: Nullable<Chapter>;
    chapterId: string;
    questionBank: QuestionBank;
    questionBankId: string;
    subject: Subject;
    questions: Question[];
    orderSequence: number;
    photo?: Nullable<PhotoAttachment>;
    tags: Tag[];
    tagIds: string[];
}

export interface Question extends Node {
    id: string;
    createAt?: Nullable<DateTime>;
    updateAt?: Nullable<DateTime>;
    level: number;
    questionTypeId?: Nullable<string>;
    questionType?: Nullable<QuestionType>;
    questionGroupId?: Nullable<string>;
    questionGroup?: Nullable<QuestionGroup>;
    questionBankId: string;
    chapter?: Nullable<Chapter>;
    chapterId?: Nullable<string>;
    isFlaged: boolean;
    inputType: QuestionInputTypes;
    content: QuestionContentGQLType;
    tags: Tag[];
    tagIds: string[];
    conceptCards: ConceptCard[];
    conceptCardIds: string[];
    photoAttachments: PhotoAttachment[];
}

export interface MCContent extends QuestionContentGQLType {
    inputType: QuestionInputTypes;
    body: string;
    answers: MCAnswer[];
    correctAnswerIds: string[];
    solution: string;
}

export interface MCAnswer {
    id: string;
    body: string;
}

export interface ShortQuestionContent extends QuestionContentGQLType {
    inputType: QuestionInputTypes;
    body: string;
    answer: string;
    solution: string;
}

export interface LongQuestionContent extends QuestionContentGQLType {
    inputType: QuestionInputTypes;
    body: string;
    answer: string;
    solution: string;
}

export interface GraphContent extends QuestionContentGQLType {
    inputType: QuestionInputTypes;
    body: string;
    answer: string;
    solution: string;
}

export interface QuestionResponse {
    questionSequence: number;
    questionId: string;
    question: Question;
    studentAnswer: string;
    isCorrect: boolean;
    isFinished: boolean;
    timeUsed: number;
}

export interface SessionResponse {
    sessionSequence: number;
    questions: Question[];
    difficultyInputType: DifficultyInputTypes;
    questionResponses: QuestionResponse[];
    isFinished: boolean;
    timeUsed: number;
}

export interface HomeworkResponse extends Node {
    id: string;
    createAt?: Nullable<DateTime>;
    updateAt?: Nullable<DateTime>;
    courseId?: Nullable<string>;
    homeworkId: string;
    studentId: string;
    student: CourseMember;
    sessionResponses: SessionResponse[];
    currentActiveSessionSequence: number;
    totalSessions: number;
    isFinished: boolean;
}

export interface Homework extends Node {
    id: string;
    createAt?: Nullable<DateTime>;
    updateAt?: Nullable<DateTime>;
    courseId: string;
    subject: Subject;
    name: string;
    selectedStudents: User[];
    studentIds: string[];
    inputType: HomeworkInputTypes;
    selectedChapters: Chapter[];
    selectedQuestions: Question[];
    timeLimit: number;
    totalSessionQuestions: number;
    offsetQuestions: number;
    totalSessions: number;
    startDate: DateTime;
    endDate: DateTime;
    homeworkResponses: HomeworkResponse[];
    homeworkStatistic: HomeworkStatistic;
}

export interface Course extends Node {
    id: string;
    createAt?: Nullable<DateTime>;
    updateAt?: Nullable<DateTime>;
    name: string;
    subject: Subject;
    questionBankId: string;
    questionBank: QuestionBank;
    students: CourseMember[];
    teachers: CourseMember[];
    homeworks: Homework[];
}

export interface CourseOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<Course>;
}

export interface CourseArrayOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<Course[]>;
}

export interface HomeworkResponseOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<HomeworkResponse>;
}

export interface HomeworkResponseArrayOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<HomeworkResponse[]>;
}

export interface HomeworkOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<Homework>;
}

export interface HomeworkArrayOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<Homework[]>;
}

export interface CMCourseInviteStudentOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<CourseMember>;
}

export interface CMCourseInviteeAcceptInvitationOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<CourseMember>;
}

export interface CourseMemberOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<CourseMember>;
}

export interface CourseMemberArrayOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<CourseMember[]>;
}

export interface QuestionHistory {
    id: string;
    chapterId: string;
    homeworkId: string;
    studentId: string;
    typeId: string;
    courseId: string;
    groupId: string;
    homeworkResponseId: string;
    question: Question;
    sessionSequence: number;
    questionId: string;
    studentAnswer: string;
    timeUsed: number;
    isCorrect: boolean;
    archived: boolean;
    createAt?: Nullable<DateTime>;
    updateAt?: Nullable<DateTime>;
}

export interface QuestionHistoryOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<QuestionHistory>;
}

export interface QuestionHistoryArrayOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<QuestionHistory[]>;
}

export interface BookmarkQuestion {
    id: string;
    studentId: string;
    courseId: string;
    chapterId: string;
    questionId: string;
    question: Question;
    createAt?: Nullable<DateTime>;
    updateAt?: Nullable<DateTime>;
}

export interface BookmarkQuestionOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<BookmarkQuestion>;
}

export interface BookmarkQuestionArrayOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<BookmarkQuestion[]>;
}

export interface HomeworkWithResponse extends Node {
    id: string;
    createAt?: Nullable<DateTime>;
    updateAt?: Nullable<DateTime>;
    homework: Homework;
    homeworkResponse?: Nullable<HomeworkResponse>;
}

export interface HomeworkWithResponseArrayOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<HomeworkWithResponse[]>;
}

export interface QuestionBankOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<QuestionBank>;
}

export interface QuestionBankArrayOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<QuestionBank[]>;
}

export interface FolderOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<Folder>;
}

export interface FolderArrayOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<Folder[]>;
}

export interface ChapterOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<Chapter>;
}

export interface ChapterArrayOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<Chapter[]>;
}

export interface ConceptCardOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<ConceptCard>;
}

export interface ConceptCardArrayOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<ConceptCard[]>;
}

export interface QuestionOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<Question>;
}

export interface QuestionArrayOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<Question[]>;
}

export interface TagOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<Tag>;
}

export interface TagArrayOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<Tag[]>;
}

export interface TagPerformanceArrayOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<TagPerformance[]>;
}

export interface QuestionGroupOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<QuestionGroup>;
}

export interface QuestionGroupArrayOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<QuestionGroup[]>;
}

export interface QuestionTypeOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<QuestionType>;
}

export interface QuestionTypeArrayOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<QuestionType[]>;
}

export interface CTMDateInput {
    weekofday?: Nullable<DayOfWeekEnum>;
    month?: Nullable<number>;
    day?: Nullable<number>;
    hour?: Nullable<number>;
    minute?: Nullable<number>;
    second?: Nullable<number>;
}

export interface CronjobSchedule {
    id: string;
    name: string;
    functionName: string;
    NextTaskStartTime?: Nullable<DateTime>;
    cronDateTime?: Nullable<CTMDateInput>;
    enable: boolean;
    updateAt: DateTime;
    createAt: DateTime;
}

export interface CronjobScheduleOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<CronjobSchedule>;
}

export interface CronjobScheduleArrayOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<CronjobSchedule[]>;
}

export interface HomeworkStatistic {
    completed: number;
    total: number;
}

export interface CronJobRecord {
    id: string;
    cronJobId: string;
    functionName: string;
    startTime: DateTime;
    endTime?: Nullable<DateTime>;
    isCompleted: boolean;
    updateAt: DateTime;
    createAt: DateTime;
}

export interface CronJobRecordOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<CronJobRecord>;
}

export interface CronJobRecordArrayOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<CronJobRecord[]>;
}

export interface StudentCronJobGroupScore {
    id: string;
    studentId: string;
    groupId: string;
    score: number;
    totalDoneNum: number;
    correctNum: number;
    correctPercentage: number;
    averageTimeSpent: number;
    totalTimeSpent: number;
    updateAt: DateTime;
    createAt: DateTime;
}

export interface StudentCronJobGroupScoreOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<StudentCronJobGroupScore>;
}

export interface StudentCronJobGroupScoreArrayOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<StudentCronJobGroupScore[]>;
}

export interface StudentCronJobTypeScore {
    id: string;
    studentId: string;
    typeId: string;
    score: number;
    totalDoneNum: number;
    correctNum: number;
    correctPercentage: number;
    averageTimeSpent: number;
    totalTimeSpent: number;
    updateAt: DateTime;
    createAt: DateTime;
}

export interface StudentCronJobTypeScoreOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<StudentCronJobTypeScore>;
}

export interface StudentCronJobTypeScoreArrayOutput extends ResponseFormat {
    isSuccess: boolean;
    code: number;
    errorMessage?: Nullable<string>;
    pagination?: Nullable<PaginationOutput>;
    data?: Nullable<StudentCronJobTypeScore[]>;
}

export interface IQuery {
    me(): UserSafe | Promise<UserSafe>;
    userGet(userFilterInput: UserFilterInput): Nullable<UserSafeOutput> | Promise<Nullable<UserSafeOutput>>;
    usersGet(userArrayFilterInput?: Nullable<UserArrayFilterInput>): Nullable<UserSafeArrayOutput> | Promise<Nullable<UserSafeArrayOutput>>;
    userProfilesGet(userProfileFilterInput?: Nullable<UserProfileFilterInput>): UserProfileArrayOutput | Promise<UserProfileArrayOutput>;
    userProfileGet(userId: string): Nullable<UserProfileOutput> | Promise<Nullable<UserProfileOutput>>;
    eventTokensGet(eventTokenArrayFilterInput?: Nullable<EventTokenArrayFilterInput>): EventTokenArrayOutput | Promise<EventTokenArrayOutput>;
    eventTokenGet(id: string): Nullable<EventTokenOutput> | Promise<Nullable<EventTokenOutput>>;
    umUserGets(userArrayFilterInput?: Nullable<UserArrayFilterInput>): UserSafeArrayOutput | Promise<UserSafeArrayOutput>;
    myCourses(courseMemberFilterInput?: Nullable<CourseMemberFilterInput>): CourseMemberArrayOutput | Promise<CourseMemberArrayOutput>;
    myHomeworks(cmMyHomeworksFilterInput?: Nullable<CMMyHomeworksFilterInput>): HomeworkWithResponseArrayOutput | Promise<HomeworkWithResponseArrayOutput>;
    cmMyCourseDetail(courseId: string): CourseOutput | Promise<CourseOutput>;
    cmMyCourseHomeworkDetail(homeworkId: string, courseId: string): HomeworkOutput | Promise<HomeworkOutput>;
    cmMyCoursesHomeworksDetail(cmCoursesHomeworksFilterInput: CMCoursesHomeworksFilterInput): HomeworkArrayOutput | Promise<HomeworkArrayOutput>;
    cmMyCourseHomeworkResponseDetail(cmCourseHomeworkResponsesDetailInput: CMCourseHomeworkResponsesDetailInput): HomeworkResponseArrayOutput | Promise<HomeworkResponseArrayOutput>;
    cmMyBookmarkQuestions(bookmarkQuestionFilterInput?: Nullable<BookmarkQuestionFilterInput>): Nullable<BookmarkQuestionArrayOutput> | Promise<Nullable<BookmarkQuestionArrayOutput>>;
    cmMyHomeworkResponses(homeworkResponseFilterInput?: Nullable<HomeworkResponseFilterInput>): HomeworkResponseArrayOutput | Promise<HomeworkResponseArrayOutput>;
    cmMyQuestionHistorys(questionHistoryFilterInput?: Nullable<QuestionHistoryFilterInput>): QuestionHistoryArrayOutput | Promise<QuestionHistoryArrayOutput>;
    bookmarkQuestionGet(id: string): Nullable<BookmarkQuestionOutput> | Promise<Nullable<BookmarkQuestionOutput>>;
    bookmarkQuestionsGet(bookmarkQuestionFilterInput?: Nullable<BookmarkQuestionFilterInput>): Nullable<BookmarkQuestionArrayOutput> | Promise<Nullable<BookmarkQuestionArrayOutput>>;
    courseMembersGet(courseMemberFilterInput?: Nullable<CourseMemberFilterInput>): CourseMemberArrayOutput | Promise<CourseMemberArrayOutput>;
    courseMemberGet(id: string): Nullable<CourseMemberOutput> | Promise<Nullable<CourseMemberOutput>>;
    coursesGet(courseFilterInput?: Nullable<CourseFilterInput>): CourseArrayOutput | Promise<CourseArrayOutput>;
    courseGet(id: string): Nullable<CourseOutput> | Promise<Nullable<CourseOutput>>;
    homeworkResponsesGet(homeworkResponseFilterInput?: Nullable<HomeworkResponseFilterInput>): HomeworkResponseArrayOutput | Promise<HomeworkResponseArrayOutput>;
    homeworkResponseGet(id: string): Nullable<HomeworkResponseOutput> | Promise<Nullable<HomeworkResponseOutput>>;
    homeworksGet(homeworkFilterInput?: Nullable<HomeworkFilterInput>): HomeworkArrayOutput | Promise<HomeworkArrayOutput>;
    homeworkGet(id: string): Nullable<HomeworkOutput> | Promise<Nullable<HomeworkOutput>>;
    questionHistoryGet(id: string): Nullable<QuestionHistoryOutput> | Promise<Nullable<QuestionHistoryOutput>>;
    questionHistorysGet(questionHistoryFilterInput?: Nullable<QuestionHistoryFilterInput>): Nullable<QuestionHistoryArrayOutput> | Promise<Nullable<QuestionHistoryArrayOutput>>;
    tagPerformancesGet(): TagPerformanceArrayOutput | Promise<TagPerformanceArrayOutput>;
    tagPerformancesByUserId(userId: string): Nullable<TagPerformanceArrayOutput> | Promise<Nullable<TagPerformanceArrayOutput>>;
    chaptersGet(chapterFilterInput?: Nullable<ChapterFilterInput>): ChapterArrayOutput | Promise<ChapterArrayOutput>;
    chapterGet(id: string): Nullable<ChapterOutput> | Promise<Nullable<ChapterOutput>>;
    conceptCardsGet(conceptCardFilterInput?: Nullable<ConceptCardFilterInput>): ConceptCardArrayOutput | Promise<ConceptCardArrayOutput>;
    conceptCardGet(id: string): Nullable<ConceptCardOutput> | Promise<Nullable<ConceptCardOutput>>;
    foldersGet(folderFilterInput?: Nullable<FolderFilterInput>): FolderArrayOutput | Promise<FolderArrayOutput>;
    folderGet(id: string): Nullable<FolderOutput> | Promise<Nullable<FolderOutput>>;
    questionBanksGet(questionBankFilterInput?: Nullable<QuestionBankFilterInput>): QuestionBankArrayOutput | Promise<QuestionBankArrayOutput>;
    questionBankGet(id: string): Nullable<QuestionBankOutput> | Promise<Nullable<QuestionBankOutput>>;
    questionGroupsGet(questionGroupFilterInput?: Nullable<QuestionGroupFilterInput>): QuestionGroupArrayOutput | Promise<QuestionGroupArrayOutput>;
    questionGroupGet(id: string): Nullable<QuestionGroupOutput> | Promise<Nullable<QuestionGroupOutput>>;
    questionTypesGet(questionTypeFilterInput?: Nullable<QuestionTypeFilterInput>): QuestionTypeArrayOutput | Promise<QuestionTypeArrayOutput>;
    questionTypeGet(id: string): Nullable<QuestionTypeOutput> | Promise<Nullable<QuestionTypeOutput>>;
    questionsGet(questionFilterInput?: Nullable<QuestionFilterInput>): QuestionArrayOutput | Promise<QuestionArrayOutput>;
    questionGet(id: string): Nullable<QuestionOutput> | Promise<Nullable<QuestionOutput>>;
    tagsGet(tagFilterInput?: Nullable<TagFilterInput>): TagArrayOutput | Promise<TagArrayOutput>;
    tagGet(id: string): Nullable<TagOutput> | Promise<Nullable<TagOutput>>;
    cronJobRecordGet(id: string): Nullable<CronJobRecordOutput> | Promise<Nullable<CronJobRecordOutput>>;
    cronJobRecordsGet(cronJobRecordFilterInput?: Nullable<CronJobRecordFilterInput>): Nullable<CronJobRecordArrayOutput> | Promise<Nullable<CronJobRecordArrayOutput>>;
    cronjobScheduleGet(id: string): Nullable<CronjobScheduleOutput> | Promise<Nullable<CronjobScheduleOutput>>;
    cronjobSchedulesGet(cronjobScheduleFilterInput?: Nullable<CronjobScheduleFilterInput>): Nullable<CronjobScheduleArrayOutput> | Promise<Nullable<CronjobScheduleArrayOutput>>;
    studentCronJobTypeScoreGet(id: string): Nullable<StudentCronJobTypeScoreOutput> | Promise<Nullable<StudentCronJobTypeScoreOutput>>;
    studentCronJobTypeScoresGet(studentCronJobTypeScoreFilterInput?: Nullable<StudentCronJobTypeScoreFilterInput>): Nullable<StudentCronJobTypeScoreArrayOutput> | Promise<Nullable<StudentCronJobTypeScoreArrayOutput>>;
    studentCronJobGroupScoreGet(id: string): Nullable<StudentCronJobGroupScoreOutput> | Promise<Nullable<StudentCronJobGroupScoreOutput>>;
    studentCronJobGroupScoresGet(studentCronJobGroupScoreFilterInput?: Nullable<StudentCronJobGroupScoreFilterInput>): Nullable<StudentCronJobGroupScoreArrayOutput> | Promise<Nullable<StudentCronJobGroupScoreArrayOutput>>;
}

export interface IMutation {
    firebaseUserRegister(firebaseUserRegisterInput: FirebaseUserRegisterInput): AuthFirebaseRegisterOutput | Promise<AuthFirebaseRegisterOutput>;
    firebaseUserLogin(firebaseUserLoginInput: FirebaseUserLoginInput): AuthFirebaseLoginOutput | Promise<AuthFirebaseLoginOutput>;
    firebaseUserRemove(): AuthFirebaseRegisterOutput | Promise<AuthFirebaseRegisterOutput>;
    tokenRefresh(): AuthTokenRefreshOutput | Promise<AuthTokenRefreshOutput>;
    devUserLogin(email: string): AuthFirebaseLoginOutput | Promise<AuthFirebaseLoginOutput>;
    mfaOTPCreate(mfaOTPCreateInput: MFAOTPCreateInput): EventTokenOutput | Promise<EventTokenOutput>;
    mfaOTPVerify(otpToken: string, otpId: string): DefaultResponseFormatOutput | Promise<DefaultResponseFormatOutput>;
    mfaOTPVerifyInHeader(): DefaultResponseFormatOutput | Promise<DefaultResponseFormatOutput>;
    umFirebaseEmailPasswordUserCreate(umFirebaseEmailPasswordUserCreateInput: UMFirebaseEmailPasswordUserCreateInput): UserSafeOutput | Promise<UserSafeOutput>;
    umUserProfileUpdate(umUserProfileUpdateInput: UserProfileUpdateInput): UserProfileOutput | Promise<UserProfileOutput>;
    umUserDeleteById(userId: string): UserSafeOutput | Promise<UserSafeOutput>;
    userProfileUpdateByUser(userProfileUpdateInput: UserProfileUpdateInput): UserProfileOutput | Promise<UserProfileOutput>;
    qbQuestionBankCreate(questionBankCreateInput: QuestionBankCreateInput): QuestionBankOutput | Promise<QuestionBankOutput>;
    qbFolderCreate(folderCreateInput: FolderCreateInput): FolderOutput | Promise<FolderOutput>;
    qbFolderUpdateById(folderUpdateInput: FolderUpdateInput): FolderOutput | Promise<FolderOutput>;
    qbFolderAddOrRemoveChapterById(folderAddOrRemoveChapterInput: FolderAddOrRemoveChapterInput): FolderOutput | Promise<FolderOutput>;
    qbFolderDeleteById(folderId: string): FolderOutput | Promise<FolderOutput>;
    qbFoldersRearrangeByIds(foldersRearrangeInput: FoldersRearrangeInput): FolderArrayOutput | Promise<FolderArrayOutput>;
    qbQuestionTypesRearrangeByIds(questionTypesRearrangeInput: QuestionTypesRearrangeInput): QuestionTypeArrayOutput | Promise<QuestionTypeArrayOutput>;
    qbQuestionGroupsRearrangeByIds(questionGroupsRearrangeInput: QuestionGroupsRearrangeInput): QuestionGroupArrayOutput | Promise<QuestionGroupArrayOutput>;
    qbChapterCreate(folderChapterCreateInput: FolderChapterCreateInput): ChapterOutput | Promise<ChapterOutput>;
    qbChapterUpdateById(chapterUpdateInput: ChapterUpdateInput): ChapterOutput | Promise<ChapterOutput>;
    qbChapterDeleteById(chapterId: string): ChapterOutput | Promise<ChapterOutput>;
    qbChaptersRearrangeByIds(chaptersRearrangeInput: ChaptersRearrangeInput): ChapterArrayOutput | Promise<ChapterArrayOutput>;
    qbChapterMoveToFolderById(qbChapterMoveToFolderInput: QBChapterMoveToFolderInput): FolderOutput | Promise<FolderOutput>;
    qbConceptCardCreate(conceptCardCreateInput: ConceptCardCreateInput): ConceptCardOutput | Promise<ConceptCardOutput>;
    qbConceptCardUpdateById(conceptCardUpdateInput: ConceptCardUpdateInput): ConceptCardOutput | Promise<ConceptCardOutput>;
    qbConceptCardDeleteById(conceptCardId: string): ConceptCardOutput | Promise<ConceptCardOutput>;
    qbConceptCardsRearrangeByIds(conceptCardsRearrangeInput: ConceptCardsRearrangeInput): ConceptCardArrayOutput | Promise<ConceptCardArrayOutput>;
    qbQuestionCreate(questionCreateInput: QuestionCreateInput): QuestionOutput | Promise<QuestionOutput>;
    qbQuestionUpdateById(questionUpdateInput: QuestionUpdateInput): QuestionOutput | Promise<QuestionOutput>;
    qbQuestionDeleteById(questionId: string): QuestionOutput | Promise<QuestionOutput>;
    qbTagCreate(tagCreateInput: TagCreateInput): TagOutput | Promise<TagOutput>;
    qbTagPerformanceUpsertBulk(tagPerformanceUpsertBulkInput: TagPerformanceUpsertBulkInput): TagPerformanceArrayOutput | Promise<TagPerformanceArrayOutput>;
    qbTagDifficultyUpdateBulk(tagDifficultyUpdateBulkInput: TagDifficultyUpdateBulkInput): TagArrayOutput | Promise<TagArrayOutput>;
    qbQuestionGroupCreate(questionGroupCreateInput: QuestionGroupCreateInput): QuestionGroupOutput | Promise<QuestionGroupOutput>;
    qbQuestionGroupUpdateById(questionGroupUpdateInput: QuestionGroupUpdateInput): QuestionGroupOutput | Promise<QuestionGroupOutput>;
    qbQuestionGroupSetDefaultQuestionById(questionGroupSetDefaultQuestionInput: QuestionGroupSetDefaultQuestionInput): QuestionGroupOutput | Promise<QuestionGroupOutput>;
    qbQuestionGroupDeleteById(questionGroupId: string): QuestionGroupOutput | Promise<QuestionGroupOutput>;
    qbQuestionTypeCreate(questionTypeCreateInput: QuestionTypeCreateInput): QuestionTypeOutput | Promise<QuestionTypeOutput>;
    qbQuestionTypeUpdateById(questionTypeUpdateInput: QuestionTypeUpdateInput): QuestionTypeOutput | Promise<QuestionTypeOutput>;
    qbQuestionTypeDeleteById(questionTypeId: string): QuestionTypeOutput | Promise<QuestionTypeOutput>;
    cmAddEmailsToCourse(cmCourseAdminAddEmailsInput: CMCourseAdminAddEmailsInput): CourseMemberArrayOutput | Promise<CourseMemberArrayOutput>;
    cmCourseCreate(courseCreateInput: CourseCreateInput): CourseOutput | Promise<CourseOutput>;
    cmCourseInviteStudent(courseInviteStudentInput: CourseInviteStudentInput): CMCourseInviteStudentOutput | Promise<CMCourseInviteStudentOutput>;
    cmCourseInviteeAcceptInvitation(courseInviteeAcceptInvitationInput: CourseInviteeAcceptInvitationInput): CMCourseInviteeAcceptInvitationOutput | Promise<CMCourseInviteeAcceptInvitationOutput>;
    cmHomeworkCreate(homeworkCreateInput: HomeworkCreateInput): HomeworkOutput | Promise<HomeworkOutput>;
    cmHomeworkResponseCreate(homeworkResponseCreateInput: HomeworkResponseCreateInput): HomeworkResponseOutput | Promise<HomeworkResponseOutput>;
    cmHomeworkResponseSessionRequestNext(CMHomeworkResponseSessionRequestNextInput: CMHomeworkResponseSessionRequestNextInput): HomeworkResponseOutput | Promise<HomeworkResponseOutput>;
    cmHomeworkResponseSessionCompleted(CMHomeworkResponseSessionCompletedInput: CMHomeworkResponseSessionCompletedInput): HomeworkResponseOutput | Promise<HomeworkResponseOutput>;
    cmHomeworkResponseSessionAllCompleted(CMHomeworkResponseSessionAllCompletedInput: CMHomeworkResponseSessionCompletedInput): HomeworkResponseOutput | Promise<HomeworkResponseOutput>;
    cmBookMarkQuestionByQuestionId(CMBookmarkQuestionInput: CMBookmarkQuestionInput): BookmarkQuestionOutput | Promise<BookmarkQuestionOutput>;
    cmArchiveQuestionHistoryById(CMArchiveQuestionHistoryInput: CMArchiveQuestionHistoryInput): QuestionHistoryOutput | Promise<QuestionHistoryOutput>;
    ctmCronTaskCreate(ctmCronTaskCreateInput: CTMCronTaskCreateInput): Nullable<CronjobScheduleOutput> | Promise<Nullable<CronjobScheduleOutput>>;
    ctmCronTaskUpdate(ctmCronTaskUpdateInput: CTMCronTaskUpdateInput): Nullable<CronjobScheduleOutput> | Promise<Nullable<CronjobScheduleOutput>>;
    ctmCronTaskDelete(ctmCronTaskDeleteInput: CTMCronTaskDeleteInput): Nullable<CronjobScheduleOutput> | Promise<Nullable<CronjobScheduleOutput>>;
    setQuestionTypeDefaultOrderSequence(): QuestionTypeArrayOutput | Promise<QuestionTypeArrayOutput>;
    questionBulkUpdate(questionBulkUpdateInput: QuestionBulkUpdateInput): QuestionArrayOutput | Promise<QuestionArrayOutput>;
    cronJobRecordCreate(cronJobRecordCreateInput: CronJobRecordCreateInput): Nullable<CronJobRecordOutput> | Promise<Nullable<CronJobRecordOutput>>;
    cronJobRecordUpdate(cronJobRecordUpdateInput: CronJobRecordUpdateInput): Nullable<CronJobRecordOutput> | Promise<Nullable<CronJobRecordOutput>>;
    cronJobRecordDeleteByID(id: string): Nullable<CronJobRecordOutput> | Promise<Nullable<CronJobRecordOutput>>;
    cronjobScheduleCreate(cronjobScheduleCreateInput: CronjobScheduleCreateInput): Nullable<CronjobScheduleOutput> | Promise<Nullable<CronjobScheduleOutput>>;
    cronjobScheduleUpdate(cronjobScheduleUpdateInput: CronjobScheduleUpdateInput): Nullable<CronjobScheduleOutput> | Promise<Nullable<CronjobScheduleOutput>>;
    cronjobScheduleDeleteByID(id: string): Nullable<CronjobScheduleOutput> | Promise<Nullable<CronjobScheduleOutput>>;
    studentCronJobTypeScoreCreate(studentCronJobTypeScoreCreateInput: StudentCronJobTypeScoreCreateInput): Nullable<StudentCronJobTypeScoreOutput> | Promise<Nullable<StudentCronJobTypeScoreOutput>>;
    studentCronJobTypeScoreUpdate(studentCronJobTypeScoreUpdateInput: StudentCronJobTypeScoreUpdateInput): Nullable<StudentCronJobTypeScoreOutput> | Promise<Nullable<StudentCronJobTypeScoreOutput>>;
    studentCronJobTypeScoreDeleteByID(id: string): Nullable<StudentCronJobTypeScoreOutput> | Promise<Nullable<StudentCronJobTypeScoreOutput>>;
    studentCronJobGroupScoreCreate(studentCronJobGroupScoreCreateInput: StudentCronJobGroupScoreCreateInput): Nullable<StudentCronJobGroupScoreOutput> | Promise<Nullable<StudentCronJobGroupScoreOutput>>;
    studentCronJobGroupScoreUpdate(studentCronJobGroupScoreUpdateInput: StudentCronJobGroupScoreUpdateInput): Nullable<StudentCronJobGroupScoreOutput> | Promise<Nullable<StudentCronJobGroupScoreOutput>>;
    studentCronJobGroupScoreDeleteByID(id: string): Nullable<StudentCronJobGroupScoreOutput> | Promise<Nullable<StudentCronJobGroupScoreOutput>>;
}

export type DateTime = any;
type Nullable<T> = T | null;
