import { gql } from '@apollo/client'

import { PHOTO_DETAIL_FIELDS } from '../fragments/photoDetail.gql'

export const GET_CHAPTERS_BY_FILTER = gql`
    query getChaptersByFilter($chapterFilterInput: ChapterFilterInput) {
        chaptersGet(chapterFilterInput: $chapterFilterInput) {
            isSuccess
            errorMessage
            code
            data {
                id
                name
                questionBankId
                description
                isEnable
                isFlagged
                subject
                orderSequence
                icon {
                    ...photoDetail
                }
                createAt
                updateAt
            }
        }
    }

    ${PHOTO_DETAIL_FIELDS}
`
