import { gql } from '@apollo/client'
import { PHOTO_DETAIL_FIELDS } from '../fragments/photoDetail.gql'

export const QB_CONCEPT_CARD_UPDATE_BY_ID_MUTATION = gql`
    mutation qbConceptCardUpdateById(
        $conceptCardUpdateInput: ConceptCardUpdateInput!
    ) {
        qbConceptCardUpdateById(
            conceptCardUpdateInput: $conceptCardUpdateInput
        ) {
            isSuccess
            code
            errorMessage
            data {
                id
                name
                chapterId
                chapter {
                    id
                    name
                }
                questionBankId
                subject
                orderSequence
                photo {
                    ...photoDetail
                }
                tags {
                    id
                    name
                }
                tagIds
                createAt
            }
        }
    }

    ${PHOTO_DETAIL_FIELDS}
`
