import { OTPValidateInput } from './type'

function ConvertOTPToHeader(otpValidateInput?: OTPValidateInput) {
    if (
        !otpValidateInput ||
        otpValidateInput === undefined ||
        otpValidateInput === null
    ) {
        return {}
    } else
        return {
            otp: `${otpValidateInput.otpId} ${otpValidateInput.otpToken}`,
        }
}

export const useMFAValidateService = () => {
    return {
        ConvertOTPToHeader,
    }
}
