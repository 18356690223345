import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { FText } from '@fantaskticedtechlimited/fui-complib'
import { FFontTypes } from '@fantaskticedtechlimited/fui-fontlib'
import { useState } from 'react'
import * as styles from './styles'
import { QBAssetDropdownOptionProps } from './types'

const QBAssetDropdownOption = (props: QBAssetDropdownOptionProps) => {
    const [isHover, setIsHover] = useState(false)
    return (
        <>
            <div
                className={styles.OptionCard_Container}
                style={props.style}
                onClick={props.onClick}
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
            >
                {/* {isHover ? props.hoverIcon : props.normalIcon} */}
                {typeof props.icon === 'function'
                    ? props.icon(isHover)
                    : props.icon}
                <FText
                    font={FFontTypes.Text({ fontWeight: 500 })}
                    color={
                        isHover
                            ? FLegacyColorTypes.PRIMARY_BLACK
                            : FLegacyColorTypes.PRIMARY_GREY
                    }
                >
                    {props.name}
                </FText>
            </div>
        </>
    )
}

export default QBAssetDropdownOption
