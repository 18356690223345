import { FDragAndDrop, FUseColor } from '@fantaskticedtechlimited/fui-complib'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQBAssetLibraryContext } from '../../../../../contexts/qbAssetLibraryContext'
import { QBDirectoryViewMode } from '../../../../../contexts/qbAssetLibraryContext/types'
import { useQuestionPopUpWrapperContext } from '../../../../QuestionBankContent/components/popUp/WithQuestionPopUpWrapper'
import QuestionPreview from '../../../../QuestionBankContent/components/Question/QuestionPreview'
import { QBAssetDropdownMenu } from '../../../components/QBAssetDropdownMenu'
import QBAssetLibraryURLGenerator from '../../../components/QBAssetLibraryURLGenerator'
import { QBAssetListCardQuestionsContent } from '../../../components/QBAssetListCardContent/Questions'
import { styles } from './styles'

export const QBDirectoryViewQuestionsBody = () => {
    const { questionGroup, onRefresh, question, isQBAssetLoading } =
        useQBAssetLibraryContext()
    const navigate = useNavigate()
    const { onQuestionUpdatePopUpOpen, onQuestionDeletePopUpOpen } =
        useQuestionPopUpWrapperContext()
    const [currentQsIndex, setCurrentQsIndex] = useState<number>(-1)

    useEffect(() => {
        setCurrentQsIndex(-1)
    }, [])

    return (
        <div className={styles.Questions_Container}>
            <div className={styles.Questions_ContentDiv}>
                <FDragAndDrop
                    data={questionGroup?.questions!}
                    disableDndAction
                    style={(_, index) => ({
                        padding: '12px',
                        borderColor:
                            currentQsIndex === index
                                ? FUseColor({ colorName: 'Main' })
                                : undefined,
                    })}
                    children={(question, index) => (
                        <>
                            <QBAssetListCardQuestionsContent
                                data={question}
                                onClick={() => {
                                    setCurrentQsIndex(index!)
                                    navigate(
                                        QBAssetLibraryURLGenerator({
                                            viewMode:
                                                QBDirectoryViewMode.QUESTIONS,
                                            questionId: question.id,
                                            questionGroupId: questionGroup?.id,
                                            questionBankId:
                                                question.questionBankId,
                                            noLoading: true,
                                        })
                                    )
                                }}
                            />
                            <QBAssetDropdownMenu
                                onEditInfo={() => {
                                    onQuestionUpdatePopUpOpen(
                                        question,
                                        async () => {
                                            await onRefresh()
                                        }
                                    )
                                }} 
                                onDelete={() => {
                                    onQuestionDeletePopUpOpen(
                                        question,
                                        async () => {
                                            await onRefresh()
                                        }
                                    )
                                }}
                            />
                        </>
                    )}
                />
            </div>
            {currentQsIndex !== -1 && question && (
                <div style={{ width: '50%' }}>
                    <QuestionPreview
                        question={question}
                        isLoading={isQBAssetLoading}
                    />
                </div>
            )}
        </div>
    )
}
