import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { style } from 'typestyle'

export const ContentHome_Header_Container = style({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: '12px',
})

export const ContentHome_Content_Container = style({
    display: 'flex',
    flexDirection: 'column',
    rowGap: '2rem',
})

export const divider = style({
    margin: '0 1rem',
    height: '0.075rem',
    backgroundColor: FLegacyColorTypes.PRIMARY_LIGHT,
})
