import { gql } from '@apollo/client'
import { PHOTO_DETAIL_FIELDS } from '../fragments/photoDetail.gql'

export const QB_FOLDER_UPDATE_BY_ID_MUTATION = gql`
    mutation qbFolderUpdateById($folderUpdateInput: FolderUpdateInput!) {
        qbFolderUpdateById(folderUpdateInput: $folderUpdateInput) {
            isSuccess
            code
            errorMessage
            data {
                id
                orderSequence
                name
                subject
                icon {
                    ...photoDetail
                }
                isEnable
                isFlagged
            }
        }
    }

    ${PHOTO_DETAIL_FIELDS}
`
