import { gql } from '@apollo/client'

export const QB_CHAPTER_CREATE_MUTATION = gql`
    mutation qbChapterCreate(
        $folderChapterCreateInput: FolderChapterCreateInput!
    ) {
        qbChapterCreate(folderChapterCreateInput: $folderChapterCreateInput) {
            isSuccess
            code
            data {
                id
                name
            }
        }
    }
`
