import { gql } from '@apollo/client'

export const QB_QUESTIONGROUPS_REARRANGE_BY_IDS_MUTATION = gql`
    mutation qbQuestionGroupsRearrangeByIds(
        $questionGroupsRearrangeInput: QuestionGroupsRearrangeInput!
    ) {
        qbQuestionGroupsRearrangeByIds(
            questionGroupsRearrangeInput: $questionGroupsRearrangeInput
        ) {
            isSuccess
            code
            errorMessage
            data {
                id
                orderSequence
            }
        }
    }
`
