import {
    createContext,
    ElementType,
    Fragment,
    useContext,
    useState,
} from 'react'
import { LoadingAnimationProps, LoadingPageWrapperContextProps } from './types'
import Lottie from 'react-lottie'
import { styles } from './styles'

const animationData = require('./97952-loading-animation-blue.json')

// https://stackoverflow.com/questions/55696601/react-using-usestate-hooks-in-hoc-causes-error-hooks-can-only-be-called-inside
// https://stackoverflow.com/questions/60556221/react-hooks-inside-a-curry-function-creating-a-hoc-returning-an-error-from-lin
// https://github.com/facebook/react/issues/20531

const LoadingPageWrapperContext = createContext<LoadingPageWrapperContextProps>(
    {
        isLoadingPageWrapperLoading: false,
        setLoadingStart: () => {},
        setLoadingEnd: () => {},
    }
)

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
}

export const LoadingAnimation = (props: LoadingAnimationProps) => {
    return (
        <Lottie
            options={defaultOptions}
            height={props.height ?? 120}
            width={props.width ?? 120}
            style={{ display: 'flex', alignItems: 'center' }}
        />
    )
}

export const useLoadingPageWrapperContext = () =>
    useContext(LoadingPageWrapperContext)
export const WithLoadingPageWrapper = (
    Component: ElementType,
    defaultLoading?: boolean
) => {
    return function WithLoadingPageWrapper(props: any) {
        const [isLoading, setIsLoading] = useState(defaultLoading ?? false)

        const handleLoadingStop = () => {
            setIsLoading(false)
        }
        const handleLoadingStart = () => {
            setIsLoading(true)
        }

        const defaultContextValue: LoadingPageWrapperContextProps = {
            isLoadingPageWrapperLoading: isLoading,
            setLoadingStart: handleLoadingStart,
            setLoadingEnd: handleLoadingStop,
        }

        return (
            <Fragment>
                <LoadingPageWrapperContext.Provider value={defaultContextValue}>
                    {isLoading && (
                        <div className={styles.Loading_Container}>
                            <LoadingAnimation />
                        </div>
                    )}
                    <div className={styles.ComponentAfterLoading_Container}>
                        <Component {...props} />
                    </div>
                </LoadingPageWrapperContext.Provider>
            </Fragment>
        )
    }
}
