import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { FConfirmPopUp, FText } from '@fantaskticedtechlimited/fui-complib'
import { FFontTypes } from '@fantaskticedtechlimited/fui-fontlib'
import { styles } from './styles'
import { DeletePopUpProps } from './types'

export const DeletePopUp = (props: DeletePopUpProps) => {
    const title = 'Delete ' + props.targetType

    return (
        <FConfirmPopUp
            style={{ width: '30%', height: 'fit-content' }}
            disableCloseWhenClickOutside
            onClose={props.onClose}
            title={title}
            bottomBarProps={{
                actionButtonLabel: 'Delete',
                actionButtonProps: {
                    style: () => ({
                        backgroundColor: FLegacyColorTypes.SECONDARY_RED,
                    }),
                },
                onLeadingButtonClick: props.onClose,
                onActionButtonClick: props.onActionButtonClick,
            }}
        >
            <FText
                font={FFontTypes.Large_Text({ fontWeight: 400 })}
                children={
                    <div className={styles.DeletePopUp_ContentDiv}>
                        This will {title.toLowerCase()}
                        <FText
                            style={{ fontStyle: 'italic' }}
                            children={props.targetName + '.'}
                        />
                        Are you sure?
                    </div>
                }
            />
        </FConfirmPopUp>
    )
}
