import {
    FText,
    FInputField,
    FButton,
    FPasswordInputField,
} from '@fantaskticedtechlimited/fui-complib'
import { FFontTypes } from '@fantaskticedtechlimited/fui-fontlib'
import { getAuth } from 'firebase/auth'
import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import useAuthAPIService from '../../../apiService/auth'
import firebaseAppWithConfig from '../../../config/firebaseConfig'
import useFirebaseAuthService from '../../../utils/useFirebaseAuthService'
import * as styles from './styles'

import { ImageAsset } from '../../../assets/images'

function Register() {
    const authAPIService = useAuthAPIService()
    const firebaseAuthService = useFirebaseAuthService()
    const navigate = useNavigate()
    let [searchParams] = useSearchParams()
    const [redirectURL] = useState(searchParams.get('redirectURL') ?? '/')

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const handleEmailPasswordRegister = async (
        _email: string,
        _password: string
    ) => {
        try {
            setIsLoading(true)
            let auth = getAuth(firebaseAppWithConfig)
            const { firebaseToken } =
                await firebaseAuthService.handleCreateUserWithEmailAndPassword(
                    auth,
                    _email,
                    _password
                )
            return await authAPIService.handleSignInSuccessRedirect(
                '/',
                firebaseToken,
                redirectURL
            )
        } catch (error) {
            alert('Failed to signIn with Email Password')
        }
        setIsLoading(false)
    }

    return (
        <div className={styles.loginBoxContainer}>
            <img
                src={ImageAsset.backBtn}
                className={styles.backButton}
                onClick={() => navigate('/login', { replace: true })}
                alt="backBtn"
            />
            <FText font={FFontTypes.Title()}>Register</FText>
            <FInputField
                value={email}
                onInput={(v) => setEmail(v)}
                label="Email"
            />
            <FPasswordInputField
                style={{ width: '100%' }}
                value={password}
                onInput={(v) => setPassword(v)}
                placeholder="password"
            />
            <FButton
                className={() => styles.loginButton}
                type="Primary"
                label="Register"
                disabled={isLoading}
                onClick={() => handleEmailPasswordRegister(email, password)}
            />
        </div>
    )
}

export default Register
