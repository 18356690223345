import { gql } from '@apollo/client'

export const GET_QUESTIONBANKS_ARCHITECTURE_BY_FILTER = gql`
    query getQuestionBanksArchitectureByFilter(
        $questionBankFilterInput: QuestionBankFilterInput
    ) {
        questionBanksGet(questionBankFilterInput: $questionBankFilterInput) {
            isSuccess
            errorMessage
            code
            data {
                id
                name
                subject
                examSystem
                folders {
                    id
                    name
                    isEnable
                    isFlagged
                    chapters {
                        id
                        name
                        questionTypes {
                            id
                            chapterId
                            name
                            questionGroups {
                                id
                                questionTypeId
                                name
                                chapterId
                                questions {
                                    id
                                }
                                createAt
                                updateAt
                            }
                            createAt
                            updateAt
                        }
                        createAt
                        updateAt
                    }
                    createAt
                    updateAt
                }
                createAt
                updateAt
            }
        }
    }
`
