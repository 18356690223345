import { style } from 'typestyle'

export const toolbarContainer = style({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    columnGap: '0.75rem',
    minHeight: '5rem',
})

export const questionSelectAndPreviewContainer = style({
    display: 'flex',
    flexDirection: 'row',
    columnGap: '2rem',
    overflowY: 'hidden',
    width: '100%',
})
