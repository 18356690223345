import {
    Folder,
    QuestionGroup,
    Question,
    QuestionBank,
    QuestionType,
    Chapter,
} from '../../assets/type/backend.type'

export enum QBDirectoryViewMode {
    FOLDERS = 'FOLDERS',
    CHAPTERS = 'CHAPTERS',
    QUESTION_TYPES = 'QUESTION_TYPES',
    QUESTION_GROUPS = 'QUESTION_GROUPS',
    QUESTIONS = 'QUESTIONS', 
    ROOT_CHAPTER = 'ROOT_CHAPTER',
    QUESTION = 'QUESTION',
}
export interface QBAssetLibraryDirectoryViewParams {
    viewMode: QBDirectoryViewMode
    noLoading?: boolean
    questionBankId: string
    folderId?: string | null
    chapterId?: string | null
    questionTypeId?: string | null
    questionGroupId?: string | null
    questionId?: string | null
}
export interface QBAssetLibraryContextProps {
    isQBAssetLoading: boolean
    viewMode: QBDirectoryViewMode
    questionBank: QuestionBank | null
    folder: Folder | null
    chapter: Chapter | null
    questionType: QuestionType | null
    questionGroup: QuestionGroup | null
    question: Question | null
    onViewModeChange: (
        params: QBAssetLibraryDirectoryViewParams,
        withRefresh?: boolean
    ) => Promise<void>
    onRefresh: (withRefresh?: boolean) => Promise<void>
    handleRearrangeFolders: (newDatas: Folder[]) => Promise<void>
    handleRearrangeChapters: (newDatas: Chapter[]) => Promise<void>
    handleRearrangeQuestionTypes: (newDatas: QuestionType[]) => Promise<void>
    handleRearrangeQuestionGroups: (newDatas: QuestionGroup[]) => Promise<void>
}
