import { gql } from '@apollo/client'

export const QB_FOLDER_CREATE_MUTATION = gql`
    mutation qbFolderCreate($folderCreateInput: FolderCreateInput!) {
        qbFolderCreate(folderCreateInput: $folderCreateInput) {
            isSuccess
            code
            errorMessage
            data {
                id
                orderSequence
                name
            }
        }
    }
`
