import { FForm, FInputField, FText } from '@fantaskticedtechlimited/fui-complib'
import { Fragment, useEffect, useState } from 'react'
import useQBAPIService from '../../../../../apiService/questionBank'
import {
    Chapter,
    FolderChapterCreateInput,
    Folder,
    AttachmentTypes,
} from '../../../../../assets/type/backend.type'
import { ChapterCreateFormProps } from './types'
import * as styles from './styles'
import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { FFontTypes } from '@fantaskticedtechlimited/fui-fontlib'
import { FIcon, FIconNames } from '@fantaskticedtechlimited/fui-iconlib'
import { ReactFileUploader } from '@files-drop/react-uploader/lib/cjs'

export const ChapterCreateForm = (props: ChapterCreateFormProps) => {
    const { questionBankId, presetFolderId } = props
    const qbAPIService = useQBAPIService()
    const [folders, setFolders] = useState<Folder[]>([])
    const [selectedFolder, setSelectedFolder] = useState<Folder | null>(null)
    let defaultValue: FolderChapterCreateInput = {
        questionBankId: props.questionBankId,
        name: 'Name',
        description: '',
        icon: null,
        orderSequence: 0,
    }
    const [chapterCreateInput, setFolderChapterCreateInput] =
        useState<FolderChapterCreateInput>({
            ...defaultValue,
        })

    const handleGetFoldersAndSetDefault = async (
        _questionBankId: string,
        _presetFolderId?: string
    ) => {
        try {
            const foldersRes = await qbAPIService.getFoldersByFilter({
                folderFilterInput: {
                    questionBankId: _questionBankId,
                },
            })
            const { foldersGet } = foldersRes.data
            if (!foldersGet.isSuccess) {
                return setFolders([])
            }

            let _folders = foldersGet.data ?? []
            setFolders(_folders)
            // set default Folder
            if (_presetFolderId) {
                const foundFolder = _folders.find(
                    (e) => e.id === _presetFolderId
                )
                if (foundFolder) {
                    setSelectedFolder(foundFolder)
                }
            }
        } catch (error) {
            console.log('error', error)
            setFolders([])
            setSelectedFolder(null)
        }
    }

    const handleChapterCreate = async (
        folderChapterCreateInput: FolderChapterCreateInput
    ) => {
        try {
            // let chapterUpdateInput = removeTypename(_chapterUpdateInput)
            const result = await qbAPIService.qbChapterCreateMutation({
                variables: {
                    folderChapterCreateInput,
                },
            }) 
            // console.log("result", result);
            if (result.data?.qbChapterCreate.isSuccess) {
                props.onSubmit &&
                    (await props.onSubmit(
                        result.data.qbChapterCreate.data as Chapter
                    ))
                props.onClose && (await props.onClose())
            } else {
                console.error(
                    `Chapter Create failed, Reason. ${JSON.stringify(result)}`
                )
                alert(
                    `Chapter Create failed, Reason.  ${result.data?.qbChapterCreate.errorMessage}`
                )
            }
        } catch (error) {
            console.log('error', error)
            alert(`Chapter Create failed, Error: ${JSON.stringify(error)}`)
        }
    }

    useEffect(() => {
        handleGetFoldersAndSetDefault(questionBankId, presetFolderId)
    }, [presetFolderId, questionBankId])

    return (
        <FForm
            onClose={props.onClose}
            onSubmit={() =>
                handleChapterCreate({
                    ...chapterCreateInput,
                    folderId: selectedFolder ? selectedFolder.id : null,
                })
            }
        >
            <FInputField
                autoFocus
                label="Chapter Name"
                value={chapterCreateInput.name}
                wordCount={100}
                onInput={(v) =>
                    setFolderChapterCreateInput({
                        ...chapterCreateInput,
                        name: v,
                    })
                }
            />
            <FInputField
                label="Order Sequence"
                value={String(chapterCreateInput.orderSequence)}
                onInput={(v) =>
                    setFolderChapterCreateInput({
                        ...chapterCreateInput,
                        orderSequence: Number(v),
                    })
                }
            />
            {/* Chapter Icon */}
            {/* <div className={styles.ChapterCreateForm_IconRow_Div}>
                <FText font={FFontTypes.Large_Text()} children="Icon: " />
                {chapterCreateInput.icon !== null ? (
                    <div className={styles.ChapterCreateForm_IconDisplay_Div}>
                        <img
                            alt="icon"
                            src={chapterCreateInput.icon?.fileURL}
                            style={{
                                width: '200px',
                                height: '200px',
                            }}
                        />
                        <FIcon
                            name={FIconNames.DELETE}
                            strokeColor={FLegacyColorTypes.SECONDARY_RED}
                            onClick={() => {
                                setFolderChapterCreateInput({
                                    ...chapterCreateInput,
                                    icon: null,
                                })
                            }}
                        />
                    </div>
                ) : (
                    <FText
                        font={FFontTypes.Text()}
                        color={FLegacyColorTypes.SECONDARY_RED}
                        style={{ fontStyle: 'italic' }}
                        children="No Icon"
                    />
                )}
            </div>
            <ReactFileUploader
                url={process.env.REACT_APP_UPLOAD_URL ?? ''}
                apiKey={process.env.REACT_APP_UPLOAD_TOKEN ?? ''}
                multiple={false}
                maxNumberOfFiles={1}
                accept=".jpg,.jpeg,.png,.gif"
                iconUploaded={chapterCreateInput.icon !== null}
                onUploadedFiles={(files) => {
                    if (files.length === 1) {
                        const file = files[0]
                        setFolderChapterCreateInput({
                            ...chapterCreateInput,
                            icon: {
                                fileURL: file.fileURL,
                                filePath: file.filePath,
                                fileName: file.fileName,
                                originalFileName: file.originalFileName,
                                attachmentFileType: AttachmentTypes.ICON,
                                size: 0,
                                contentType: file.contentType,
                            },
                        })
                    }
                }}
                withDND
                withStatus
            /> */}
            <FInputField
                label="Description"
                value={chapterCreateInput.description ?? ''}
                wordCount={500}
                multiline
                onInput={(v) =>
                    setFolderChapterCreateInput({
                        ...chapterCreateInput,
                        description: v,
                    })
                }
            />
        </FForm>
    )
}
