import {
    FOnClickFunction,
    FOnSubmitFunction,
} from '@fantaskticedtechlimited/fui-complib'
import { ReactNode } from 'react'
import { GroupBase, StylesConfig } from 'react-select/dist/declarations/src'

export interface FCreateSelectProps<T> {
    options: T[]
    value: T[] | T
    placeholder?: ReactNode
    isMulti?: boolean
    isLoading?: boolean
    isDisable?: boolean
    isDisableCreate?: boolean
    isClearable?: boolean
    // can be string or Component
    getOptionLabel: (d: T) => ReactNode
    getOptionKey: (d: T) => string | number
    onClear?: FOnClickFunction
    onRemove?: FOnSubmitFunction<T> // only Run in multiple
    onSelect?: FOnSubmitFunction<T>
    onCreate?: FOnSubmitFunction<string>
    onCompareInputValue: (data: T, inputValue: string) => boolean

    // custom classNames / styles
    containerClassName?: string
    contentContainerClassName?: string
    menuClassName?: string
    componentClassName?: string
    clearIconClassName?: string
    styles?: StylesConfig<T, boolean, GroupBase<T>> | undefined
}

// react-select class names
export enum SpecifiedClassNames {
    BEFORE_OPEN_MENU_CONTAINER = '.css-1s2u09g-control',
    AFTER_OPEN_MENU_CONTAINER = '.css-1pahdxg-control',
    CONTENT_CONTAINER = '.css-g1d714-ValueContainer',
    MENU_WRAPPER = '.css-26l3qy-menu',
    MENU_LIST_CONTAINER = '.css-4ljt47-MenuList',
    RENDERED_COMPONENT_WRAPPER = '.css-1rhbuit-multiValue',
    RENDERED_COMPONENT_CONTENT_CONTAINER = '.css-12jo7m5',
    CLEAR_ICON_WRAPPER = '.css-xb97g8',
    CLEAR_ICON_SVG_CONTAINER = '.css-tj5bde-Svg',
}
