import { gql } from '@apollo/client'

export const QB_CONCEPTCARDS_REARRANGE_BY_IDS_MUTATION = gql`
    mutation qbConceptCardsRearrangeByIds(
        $conceptCardsRearrangeInput: ConceptCardsRearrangeInput!
    ) {
        qbConceptCardsRearrangeByIds(
            conceptCardsRearrangeInput: $conceptCardsRearrangeInput
        ) {
            isSuccess
            code
            errorMessage
            data {
                id
                orderSequence
            }
        }
    }
`
