import { gql } from '@apollo/client'

export const QB_CHAPTER_MOVE_TO_FOLDER_BY_ID_MUTATION = gql`
    mutation qbChapterMoveToFolderById(
        $qbChapterMoveToFolderInput: QBChapterMoveToFolderInput!
    ) {
        qbChapterMoveToFolderById(
            qbChapterMoveToFolderInput: $qbChapterMoveToFolderInput
        ) {
            isSuccess
            errorMessage
            code
            data {
                id
            }
        }
    }
`
