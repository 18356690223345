import { gql } from '@apollo/client'

export const QB_FOLDER_DELETE_BY_ID_MUTATION = gql`
    mutation qbFolderDeleteById($folderId: String!) {
        qbFolderDeleteById(folderId: $folderId) {
            isSuccess
            code
            data {
                id
                name
            }
        }
    }
`
