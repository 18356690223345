import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { FOnClickFunction } from '@fantaskticedtechlimited/fui-complib'
import { FIcon, FIconNames } from '@fantaskticedtechlimited/fui-iconlib'
import { useState, useEffect } from 'react'
import { Chapter } from '../../../../../assets/type/backend.type'

import { useQuestionTypePopUpWrapperContext } from '../../../../QuestionBankContent/components/popUp/WithQuestionTypePopUpWrapper'
import { useChapterPopUpWrapperContext } from '../../../../QuestionBankContent/components/popUp/WithChapterPopUpWrapper'
import { ArchiQuestionTypeCard } from '../ArchiQuestionTypeCard'
import { ArrowOpenCloseIcon } from '../ArrowOpenCloseIcon'

interface ArchiChapterCardProps {
    questionBankId: string
    chapter: Chapter
    isRootOpen?: boolean
    onGlobalUpdate?: FOnClickFunction
}
export const ArchiChapterCard = (props: ArchiChapterCardProps) => {
    const { onQuestionTypeCreatePopUpOpen } =
        useQuestionTypePopUpWrapperContext()
    const { onChapterMoreActionPopUpOpen } = useChapterPopUpWrapperContext()

    const { chapter, isRootOpen, questionBankId } = props
    const [isOpenChapter, setIsOpenChapter] = useState(false)
    useEffect(() => {
        if (!isRootOpen) {
            setIsOpenChapter(false)
        } else {
            setIsOpenChapter(true)
        }
    }, [isRootOpen])
    return (
        <div>
            {/* chapter title */}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    columnGap: '10px',
                }}
            >
                <ArrowOpenCloseIcon
                    isOpen={isOpenChapter}
                    onOpen={() => {
                        console.log('open arrow')
                        setIsOpenChapter(true)
                    }}
                    onClose={() => {
                        console.log('close arrow')
                        setIsOpenChapter(false)
                    }}
                />
                <span>Chapter:</span>
                {chapter.name}
                {!isOpenChapter && `(${chapter.questionTypes.length ?? 0})`}
                <button
                    onClick={() =>
                        onQuestionTypeCreatePopUpOpen(props.chapter, async () =>
                            // handleGetQuestionTypesByChapterId(props?.chapter.id)
                            {
                                props.onGlobalUpdate &&
                                    (await props.onGlobalUpdate())
                            }
                        )
                    }
                >
                    +
                </button>
                {/* More Action button */}
                <FIcon
                    name={FIconNames.MORE}
                    size="small"
                    color={() => FLegacyColorTypes.PRIMARY_GREY}
                    onClick={() => {
                        onChapterMoreActionPopUpOpen(chapter, async () => {
                            props.onGlobalUpdate &&
                                (await props.onGlobalUpdate())
                        })
                    }}
                />
            </div>

            {/* Question Types */}
            {isOpenChapter && (
                <div style={{ marginLeft: '48px' }}>
                    {chapter.questionTypes.map((questionType, i) => {
                        return (
                            <ArchiQuestionTypeCard
                                questionBankId={questionBankId}
                                isRootOpen={isRootOpen}
                                questionType={questionType}
                                key={i}
                                onGlobalUpdate={props.onGlobalUpdate}
                            />
                        )
                    })}
                </div>
            )}
        </div>
    )
}
