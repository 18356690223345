import { Fragment } from 'react'
import {
    Navigate,
    Route,
    Routes,
    useLocation,
    useSearchParams,
} from 'react-router-dom'
import { FRouteConfig } from '../../utils/useRouteService/types'
import { PageRoutesRenderProps } from './type'

const renderRoute = (routes: FRouteConfig[]) => {
    return routes.map((r, i) => {
        return (
            <Route
                {...(r as FRouteConfig)} // for exact, path ... settings
                key={r.key || i}
                element={<r.component route={r} />}
            >
                {Array.isArray(r.routes) && renderRoute(r.routes)}
            </Route>
        )
    })
}
export function PageRoutesRender(props: PageRoutesRenderProps): JSX.Element {
    let location = useLocation()
    const { redirectPathIfNotExist = '/' } = props
    const redirectURL = location.pathname
    const [searchParams] = useSearchParams()
    const queryString = searchParams.toString()
    return (
        <Routes>
            <Fragment>
                {renderRoute(props.routes)}
                <Route
                    path={'*'}
                    element={
                        <Navigate
                            to={`${redirectPathIfNotExist}?redirectURL=${redirectURL}&${queryString}`}
                        />
                    }
                />
            </Fragment>
        </Routes>
    )
}
