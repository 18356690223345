import { FDragAndDrop } from '@fantaskticedtechlimited/fui-complib'
import { useState, Fragment } from 'react'

function TestForm() {
    // const { currentUserSetting } = useUserSettingContext();

    const dummyDatas = [
        {
            id: '1111',
            name: 'apple',
        },
        {
            id: '1112',
            name: 'banana',
        },
        {
            id: '1113',
            name: 'watermelon',
        },
        {
            id: '1114',
            name: 'melon',
        },
    ]
    const testData = {
        name: 'asdasd',
        array: dummyDatas,
    }
    function sleep(ms: number) {
        return new Promise((resolve) => setTimeout(resolve, ms))
    }
    const [testInput, setTestInput] = useState('')
    // const [fontArray, setFontArray] = useState(dummyDatas)
    const [data, setData] = useState(testData)
    return (
        <Fragment>
            <FDragAndDrop
                data={data.array}
                onUpdateData={async (newDatas) => {
                    console.log('start ondrop')
                    await sleep(500)
                    console.log('newData', newDatas)
                    // setFontArray([...newDatas])
                    setData({
                        ...data,
                        array: [...newDatas],
                    })
                }}
                children={(data, index) => {
                    return (
                        <div
                            style={{
                                border: '1px solid black',
                                height: '50px',
                                width: '100%',
                            }}
                        >{`${data.id}-${data.name}-${index}`}</div>
                    )
                }}
            />
            {/* <FMarkdownEditor
                mdValue={testInput}
                onChange={(v) => setTestInput(v)}

                // height={200}
            /> */}
            {/* <FMarkdownView children={testInput} /> */}
            {/* {(
				<QuestionCreateForm
					chapterId={"44b5b69c-7f52-41bf-a1f1-346e8fa9e2c7"}
					onSubmitCallback={async (data) =>
						console.log("question create form data", data)
					}
					onClose={() => console.log("close")}
					// questionBankId={currentUserSetting.questionBank.id}
					questionBankId={"c0a29f4b-f86e-4f39-b2ba-0a0c284d68df"}
				/>
			)} */}
            {/* <QuestionBankCreateForm
				onSubmitCallback={async (data) =>
					console.log("question bank create form data", data)
				}
				onClose={() => console.log("close")}
			/> */}
        </Fragment>
    )
}

export default TestForm
