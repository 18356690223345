import { gql } from '@apollo/client'

export const QB_QUESTION_DELETE_BY_ID_MUTATION = gql`
    mutation qbQuestionDeleteById($questionId: String!) {
        qbQuestionDeleteById(questionId: $questionId) {
            isSuccess
            code
            errorMessage
            data {
                id
            }
        }
    }
`
