import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { stylesheet } from 'typestyle'

export const styles = stylesheet({
    QBAssetDropdown_Wrapper: {
        justifyContent: 'flex-end',
        position: 'relative',
    },
    QBAssetDropdown_Container: {
        position: 'absolute',
        zIndex: 20,
        width: '160px',
        overflow: 'hidden',
        marginTop: '32px',
        padding: '4px',
        border: '1.6px solid' + FLegacyColorTypes.SPECIAL_STROKE,
        rowGap: '4px',
    },
})
