import { gql } from '@apollo/client'

export const QB_QUESTION_TYPE_UPDATE_BY_ID_MUTATION = gql`
    mutation qbQuestionTypeUpdateById(
        $questionTypeUpdateInput: QuestionTypeUpdateInput!
    ) {
        qbQuestionTypeUpdateById(
            questionTypeUpdateInput: $questionTypeUpdateInput
        ) {
            isSuccess
            code
            errorMessage
            data {
                id
                name
                questionIds
                questions {
                    id
                }
                chapterId
                chapter {
                    id
                    name
                }
                questionBankId
            }
        }
    }
`
