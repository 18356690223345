import { FOnClickFunction } from '@fantaskticedtechlimited/fui-complib'
import { FIcon, FIconNames } from '@fantaskticedtechlimited/fui-iconlib'
import { useState } from 'react'

interface ArrowOpenCloseIconProps {
    isOpen?: boolean
    onOpen?: FOnClickFunction
    onClose?: FOnClickFunction
}
export const ArrowOpenCloseIcon = (props: ArrowOpenCloseIconProps) => {
    const { isOpen, onClose, onOpen } = props
    const [isInternalOpen, setIsInternalOpen] = useState(false)
    const isFinalOpen = isOpen !== undefined ? isOpen : isInternalOpen
    return (
        <FIcon
            name={isFinalOpen ? FIconNames.ARROW_DOWN : FIconNames.RIGHT_ARROW}
            onClick={() => {
                if (isFinalOpen) {
                    // from open to close
                    onClose && onClose()
                    setIsInternalOpen(false)
                } else {
                    // from close to open
                    onOpen && onOpen()
                    setIsInternalOpen(true)
                }
            }}
        />
    )
}
