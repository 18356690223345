import { gql } from '@apollo/client'

import { QUESTION_DETAIL_FIELDS } from '../fragments/questionDetail.gql'

export const GET_GROUP_DETAIL_BY_ID_QUERY = gql`
    query getQuestionGroupDetailById($questionGroupId: String!) {
        questionGroupGet(id: $questionGroupId) {
            code
            errorMessage
            isSuccess
            data {
                id
                orderSequence
                chapterId
                questionTypeId
                name
                questionBankId
                defaultQuestionId
                defaultQuestion {
                    ...questionDetail
                }
                questions {
                    ...questionDetail
                }
                createAt
                updateAt
            }
        }
    }
    ${QUESTION_DETAIL_FIELDS}
`
