import {
    FButton,
    FDivider,
    FNoData,
    FScrollableContentWrapper,
    FText,
} from '@fantaskticedtechlimited/fui-complib'

import { FFontTypes } from '@fantaskticedtechlimited/fui-fontlib'
import React, { Fragment, useEffect, useState } from 'react'
import useQBAPIService from '../../../apiService/questionBank'
import { QuestionBank } from '../../../assets/type/backend.type'
import { useUserSettingContext } from '../../../contexts/userSettingContext'
import { PageContainer } from '../../../layout/PageContainer'
import {
    useLoadingPageWrapperContext,
    WithLoadingPageWrapper,
} from '../../../layout/WithLoadingPageWrapper'
import {
    useFolderPopUpWrapperContext,
    WithFolderPopUpWrapper,
} from '../../QuestionBankContent/components/popUp/WithFolderPopUpWrapper'
import { WithQuestionGroupPopUpWrapper } from '../../QuestionBankContent/components/popUp/WithQuestionGroupPopUpWrapper'
import { WithQuestionPopUpWrapper } from '../../QuestionBankContent/components/popUp/WithQuestionPopUpWrapper'
import { WithQuestionTypePopUpWrapper } from '../../QuestionBankContent/components/popUp/WithQuestionTypePopUpWrapper'
import {
    useChapterPopUpWrapperContext,
    WithChapterPopUpWrapper,
} from '../../QuestionBankContent/components/popUp/WithChapterPopUpWrapper'
import { ArchiFolderCard } from './components/ArchiFolderCard'
import { ArchiChapterCard } from './components/ArchiChapterCard'
import * as styles from './styles'

function QBArchitectureDetail() {
    const { currentUserSetting } = useUserSettingContext()
    const { setLoadingEnd, setLoadingStart } = useLoadingPageWrapperContext()
    const { onChapterCreatePopUpOpen } = useChapterPopUpWrapperContext()
    const { onFolderCreatePopUpOpen } = useFolderPopUpWrapperContext()
    const qbAPIService = useQBAPIService()
    const [questionBank, setQuestionBank] = useState<QuestionBank | null>(null)
    const totalFolders = questionBank?.folders?.length ?? 0
    const totalChapters = questionBank?.chapters?.length ?? 0
    const handleGetFullQuestionBank = async (
        questionBankId: string,
        withLoading?: boolean
    ) => {
        withLoading && setLoadingStart()
        try {
            const result = await qbAPIService.getQuestionBankArchitectureById({
                questionBankId,
            })
            const { questionBankGet } = result.data
            setQuestionBank(
                questionBankGet.isSuccess ? questionBankGet.data! : null
            )
        } catch (error) {
            console.log('error', error)
            alert(JSON.stringify(error))
            setQuestionBank(null)
        }
        withLoading && setLoadingEnd()
    }

    const [isShowAll, setIsShowAll] = useState(false)
    // When QB change, all data refetched
    useEffect(() => {
        if (currentUserSetting.questionBank) {
            handleGetFullQuestionBank(currentUserSetting.questionBank.id, true)
        } else setQuestionBank(null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUserSetting.questionBank])

    // for loading effect or block when QB is not loaded
    if (questionBank === null) {
        return <FNoData description="No QuestionBank" />
    }

    return (
        <PageContainer
            header={
                <div className={styles.ContentHome_Header_Container}>
                    <FText
                        style={{
                            flexGrow: 1,
                        }}
                        font={FFontTypes.Title()}
                        children="QB Architecture Detail"
                    />
                    <FButton
                        type="Primary"
                        label="ShowAll"
                        onClick={() => {
                            setIsShowAll(true)
                        }}
                    />
                    <FButton
                        type="Secondary"
                        label="Collapse"
                        onClick={() => {
                            setIsShowAll(false)
                        }}
                    />
                    <FButton
                        type="Primary"
                        onClick={() =>
                            onChapterCreatePopUpOpen(
                                {
                                    questionBankId: questionBank.id,
                                },
                                async () => {
                                    await handleGetFullQuestionBank(
                                        questionBank.id
                                    )
                                }
                            )
                        }
                        label="New Chapter"
                    />
                    <FButton
                        type="Primary"
                        onClick={() =>
                            onFolderCreatePopUpOpen(questionBank, async () => {
                                // await handleGetFoldersByQuestionBankId(
                                // 	questionBank?.id
                                // );
                                await handleGetFullQuestionBank(questionBank.id)
                            })
                        }
                        label="New Folder"
                    />
                    <FButton
                        type="Primary"
                        onClick={() =>
                            handleGetFullQuestionBank(questionBank.id, true)
                        }
                        label="Refresh"
                    />
                </div>
            }
            body={
                <FScrollableContentWrapper>
                    <Fragment>
                        {totalFolders === 0 && totalChapters === 0 ? (
                            <FNoData />
                        ) : (
                            <div
                                className={styles.ContentHome_Content_Container}
                            >
                                {questionBank.folders.map((folder, i) => {
                                    return (
                                        <ArchiFolderCard
                                            questionBankId={questionBank.id}
                                            isRootOpen={isShowAll}
                                            folder={folder}
                                            key={i}
                                            onGlobalUpdate={async () => {
                                                await handleGetFullQuestionBank(
                                                    questionBank.id
                                                )
                                            }}
                                        />
                                    )
                                })}
                            </div>
                        )}
                    </Fragment>
                </FScrollableContentWrapper>
            }
        />
    )
}

export default WithLoadingPageWrapper(
    WithChapterPopUpWrapper(
        WithFolderPopUpWrapper(
            WithQuestionTypePopUpWrapper(
                WithQuestionGroupPopUpWrapper(
                    WithQuestionPopUpWrapper(QBArchitectureDetail)
                )
            )
        )
    ),
    true
)
