import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { FOverrideStyle } from '@fantaskticedtechlimited/fui-complib'
import { style } from 'typestyle'

export const CTAButton_Container = (isHover?: boolean) =>
    style({
        borderRadius: '36px',
        padding: '8px 12px',
        border: '2px solid' + FLegacyColorTypes.PRIMARY_DARK,
        backgroundColor: FOverrideStyle(
            isHover
                ? FLegacyColorTypes.PRIMARY_BLACK
                : FLegacyColorTypes.PRIMARY_WHITE
        ),
    })

export const CTAButtonLabel_Div = (isHover?: boolean) =>
    style({
        color: FOverrideStyle(
            isHover
                ? FLegacyColorTypes.PRIMARY_WHITE
                : FLegacyColorTypes.PRIMARY_BLACK
        ),
    })
