import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import {
    FText,
    FInputField,
    FButton,
    FPasswordInputField,
} from '@fantaskticedtechlimited/fui-complib'
import { FFontTypes } from '@fantaskticedtechlimited/fui-fontlib'
import { getAuth, indexedDBLocalPersistence } from 'firebase/auth'
import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import firebaseAppWithConfig from '../../../config/firebaseConfig'
import useFirebaseAuthService from '../../../utils/useFirebaseAuthService'
import { FirebaseSignInProvider } from '@firebase/util'
import * as styles from './styles'

import { ImageAsset } from '../../../assets/images'
import useAuthAPIService from '../../../apiService/auth'
import { useAuthContext } from '../../../contexts/authContext'

function LoginHome() {
    const authAPIService = useAuthAPIService()
    const firebaseAuthService = useFirebaseAuthService()
    const navigate = useNavigate()
    let [searchParams] = useSearchParams()
    const [redirectURL] = useState(searchParams.get('redirectURL') ?? '/')
    const { setSignin } = useAuthContext()

    const supportProvider: FirebaseSignInProvider[] = [
        'google.com',
        'apple.com',
        'facebook.com',
    ]
    const [email, setEmail] = useState('teacher03@fantasktic.test.ai')
    const [password, setPassword] = useState('12345678')

    const handleEmailPasswordSignIn = async (
        _email: string,
        _password: string
    ) => {
        try {
            let auth = getAuth(firebaseAppWithConfig)
            auth.setPersistence(indexedDBLocalPersistence)
            const { firebaseToken } =
                await firebaseAuthService.handleSignInWithEmailAndPassword(
                    auth,
                    _email,
                    _password
                )
            await authAPIService.handleSignInSuccessRedirect(
                '/',
                firebaseToken,
                redirectURL
            )
        } catch (error) {
            alert('Failed to signIn with Email Password')
        }
    }
    const handlePopUpSignIn = async (provider: FirebaseSignInProvider) => {
        try {
            let auth = getAuth(firebaseAppWithConfig)
            auth.setPersistence(indexedDBLocalPersistence)
            const { isSuccess, firebaseToken } =
                await firebaseAuthService.handleSignInwithPopup(auth, provider)
            alert(`IsSuccess:${isSuccess}, FirebaseToken:${firebaseToken}`)
            if (isSuccess) {
                await authAPIService.handleSignInSuccessRedirect(
                    '/',
                    firebaseToken,
                    redirectURL
                )
            }
        } catch (error) {
            alert('Failed PopUp SignIn')
        }
    }

    return (
        <div className={styles.loginBoxContainer}>
            <img
                src={ImageAsset.logo}
                className={styles.loginBoxLogo}
                alt="logo"
            />
            <FText font={FFontTypes.Title()}>QB Portal</FText>
            <FText
                font={FFontTypes.Large_Text()}
                color={FLegacyColorTypes.PRIMARY_GREY}
            >
                Please select a login method
            </FText>
            <FInputField
                value={email}
                onInput={(v) => setEmail(v)}
                label="Email"
            />
            <FPasswordInputField
                style={{
                    width: '100%',
                }}
                value={password}
                onInput={(v) => setPassword(v)}
                placeholder="password"
            />
            <FButton
                className={() => styles.loginButton}
                type="Primary"
                label="Login"
                onClick={() => handleEmailPasswordSignIn(email, password)}
            />

            <FText
                font={FFontTypes.Text()}
                color={FLegacyColorTypes.PRIMARY_GREY}
            >
                Or
            </FText>
            <FButton
                className={() => styles.ssoButtonBox}
                onClick={() => {
                    setSignin(true)
                    navigate(redirectURL, { replace: true })
                }}
                type="Outline"
                label={`Dev Login`}
                labelClassName={() => styles.ssoButtonLabel}
            ></FButton>
            <FButton
                className={() => styles.ssoButtonBox}
                onClick={() => {
                    navigate('/register')
                }}
                type="Outline"
                label={`Register`}
                labelClassName={() => styles.ssoButtonLabel}
            ></FButton>
            <FButton
                className={() => styles.ssoButtonBox}
                onClick={() => {
                    navigate('/magicLink')
                }}
                type="Outline"
                label={`Magic Link`}
                labelClassName={() => styles.ssoButtonLabel}
            ></FButton>
            {supportProvider.map((provider, i) => {
                return (
                    <FButton
                        key={i}
                        className={() => styles.ssoButtonBox}
                        onClick={() => handlePopUpSignIn(provider)}
                        leadingComponents={
                            <img
                                src={firebaseAuthService.extractFirebaseProviderLogo(
                                    provider
                                )}
                                className={styles.ssoButtonLogo}
                                alt="ssoLogo"
                            />
                        }
                        type="Outline"
                        label={`Continue with ${firebaseAuthService.extractFirebaseProviderName(
                            provider
                        )}`}
                        labelClassName={() => styles.ssoButtonLabel}
                    ></FButton>
                )
            })}
        </div>
    )
}

export default LoginHome
