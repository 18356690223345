import { createContext, ElementType, useContext, useState } from 'react'
import useQBAPIService from '../../apiService/questionBank'
import {
    QuestionBank,
    Folder,
    Chapter,
    QuestionType,
    QuestionGroup,
    Question,
    QuestionTypesOrderSequenceInput,
    FolderOrderSequenceInput,
    ChapterOrderSequenceInput,
    QuestionGroupOrderSequenceInput,
} from '../../assets/type/backend.type'
import { duplicateAnObject } from '../../utils/duplicateAnObject'
import { sortArrayByOrderSequence } from '../../utils/sortArrayByOrderSequence'
import {
    QBAssetLibraryContextProps,
    QBAssetLibraryDirectoryViewParams,
    QBDirectoryViewMode,
} from './types'

const QBAssetLibraryContext = createContext<QBAssetLibraryContextProps>({
    isQBAssetLoading: false,
    viewMode: QBDirectoryViewMode.FOLDERS,
    questionBank: null,
    folder: null,
    chapter: null,
    questionType: null,
    questionGroup: null,
    question: null,
    onViewModeChange: async () => {},
    onRefresh: async () => {},
    handleRearrangeFolders: async () => {},
    handleRearrangeChapters: async () => {},
    handleRearrangeQuestionTypes: async () => {},
    handleRearrangeQuestionGroups: async () => {},
})
export const useQBAssetLibraryContext = () => useContext(QBAssetLibraryContext)
export const WithQBAssetLibraryContext = (Component: ElementType) => {
    return function WithQBAssetLibraryContext(props: any) {
        const qbAPIService = useQBAPIService()
        const [isQBAssetLoading, setIsQBAssetLoading] = useState(false)
        const [viewMode, setViewMode] = useState<QBDirectoryViewMode>(
            QBDirectoryViewMode.FOLDERS
        )
        const [questionBank, setQuestionBank] = useState<QuestionBank | null>(
            null
        )
        const [folder, setFolder] = useState<Folder | null>(null)
        const [chapter, setChapter] = useState<Chapter | null>(null)
        const [questionType, setQuestionType] = useState<QuestionType | null>(
            null
        )
        const [questionGroup, setQuestionGroup] =
            useState<QuestionGroup | null>(null)
        const [question, setQuestion] = useState<Question | null>(null)

        const handleSelectQuestionBank = async (qbId: string) => {
            try {
                const result = await qbAPIService.getQuestionBankContentById({
                    id: qbId,
                })
                const { questionBankGet } = result.data
                if (questionBankGet.isSuccess) {
                    setQuestionBank({
                        ...questionBankGet.data!,
                        folders: sortArrayByOrderSequence([
                            ...questionBankGet.data?.folders!,
                        ]),
                    })
                }
            } catch (error) {
                alert(JSON.stringify(error))
                setQuestionBank(null)
            }
        }

        const handleSelectFolder = async (folderId: string) => {
            try {
                const result = await qbAPIService.getFolderDetailById({
                    folderId,
                })
                const { folderGet } = result.data
                if (folderGet.isSuccess) {
                    setFolder({
                        ...folderGet.data!,
                        chapters: sortArrayByOrderSequence([
                            ...folderGet.data?.chapters!,
                        ]),
                    })
                }
            } catch (error) {
                alert(JSON.stringify(error))
                setFolder(null)
            }
        }

        const handleSelectChapter = async (chapterId: string) => {
            try {
                const result = await qbAPIService.getChapterDetailById({
                    chapterId,
                })
                const { chapterGet } = result.data
                if (chapterGet.isSuccess) {
                    setChapter({
                        ...chapterGet.data!,
                        questionTypes: sortArrayByOrderSequence([
                            ...chapterGet.data?.questionTypes!,
                        ]),
                    })
                }
            } catch (error) {
                alert(JSON.stringify(error))
                setChapter(null)
            }
        }

        const handleSelectQuestionType = async (questionTypeId: string) => {
            try {
                const result = await qbAPIService.getQuestionTypeDetailById({
                    questionTypeId,
                })
                const { questionTypeGet } = result.data
                if (questionTypeGet.isSuccess) {
                    setQuestionType({
                        ...questionTypeGet.data!,
                        questionGroups: sortArrayByOrderSequence([
                            ...questionTypeGet.data?.questionGroups!,
                        ]),
                    })
                }
            } catch (error) {
                alert(JSON.stringify(error))
                setQuestionType(null)
            }
        }

        const handleSelectQuestionGroup = async (questionGroupId: string) => {
            try {
                const result = await qbAPIService.getQuestionGroupDetailById({
                    questionGroupId,
                })
                const { questionGroupGet } = result.data
                if (questionGroupGet.isSuccess) {
                    setQuestionGroup({
                        ...questionGroupGet.data!,
                        questions: sortArrayByOrderSequence([
                            ...questionGroupGet.data?.questions!,
                        ]),
                    })
                }
            } catch (error) {
                alert(JSON.stringify(error))
                setQuestionGroup(null)
            }
        }

        const handleSelectQuestion = async (questionId: string) => {
            try {
                const result = await qbAPIService.getQuestionDetailById({
                    questionId,
                })
                const { questionGet } = result.data
                setQuestion(questionGet.data ?? null)
            } catch (error) {
                alert(JSON.stringify(error))
                setQuestion(null)
            }
        }

        const handleRearrangeFolders = async (newDatas: Folder[]) => {
            if (questionBank === null) {
                return
            }
            // update to API
            try {
                const newOrder: FolderOrderSequenceInput[] = []
                newDatas.map((e, i) => {
                    newOrder.push({
                        folderId: e.id,
                        orderSequence: i,
                    })
                })
                let resultArray: Folder[] = []
                newOrder.map((newOrder) => {
                    let index = newDatas.findIndex(
                        (e) => e.id === newOrder.folderId
                    )
                    if (index > -1) {
                        let newFolder: Folder = duplicateAnObject(
                            newDatas.at(index)!
                        )
                        newFolder.orderSequence = newOrder.orderSequence
                        resultArray.push(newFolder)
                    }
                })
                const updateResult = await qbAPIService.qbFoldersRearrangeByIds(
                    {
                        variables: {
                            foldersRearrangeInput: {
                                folderOrderSequences: newOrder,
                            },
                        },
                    }
                )
                if (updateResult.data?.qbFoldersRearrangeByIds.isSuccess) {
                    setQuestionBank({
                        ...questionBank,
                        folders: resultArray,
                    })
                }
            } catch (error) {
                console.log('rearrange folders error', error)
            }
            // update to UI
        }

        const handleRearrangeChapters = async (newDatas: Chapter[]) => {
            if (folder === null) {
                return
            }
            // update to API
            try {
                const newOrder: ChapterOrderSequenceInput[] = []
                newDatas.map((e, i) => {
                    newOrder.push({
                        chapterId: e.id,
                        orderSequence: i,
                    })
                })
                let resultArray: Chapter[] = []
                newOrder.map((newOrder) => {
                    let index = newDatas.findIndex(
                        (e) => e.id === newOrder.chapterId
                    )
                    if (index > -1) {
                        let newChapter: Chapter = duplicateAnObject(
                            newDatas.at(index)!
                        )
                        newChapter.orderSequence = newOrder.orderSequence
                        resultArray.push(newChapter)
                    }
                })
                const updateResult =
                    await qbAPIService.qbChaptersRearrangeByIds({
                        variables: {
                            chaptersRearrangeInput: {
                                chapterOrderSequences: newOrder,
                            },
                        },
                    })
                if (updateResult.data?.qbChaptersRearrangeByIds.isSuccess) {
                    setFolder({
                        ...folder,
                        chapters: [...resultArray],
                    })
                }
            } catch (error) {
                console.log('rearrange chapters error', error)
            }
            // update to UI
        }

        const handleRearrangeQuestionTypes = async (
            newDatas: QuestionType[]
        ) => {
            if (chapter === null) {
                return
            }
            // update to API
            try {
                const newOrder: QuestionTypesOrderSequenceInput[] = []
                newDatas.map((e, i) => {
                    newOrder.push({
                        questionTypeId: e.id,
                        orderSequence: i,
                    })
                })
                let resultArray: QuestionType[] = []
                newOrder.map((newOrder) => {
                    let index = newDatas.findIndex(
                        (e) => e.id === newOrder.questionTypeId
                    )
                    if (index > -1) {
                        let newQuestionType: QuestionType = duplicateAnObject(
                            newDatas.at(index)!
                        )
                        newQuestionType.orderSequence = newOrder.orderSequence
                        resultArray.push(newQuestionType)
                    }
                })
                const updateResult =
                    await qbAPIService.qbQuestionTypesRearrangeByIds({
                        variables: {
                            questionTypesRearrangeInput: {
                                questionTypesOrderSequences: newOrder,
                            },
                        },
                    })
                if (
                    updateResult.data?.qbQuestionTypesRearrangeByIds.isSuccess
                ) {
                    setChapter({
                        ...chapter,
                        questionTypes: [...resultArray],
                    })
                }
            } catch (error) {
                console.log('rearrange question types error', error)
            }
            // update to UI
        }

        const handleRearrangeQuestionGroups = async (
            newDatas: QuestionGroup[]
        ) => {
            if (questionType === null) {
                return
            }
            // update to API
            try {
                const newOrder: QuestionGroupOrderSequenceInput[] = []
                newDatas.map((e, i) => {
                    newOrder.push({
                        questionGroupId: e.id,
                        orderSequence: i,
                    })
                })
                let resultArray: QuestionGroup[] = []
                newOrder.map((newOrder) => {
                    let index = newDatas.findIndex(
                        (e) => e.id === newOrder.questionGroupId
                    )
                    if (index > -1) {
                        let newQuestionGroup: QuestionGroup = duplicateAnObject(
                            newDatas.at(index)!
                        )
                        newQuestionGroup.orderSequence = newOrder.orderSequence
                        resultArray.push(newQuestionGroup)
                    }
                })
                const updateResult =
                    await qbAPIService.qbQuestionGroupsRearrangeByIds({
                        variables: {
                            questionGroupsRearrangeInput: {
                                questionGroupsOrderSequences: newOrder,
                            },
                        },
                    })
                if (
                    updateResult.data?.qbQuestionGroupsRearrangeByIds.isSuccess
                ) {
                    setQuestionType({
                        ...questionType,
                        questionGroups: [...resultArray],
                    })
                }
            } catch (error) {
                console.log('rearrange question groups error', error)
            }
            // update to UI
        }

        const handleRefresh = async (withRefresh: boolean = false) => {
            withRefresh && setIsQBAssetLoading(true)
            switch (viewMode) {
                case QBDirectoryViewMode.FOLDERS:
                    if (questionBank !== null) {
                        await handleSelectQuestionBank(questionBank.id)
                        setFolder(null)
                    }
                    break
                case QBDirectoryViewMode.CHAPTERS:
                    if (folder !== null) {
                        await handleSelectFolder(folder?.id)
                        setChapter(null)
                    }
                    break
                case QBDirectoryViewMode.QUESTION_TYPES:
                case QBDirectoryViewMode.ROOT_CHAPTER:
                    if (chapter !== null) {
                        await handleSelectChapter(chapter?.id)
                        setQuestionType(null)
                    }
                    break
                case QBDirectoryViewMode.QUESTION_GROUPS:
                    if (questionType !== null) {
                        handleSelectQuestionType(questionType?.id)
                        setQuestionGroup(null)
                    }
                    break
                case QBDirectoryViewMode.QUESTIONS:
                    questionGroup !== null &&
                        (await handleSelectQuestionGroup(questionGroup?.id))
                    question !== null &&
                        (await handleSelectQuestion(question.id))
                    break
                case QBDirectoryViewMode.QUESTION:
                    question !== null &&
                        (await handleSelectQuestion(question?.id))
                    break
                default:
                    break
            }
            withRefresh && setIsQBAssetLoading(false)
        }

        const handleViewModeChange = async (
            params: QBAssetLibraryDirectoryViewParams,
            withRefresh: boolean = false
        ) => {
            withRefresh && setIsQBAssetLoading(true)
            switch (params.viewMode) {
                case QBDirectoryViewMode.FOLDERS:
                    if (params.questionBankId) {
                        if (
                            questionBank === null ||
                            (questionBank !== null &&
                                questionBank.id !== params.questionBankId)
                        ) {
                            await handleSelectQuestionBank(
                                params.questionBankId
                            )
                        }
                        setViewMode(QBDirectoryViewMode.FOLDERS)
                    }

                    break
                case QBDirectoryViewMode.CHAPTERS:
                    if (params.folderId) {
                        if (
                            folder === null ||
                            (folder !== null && folder.id !== params.folderId)
                        ) {
                            await handleSelectFolder(params.folderId)
                        }
                        setViewMode(QBDirectoryViewMode.CHAPTERS)
                    }

                    break
                case QBDirectoryViewMode.QUESTION_TYPES:
                    if (params.chapterId) {
                        if (
                            chapter === null ||
                            (chapter !== null &&
                                chapter.id !== params.chapterId)
                        ) {
                            await handleSelectChapter(params.chapterId)
                        }
                        setViewMode(QBDirectoryViewMode.QUESTION_TYPES)
                    }

                    break
                case QBDirectoryViewMode.ROOT_CHAPTER:
                    if (params.chapterId) {
                        if (
                            chapter === null ||
                            (chapter !== null &&
                                chapter.id !== params.chapterId)
                        ) {
                            await handleSelectChapter(params.chapterId)
                        }
                        setViewMode(QBDirectoryViewMode.ROOT_CHAPTER)
                    }

                    break
                case QBDirectoryViewMode.QUESTION_GROUPS:
                    if (params.questionTypeId) {
                        if (
                            questionType === null ||
                            (questionType !== null &&
                                questionType.id !== params.questionTypeId)
                        ) {
                            await handleSelectQuestionType(
                                params.questionTypeId
                            )
                        }
                        setViewMode(QBDirectoryViewMode.QUESTION_GROUPS)
                    }

                    break
                case QBDirectoryViewMode.QUESTIONS:
                    if (params.questionGroupId) {
                        if (
                            questionGroup === null ||
                            (questionGroup !== null &&
                                questionGroup.id !== params.questionGroupId)
                        ) {
                            await handleSelectQuestionGroup(
                                params.questionGroupId
                            )
                        }
                        if (params.questionId) {
                            if (
                                question === null ||
                                (question !== null &&
                                    question.id !== params.questionId)
                            ) {
                                await handleSelectQuestion(params.questionId)
                            }
                        }
                        setViewMode(QBDirectoryViewMode.QUESTIONS)
                    }

                    break
                // case QBDirectoryViewMode.QUESTION:
                // 	if (params.questionId) {
                // 		if (
                // 			question === null ||
                // 			(question !== null &&
                // 				question.id !== params.questionId)
                // 		) {
                // 			await handleSelectQuestion(params.questionId)
                // 		}
                // 		setViewMode(QBDirectoryViewMode.QUESTION)
                // 	}

                // 	break
                default:
                    return
            }
            withRefresh && setIsQBAssetLoading(false)
        }

        const defaultContextValue: QBAssetLibraryContextProps = {
            isQBAssetLoading,
            viewMode: viewMode,
            questionBank: questionBank,
            folder: folder,
            chapter: chapter,
            questionType: questionType,
            questionGroup: questionGroup,
            question: question,
            onViewModeChange: handleViewModeChange,
            onRefresh: handleRefresh,
            handleRearrangeFolders,
            handleRearrangeChapters,
            handleRearrangeQuestionTypes,
            handleRearrangeQuestionGroups,
        }

        return (
            <QBAssetLibraryContext.Provider value={defaultContextValue}>
                <Component {...props} />
            </QBAssetLibraryContext.Provider>
        )
    }
}
