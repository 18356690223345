import {
    createContext,
    ElementType,
    Fragment,
    useContext,
    useState,
} from 'react'
import { ConceptCard, Chapter } from '../../../../../assets/type/backend.type'
import ConceptCardCreateForm from '../../form/ConceptCardCreateForm'
import ConceptCardDeletePopUp from '../ConceptCardDeletePopUp'
import ConceptCardMoreActionPopUp from '../ConceptCardMoreActionPopUp'
import ConceptCardUpdateForm from '../../form/ConceptCardUpdateForm'
// import ConceptCardDeletePopUp from "../ConceptCardDeletePopUp"
// import ConceptCardMoreActionPopUp from "../ConceptCardMoreActionPopUp"

import { ConceptCardPopUpWrapperProps } from './type'
import { FConfirmPopUp } from '@fantaskticedtechlimited/fui-complib'
import { FOnSubmitFunction } from '@fantaskticedtechlimited/fui-complib'

const ConceptCardPopUpWrapperContext =
    createContext<ConceptCardPopUpWrapperProps>({
        isConceptCardMoreActionPopUpOpen: false,
        onConceptCardMoreActionPopUpOpen: async () => {},

        isConceptCardCreatePopUpOpen: false,
        onConceptCardCreatePopUpOpen: async () => {},

        isConceptCardUpdatePopUpOpen: false,
        onConceptCardUpdatePopUpOpen: async () => {},

        isConceptCardDeletePopUpOpen: false,
        onConceptCardDeletePopUpOpen: async () => {},
    })
export const useConceptCardPopUpContext = () =>
    useContext(ConceptCardPopUpWrapperContext)
export const WithConceptCardPopUpWrapper = (Component: ElementType) => {
    return function WithConceptCardPopUpWrapper(props: any) {
        // More Action PopUp
        const [moreActionData, setMoreActionData] = useState<{
            conceptCard: ConceptCard
            onDeleteCallback: FOnSubmitFunction<ConceptCard>
            onUpdateCallback: FOnSubmitFunction<ConceptCard>
        } | null>(null)
        const [
            isConceptCardMoreActionPopUpOpen,
            setIsConceptCardMoreActionPopUpOpen,
        ] = useState(false)
        const handleConceptCardMoreActionPopUpOpen = async (
            _conceptCard: ConceptCard,
            _callbackFunctions: {
                onUpdate: FOnSubmitFunction<ConceptCard>
                onDelete: FOnSubmitFunction<ConceptCard>
            }
        ) => {
            setIsConceptCardMoreActionPopUpOpen(true)
            setMoreActionData({
                conceptCard: _conceptCard,
                onDeleteCallback: _callbackFunctions.onDelete,
                onUpdateCallback: _callbackFunctions.onUpdate,
            })
        }
        const handleConceptCardMoreActionPopUpClose = () => {
            setIsConceptCardMoreActionPopUpOpen(false)
            setMoreActionData(null)
        }

        // ConceptCard Create
        const [conceptCardCreateData, setConceptCardCreateData] = useState<{
            chapter: Chapter
            callback: FOnSubmitFunction<ConceptCard>
        } | null>(null)
        const [isConceptCardCreatePopUpOpen, setIsConceptCardCreatePopUpOpen] =
            useState(false)
        const handleConceptCardCreatePopUpOpen = async (
            _chapter: Chapter,
            _callbackFunction: FOnSubmitFunction<ConceptCard>
        ) => {
            setConceptCardCreateData({
                chapter: _chapter,
                callback: _callbackFunction,
            })
            setIsConceptCardCreatePopUpOpen(true)
        }
        const handleConceptCardCreatePopUpSubmit = async (
            newConceptCard: ConceptCard
        ) => {
            conceptCardCreateData &&
                (await conceptCardCreateData.callback(newConceptCard)) 
        }
        const handleConceptCardCreatePopUpClose = () => {
            setIsConceptCardCreatePopUpOpen(false)
            setConceptCardCreateData(null)
        }

        // ConceptCard Update
        const [conceptCardUpdateData, setConceptCardUpdateData] = useState<{
            conceptCard: ConceptCard
            callback: FOnSubmitFunction<ConceptCard>
        } | null>(null)
        const [isConceptCardUpdatePopUpOpen, setIsConceptCardUpdatePopUpOpen] =
            useState(false)
        const handleConceptCardUpdatePopUpOpen = async (
            _conceptCard: ConceptCard,
            _callbackFunction: FOnSubmitFunction<ConceptCard>
        ) => {
            setConceptCardUpdateData({
                conceptCard: _conceptCard,
                callback: _callbackFunction,
            })
            setIsConceptCardUpdatePopUpOpen(true)
        }
        const handleConceptCardUpdatePopUpSubmit = async (
            updatedConceptCard: ConceptCard
        ) => {
            conceptCardUpdateData &&
                (await conceptCardUpdateData.callback(updatedConceptCard)) 
        }
        const handleConceptCardUpdatePopUpClose = () => {
            setIsConceptCardUpdatePopUpOpen(false)
            setConceptCardUpdateData(null)
        }

        // ConceptCard Delete
        const [conceptCardDeleteData, setConceptCardDeleteData] = useState<{
            conceptCard: ConceptCard
            callback: FOnSubmitFunction<ConceptCard>
        } | null>(null)
        const [isConceptCardDeletePopUpOpen, setIsConceptCardDeletePopUpOpen] =
            useState(false)
        const handleConceptCardDeletePopUpOpen = async (
            _conceptCard: ConceptCard,
            _callbackFunction: FOnSubmitFunction<ConceptCard>
        ) => {
            setIsConceptCardDeletePopUpOpen(true)
            setConceptCardDeleteData({
                conceptCard: _conceptCard,
                callback: _callbackFunction,
            })
        }
        const handleConceptCardDeletePopUpClose = () => {
            setIsConceptCardDeletePopUpOpen(false)
            setConceptCardDeleteData(null)
        }

        // Context
        const defaultContextValue: ConceptCardPopUpWrapperProps = {
            isConceptCardMoreActionPopUpOpen,
            onConceptCardMoreActionPopUpOpen:
                handleConceptCardMoreActionPopUpOpen,

            isConceptCardCreatePopUpOpen,
            onConceptCardCreatePopUpOpen: handleConceptCardCreatePopUpOpen,

            isConceptCardUpdatePopUpOpen,
            onConceptCardUpdatePopUpOpen: handleConceptCardUpdatePopUpOpen,

            isConceptCardDeletePopUpOpen,
            onConceptCardDeletePopUpOpen: handleConceptCardDeletePopUpOpen,
        }

        return (
            <ConceptCardPopUpWrapperContext.Provider
                value={defaultContextValue}
            >
                <Fragment>
                    <Component {...props} />

                    {/* More Action PopUp */}
                    {/* {isConceptCardMoreActionPopUpOpen &&
                        moreActionData !== null && (
                            <ConceptCardMoreActionPopUp
                                onClose={() =>
                                    handleConceptCardMoreActionPopUpClose()
                                }
                                conceptCard={moreActionData.conceptCard}
                                onCallback={async (data) =>
                                    await moreActionData.onDeleteCallback(data)
                                }
                            />
                        )} */}

                    {/* ConceptCard Create PopUp */}
                    {/* {isConceptCardCreatePopUpOpen && conceptCardCreateData && (
                        <FConfirmPopUp
                            style={{ minWidth: '30%' }}
                            title="New ConceptCard"
                            disableCloseWhenClickOutside
                            disableBottomBar
                        >
                            <ConceptCardCreateForm
                                chapter={conceptCardCreateData.chapter}
                                onSubmitCallback={async (data) =>
                                    await handleConceptCardCreatePopUpSubmit(
                                        data
                                    )
                                }
                                onClose={handleConceptCardCreatePopUpClose}
                            />
                        </FConfirmPopUp>
                    )} */}

                    {/* Update ConceptCard PopUp*/}
                    {/* {isConceptCardUpdatePopUpOpen && conceptCardUpdateData && (
                        <FConfirmPopUp
                            style={{ minWidth: '30%' }}
                            title="Edit ConceptCard"
                            disableCloseWhenClickOutside
                            disableBottomBar
                        >
                            <ConceptCardUpdateForm
                                conceptCard={conceptCardUpdateData.conceptCard}
                                onClose={handleConceptCardUpdatePopUpClose}
                                onSubmitCallback={async (data) =>
                                    await handleConceptCardUpdatePopUpSubmit(
                                        data
                                    )
                                }
                            />
                        </FConfirmPopUp>
                    )} */}

                    {/* ConceptCard Delete PopUp */}
                    {/* {isConceptCardDeletePopUpOpen &&
                        conceptCardDeleteData !== null && (
                            <ConceptCardDeletePopUp
                                style={{ minWidth: '30%' }}
                                onClose={handleConceptCardDeletePopUpClose}
                                conceptCard={conceptCardDeleteData?.conceptCard}
                                onCallback={async (data) =>
                                    await conceptCardDeleteData.callback(data)
                                }
                            />
                        )} */}
                </Fragment>
            </ConceptCardPopUpWrapperContext.Provider>
        )
    }
}
