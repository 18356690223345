import { gql } from '@apollo/client'

export const QB_GROUP_DELETE_BY_ID_MUTATION = gql`
    mutation qbQuestionGroupDeleteById($questionGroupId: String!) {
        qbQuestionGroupDeleteById(questionGroupId: $questionGroupId) {
            isSuccess
            code
            data {
                id
                name
                questionTypeId
            }
        }
    }
`
