import {
    FNoData,
    FScrollBarStyle,
    FDragAndDrop,
} from '@fantaskticedtechlimited/fui-complib'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useQBAPIService from '../../../apiService/questionBank'
import { QuestionBank } from '../../../assets/type/backend.type'
import { useUserSettingContext } from '../../../contexts/userSettingContext'
import { PageContainer } from '../../../layout/PageContainer'
import {
    useLoadingPageWrapperContext,
    WithLoadingPageWrapper,
} from '../../../layout/WithLoadingPageWrapper'
import { QBAssetDropdownMenu } from '../../QBAssetLibrary/components/QBAssetDropdownMenu'
import { QBAssetListCardQBContent } from '../../QBAssetLibrary/components/QBAssetListCardContent/QuestionBank'
import { QBDirectoryViewHeader } from '../../QBAssetLibrary/QBAssetLibraryView/header'
import { useQuestionBankPopUpContext } from '../../QuestionBankContent/components/popUp/WithQuestionBankPopUpWrapper'
import { styles } from './styles'

function QuestionBankHome() {
    const { setLoadingStart, setLoadingEnd } = useLoadingPageWrapperContext()
    const navigate = useNavigate()
    const { setQuestionBank } = useUserSettingContext()
    const { onQuestionBankCreatePopUpOpen } = useQuestionBankPopUpContext()
    const QBAPI = useQBAPIService()
    const [questionBankBasicList, setQuestionBankBasicList] = useState<
        QuestionBank[]
    >([])

    const handleGetQB = async () => {
        setLoadingStart()
        try {
            const QBResult = await QBAPI.getQuestionBanksArchitectureByFilter(
                {}
            )
            if (QBResult.data.questionBanksGet.isSuccess) {
                setQuestionBankBasicList(
                    QBResult.data.questionBanksGet.data || []
                )
            }
        } catch (error) {
            console.log('Error = ', error)
        }
        setLoadingEnd()
    }

    useEffect(() => {
        handleGetQB()
    }, [])

    return (
        <PageContainer
            showBreadcrumb={false}
            header={
                <QBDirectoryViewHeader
                    disableBackButton
                    title="Question Banks"
                    CTAButtonLabel="+  Add Question Bank"
                    onCTAButtonClick={() =>
                        onQuestionBankCreatePopUpOpen(async () => {
                            await handleGetQB()
                        })
                    }
                />
            }
            body={
                <div
                    className={
                        styles.questionBankList_Wrapper +
                        ' ' +
                        FScrollBarStyle()
                    }
                >
                    {questionBankBasicList.length === 0 ? (
                        <FNoData />
                    ) : (
                        <FDragAndDrop
                            disableDndAction
                            data={questionBankBasicList}
                            children={(_questionBank) => (
                                <>
                                    <QBAssetListCardQBContent
                                        data={_questionBank}
                                        onClick={() => {
                                            setQuestionBank(_questionBank)
                                            navigate('/qbAssetLibrary')
                                        }}
                                    />
                                    <QBAssetDropdownMenu disabled />
                                </>
                            )}
                        />
                    )}
                </div>
            }
        />
    )
}

export default WithLoadingPageWrapper(QuestionBankHome, true)
