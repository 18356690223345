import { gql } from '@apollo/client'
import { PHOTO_DETAIL_FIELDS } from '../fragments/photoDetail.gql'

export const GET_QUESTIONBANK_DETAIL_BY_FILTER = gql`
    query getQuestionBanksDetailByFilter(
        $questionBankFilterInput: QuestionBankFilterInput
    ) {
        questionBanksGet(questionBankFilterInput: $questionBankFilterInput) {
            isSuccess
            errorMessage
            code
            data {
                id
                name
                subject
                examSystem
                folders {
                    id
                    name
                    isEnable
                    isFlagged
                    icon {
                        ...photoDetail
                    }
                    chapters {
                        id
                        name
                    }
                }
            }
        }
    }

    ${PHOTO_DETAIL_FIELDS}
`
