import {
    createContext,
    ElementType,
    Fragment,
    useContext,
    useState,
} from 'react'
import { QuestionBank } from '../../../../../assets/type/backend.type'
import { QuestionBankPopUpWrapperProps } from './type'
import {
    FConfirmPopUp,
    FOnSubmitFunction,
} from '@fantaskticedtechlimited/fui-complib'
import QuestionBankDeletePopUp from '../QuestionBankDeletePopUp'
import QuestionBankCreateForm from '../../form/QuestionBankCreateForm'

const QuestionBankPopUpWrapperContext =
    createContext<QuestionBankPopUpWrapperProps>({
        isQuestionBankCreatePopUpOpen: false,
        onQuestionBankCreatePopUpOpen: async () => {},

        // isQuestionBankUpdatePopUpOpen: false,
        // onQuestionBankUpdatePopUpOpen: async () => {},

        // isQuestionBankDeletePopUpOpen: false,
        // onQuestionBankDeletePopUpOpen: async () => {},
    })
export const useQuestionBankPopUpContext = () =>
    useContext(QuestionBankPopUpWrapperContext)

export const WithQuestionBankPopUpWrapper = (Component: ElementType) => {
    return function WithQuestionBankPopUpWrapper(props: any) {
        // More Action PopUp
        const [moreActionData, setMoreActionData] = useState<{
            questionBank: QuestionBank
            onDeleteCallback: FOnSubmitFunction<QuestionBank>
            onUpdateCallback: FOnSubmitFunction<QuestionBank>
        } | null>(null)
        const [
            isQuestionBankMoreActionPopUpOpen,
            setIsQuestionBankMoreActionPopUpOpen,
        ] = useState(false)
        // QuestionBank Create
        const [questionBankCreateData, setQuestionBankCreateData] = useState<{
            callback: FOnSubmitFunction<QuestionBank>
        } | null>(null)
        const [
            isQuestionBankCreatePopUpOpen,
            setIsQuestionBankCreatePopUpOpen,
        ] = useState(false)
        // QuestionBank Update
        const [questionBankUpdateData, setQuestionBankUpdateData] = useState<{
            questionBank: QuestionBank
            callback: FOnSubmitFunction<QuestionBank>
        } | null>(null)
        const [
            isQuestionBankUpdatePopUpOpen,
            setIsQuestionBankUpdatePopUpOpen,
        ] = useState(false)
        // QuestionBank Delete
        const [questionBankDeleteData, setQuestionBankDeleteData] = useState<{
            questionBank: QuestionBank
            callback: FOnSubmitFunction<QuestionBank>
        } | null>(null)
        const [
            isQuestionBankDeletePopUpOpen,
            setIsQuestionBankDeletePopUpOpen,
        ] = useState(false)

        const handleQuestionBankMoreActionPopUpOpen = async (
            _questionBank: QuestionBank,
            _callbackFunctions: {
                onUpdate: FOnSubmitFunction<QuestionBank>
                onDelete: FOnSubmitFunction<QuestionBank>
            }
        ) => {
            setIsQuestionBankMoreActionPopUpOpen(true)
            setMoreActionData({
                questionBank: _questionBank,
                onDeleteCallback: _callbackFunctions.onDelete,
                onUpdateCallback: _callbackFunctions.onUpdate,
            })
        }

        const handleQuestionBankMoreActionPopUpClose = () => {
            setIsQuestionBankMoreActionPopUpOpen(false)
            setMoreActionData(null)
        }

        const handleQuestionBankCreatePopUpOpen = async (
            _callbackFunction: FOnSubmitFunction<QuestionBank>
        ) => {
            setQuestionBankCreateData({
                callback: _callbackFunction,
            })
            setIsQuestionBankCreatePopUpOpen(true)
        }

        const handleQuestionBankCreatePopUpSubmit = async (
            newQuestionBank: QuestionBank
        ) => {
            questionBankCreateData &&
                (await questionBankCreateData.callback(newQuestionBank)) 
        }

        const handleQuestionBankCreatePopUpClose = () => {
            setIsQuestionBankCreatePopUpOpen(false)
            setQuestionBankCreateData(null)
        }

        const handleQuestionBankUpdatePopUpOpen = async (
            _questionBank: QuestionBank,
            _callbackFunction: FOnSubmitFunction<QuestionBank>
        ) => {
            setQuestionBankUpdateData({
                questionBank: _questionBank,
                callback: _callbackFunction,
            })
            setIsQuestionBankUpdatePopUpOpen(true)
        }

        const handleQuestionBankUpdatePopUpSubmit = async (
            updatedQuestionBank: QuestionBank
        ) => {
            questionBankUpdateData &&
                (await questionBankUpdateData.callback(updatedQuestionBank)) 
        }

        const handleQuestionBankUpdatePopUpClose = () => {
            setIsQuestionBankUpdatePopUpOpen(false)
            setQuestionBankUpdateData(null)
        }

        const handleQuestionBankDeletePopUpOpen = async (
            _questionBank: QuestionBank,
            _callbackFunction: FOnSubmitFunction<QuestionBank>
        ) => {
            setIsQuestionBankDeletePopUpOpen(true)
            setQuestionBankDeleteData({
                questionBank: _questionBank,
                callback: _callbackFunction,
            })
        }

        const handleQuestionBankDeletePopUpClose = () => {
            setIsQuestionBankDeletePopUpOpen(false)
            setQuestionBankDeleteData(null)
        }

        // Context
        const defaultContextValue: QuestionBankPopUpWrapperProps = {
            isQuestionBankCreatePopUpOpen,
            onQuestionBankCreatePopUpOpen: handleQuestionBankCreatePopUpOpen,

            // isQuestionBankUpdatePopUpOpen,
            // onQuestionBankUpdatePopUpOpen: handleQuestionBankUpdatePopUpOpen,

            // isQuestionBankDeletePopUpOpen,
            // onQuestionBankDeletePopUpOpen: handleQuestionBankDeletePopUpOpen,
        }

        return (
            <QuestionBankPopUpWrapperContext.Provider
                value={defaultContextValue}
            >
                <Fragment>
                    <Component {...props} />

                    {/* QuestionBank Create PopUp */}
                    {isQuestionBankCreatePopUpOpen && questionBankCreateData && (
                        <FConfirmPopUp
                            title="Create QuestionBank"
                            disableCloseWhenClickOutside
                            disableBottomBar
                            style={{ width: '30%', height: 'fit-content' }}
                        >
                            <QuestionBankCreateForm
                                onSubmitCallback={async (data) =>
                                    await handleQuestionBankCreatePopUpSubmit(
                                        data
                                    )
                                }
                                onClose={handleQuestionBankCreatePopUpClose}
                            />
                        </FConfirmPopUp>
                    )}

                    {/* Update QuestionBank PopUp*/}
                    {/* {isQuestionBankUpdatePopUpOpen && questionBankUpdateData && (
                        <FConfirmPopUp 
                            title="Edit QuestionBank"
                            disableCloseWhenClickOutside
                            
                        >
                              <QuestionBankUpdateForm
								questionBank={questionBankUpdateData.questionBank}
								onClose={handleQuestionBankUpdatePopUpClose}
								onSubmit={async (data) =>
									await handleQuestionBankUpdatePopUpSubmit(data)
								}
							/>  
                        </FConfirmPopUp>
                    )} */}

                    {/* QuestionBank Delete PopUp */}
                    {/* {isQuestionBankDeletePopUpOpen &&
                        questionBankDeleteData !== null && (
                            <QuestionBankDeletePopUp
                                onClose={handleQuestionBankDeletePopUpClose}
                                questionBank={
                                    questionBankDeleteData?.questionBank
                                }
                                onCallback={async (data) =>
                                    await questionBankDeleteData.callback(data)
                                }
                            />
                        )} */}
                </Fragment>
            </QuestionBankPopUpWrapperContext.Provider>
        )
    }
}
