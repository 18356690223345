import { gql } from '@apollo/client'

export const QB_QUESTION_BANK_CREATE_MUTATION = gql`
    mutation qbQuestionBankCreate(
        $questionBankCreateInput: QuestionBankCreateInput!
    ) {
        qbQuestionBankCreate(
            questionBankCreateInput: $questionBankCreateInput
        ) {
            code
            isSuccess
            errorMessage
            data {
                id
                name
                examSystem
                subject
            }
        }
    }
`
