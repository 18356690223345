import { style } from 'typestyle'

export const FormContent_Container = style({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    columnGap: '12px',
})

export const FormContent_Input_Container = style({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    rowGap: '1rem',

    // for scrolling
    height: '100%',
    width: '100%',
})

export const FormContent_Preview_Container = style({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    rowGap: '1rem',

    // for scrolling
    height: '100%',
    width: '100%',
})
