export const dragDropIcon = (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        cursor="move"
    >
        <path
            d="M8 13C8.55228 13 9 12.5523 9 12C9 11.4477 8.55228 11 8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13Z"
            fill="#D0D0D7"
            stroke="#D0D0D7"
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8 6C8.55228 6 9 5.55228 9 5C9 4.44772 8.55228 4 8 4C7.44772 4 7 4.44772 7 5C7 5.55228 7.44772 6 8 6Z"
            fill="#D0D0D7"
            stroke="#D0D0D7"
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8 20C8.55228 20 9 19.5523 9 19C9 18.4477 8.55228 18 8 18C7.44772 18 7 18.4477 7 19C7 19.5523 7.44772 20 8 20Z"
            fill="#D0D0D7"
            stroke="#D0D0D7"
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16 13C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11C15.4477 11 15 11.4477 15 12C15 12.5523 15.4477 13 16 13Z"
            fill="#D0D0D7"
            stroke="#D0D0D7"
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16 6C16.5523 6 17 5.55228 17 5C17 4.44772 16.5523 4 16 4C15.4477 4 15 4.44772 15 5C15 5.55228 15.4477 6 16 6Z"
            fill="#D0D0D7"
            stroke="#D0D0D7"
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16 20C16.5523 20 17 19.5523 17 19C17 18.4477 16.5523 18 16 18C15.4477 18 15 18.4477 15 19C15 19.5523 15.4477 20 16 20Z"
            fill="#D0D0D7"
            stroke="#D0D0D7"
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export const folderIcon = (
    <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            width="40"
            height="40"
            rx="20"
            fill="#483FE2"
            fillOpacity="0.08"
        />
        <path
            d="M30.0001 27C30.0001 27.5304 29.7894 28.0391 29.4143 28.4142C29.0392 28.7893 28.5305 29 28.0001 29H12.0001C11.4697 29 10.961 28.7893 10.5859 28.4142C10.2108 28.0391 10.0001 27.5304 10.0001 27V13C10.0001 12.4696 10.2108 11.9609 10.5859 11.5858C10.961 11.2107 11.4697 11 12.0001 11H17.0001L19.0001 14H28.0001C28.5305 14 29.0392 14.2107 29.4143 14.5858C29.7894 14.9609 30.0001 15.4696 30.0001 16V27Z"
            stroke="#4B41F5"
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export const selectionButtonBookOpenIcon = (
    <svg
        overflow="visible"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M2 3H8C9.06087 3 10.0783 3.42143 10.8284 4.17157C11.5786 4.92172 12 5.93913 12 7V21C12 20.2044 11.6839 19.4413 11.1213 18.8787C10.5587 18.3161 9.79565 18 9 18H2V3Z"
            stroke="white"
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M22 3H16C14.9391 3 13.9217 3.42143 13.1716 4.17157C12.4214 4.92172 12 5.93913 12 7V21C12 20.2044 12.3161 19.4413 12.8787 18.8787C13.4413 18.3161 14.2044 18 15 18H22V3Z"
            stroke="white"
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export const selectionButtonFolderIcon = (
    <svg
        overflow="visible"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M14.6667 12.6667C14.6667 13.0203 14.5262 13.3594 14.2761 13.6095C14.0261 13.8595 13.687 14 13.3333 14H2.66667C2.31304 14 1.9739 13.8595 1.72386 13.6095C1.47381 13.3594 1.33333 13.0203 1.33333 12.6667V3.33333C1.33333 2.97971 1.47381 2.64057 1.72386 2.39052C1.9739 2.14048 2.31304 2 2.66667 2H6L7.33333 4H13.3333C13.687 4 14.0261 4.14048 14.2761 4.39052C14.5262 4.64057 14.6667 4.97971 14.6667 5.33333V12.6667Z"
            stroke="white"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export const popupButtonCopyIcon = (
    <svg
        overflow="visible"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M13.3333 6H7.33333C6.59695 6 6 6.59695 6 7.33333V13.3333C6 14.0697 6.59695 14.6667 7.33333 14.6667H13.3333C14.0697 14.6667 14.6667 14.0697 14.6667 13.3333V7.33333C14.6667 6.59695 14.0697 6 13.3333 6Z"
            stroke="#14153A"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M3.33325 9.99992H2.66659C2.31296 9.99992 1.97382 9.85944 1.72378 9.60939C1.47373 9.35935 1.33325 9.02021 1.33325 8.66659V2.66659C1.33325 2.31296 1.47373 1.97382 1.72378 1.72378C1.97382 1.47373 2.31296 1.33325 2.66659 1.33325H8.66659C9.02021 1.33325 9.35935 1.47373 9.60939 1.72378C9.85944 1.97382 9.99992 2.31296 9.99992 2.66659V3.33325"
            stroke="#14153A"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export const popupButtonEditIcon = (
    <svg
        overflow="visible"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11.3333 2.00004C11.5083 1.82494 11.7162 1.68605 11.945 1.59129C12.1738 1.49653 12.419 1.44775 12.6666 1.44775C12.9142 1.44775 13.1594 1.49653 13.3882 1.59129C13.617 1.68605 13.8248 1.82494 13.9999 2.00004C14.175 2.17513 14.3139 2.383 14.4087 2.61178C14.5034 2.84055 14.5522 3.08575 14.5522 3.33337C14.5522 3.58099 14.5034 3.82619 14.4087 4.05497C14.3139 4.28374 14.175 4.49161 13.9999 4.66671L4.99992 13.6667L1.33325 14.6667L2.33325 11L11.3333 2.00004Z"
            stroke="#14153A"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export const popupButtonFolderIcon = (
    <svg
        overflow="visible"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M14.6666 12.6667C14.6666 13.0203 14.5261 13.3594 14.2761 13.6095C14.026 13.8595 13.6869 14 13.3333 14H2.66659C2.31296 14 1.97382 13.8595 1.72378 13.6095C1.47373 13.3594 1.33325 13.0203 1.33325 12.6667V3.33333C1.33325 2.97971 1.47373 2.64057 1.72378 2.39052C1.97382 2.14048 2.31296 2 2.66659 2H5.99992L7.33325 4H13.3333C13.6869 4 14.026 4.14048 14.2761 4.39052C14.5261 4.64057 14.6666 4.97971 14.6666 5.33333V12.6667Z"
            stroke="#14153A"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8 7.33325V11.3333"
            stroke="#14153A"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6 9.33325H10"
            stroke="#14153A"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export const popupButtonArchiveIcon = (
    <svg
        overflow="visible"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M14 5.33325V13.9999H2V5.33325"
            stroke="#F95141"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M15.3334 2H0.666748V5.33333H15.3334V2Z"
            stroke="#F95141"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6.66675 8H9.33341"
            stroke="#F95141"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export const popupCloseIcon = (
    <svg
        overflow="visible"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
            stroke="#D0D0D7"
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M15 9L9 15"
            stroke="#D0D0D7"
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9 9L15 15"
            stroke="#D0D0D7"
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export const dropdownListIcon = (
    <svg
        overflow="visible"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M5.33337 4H14"
            stroke="#14153A"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M5.33337 8H14"
            stroke="#14153A"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M5.33337 12H14"
            stroke="#14153A"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M2 4H2.00667"
            stroke="#14153A"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M2 8H2.00667"
            stroke="#14153A"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M2 12H2.00667"
            stroke="#14153A"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
