import { QuestionRowProps } from './types'
import {
    MCContent,
    Question,
    QuestionInputTypes,
} from '../../../../../assets/type/backend.type'
import { FText } from '@fantaskticedtechlimited/fui-complib'
import FListCard from '../../../../../Components/FListCard'
import { Fragment } from 'react'
import { FIcon, FIconNames } from '@fantaskticedtechlimited/fui-iconlib'
import FMarkdownView from '../../../../../Components/FMarkdown/FMarkdownView'
import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { FFontTypes, FFontFamily } from '@fantaskticedtechlimited/fui-fontlib'
import * as styles from './styles'

const MCQuestionRowCard = (question: Question) => {
    //
    let _content = question.content as MCContent
    return <FMarkdownView>{_content.body}</FMarkdownView>
}
const DefaultQuestionRowCard = (question: Question) => {
    return (
        <FText>
            {`Require Question Row card on inputType ${question.inputType}`}
        </FText>
    )
}

const QuestionRowCardPicker = (question: Question) => {
    switch (question.inputType) {
        case QuestionInputTypes.MULTIPLE_CHOICE:
            return MCQuestionRowCard(question)
        default:
            return DefaultQuestionRowCard(question)
    }
}
function QuestionRow(props: QuestionRowProps) {
    //
    return (
        <FListCard
            onClick={props.onClick}
            content={
                <Fragment>
                    {/* data in middle top */}
                    {QuestionRowCardPicker(props.question)}
                    {/* data in middle bottom */}
                    {/* tags */}
                    <div className={styles.FListCard_TagsRow_Container}>
                        {props.question.tags.map((tag, tagIndex) => {
                            return (
                                <FText
                                    key={tagIndex}
                                    font={FFontTypes.Text({
                                        fontFamily: FFontFamily.Poppins,
                                        fontWeight: 400,
                                    })}
                                    color={FLegacyColorTypes.PRIMARY_GREY}
                                    children={`#${tag.name} `}
                                />
                            )
                        })}
                    </div>
                </Fragment>
            }
            toolBar={
                <FIcon
                    name={FIconNames.MORE}
                    color={() => FLegacyColorTypes.PRIMARY_GREY}
                    onClick={() => {
                        props.onMoreActionButtonClick()
                    }}
                />
            }
        />
    )
}

export default QuestionRow
