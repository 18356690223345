import { gql } from '@apollo/client'
import { PHOTO_DETAIL_FIELDS } from '../fragments/photoDetail.gql'

export const GET_QUESTIONBANK_CONTENT_QUERY = gql`
    query getQuestionBankContentById($id: String!) {
        questionBankGet(id: $id) {
            isSuccess
            errorMessage
            code
            data {
                id
                name
                subject
                examSystem
                folders {
                    id
                    orderSequence
                    questionBankId
                    name
                    isEnable
                    isFlagged
                    icon {
                        ...photoDetail
                    }
                    chapters {
                        id
                        orderSequence
                        questionBankId
                        name
                        questionTypes {
                            id
                            orderSequence
                            questionGroups {
                                id
                                orderSequence
                            }
                        }
                    }
                    createAt
                    updateAt
                }
            }
        }
    }

    ${PHOTO_DETAIL_FIELDS}
`
