import { gql } from '@apollo/client'

export const GET_GROUPS_BY_FILTER = gql`
    query getQuestionGroupsByFilter(
        $questionGroupFilterInput: QuestionGroupFilterInput
    ) {
        questionGroupsGet(questionGroupFilterInput: $questionGroupFilterInput) {
            code
            errorMessage
            isSuccess
            data {
                id
                chapterId
                questionTypeId
                name
                questionBankId
                defaultQuestionId
                createAt
                updateAt
            }
        }
    }
`
