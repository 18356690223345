import { FForm, FInputField, FText } from '@fantaskticedtechlimited/fui-complib'
import { Fragment, useState } from 'react'
import useQBAPIService from '../../../../../apiService/questionBank'
import {
    ChapterUpdateInput,
    Chapter,
    AttachmentTypes,
} from '../../../../../assets/type/backend.type'
import FirebaseFileUploader from '../../../../../Components/FirebaseFileUploader'
import firebaseAppWithConfig from '../../../../../config/firebaseConfig'
import removeTypename from '../../../../../utils/removeTypename'

import { ChapterUpdateFormProps } from './types'
import * as styles from './styles'
import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { FFontTypes } from '@fantaskticedtechlimited/fui-fontlib'
import { FIcon, FIconNames } from '@fantaskticedtechlimited/fui-iconlib'

export const ChapterUpdateForm = (props: ChapterUpdateFormProps) => {
    const qbAPIService = useQBAPIService()
    let defaultValue: ChapterUpdateInput = {
        chapterId: props.chapter.id,
        name: props.chapter.name ?? 'Name',
        description: props.chapter.description,
        icon: props.chapter.icon,
        isClearDescription: false,
        isClearIcon: false,
    }
    const [chapterUpdateInput, setChapterUpdateInput] =
        useState<ChapterUpdateInput>({
            ...defaultValue,
        })

    const handleChapterUpdate = async (
        _chapterUpdateInput: ChapterUpdateInput
    ) => {
        try {
            let chapterUpdateInput = removeTypename(_chapterUpdateInput)
            const result = await qbAPIService.qbChapterUpdateByIdMutation({
                variables: {
                    chapterUpdateInput,
                },
            })
            if (result.data?.qbChapterUpdateById.isSuccess) {
                props.onSubmit &&
                    (await props.onSubmit(
                        result.data.qbChapterUpdateById.data as Chapter
                    ))
                props.onClose && (await props.onClose())
            } else {
                console.error(
                    `Chapter update failed, Reason. ${JSON.stringify(result)}`
                )
                alert(
                    `Chapter update failed, Reason.  ${result.data?.qbChapterUpdateById.errorMessage}`
                )
            }
        } catch (error) {
            console.log('error', error)
            alert(`Chapter update failed, Error: ${JSON.stringify(error)}`)
        }
    }

    return (
        <FForm
            onClose={props.onClose}
            onSubmit={() => handleChapterUpdate(chapterUpdateInput)}
            bottomBarProps={{ actionButtonLabel: 'Update' }}
        >
            <FInputField
                autoFocus
                label="Chapter Name"
                value={chapterUpdateInput.name!}
                wordCount={100}
                onInput={(v) =>
                    setChapterUpdateInput({
                        ...chapterUpdateInput,
                        name: v,
                    })
                }
            />
            {/* Chapter Icon */}
            {/* <div className={styles.ChapterUpdateForm_IconRow_Div}>
                    <FText font={FFontTypes.Large_Text()} children="Icon: " />
                    {chapterUpdateInput.icon !== null ? (
                        <div
                            className={styles.ChapterUpdateForm_IconDisplay_Div}
                        >
                            <img
                                alt="icon"
                                src={chapterUpdateInput.icon?.fileURL}
                                style={{
                                    width: '200px',
                                    height: '200px',
                                }}
                            />
                            <FIcon
                                name={FIconNames.DELETE}
                                strokeColor={FLegacyColorTypes.SECONDARY_RED}
                                onClick={() => {
                                    setChapterUpdateInput({
                                        ...chapterUpdateInput,
                                        icon: null,
                                    })
                                }}
                            />
                        </div>
                    ) : (
                        <FText
                            font={FFontTypes.Large_Text()}
                            color={FLegacyColorTypes.SECONDARY_RED}
                            children="No Icon"
                        />
                    )}
                </div>
                <FirebaseFileUploader
                    multiple={false}
                    maxNumberOfFiles={1}
                    accept=".jpg,.jpeg,.png,.gif"
                    iconUploaded={chapterUpdateInput.icon !== null}
                    onUploadedFiles={(e) => {
                        if (e.length === 1) {
                            const file = e[0]
                            setChapterUpdateInput({
                                ...chapterUpdateInput,
                                icon: {
                                    fileURL: file.fileURL,
                                    filePath: file.filePath,
                                    fileName: file.fileName,
                                    originalFileName: file.originalFileName,
                                    attachmentFileType: AttachmentTypes.ICON,
                                    size: 0,
                                    contentType: file.contentType,
                                },
                                isClearIcon: false,
                            })
                        }
                    }}
                    firebaseApp={firebaseAppWithConfig}
                    fileSavePath={iconPath}
                    withDND
                    withStatus
                /> */}
            <FInputField
                label="Description"
                value={chapterUpdateInput.description ?? ''}
                wordCount={500}
                multiline
                onInput={(v) =>
                    setChapterUpdateInput({
                        ...chapterUpdateInput,
                        description: v,
                    })
                }
            />
        </FForm>
    )
}
