import { gql } from '@apollo/client'

import { PHOTO_DETAIL_FIELDS } from '../fragments/photoDetail.gql'

export const GET_FOLDERS_BY_FILTER = gql`
    query getFoldersByFilter($folderFilterInput: FolderFilterInput) {
        foldersGet(folderFilterInput: $folderFilterInput) {
            isSuccess
            errorMessage
            code
            data {
                id
                name
                isEnable
                isFlagged
                icon {
                    ...photoDetail
                }
                chapters {
                    id
                    name
                    createAt
                    updateAt
                }
                createAt
                updateAt
            }
        }
    }
    ${PHOTO_DETAIL_FIELDS}
`
