import { gql } from '@apollo/client'

export const UM_USER_DELETE_BY_ID_MUTATION = gql`
    mutation umUserDeleteById($userId: String!) {
        umUserDeleteById(userId: $userId) {
            isSuccess
            code
            errorMessage
            data {
                id
                email
                firebaseUID
            }
        }
    }
`
