import { gql } from '@apollo/client'

export const QUESTION_DETAIL_FIELDS = gql`
    fragment questionDetail on Question {
        id
        questionBankId
        level
        questionGroupId

        isFlaged
        inputType
        content {
            inputType
            ... on MCContent {
                body
            }
            ... on ShortQuestionContent {
                body
            }
        }
    }
`
