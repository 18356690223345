import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { FMoreActionPopUp } from '@fantaskticedtechlimited/fui-complib'
import { MoreActionItemProps } from '@fantaskticedtechlimited/fui-complib/lib/esm/FMoreActionPopUp/MoreActionItem/types'
import { Folder } from '../../../../../assets/type/backend.type'

import { popupButtonEditIcon, popupButtonArchiveIcon } from '../../svg'
import { useFolderPopUpWrapperContext } from '../WithFolderPopUpWrapper'
import { FolderMoreActionPopUpProps } from './types'

function FolderMoreActionPopUp(props: FolderMoreActionPopUpProps) {
    const {
        onFolderUpdatePopUpOpen,
        isFolderUpdatePopUpOpen,
        onFolderDeletePopUpOpen,
        isFolderDeletePopUpOpen,
    } = useFolderPopUpWrapperContext()

    const folderPopupButtonParams: MoreActionItemProps[] = [
        {
            name: 'Edit Folder',
            icon: popupButtonEditIcon,
            onClick: () => {
                onFolderUpdatePopUpOpen(props.folder, handleFolderUpdate)
            },
        },
        {
            name: 'Delete Folder',
            icon: popupButtonArchiveIcon,
            fontColor: FLegacyColorTypes.SECONDARY_RED,
            onClick: () => {
                onFolderDeletePopUpOpen(props.folder, handleFolderDelete)
            },
        },
    ]

    const handleFolderUpdate = async (data: Folder) => {
        // await props.onCallback(data)
        //  if add await, it will wait refresh then close
        await props.onCallback(data)
        props.onClose && (await props.onClose())
    }
    const handleFolderMoveToFolder = async (data: Folder) => {
        await props.onCallback(data)
        props.onClose && (await props.onClose())
    }

    const handleFolderDelete = async (data: Folder) => {
        await props.onCallback(data)
        props.onClose && (await props.onClose())
    }

    return (
        <FMoreActionPopUp
            header="More Action"
            title={`Folder: ${props.folder.name}`}
            moreActionItemList={folderPopupButtonParams}
            onClose={props.onClose!}
            disableCloseWhenClickOutside={
                props.disableCloseWhenClickOutside ||
                isFolderDeletePopUpOpen ||
                isFolderUpdatePopUpOpen
            }
            children={undefined}
        />
    )
}

export default FolderMoreActionPopUp
