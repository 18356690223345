import {
    FForm,
    FInputField,
    FSelect,
} from '@fantaskticedtechlimited/fui-complib'
import { Fragment, useState } from 'react'
import useQBAPIService from '../../../../../apiService/questionBank'
import {
    QuestionGroupCreateInput,
    QuestionType,
    QuestionTypeFilterInput,
} from '../../../../../assets/type/backend.type'

import useMount from '../../../../../utils/useMount'
import { QuestionGroupCreateFormProps } from './types'

export const QuestionGroupCreateForm = (
    props: QuestionGroupCreateFormProps
) => {
    const qbAPIService = useQBAPIService()
    const [questionTypes, setQuestionTypes] = useState<QuestionType[]>([])
    const [selectedQuestionType, setSelectedQuestionType] =
        useState<QuestionType | null>(null)
    let defaultValue: QuestionGroupCreateInput = {
        questionTypeId: props.questionTypeId || '',
        name: 'Name',
        orderSequence: 0,
    }
    const [questionGroupCreateInput, setQuestionGroupCreateInput] =
        useState<QuestionGroupCreateInput>({
            ...defaultValue,
        })

    const handleGetQuestionTypesByFilter = async (
        questionTypeFilterInput: QuestionTypeFilterInput
    ) => {
        try {
            const result = await qbAPIService.getQuestionTypesByFilter({
                questionTypeFilterInput,
            })
            const { questionTypesGet } = result.data
            if (!questionTypesGet.isSuccess) {
                return setQuestionTypes([])
            }
            let tempQuestionTypes = questionTypesGet.data || []
            setQuestionTypes(tempQuestionTypes)
            // set preset question type
            if (props.questionTypeId) {
                const found = tempQuestionTypes.find(
                    (e) => e.id === props.questionTypeId
                )
                found && setSelectedQuestionType(found)
            }
        } catch (error) {
            console.log('error', error)
            setQuestionTypes([])
        }
    }

    const handleQuestionGroupCreate = async (
        questionGroupCreateInput: QuestionGroupCreateInput
    ) => {
        try {
            if (questionGroupCreateInput.questionTypeId === '') {
                return alert('Question Type cannot be empty.')
            }
            const result = await qbAPIService.qbQuestionGroupCreateMutation({
                variables: {
                    questionGroupCreateInput,
                },
            })
            if (
                result.data?.qbQuestionGroupCreate.isSuccess &&
                result.data.qbQuestionGroupCreate.data
            ) {
                props.onSubmitCallback &&
                    (await props.onSubmitCallback(
                        result.data.qbQuestionGroupCreate.data
                    ))
                props.onClose && (await props.onClose())
            } else {
                console.error(
                    `QuestionGroup Create failed, Reason. ${JSON.stringify(
                        result
                    )}`
                )
                alert(
                    `QuestionGroup Create failed, Reason.  ${result.data?.qbQuestionGroupCreate.errorMessage}`
                )
            }
        } catch (error) {
            console.log('error', error)
            alert(
                `QuestionGroup Create failed, Error: ${JSON.stringify(error)}`
            )
        }
    }

    useMount(() => {
        handleGetQuestionTypesByFilter({
            chapterId: props.chapterId,
        })
    })

    return (
        <FForm
            onClose={props.onClose}
            onSubmit={() =>
                handleQuestionGroupCreate({
                    ...questionGroupCreateInput,
                    questionTypeId: selectedQuestionType?.id ?? '',
                })
            }
        >
            <FInputField
                autoFocus
                label="Question Group Name"
                value={questionGroupCreateInput.name}
                wordCount={100}
                onInput={(v) =>
                    setQuestionGroupCreateInput({
                        ...questionGroupCreateInput,
                        name: v,
                    })
                }
            />
            <FInputField
                label="Order Sequence"
                value={String(questionGroupCreateInput.orderSequence)}
                onInput={(v) =>
                    setQuestionGroupCreateInput({
                        ...questionGroupCreateInput,
                        orderSequence: Number(v),
                    })
                }
            />
        </FForm>
    )
}
