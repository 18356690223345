import { gql } from '@apollo/client'

import { PHOTO_DETAIL_FIELDS } from '../fragments/photoDetail.gql'

export const GET_FOLDER_DETAIL_BY_ID_QUERY = gql`
    query getFolderDetailById($folderId: String!) {
        folderGet(id: $folderId) {
            isSuccess
            code
            errorMessage
            data {
                id
                orderSequence
                name
                questionBankId
                isEnable
                isFlagged
                icon {
                    ...photoDetail
                }
                chapters {
                    id
                    name
                    questionBankId
                    description
                    isEnable
                    isFlagged
                    subject
                    orderSequence
                    icon {
                        ...photoDetail
                    }
                    questionTypes {
                        id
                        orderSequence
                        questionGroups {
                            id
                            orderSequence
                        }
                    }
                    createAt
                    updateAt
                }
                createAt
                updateAt
            }
        }
    }

    ${PHOTO_DETAIL_FIELDS}
`
