import {
    FScrollBarStyle,
    FDragAndDrop,
} from '@fantaskticedtechlimited/fui-complib'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Folder } from '../../../../../assets/type/backend.type'
import { useQBAssetLibraryContext } from '../../../../../contexts/qbAssetLibraryContext'
import { QBDirectoryViewMode } from '../../../../../contexts/qbAssetLibraryContext/types'
import { useFolderPopUpWrapperContext } from '../../../../QuestionBankContent/components/popUp/WithFolderPopUpWrapper'
import { QBAssetDropdownMenu } from '../../../components/QBAssetDropdownMenu'
import QBAssetLibraryURLGenerator from '../../../components/QBAssetLibraryURLGenerator'
import { QBAssetListCardFoldersContent } from '../../../components/QBAssetListCardContent/Folders'
import QBAssetSwitchButton from '../../../components/QBAssetSwitchButton'
import { styles } from '../styles'

export const QBDirectoryViewFoldersBody = () => {
    const { questionBank, onRefresh, handleRearrangeFolders } =
        useQBAssetLibraryContext()
    const {
        onFolderToggleEnablePopUpOpen,
        onFolderUpdatePopUpOpen,
        onFolderDeletePopUpOpen,
    } = useFolderPopUpWrapperContext()
    const navigate = useNavigate()
    const [foldersData, setFoldersData] = useState<Folder[]>([])

    useEffect(() => {
        if (questionBank) setFoldersData(questionBank.folders)
    }, [questionBank])

    return (
        <div
            className={
                styles.QBDirectoryView_ListWrapper + ' ' + FScrollBarStyle()
            }
        >
            <FDragAndDrop
                data={foldersData}
                onUpdateData={async (newDatas) => {
                    await handleRearrangeFolders(newDatas)
                }}
                children={(folder) => {
                    return (
                        <>
                            <QBAssetListCardFoldersContent
                                data={folder}
                                onClick={() => {
                                    navigate(
                                        QBAssetLibraryURLGenerator({
                                            viewMode:
                                                QBDirectoryViewMode.CHAPTERS,
                                            folderId: folder.id,
                                            questionBankId:
                                                folder.questionBankId,
                                        })
                                    )
                                }}
                            />
                            <QBAssetSwitchButton
                                isEnable={folder.isEnable}
                                onClick={() => {
                                    onFolderToggleEnablePopUpOpen(
                                        folder,
                                        async () => {
                                            await onRefresh()
                                        }
                                    )
                                }}
                            />
                            <QBAssetDropdownMenu
                                onEditInfo={() => {
                                    onFolderUpdatePopUpOpen(
                                        folder,
                                        async () => {
                                            await onRefresh()
                                        }
                                    )
                                }}
                                onDelete={() => {
                                    onFolderDeletePopUpOpen(
                                        folder,
                                        async () => {
                                            await onRefresh()
                                        }
                                    )
                                }}
                            />
                        </>
                    )
                }}
            />
        </div>
    )
}
