import { gql } from '@apollo/client'

export const GET_TAG_DETAIL_BY_ID_QUERY = gql`
    query tag($tagId: String!) {
        tagGet(id: $tagId) {
            isSuccess
            errorMessage
            code
            data {
                id
                name
                questionBankId
                subject
                difficulty
                createAt
                updateAt
            }
        }
    }
`
