import { gql } from '@apollo/client'
import { PHOTO_DETAIL_FIELDS } from '../fragments/photoDetail.gql'

export const QB_QUESTION_UPDATE_BY_ID_MUTATION = gql`
    mutation qbQuestionUpdateById($questionUpdateInput: QuestionUpdateInput!) {
        qbQuestionUpdateById(questionUpdateInput: $questionUpdateInput) {
            code
            isSuccess
            errorMessage
            data {
                id
                questionBankId
                level
                questionGroupId
                questionTypeId
                isFlaged
                inputType
                content {
                    ... on MCContent {
                        body
                        answers {
                            id
                            body
                        }
                        correctAnswerIds
                        solution
                    }
                }
                tags {
                    id
                    name
                }
                conceptCards {
                    id
                    name
                }
                photoAttachments {
                    ...photoDetail
                }
            }
        }
    }

    ${PHOTO_DETAIL_FIELDS}
`
