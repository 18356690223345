import { ConceptCardRowProps } from './type'

import FListCard from '../../../../../Components/FListCard'
import { Fragment } from 'react'
import { FText } from '@fantaskticedtechlimited/fui-complib'
import { FIcon, FIconNames } from '@fantaskticedtechlimited/fui-iconlib'
import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'

function ConceptCardRow(props: ConceptCardRowProps) {
    const { conceptCard } = props

    // const history = useHistory();
    return (
        <FListCard
            index={props.index}
            isDisableToolBar={props.isDisableToolbar}
            draggable={true}
            icon={
                <img
                    alt="icon"
                    src={props.conceptCard.photo?.fileURL}
                    style={{
                        width: '80px',
                        height: '80px',
                    }}
                />
            }
            content={
                <Fragment>
                    {/* data in middle top */}
                    {/* ConceptCard Name */}
                    <FText maxRows={2}>{conceptCard.name}</FText>
                    {/* data in middle bottom */}
                    {/* tags */}
                    <div>
                        {props.conceptCard.tags.map((tag, tagIndex) => {
                            return (
                                <span key={tagIndex}>{`# ${tag.name} `}</span>
                            )
                        })}
                    </div>
                </Fragment>
            }
            toolBar={
                <Fragment>
                    <FIcon
                        name={FIconNames.MORE}
                        color={() => FLegacyColorTypes.PRIMARY_GREY}
                        onClick={() => {
                            props.onMoreActionButtonClick &&
                                props.onMoreActionButtonClick()
                        }}
                    />
                </Fragment>
            }
        />
    )
}

export default ConceptCardRow
