import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { style } from 'typestyle'

// export const homeLink = style({
//     textDecoration: "none"
// })

export const sideNavbar = style({
    background: 'white !important',
    border: '1px solid ' + FLegacyColorTypes.SPECIAL_STROKE,
    boxShadow: 'none !important',
    boxSizing: 'border-box',
})

export const societyContainer = style({
    width: '160px',
    borderRadius: '4px',
    border: '1px solid #E2E2E2',
    marginLeft: '8px',
    display: 'flex',
    boxSizing: 'border-box',
    padding: '8px',
})

export const societyIcon = style({
    width: '32px',
    height: '32px',
    borderRadius: '50px',
    background: 'black',
    marginRight: '8px',
})
