import { FConfirmPopUp } from '@fantaskticedtechlimited/fui-complib'
import { Fragment, useState } from 'react'
import { Chapter } from '../../../../assets/type/backend.type'
import { FFloatingActionButton } from '../../../../Components/FFloatingActionButton'
import { FFloatingActionSubButton } from '../../../../Components/FFloatingActionButton/FFloatingActionSubButton'
import { useChapterPopUpWrapperContext } from '../popUp/WithChapterPopUpWrapper'
import { selectionButtonFolderIcon, selectionButtonBookOpenIcon } from '../svg'
import * as styles from './style'
import { ContentFloatingActionButtonProps } from './type'
function ContentFloatingActionButton(props: ContentFloatingActionButtonProps) {
    const { onChapterCreatePopUpOpen } = useChapterPopUpWrapperContext()
    const [isFolderCreatePopUpOpen, setIsFolderCreatePopUpOpen] =
        useState(false)

    // const handleFolderCreate = async (createdFolder: Folder) => {
    // 	console.log("folder create");
    // };
    const handleChapterCreate = async (createdChapter: Chapter) => {
        console.log('createdChapter', createdChapter)
        props.onRefresh && (await props.onRefresh())
    }
    return (
        <Fragment>
            <FFloatingActionButton
                veticalfloat="bottom"
                horizontalfloat="right"
            >
                <div className={styles.chapterFloatingButtonContentDiv}>
                    {/* New Folder button */}
                    <FFloatingActionSubButton
                        icon={selectionButtonFolderIcon}
                        name="New Folder"
                        onClick={() => {
                            setIsFolderCreatePopUpOpen(true)
                        }}
                    />
                    {/* New Chapter button */}
                    <FFloatingActionSubButton
                        icon={selectionButtonBookOpenIcon}
                        name="New Chapter"
                        onClick={() => {
                            onChapterCreatePopUpOpen(
                                {
                                    questionBankId: props.questionBank.id,
                                },
                                handleChapterCreate
                            )
                        }}
                    />
                </div>
            </FFloatingActionButton>

            {isFolderCreatePopUpOpen && (
                <FConfirmPopUp
                    title="New Folder"
                    disableCloseWhenClickOutside
                    onClose={() => setIsFolderCreatePopUpOpen(false)}
                    bottomBarProps={{
                        onLeadingButtonClick: () =>
                            setIsFolderCreatePopUpOpen(false),
                    }}
                />
            )}
        </Fragment>
    )
}

export default ContentFloatingActionButton
