import {
    FForm,
    FInputField,
    FText,
    Nullable,
} from '@fantaskticedtechlimited/fui-complib'
import { Fragment, useEffect, useState } from 'react'
import useQBAPIService from '../../../../../apiService/questionBank'
import {
    FolderUpdateInput,
    Folder,
    AttachmentTypes,
} from '../../../../../assets/type/backend.type'
import removeTypename from '../../../../../utils/removeTypename'
import { FolderUpdateFormProps } from './types'
import * as styles from './styles'
import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { FFontTypes } from '@fantaskticedtechlimited/fui-fontlib'
import { FIcon, FIconNames } from '@fantaskticedtechlimited/fui-iconlib'
import { ReactFileUploader } from '@files-drop/react-uploader/lib/cjs'

export const FolderUpdateForm = (props: FolderUpdateFormProps) => {
    const qbAPIService = useQBAPIService()

    let defaultValue: FolderUpdateInput = {
        folderId: props.folder.id,
        name: props.folder.name ?? 'Name',
        icon: props.folder.icon,
        isClearIcon: false,
    }
    const [folderUpdateInput, setFolderUpdateInput] =
        useState<FolderUpdateInput>(defaultValue)

    const handleFolderUpdate = async (
        _folderUpdateInput: FolderUpdateInput
    ) => {
        try {
            let folderUpdateInput = removeTypename(_folderUpdateInput)
            const result = await qbAPIService.qbFolderUpdateByIdMutation({
                variables: {
                    folderUpdateInput,
                },
            })
            if (result.data?.qbFolderUpdateById.isSuccess) {
                props.onSubmit &&
                    (await props.onSubmit(
                        result.data.qbFolderUpdateById.data as Folder
                    ))
                props.onClose && (await props.onClose())
            } else {
                console.error(
                    `Folder update failed, Reason. ${JSON.stringify(result)}`
                )
                alert(
                    `Folder update failed, Reason.  ${result.data?.qbFolderUpdateById.errorMessage}`
                )
            }
        } catch (error) {
            console.log('error', error)
            alert(`Folder update failed, Error: ${JSON.stringify(error)}`)
        }
    }

    return (
        <FForm
            onClose={props.onClose}
            onSubmit={() => handleFolderUpdate(folderUpdateInput)}
            bottomBarProps={{ actionButtonLabel: 'Update' }}
        >
            <FInputField
                autoFocus
                label="Folder Name"
                value={folderUpdateInput.name!}
                wordCount={100}
                onInput={(v) =>
                    setFolderUpdateInput({
                        ...folderUpdateInput,
                        name: v,
                    })
                }
            />
            <div className={styles.FolderUpdateForm_IconRow_Div}>
                <FText font={FFontTypes.Large_Text()} children="Icon: " />
                {folderUpdateInput.icon !== null ? (
                    <div className={styles.FolderUpdateForm_IconDisplay_Div}>
                        <img
                            alt="icon"
                            src={folderUpdateInput.icon?.fileURL}
                            style={{
                                width: '250px',
                                height: '250px',
                            }}
                        />
                        <FIcon
                            name={FIconNames.DELETE}
                            color={() =>FLegacyColorTypes.SECONDARY_RED}
                            onClick={() => {
                                setFolderUpdateInput({
                                    ...folderUpdateInput,
                                    icon: null,
                                })
                            }}
                        />
                    </div>
                ) : (
                    <FText
                        font={FFontTypes.Large_Text()}
                        color={FLegacyColorTypes.SECONDARY_RED}
                        children="No Icon"
                    />
                )}
            </div>
            <ReactFileUploader
                url={process.env.REACT_APP_UPLOAD_URL ?? ''}
                apiKey={process.env.REACT_APP_UPLOAD_TOKEN ?? ''}
                multiple={false}
                maxNumberOfFiles={1}
                accept=".jpg,.jpeg,.png,.gif"
                iconUploaded={folderUpdateInput.icon !== null}
                onUploadedFiles={(files) => {
                    if (files.length === 1) {
                        const file = files[0]
                        setFolderUpdateInput({
                            ...folderUpdateInput,
                            icon: {
                                fileURL: file.fileURL,
                                filePath: file.filePath,
                                fileName: file.fileName,
                                originalFileName: file.originalFileName,
                                attachmentFileType: AttachmentTypes.ICON,
                                size: 0,
                                contentType: file.contentType,
                            },
                            isClearIcon: false,
                        })
                    }
                }}
                withDND
                withStatus
            />
        </FForm>
    )
}
