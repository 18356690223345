import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { FFontFamily, FFontTypes } from '@fantaskticedtechlimited/fui-fontlib'
import { style } from 'typestyle'
import { FMarkdownViewProps } from './types'

export const FMarkdownView_Container = (props: FMarkdownViewProps) =>
    style({
        font:
            props.font ??
            FFontTypes.Large_Text({
                fontFamily: FFontFamily.Montserrat,
                fontWeight: 300,
            }),
        color: props.color ?? FLegacyColorTypes.PRIMARY_BLACK,
        display: props.maxRows ? '-webkit-box' : 'block',
        '-webkit-line-clamp': props.maxRows ? props.maxRows : undefined,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
        minHeight: '20px',
        width: '100%',
        $nest: {
            img: {
                width: props.imageSize ? props.imageSize : '100%',
            },
        },
    })

export const FMarkdownView_P = style({
    $nest: {
        p: {
            margin: 0,
            boxSizing: 'border-box',
        },
    },
})

export const FMarkdownView_Span = style({
    $nest: {
        span: {
            margin: 0,
            $nest: {
                '.katex': {
                    textAlign: 'unset',
                    whiteSpace: 'pre-wrap',
                    font: 'unset',
                },
                '.base': {
                    position: 'static',
                },
                '.mathnormal': {
                    fontFamily: 'unset',
                    fontStyle: 'normal',
                },
                '.mathbf': {
                    fontFamily: 'unset',
                    fontWeight: 'unset',
                },
            },
        },
    },
})
