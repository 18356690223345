import { FDateDisplayerProps } from './types'
import moment from 'moment-timezone'
function FDateDisplayer({ date, disableShowNA }: FDateDisplayerProps) {
    if (date === null || date === undefined) {
        if (disableShowNA) {
            return <span></span>
        }
        return <span>N/A</span>
    }
    return <span>{moment(date).format('YYYY/MM/DD HH:mm')}</span>
}

export default FDateDisplayer
