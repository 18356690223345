import { FColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { style, stylesheet } from 'typestyle'

export const PageHeader_Container = (
    showBreadcrumb?: boolean,
    disableBottomBorder?: boolean
) =>
    style({
        width: '100%',
        display: 'flex',
        flexDirection: showBreadcrumb ? 'column' : 'row',
        rowGap: showBreadcrumb ? '16px' : 0,
        boxSizing: 'border-box',
        padding: '16px 0',
        borderBottom: disableBottomBorder
            ? undefined
            : '1.6px solid ' + FColorTypes.FPrimaryColors.STROKE_GREY.LM,
    })

export const styles = stylesheet({
    PageHeader_ChildrenDiv: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: '8px',
    },
})
