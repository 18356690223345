import { FText } from '@fantaskticedtechlimited/fui-complib'
import { Fragment } from 'react'
import { Outlet } from 'react-router-dom'

function NotFound() {
    return (
        <Fragment>
            <FText
                style={{
                    textAlign: 'center',
                    marginTop: '1rem',
                }}
            >
                404 Not Found
            </FText>
            <Outlet />
        </Fragment>
    )
}

export default NotFound
