import { FFloatingActionSubButtonProps } from './types'
import * as styles from './styles'
import { FText } from '@fantaskticedtechlimited/fui-complib'
import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { FFontTypes } from '@fantaskticedtechlimited/fui-fontlib'

export const FFloatingActionSubButton = (
    props: FFloatingActionSubButtonProps
) => {
    return (
        <div
            className={styles.selectionButtonDiv(props.width!)}
            onClick={props.onClick}
        >
            {props.icon}
            <FText
                font={FFontTypes.Text(props.font)}
                color={FLegacyColorTypes.PRIMARY_WHITE}
            >
                {props.name}
            </FText>
        </div>
    )
}
