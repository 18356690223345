import { gql } from '@apollo/client'

export const QB_FOLDERS_REARRANGE_BY_IDS_MUTATION = gql`
    mutation qbFoldersRearrangeByIds(
        $foldersRearrangeInput: FoldersRearrangeInput!
    ) {
        qbFoldersRearrangeByIds(foldersRearrangeInput: $foldersRearrangeInput) {
            isSuccess
            code
            errorMessage
            data {
                id
                orderSequence
            }
        }
    }
`
