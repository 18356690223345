import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { FConfirmPopUp, FText } from '@fantaskticedtechlimited/fui-complib'
import useQBAPIService from '../../../../../apiService/questionBank'

import { ChapterDeletePopUpProps } from './types'
import * as styles from './styles'
import { FFontTypes } from '@fantaskticedtechlimited/fui-fontlib'
import { DeletePopUp } from '../../../../../Components/DeletePopUp'

export const ChapterDeletePopUp = (props: ChapterDeletePopUpProps) => {
    const qbAPIService = useQBAPIService()
    const chapterId = props.chapter.id
    const chapterName = props.chapter.name

    const handlePopUpClose = async () => {
        props.onClose && (await props.onClose())
    }

    const handleChapterDelete = async (chapterId: string) => {
        try {
            const result = await qbAPIService.qbChapterDeleteByIdMutation({
                variables: {
                    chapterId,
                },
            })
            if (result.data?.qbChapterDeleteById.isSuccess) {
                props.onCallback &&
                    (await props.onCallback(
                        result.data?.qbChapterDeleteById.data!
                    ))
                handlePopUpClose()
            } else {
                alert(
                    `Chapter delete failed, Reason. ${JSON.stringify(result)}`
                )
            }
        } catch (error) {
            console.log('error', error)
            alert(`Chapter delete failed, Error: ${JSON.stringify(error)}`)
        }
    }

    return (
        <DeletePopUp
            targetType="Chapter"
            targetName={chapterName}
            onClose={handlePopUpClose}
            onActionButtonClick={() => handleChapterDelete(chapterId)}
        />
    )
}
