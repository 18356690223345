import {
    FNoData,
    FScrollableContentWrapper,
} from '@fantaskticedtechlimited/fui-complib'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useQBAPIService from '../../../apiService/questionBank'
import { Folder } from '../../../assets/type/backend.type'
import { PageContainer } from '../../../layout/PageContainer'
import {
    useLoadingPageWrapperContext,
    WithLoadingPageWrapper,
} from '../../../layout/WithLoadingPageWrapper'
import { WithFolderPopUpWrapper } from '../components/popUp/WithFolderPopUpWrapper'
import { WithChapterPopUpWrapper } from '../components/popUp/WithChapterPopUpWrapper'
import ChapterList from '../components/Chapter/ChapterList'
import FolderDetailHeader from './FolderDetailHeader'

function FolderDetail() {
    const param = useParams<{ folderId: string }>()

    const qbAPIService = useQBAPIService()
    const { setLoadingEnd, setLoadingStart } = useLoadingPageWrapperContext()

    const [folder, setFolder] = useState<Folder | null>(null)
    const handleGetFolderDetail = async (
        folderId: string,
        withLoading?: boolean
    ) => {
        withLoading && setLoadingStart()
        try {
            const result = await qbAPIService.getFolderDetailById({
                folderId,
            })
            const { folderGet } = result.data
            setFolder(folderGet.data ?? null)
        } catch (error) {
            console.log('error', error)
            setFolder(null)
        }
        withLoading && setLoadingEnd()
    }

    useEffect(() => {
        if (param.folderId) {
            handleGetFolderDetail(param.folderId, true)
        } else {
            setFolder(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [param.folderId])

    if (folder === null) {
        return <FNoData description="No Folder Data" />
    }
    return (
        <PageContainer
            header={
                <FolderDetailHeader
                    folder={folder}
                    onRefresh={() => handleGetFolderDetail(folder.id)}
                />
            }
            body={
                <FScrollableContentWrapper>
                    {folder.chapters.length === 0 ? (
                        <FNoData />
                    ) : (
                        <ChapterList
                            chapters={folder.chapters}
                            folders={[]}
                            folderId={folder.id}
                            questionBankId={folder.questionBankId}
                            onRefresh={async () =>
                                await handleGetFolderDetail(folder?.id)
                            }
                        />
                    )}
                </FScrollableContentWrapper>
            }
        />
    )
}

export default WithLoadingPageWrapper(
    WithChapterPopUpWrapper(WithFolderPopUpWrapper(FolderDetail)),
    true
)
