import * as styles from './styles'
import { QuestionListViewProps } from './types'
import QuestionRow from '../QuestionRow'
import { useQuestionPopUpWrapperContext } from '../../popUp/WithQuestionPopUpWrapper'
import { FScrollBarStyle } from '@fantaskticedtechlimited/fui-complib'

export const QuestionListView = (props: QuestionListViewProps) => {
    const { onQuestionMoreActionPopUpOpen } = useQuestionPopUpWrapperContext()
    const handleMoreActionPopUpCallback = async () => {
        // console.log("handleMoreActionPopUpCallback")
        await props.onRefresh()
    }
    return (
        <div
            className={
                styles.QuestionListView_Container + ' ' + FScrollBarStyle()
            }
            style={props.containerStyle}
        >
            {/* QuestionList List looping */}
            {(props.questions || []).map((question, index: number) => (
                <QuestionRow
                    key={index}
                    question={question}
                    onClick={() =>
                        props.onQuestionClicked(props.questions[index])
                    }
                    onMoreActionButtonClick={() => {
                        console.log(
                            `Question ${question.id} click more action button`
                        )
                        onQuestionMoreActionPopUpOpen(question, {
                            onDelete: () => handleMoreActionPopUpCallback(),
                            onUpdate: () => handleMoreActionPopUpCallback(),
                        })
                    }}
                />
            ))}
        </div>
    )
}
