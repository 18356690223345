import ReactMarkdown from 'react-markdown'
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import 'katex/dist/katex.min.css' // `rehype-katex` does not import the CSS for you
import { FMarkdownViewProps } from './types'
import * as styles from './styles'

function FMarkdownView(props: FMarkdownViewProps) {
    const ReactMarkdownClassNames =
        styles.FMarkdownView_Container(props) +
        ' ' +
        props.className +
        ' ' +
        // =========================================
        // p style
        styles.FMarkdownView_P +
        ' ' +
        props.pClassName +
        // =========================================
        ' ' +
        // =========================================
        // span style
        styles.FMarkdownView_Span +
        ' ' +
        props.spanClassName +
        // =========================================
        ''

    return (
        <ReactMarkdown
            {...props}
            className={ReactMarkdownClassNames}
            remarkPlugins={[remarkMath]}
            rehypePlugins={[rehypeKatex]}
        >
            {props.children}
        </ReactMarkdown>
    )
}

export default FMarkdownView
