import { gql } from '@apollo/client'

export const GET_QUESTION_TYPES_DETAIL_BY_FILTER = gql`
    query getQuestionTypesDetailByFilter(
        $questionTypeFilterInput: QuestionTypeFilterInput
    ) {
        questionTypesGet(questionTypeFilterInput: $questionTypeFilterInput) {
            code
            errorMessage
            isSuccess
            data {
                id
                name
                chapterId
                questionGroups {
                    id
                    name
                }
                questionBankId
                createAt
                updateAt
            }
        }
    }
`
