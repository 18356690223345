import { stylesheet } from 'typestyle'

export const styles = stylesheet({
    Loading_Container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    ComponentAfterLoading_Container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
    },
})
