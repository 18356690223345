import useQBAPIService from '../../../../../apiService/questionBank'
import { QuestionGroupDeletePopUpProps } from './types'
import { DeletePopUp } from '../../../../../Components/DeletePopUp'

export const QuestionGroupDeletePopUp = (
    props: QuestionGroupDeletePopUpProps
) => {
    const qbAPIService = useQBAPIService()
    const questionGroupId = props.questionGroup.id
    const questionGroupName = props.questionGroup.name

    const handlePopUpClose = async () => {
        props.onClose && (await props.onClose())
    }

    const handleQuestionGroupDelete = async (questionGroupId: string) => {
        try {
            const result = await qbAPIService.qbQuestionGroupDeleteByIdMutation(
                {
                    variables: {
                        questionGroupId: questionGroupId,
                    },
                }
            )
            if (result.data?.qbQuestionGroupDeleteById.isSuccess) {
                props.onCallback &&
                    (await props.onCallback(
                        result.data?.qbQuestionGroupDeleteById.data!
                    ))
                handlePopUpClose()
            } else {
                alert(
                    `QuestionGroup delete failed, Reason. ${JSON.stringify(
                        result
                    )}`
                )
            }
        } catch (error) {
            console.log('error', error)
            alert(
                `QuestionGroup delete failed, Error: ${JSON.stringify(error)}`
            )
        }
    }

    return (
        <DeletePopUp
            targetType="Question Group"
            targetName={questionGroupName}
            onClose={handlePopUpClose}
            onActionButtonClick={() =>
                handleQuestionGroupDelete(questionGroupId)
            }
        />
    )
}
