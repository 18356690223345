import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { QBDirectoryViewHeader } from '..'
import { useQBAssetLibraryContext } from '../../../../../contexts/qbAssetLibraryContext'
import { QBDirectoryViewMode } from '../../../../../contexts/qbAssetLibraryContext/types'
import { useLoadingPageWrapperContext } from '../../../../../layout/WithLoadingPageWrapper'
import { useChapterPopUpWrapperContext } from '../../../../QuestionBankContent/components/popUp/WithChapterPopUpWrapper'
import QBAssetLibraryURLGenerator from '../../../components/QBAssetLibraryURLGenerator'

export const QBDirectoryViewChaptersHeader = () => {
    const { folder, onRefresh } = useQBAssetLibraryContext()
    const { onChapterCreatePopUpOpen } = useChapterPopUpWrapperContext()
    const { setLoadingStart, setLoadingEnd } = useLoadingPageWrapperContext()
    const navigate = useNavigate()

    useEffect(() => {
        if (folder === null) setLoadingStart()
        else setLoadingEnd()
    }, [folder])

    return (
        <QBDirectoryViewHeader
            onBackButtonClick={() =>
                navigate(
                    QBAssetLibraryURLGenerator({
                        viewMode: QBDirectoryViewMode.FOLDERS,
                        questionBankId: folder?.questionBankId!,
                    })
                )
            }
            title={folder?.name!}
            CTAButtonLabel="+  Add Chapter"
            onCTAButtonClick={() =>
                onChapterCreatePopUpOpen(
                    {
                        questionBankId: folder?.questionBankId!,
                        presetFolderId: folder?.id!,
                    },
                    () => onRefresh()
                )
            }
        />
    )
}
