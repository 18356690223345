interface Dictionary {
    [Key: string]: any
}

export const duplicateAnObject = <T extends unknown>(objB: Dictionary) => {
    let newObject: Dictionary = {}

    const keys = Array.from(Object.keys(objB))
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i]
        newObject[key] = objB[key]
    }
    return newObject as T
}
