import { gql } from '@apollo/client'

export const QB_QUESTION_TYPE_DELETE_BY_ID_MUTATION = gql`
    mutation qbQuestionTypeDeleteById($questionTypeId: String!) {
        qbQuestionTypeDeleteById(questionTypeId: $questionTypeId) {
            isSuccess
            code
            errorMessage
            data {
                id
                name
            }
        }
    }
`
