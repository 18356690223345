import { stylesheet } from 'typestyle'

export const styles = stylesheet({
    PageBody_Container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
    },
})
