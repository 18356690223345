import { FForm, FSelect, FText } from '@fantaskticedtechlimited/fui-complib'
import { FFontTypes } from '@fantaskticedtechlimited/fui-fontlib'
import { Fragment, useEffect, useState } from 'react'
import useQBAPIService from '../../../../../apiService/questionBank'
import {
    Folder,
    QBChapterMoveToFolderInput,
} from '../../../../../assets/type/backend.type'

import { ChapterMoveToFolderFormProps } from './types'

export const ChapterMoveToFolderForm = (
    props: ChapterMoveToFolderFormProps
) => {
    const { chapter } = props
    const qbAPIService = useQBAPIService()
    const [folders, setFolders] = useState<Folder[]>([])
    const [fromFolder, setFromFolder] = useState<Folder | null>(null)
    const [selectedToFolder, setSelectedToFolder] = useState<Folder | null>(
        null
    )
    let defaultValue: QBChapterMoveToFolderInput = {
        toFolderId: '',
        chapterId: chapter.id,
        fromFolderId: null,
    }
    const [qbChapterMoveToFolderInput, setQBChapterMoveToFolderInput] =
        useState<QBChapterMoveToFolderInput>({
            ...defaultValue,
        })

    const handleGetFoldersAndSetDefault = async (_questionBankId: string) => {
        try {
            const foldersRes = await qbAPIService.getFoldersByFilter({
                folderFilterInput: {
                    questionBankId: _questionBankId,
                },
            })
            const { foldersGet } = foldersRes.data
            if (!foldersGet.isSuccess) {
                return setFolders([])
            }

            let _folders = foldersGet.data ?? []
            const foundInFolders = _folders.filter((e) => {
                const chapterIds = e.chapters.map((e) => e.id)
                return chapterIds.includes(chapter.id)
            })
            setFromFolder(foundInFolders.length > 0 ? foundInFolders[0] : null)

            // filter exist folder
            const foundInFolderIds = foundInFolders.map((e) => e.id)
            const filteredFolders = _folders.filter(
                (e) => !foundInFolderIds.includes(e.id)
            )
            setFolders(filteredFolders)
        } catch (error) {
            console.log('error', error)
            setFolders([])
            setSelectedToFolder(null)
        }
    }

    const handleChapterMoveToFolder = async (
        qbChapterMoveToFolderInput: QBChapterMoveToFolderInput
    ) => {
        if (qbChapterMoveToFolderInput.toFolderId === '') {
            return
        }
        try {
            const result = await qbAPIService.qbChapterMoveToFolderByIdMutation(
                {
                    variables: {
                        qbChapterMoveToFolderInput: qbChapterMoveToFolderInput,
                    },
                }
            )
            if (result.data?.qbChapterMoveToFolderById.isSuccess) {
                props.onSubmit &&
                    (await props.onSubmit(
                        result.data.qbChapterMoveToFolderById.data as Folder
                    ))
                props.onClose && (await props.onClose())
            } else {
                console.error(
                    `Chapter Update failed, Reason. ${JSON.stringify(result)}`
                )
                alert(
                    `Chapter Update failed, Reason.  ${result.data?.qbChapterMoveToFolderById.errorMessage}`
                )
            }
        } catch (error) {
            console.log('error', error)
            alert(
                `Chapter Update to folder failed, Error: ${JSON.stringify(
                    error
                )}`
            )
        }
    }

    useEffect(() => {
        handleGetFoldersAndSetDefault(chapter.questionBankId)
    }, [chapter])

    return (
        <FForm
            style={{
                height: '100%',
                alignItems: 'flex-start',
                overflowY: 'unset',
            }}
            contentContainerStyle={{ overflowY: 'unset' }}
            onClose={props.onClose}
            onSubmit={() =>
                handleChapterMoveToFolder({
                    ...qbChapterMoveToFolderInput,
                    fromFolderId: fromFolder !== null ? fromFolder.id : null,
                })
            }
        >
            <FText
                font={FFontTypes.Large_Text()}
                children={`From folder: ${fromFolder?.name ?? ''}`}
            />
            <FSelect<Folder>
                options={folders || []}
                wrapperStyle={{ width: '100%' }}
                label="To Folder"
                placeholder="optional for filtering"
                renderOptionNameOnly={(t: Folder) => t.name}
                selectedOptions={selectedToFolder}
                onSelect={(folder) => {
                    setSelectedToFolder(folder)
                    setQBChapterMoveToFolderInput({
                        ...qbChapterMoveToFolderInput,
                        toFolderId: folder.id,
                    })
                }}
            />
        </FForm>
    )
}
