import {
    Auth,
    createUserWithEmailAndPassword,
    FacebookAuthProvider,
    getIdToken,
    GoogleAuthProvider,
    isSignInWithEmailLink,
    OAuthCredential,
    OAuthProvider,
    sendSignInLinkToEmail,
    signInWithEmailAndPassword,
    signInWithEmailLink,
    signInWithPopup,
    User,
} from 'firebase/auth'

import { FirebaseSignInProvider } from '@firebase/util'
import { domainNameGenerator } from '../domainNameGenerator'

// logo
import google from './assets/google.png'
import apple from './assets/apple.svg'
import search from './assets/search.svg'

// email and password
async function handleCreateUserWithEmailAndPassword(
    _auth: Auth,
    _email: string,
    _password: string
) {
    const signUpResult = await createUserWithEmailAndPassword(
        _auth,
        _email,
        _password
    )
    const firebaseToken = await getIdToken(signUpResult.user, true)
    alert(`SignUp ${signUpResult.user.email}`)
    return {
        firebaseToken,
    }
}

async function handleSignInWithEmailAndPassword(
    _auth: Auth,
    _email: string,
    _password: string
) {
    const signInResult = await signInWithEmailAndPassword(
        _auth,
        _email,
        _password
    )
    const firebaseToken = await getIdToken(signInResult.user, true)
    alert(`SignIn ${signInResult.user.email}`)
    return {
        isSuccess: true,
        firebaseToken,
    }
}

// magiclink
async function handleSendSignInLinkToEmail(
    _auth: Auth,
    _email: string,
    _redirectPath: string
) {
    const actionCodeSettings = {
        url: `${domainNameGenerator()}${_redirectPath}`,
        // no need to setup if not building IOS or Andriod app
        // iOS: {
        //    bundleId: 'com.example.ios'
        // },
        // android: {
        //   packageName: 'com.example.android',
        //   installApp: true,
        //   minimumVersion: '12'
        // },
        handleCodeInApp: true,
    }
    await sendSignInLinkToEmail(_auth, _email, actionCodeSettings)
    window.localStorage.setItem('emailForSignIn', _email)
    alert(`SignIn Link Sent to ${_email}`)
}

async function handleSignInWithEmailLink(_auth: Auth, url: string) {
    let firebaseToken = ''
    let isSuccess = false
    if (isSignInWithEmailLink(_auth, url)) {
        let email = window.localStorage.getItem('emailForSignIn')
        if (!email) {
            // User opened the link on a different device. To prevent session fixation
            // attacks, ask the user to provide the associated email again. For example:
            email = window.prompt('Please provide your email for confirmation')
        }
        if (email) {
            const signInResult = await signInWithEmailLink(_auth, email, url)
            window.localStorage.removeItem('emailForSignIn')
            const _firebaseToken = await getIdToken(signInResult.user, true)
            firebaseToken = _firebaseToken
            isSuccess = true
            alert(`SignIn with MagicLink ${email}`)
        }
    }
    return {
        isSuccess,
        firebaseToken,
    }
}

// sso provider
async function handleSignInwithPopup(
    _auth: Auth,
    firebaseProvider: FirebaseSignInProvider
) {
    // https://firebase.google.com/docs/auth/web/facebook-login
    let firebaseToken = ''
    let isSuccess = false

    // Check Provider
    let authProvider:
        | GoogleAuthProvider
        | FacebookAuthProvider
        | OAuthProvider
        | null = null
    switch (firebaseProvider) {
        case 'google.com':
            authProvider = new GoogleAuthProvider()
            // authProvider.addScope('https://www.googleapis.com/auth/contacts.readonly');
            authProvider.setCustomParameters({
                prompt: 'select_account',
            })
            break
        case 'facebook.com':
            authProvider = new FacebookAuthProvider()
            break
        case 'apple.com':
            authProvider = new OAuthProvider('apple.com')
            break
        default:
            break
    }
    if (!authProvider) {
        alert('SignIn failed. No Provider.')
        return {
            isSuccess,
            firebaseToken,
        }
    }

    // Check Credential based on Provider
    const signInResult = await signInWithPopup(_auth, authProvider)

    let credential: OAuthCredential | null = null
    switch (firebaseProvider) {
        case 'google.com':
            credential = GoogleAuthProvider.credentialFromResult(signInResult)
            break
        case 'facebook.com':
            credential = FacebookAuthProvider.credentialFromResult(signInResult)
            break
        case 'apple.com':
            credential = OAuthProvider.credentialFromResult(signInResult)
            break
        default:
            break
    }
    if (!credential) {
        alert('SignIn failed. No Credential.')
        return {
            isSuccess,
            firebaseToken,
        }
    }

    const _firebaseToken = await getIdToken(signInResult.user, true)
    firebaseToken = _firebaseToken
    isSuccess = true

    return {
        isSuccess,
        firebaseToken,
    }
}

const extractFirebaseProviderName = (provider: FirebaseSignInProvider) => {
    switch (provider) {
        case 'anonymous':
            return 'Anonymous'
        case 'apple.com':
            return 'Apple'
        case 'custom':
            return 'Custom'
        case 'email':
            return 'Email'
        case 'facebook.com':
            return 'Facebook'
        case 'github.com':
            return 'Github'
        case 'google.com':
            return 'Google'
        case 'microsoft.com':
            return 'Microsoft'
        case 'password':
            return 'Password'
        case 'phone':
            return 'Phone'
        case 'twitter.com':
            return 'Twitter'
        default:
            return 'Not Support'
    }
}
const extractFirebaseProviderLogo = (provider: FirebaseSignInProvider) => {
    switch (provider) {
        case 'anonymous':
            return search
        case 'apple.com':
            return apple
        case 'custom':
            return search
        case 'email':
            return search
        case 'facebook.com':
            return search
        case 'github.com':
            return search
        case 'google.com':
            return google
        case 'microsoft.com':
            return search
        case 'password':
            return search
        case 'phone':
            return search
        case 'twitter.com':
            return search
        default:
            return search
    }
}

function getFirebaseCurrentUser(_auth: Auth): Promise<User | null> {
    return new Promise((resolve, reject) => {
        if (_auth.currentUser) {
            resolve(_auth.currentUser)
        }
        const unsubscribe = _auth.onAuthStateChanged((user) => {
            unsubscribe()
            resolve(user)
        }, reject)
    })
}

const refreshFirebaseToken = async (_auth: Auth) => {
    const currentUser = await getFirebaseCurrentUser(_auth)
    if (currentUser === null) {
        throw new Error(`No firebase user`)
    }
    const firebaseToken = await getIdToken(_auth.currentUser!, true)
    return {
        firebaseToken,
    }
}

function useFirebaseAuthService() {
    return {
        // email password
        handleCreateUserWithEmailAndPassword,
        handleSignInWithEmailAndPassword,

        // email magic link
        handleSendSignInLinkToEmail,
        handleSignInWithEmailLink,

        // popup
        handleSignInwithPopup,

        // utils
        extractFirebaseProviderName,
        extractFirebaseProviderLogo,
        getFirebaseCurrentUser,
        refreshFirebaseToken,
    }
}

export default useFirebaseAuthService
