import { FConfirmPopUp } from '@fantaskticedtechlimited/fui-complib'
import { FOnSubmitFunction } from '@fantaskticedtechlimited/fui-complib'
import {
    createContext,
    ElementType,
    Fragment,
    useContext,
    useState,
} from 'react'
import { Folder, QuestionBank } from '../../../../../assets/type/backend.type'
import { FolderCreateForm } from '../../form/FolderCreateForm'
import { FolderUpdateForm } from '../../form/FolderUpdateForm'
import { FolderDeletePopUp } from '../FolderDeletePopUp'
import FolderMoreActionPopUp from '../FolderMoreActionPopUp'
import { FolderToggleEnablePopUp } from '../FolderToggleEnablePopUp'
import { FolderPopUpWrapperProps } from './type'

const FolderPopUpWrapperContext = createContext<FolderPopUpWrapperProps>({
    isFolderMoreActionPopUpOpen: false,
    onFolderMoreActionPopUpOpen: async () => {},

    isFolderToggleEnablePopUpOpen: false,
    onFolderToggleEnablePopUpOpen: async () => {},

    isFolderCreatePopUpOpen: false,
    onFolderCreatePopUpOpen: async () => {},

    isFolderUpdatePopUpOpen: false,
    onFolderUpdatePopUpOpen: async () => {},

    isFolderDeletePopUpOpen: false,
    onFolderDeletePopUpOpen: async () => {},
})
export const useFolderPopUpWrapperContext = () =>
    useContext(FolderPopUpWrapperContext)
export const WithFolderPopUpWrapper = (Component: ElementType) => {
    return function WithFolderPopUpWrapper(props: any) {
        // More Action PopUp
        const [moreActionData, setMoreActionData] = useState<{
            folder: Folder
            callback: FOnSubmitFunction<Folder | Folder>
        } | null>(null)

        const [isFolderMoreActionPopUpOpen, setIsFolderMoreActionPopUpOpen] =
            useState(false)
        const handleFolderMoreActionPopUpOpen = async (
            _folder: Folder,
            _callbackFunction: FOnSubmitFunction<Folder | Folder>
        ) => {
            setIsFolderMoreActionPopUpOpen(true)
            setMoreActionData({
                folder: _folder,
                callback: _callbackFunction,
            })
        }
        const handleFolderMoreActionPopUpClose = () => {
            setIsFolderMoreActionPopUpOpen(false)
            setMoreActionData(null)
        }

        // Toggle Enable PopUp
        const [toggleEnableData, setToggleEnableData] = useState<{
            folder: Folder
            callback: FOnSubmitFunction<Folder>
        } | null>(null)
        const [
            isFolderToggleEnablePopUpOpen,
            setIsFolderToggleEnablePopUpOpen,
        ] = useState(false)
        const handleFolderToggleEnablePopUpOpen = async (
            _folder: Folder,
            _callbackFunction: FOnSubmitFunction<Folder>
        ) => {
            setIsFolderToggleEnablePopUpOpen(true)
            setToggleEnableData({
                folder: _folder,
                callback: _callbackFunction,
            })
        }
        const handleFolderToggleEnablePopUpClose = () => {
            setIsFolderToggleEnablePopUpOpen(false)
            setToggleEnableData(null)
        }

        // Folder Create
        const [folderCreateData, setFolderCreateData] = useState<{
            questionBank: QuestionBank
            callback: FOnSubmitFunction<Folder>
        } | null>(null)
        const [isFolderCreatePopUpOpen, setIsFolderCreatePopUpOpen] =
            useState(false)
        const handleFolderCreatePopUpOpen = async (
            _questionBank: QuestionBank,
            _callbackFunction: FOnSubmitFunction<Folder>
        ) => {
            setFolderCreateData({
                questionBank: _questionBank,
                callback: _callbackFunction,
            })
            setIsFolderCreatePopUpOpen(true)
        }
        const handleFolderCreatePopUpSubmit = async (newFolder: Folder) => {
            folderCreateData && (await folderCreateData.callback(newFolder))
        }
        const handleFolderCreatePopUpClose = () => {
            setIsFolderCreatePopUpOpen(false)
            setFolderCreateData(null)
        }

        // Folder Update
        const [folderUpdateData, setFolderUpdateData] = useState<{
            folder: Folder
            callback: FOnSubmitFunction<Folder>
        } | null>(null)
        const [isFolderUpdatePopUpOpen, setIsFolderUpdatePopUpOpen] =
            useState(false)
        const handleFolderUpdatePopUpOpen = async (
            _folder: Folder,
            _callbackFunction: FOnSubmitFunction<Folder>
        ) => {
            setFolderUpdateData({
                folder: _folder,
                callback: _callbackFunction,
            })
            setIsFolderUpdatePopUpOpen(true)
        }
        const handleFolderUpdatePopUpSubmit = async (updatedFolder: Folder) => {
            folderUpdateData && (await folderUpdateData.callback(updatedFolder))
        }

        const handleFolderUpdatePopUpClose = () => {
            setIsFolderUpdatePopUpOpen(false)
            setFolderUpdateData(null)
        }

        // Folder Delete
        const [folderDeleteData, setFolderDeleteData] = useState<{
            folder: Folder
            callback: FOnSubmitFunction<Folder>
        } | null>(null)
        const [isFolderDeletePopUpOpen, setIsFolderDeletePopUpOpen] =
            useState(false)
        const handleFolderDeletePopUpOpen = async (
            _folder: Folder,
            _callbackFunction: FOnSubmitFunction<Folder>
        ) => {
            setIsFolderDeletePopUpOpen(true)
            setFolderDeleteData({
                folder: _folder,
                callback: _callbackFunction,
            })
        }
        const handleFolderDeletePopUpClose = () => {
            setIsFolderDeletePopUpOpen(false)
            setFolderDeleteData(null)
        }

        // Context
        const defaultContextValue: FolderPopUpWrapperProps = {
            isFolderMoreActionPopUpOpen,
            onFolderMoreActionPopUpOpen: handleFolderMoreActionPopUpOpen,

            isFolderToggleEnablePopUpOpen,
            onFolderToggleEnablePopUpOpen: handleFolderToggleEnablePopUpOpen,

            isFolderCreatePopUpOpen,
            onFolderCreatePopUpOpen: handleFolderCreatePopUpOpen,

            isFolderUpdatePopUpOpen,
            onFolderUpdatePopUpOpen: handleFolderUpdatePopUpOpen,

            isFolderDeletePopUpOpen,
            onFolderDeletePopUpOpen: handleFolderDeletePopUpOpen,
        }

        return (
            <FolderPopUpWrapperContext.Provider value={defaultContextValue}>
                <Fragment>
                    <Component {...props} />

                    {/* More Action PopUp */}
                    {isFolderMoreActionPopUpOpen && moreActionData !== null && (
                        <FolderMoreActionPopUp
                            onClose={() => handleFolderMoreActionPopUpClose()}
                            folder={moreActionData.folder}
                            onCallback={async (data) =>
                                await moreActionData.callback(data)
                            }
                        />
                    )}

                    {/* Toggle Enable PopUp */}
                    {isFolderToggleEnablePopUpOpen &&
                        toggleEnableData !== null && (
                            <FolderToggleEnablePopUp
                                onClose={handleFolderToggleEnablePopUpClose}
                                folderData={toggleEnableData?.folder}
                                onCallback={async (data) =>
                                    await toggleEnableData.callback(data)
                                }
                            />
                        )}

                    {/* Folder Create PopUp */}
                    {isFolderCreatePopUpOpen && folderCreateData && (
                        <FConfirmPopUp
                            title="Create Folder"
                            subtitle="Input new folder name, order sequence and/or add an icon below."
                            subtitleProps={{ style: { fontWeight: 400 } }}
                            disableCloseWhenClickOutside
                            disableBottomBar
                            style={{ width: '30%', height: 'fit-content' }}
                        >
                            <FolderCreateForm
                                questionBankId={
                                    folderCreateData.questionBank.id
                                }
                                onSubmit={async (data) =>
                                    await handleFolderCreatePopUpSubmit(data)
                                }
                                onClose={handleFolderCreatePopUpClose}
                            />
                        </FConfirmPopUp>
                    )}

                    {/* Update Folder PopUp */}
                    {isFolderUpdatePopUpOpen && folderUpdateData && (
                        <FConfirmPopUp
                            title="Edit Folder"
                            disableCloseWhenClickOutside
                            disableBottomBar
                            style={{ width: '30%', height: 'fit-content' }}
                        >
                            <FolderUpdateForm
                                folder={folderUpdateData.folder}
                                onClose={handleFolderUpdatePopUpClose}
                                onSubmit={async (data) =>
                                    await handleFolderUpdatePopUpSubmit(data)
                                }
                            />
                        </FConfirmPopUp>
                    )}

                    {/* Folder Delete PopUp */}
                    {isFolderDeletePopUpOpen && folderDeleteData !== null && (
                        <FolderDeletePopUp
                            onClose={handleFolderDeletePopUpClose}
                            folder={folderDeleteData?.folder}
                            onCallback={async (data) =>
                                await folderDeleteData.callback(data)
                            }
                        />
                    )}
                </Fragment>
            </FolderPopUpWrapperContext.Provider>
        )
    }
}
