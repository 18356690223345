import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { style } from 'typestyle'

export const toolbarContainer = style({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    columnGap: '0.75rem',
    minHeight: '5rem',
})

export const divider = style({
    margin: '0 1rem',
    height: '0.075rem',
    backgroundColor: FLegacyColorTypes.PRIMARY_LIGHT,
})
