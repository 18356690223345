import { QBAssetListCardIconBarProps } from '../QBAssetListCardIconBar/types'
import { QBAssetListCardFoldersContentProps } from './types'
import { styles } from './styles'
import { FText, FUseColor } from '@fantaskticedtechlimited/fui-complib'
import { FFontTypes } from '@fantaskticedtechlimited/fui-fontlib'
import QBAssetListCardIconBar from '../QBAssetListCardIconBar'

export const QBAssetListCardFoldersContent = (
    props: QBAssetListCardFoldersContentProps
) => {
    const data = props.data
    const chapterData = data.chapters
    const iconURL = data.icon?.fileURL
    const iconName = data.icon?.originalFileName
    const iconSize = { width: '120px', height: '60px' }
    const iconBarProps: Partial<QBAssetListCardIconBarProps> = {
        chapterNumber: chapterData.length,
        questionTypeNumber: chapterData.reduce(
            (total, currentChapter) =>
                (total = total + currentChapter.questionTypes.length),
            0
        ),
        questionGroupsNumber: chapterData.reduce(
            (total, currentChapter) =>
                (total =
                    total +
                    currentChapter.questionTypes.reduce(
                        (total2, currentType) =>
                            (total2 =
                                total2 + currentType.questionGroups.length),
                        0
                    )),
            0
        ),
        questionsNumber: 0,
    }

    return (
        <div className={styles.CardContent_Container} onClick={props.onClick}>
            <FText
                font={FFontTypes.Large_Text()}
                color={FUseColor({ colorName: 'Grey' })}
            >
                {data.orderSequence}
            </FText>
            <div style={{ ...iconSize }}>
                {iconURL ? (
                    <div style={{ ...iconSize }}>
                        <img src={iconURL} {...iconSize} alt={iconName} />
                    </div>
                ) : (
                    <div style={{ width: 'fit-content' }}>
                        <div style={{ ...iconSize }} />
                    </div>
                )}
            </div>
            <FText
                font={FFontTypes.Large_Text()}
                maxRows={2}
                overflowHidden
                style={{ flex: 1 }}
            >
                {data.name}
            </FText>
            <QBAssetListCardIconBar {...iconBarProps} />
        </div>
    )
}
