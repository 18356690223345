import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { style, stylesheet } from 'typestyle'

export const styles = stylesheet({
    InputPanel_Container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '24px',
        overflowY: 'auto',
    },
    InputPanel_Section_Container: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '16px',
    },
    InputPanel_MCAnswerRow_Div: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: '8px',
        flex: 1,
    },
    InputPanel_DeleteIcon_Div: {
        padding: '4px',
        $nest: {
            '&:hover': {
                border: '2px solid ' + FLegacyColorTypes.SECONDARY_RED,
                borderRadius: 24,
            },
        },
    },
})

export const InputPanel_MCAnswerCorrectButton_Div = (isCorrect: boolean) =>
    style({
        minWidth: '50px',
        padding: '4px 8px !important',
        borderColor: 'transparent !important',
        $nest: {
            '&:hover': {
                borderColor: isCorrect
                    ? FLegacyColorTypes.SECONDARY_GREEN + ' !important'
                    : FLegacyColorTypes.SECONDARY_RED + ' !important',
                backgroundColor: 'transparent !important',
            },
        },
    })
