import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { FDropdown } from '@fantaskticedtechlimited/fui-complib'
import { FIcon, FIconNames } from '@fantaskticedtechlimited/fui-iconlib'
import { useCallback, useEffect, useRef, useState } from 'react'
import { QBAssetDropdownMenuProps } from './types'
import { styles } from './styles'
import QBAssetDropdownOption from './QBAssetDropdownOption'
import { QBAssetDropdownOptionProps } from './QBAssetDropdownOption/types'
import {
    HBEditIcon,
    HBMoveIcon,
    HBDeleteIcon,
    HBDuplicateIcon,
    HBClearFlagIcon,
} from './QBAssetDropdownIcon'

export const QBAssetDropdownMenu = (props: QBAssetDropdownMenuProps) => {
    const [matchedOptions, setMatchedOptions] = useState<
        QBAssetDropdownOptionProps[] | null
    >(null)
    const [openDropdown, setOpenDropdown] = useState(false)
    const ref = useRef<HTMLDivElement>(null)

    const defaultOptions: QBAssetDropdownOptionProps[] = [
        {
            name: 'Edit Info',
            icon: (isHover) => <HBEditIcon isHover={isHover} />,
        },
        {
            name: 'Move to',
            icon: (isHover) => <HBMoveIcon isHover={isHover} />,
        },
        {
            name: 'Duplicate to',
            icon: (isHover) => <HBDuplicateIcon isHover={isHover} />,
        },
        {
            name: 'Clear Flag',
            icon: (isHover) => <HBClearFlagIcon isHover={isHover} />,
        },
        {
            name: 'Delete',
            icon: (isHover) => <HBDeleteIcon isHover={isHover} />,
        },
    ]

    const checkIfClickedOutside = useCallback(
        (e: any) => {
            if (ref.current != null && ref.current.contains(e.target) === false)
                setOpenDropdown(false)
        },
        [ref]
    )

    const checkMatchedOptions = () => {
        let tempOptionArray = [...defaultOptions]
        if (!props.onEditInfo)
            tempOptionArray = tempOptionArray.filter(
                (opt) => opt.name !== 'Edit Info'
            )
        if (!props.onMoveTo)
            tempOptionArray = tempOptionArray.filter(
                (opt) => opt.name !== 'Move to'
            )
        if (!props.onDuplicateTo)
            tempOptionArray = tempOptionArray.filter(
                (opt) => opt.name !== 'Duplicate to'
            )
        if (!props.onClearFlag)
            tempOptionArray = tempOptionArray.filter(
                (opt) => opt.name !== 'Clear Flag'
            )
        if (!props.onDelete)
            tempOptionArray = tempOptionArray.filter(
                (opt) => opt.name !== 'Delete'
            )
        setMatchedOptions(tempOptionArray)
    }

    const handleOnOptionSelect = (data: QBAssetDropdownOptionProps) => {
        switch (data.name) {
            case 'Edit Info':
                return props.onEditInfo && props.onEditInfo()
            case 'Move to':
                return props.onMoveTo && props.onMoveTo()
            case 'Duplicate to':
                return props.onDuplicateTo && props.onDuplicateTo()
            case 'Clear Flag':
                return props.onClearFlag && props.onClearFlag()
            case 'Delete':
                return props.onDelete && props.onDelete()
        }
    }

    useEffect(() => {
        if (openDropdown) checkMatchedOptions()
        else setMatchedOptions(null)
    }, [openDropdown])

    useEffect(() => {
        document.addEventListener('mousedown', checkIfClickedOutside)
        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside)
        }
    }, [checkIfClickedOutside])

    return (
        <div
            ref={ref}
            style={{ display: props.disabled ? 'none' : 'flex' }}
            className={styles.QBAssetDropdown_Wrapper}
        >
            <FIcon
                name={FIconNames.MORE}
                size="large"
                color={() => FLegacyColorTypes.PRIMARY_GREY}
                onClick={() => {
                    if (!props.disabled) setOpenDropdown(!openDropdown)
                }}
            />
            {openDropdown && matchedOptions ? (
                <FDropdown
                    options={matchedOptions}
                    onSelect={handleOnOptionSelect}
                    dropdownContainerClassName={
                        styles.QBAssetDropdown_Container
                    }
                    renderCustomizedOption={(data) => (
                        <QBAssetDropdownOption {...data} />
                    )}
                />
            ) : null}
        </div>
    )
}
