import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { FSwitchButton } from '@fantaskticedtechlimited/fui-complib'
import { QBAssetSwitchButtonProps } from './types'
import * as styles from './styles'

function QBAssetSwitchButton(props: QBAssetSwitchButtonProps) {
    const { isEnable, onClick, disabled } = props
    return (
        <FSwitchButton
            disabled={disabled}
            containerStyle={{
                borderColor: 'rgba(0, 0, 0, 0.08)',
                backgroundColor: isEnable
                    ? FLegacyColorTypes.SECONDARY_GREEN
                    : FLegacyColorTypes.PRIMARY_LIGHT,
                width: '32px',
                height: '20px',
                padding: '4px',
                borderRadius: '20px',
                borderWidth: '1px',
            }}
            containerClassName={styles.switchButton_Circle(isEnable!)}
            svgStyle={{
                width: '12px',
                height: '12px',
            }}
            circleStrokeWidth={0}
            circleColor={
                isEnable
                    ? FLegacyColorTypes.PRIMARY_WHITE
                    : FLegacyColorTypes.PRIMARY_GREY
            }
            checked={isEnable}
            onClick={async () => {
                onClick && (await onClick())
            }}
        />
    )
}

export default QBAssetSwitchButton
