import { stylesheet } from 'typestyle'

export const styles = stylesheet({
    CardContent_Container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        columnGap: '16px',
    },
    CardContent_TitleDiv: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        rowGap: 4,
    },
    CardContent_InfoDiv: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: 8,
        alignItems: 'center',
    },
})
