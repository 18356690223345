import { FForm, FInputField } from '@fantaskticedtechlimited/fui-complib'
import { Fragment, useState } from 'react'
import useQBAPIService from '../../../../../apiService/questionBank'
import { QuestionTypeUpdateInput } from '../../../../../assets/type/backend.type'
import removeTypename from '../../../../../utils/removeTypename'

import { QuestionTypeUpdateFormProps } from './types'

export const QuestionTypeUpdateForm = (props: QuestionTypeUpdateFormProps) => {
    const qbAPIService = useQBAPIService()

    let defaultValue: QuestionTypeUpdateInput = {
        questionTypeId: props.questionType.id,
        name: props.questionType.name ?? 'Name',
    }
    const [questionTypeUpdateInput, setQuestionTypeUpdateInput] =
        useState<QuestionTypeUpdateInput>({
            ...defaultValue,
        })

    const handleQuestionTypeUpdate = async (
        _questionTypeUpdateInput: QuestionTypeUpdateInput
    ) => {
        try {
            let questionTypeUpdateInput = removeTypename(
                _questionTypeUpdateInput
            )
            const result = await qbAPIService.qbQuestionTypeUpdateByIdMutation({
                variables: {
                    questionTypeUpdateInput,
                },
            })
            if (
                result.data?.qbQuestionTypeUpdateById.isSuccess &&
                result.data.qbQuestionTypeUpdateById.data
            ) {
                props.onSubmitCallback &&
                    (await props.onSubmitCallback(
                        result.data.qbQuestionTypeUpdateById.data
                    ))
                props.onClose && (await props.onClose())
            } else {
                console.error(
                    `QuestionType Update failed, Reason. ${JSON.stringify(
                        result
                    )}`
                )
                alert(
                    `QuestionType Update failed, Reason.  ${result.data?.qbQuestionTypeUpdateById.errorMessage}`
                )
            }
        } catch (error) {
            console.log('error', error)
            alert(`QuestionType Update failed, Error: ${JSON.stringify(error)}`)
        }
    }

    return (
        <FForm
            onClose={props.onClose}
            onSubmit={() =>
                handleQuestionTypeUpdate({
                    ...questionTypeUpdateInput,
                })
            }
        >
            <FInputField
                autoFocus
                label="QuestionType Name"
                value={questionTypeUpdateInput.name}
                wordCount={100}
                onInput={(v) =>
                    setQuestionTypeUpdateInput({
                        ...questionTypeUpdateInput,
                        name: v,
                    })
                }
            />
        </FForm>
    )
}
