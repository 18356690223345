import { FolderListProps } from './types'
import * as styles from './styles'
import FolderRow from '../FolderRow'
import { Folder } from '../../../../../assets/type/backend.type'
import { useFolderPopUpWrapperContext } from '../../popUp/WithFolderPopUpWrapper'

function FolderList(props: FolderListProps) {
    const { onFolderMoreActionPopUpOpen, onFolderToggleEnablePopUpOpen } =
        useFolderPopUpWrapperContext()

    const handleMoreActionPopUpCallback = async () => {
        await props.onRefresh()
    }
    return (
        <div className={styles.folderListDiv}>
            {/* FolderList List looping */}
            {(props.folders || []).map((folder: Folder, index: number) => (
                <FolderRow
                    key={index}
                    index={index}
                    folder={folder}
                    onMoreActionButtonClick={() => {
                        onFolderMoreActionPopUpOpen(folder, () =>
                            handleMoreActionPopUpCallback()
                        )
                    }}
                    onToggleEnableButtonClick={() => {
                        onFolderToggleEnablePopUpOpen(folder, props.onRefresh)
                    }}
                />
            ))}
        </div>
    )
}

export default FolderList
