import useQBAPIService from '../../../../../apiService/questionBank'
import { ChapterUpdateInput } from '../../../../../assets/type/backend.type'
import { ChapterToggleEnablePopUpProps } from './types'
import { TogglePopUp } from '../../../../../Components/TogglePopUp'

export const ChapterToggleEnablePopUp = (
    props: ChapterToggleEnablePopUpProps
) => {
    const qbAPIService = useQBAPIService()
    const targetEnableStatus = !props.chapterData.isEnable

    const handlePopUpClose = async () => {
        props.onClose && (await props.onClose())
    }

    const handleChapterToggle = async (
        chapterId: string,
        _targetEnableStatus: boolean
    ) => {
        let data: ChapterUpdateInput = {
            chapterId: chapterId,
            isEnable: _targetEnableStatus,
        }
        const statusStr = data.isEnable ? 'Enable' : 'Disable'
        try {
            const result = await qbAPIService.qbChapterUpdateByIdMutation({
                variables: {
                    chapterUpdateInput: data,
                },
            })
            if (result.data?.qbChapterUpdateById.isSuccess) {
                props.onCallback &&
                    (await props.onCallback(
                        result.data?.qbChapterUpdateById.data!
                    ))
                handlePopUpClose()
            } else {
                alert(
                    `Chapter ${statusStr} failed, Reason. ${JSON.stringify(
                        result
                    )}`
                )
            }
        } catch (error) {
            console.log('error', error)
            alert(
                `Chapter ${statusStr} failed, Error: ${JSON.stringify(error)}`
            )
        }
    }

    return (
        <TogglePopUp
            isEnable={targetEnableStatus}
            targetType="Chapter"
            targetName={props.chapterData.name}
            onClose={handlePopUpClose}
            onActionButtonClick={() =>
                handleChapterToggle(props.chapterData.id, targetEnableStatus)
            }
        />
    )
}
