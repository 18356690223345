import { Fragment, useEffect, useState } from 'react'
import { QuestionBank } from '../../../assets/type/backend.type'
import {
    WithLoadingPageWrapper,
    useLoadingPageWrapperContext,
} from '../../../layout/WithLoadingPageWrapper'
import ContentFloatingActionButton from '../components/ContentFloatingActionButton'
import FolderList from '../components/Folder/FolderList'
import * as styles from './styles'
import ChapterList from '../components/Chapter/ChapterList'
import { ContentDetailProps } from './type'
import {
    useChapterPopUpWrapperContext,
    WithChapterPopUpWrapper,
} from '../components/popUp/WithChapterPopUpWrapper'
import useQBAPIService from '../../../apiService/questionBank'
import { useUserSettingContext } from '../../../contexts/userSettingContext'
import {
    FNoData,
    FButton,
    FText,
    FDivider,
    FScrollableContentWrapper,
} from '@fantaskticedtechlimited/fui-complib'

import { PageContainer } from '../../../layout/PageContainer'
import { FFontTypes } from '@fantaskticedtechlimited/fui-fontlib'
import {
    useFolderPopUpWrapperContext,
    WithFolderPopUpWrapper,
} from '../components/popUp/WithFolderPopUpWrapper'

function ContentHome(props: ContentDetailProps) {
    const { currentUserSetting } = useUserSettingContext()
    const { setLoadingEnd, setLoadingStart } = useLoadingPageWrapperContext()
    const { onChapterCreatePopUpOpen } = useChapterPopUpWrapperContext()
    const { onFolderCreatePopUpOpen } = useFolderPopUpWrapperContext()
    const qbAPIService = useQBAPIService()
    const [questionBank, setQuestionBank] = useState<QuestionBank | null>(null)
    const totalChapters = questionBank?.chapters.length || 0
    const totalFolders = questionBank?.folders.length || 0

    const handleGetQuestionBank = async (id: string, withLoading?: boolean) => {
        withLoading && setLoadingStart()
        try {
            const result = await qbAPIService.getQuestionBankContentById({ id })
            const { questionBankGet } = result.data
            setQuestionBank(
                questionBankGet.isSuccess ? questionBankGet.data! : null
            )
        } catch (error) {
            alert(JSON.stringify(error))
            setQuestionBank(null)
        }
        withLoading && setLoadingEnd()
    }
    const handleGetFoldersByQuestionBankId = async (questionBankId: string) => {
        try {
            const result = await qbAPIService.getFoldersByFilter({
                folderFilterInput: {
                    questionBankId,
                },
            })
            const { foldersGet } = result.data
            if (foldersGet.isSuccess) {
                let newQuestionBank: QuestionBank = {
                    ...questionBank!,
                    folders: foldersGet.data || [],
                }
                setQuestionBank(newQuestionBank)
            } else {
                setQuestionBank(null)
            }
        } catch (error) {
            alert(JSON.stringify(error))
            setQuestionBank(null)
        }
    }

    const handleGetChaptersByQuestionBankId = async (
        questionBankId: string
    ) => {
        try {
            const result = await qbAPIService.getChaptersByFilter({
                chapterFilterInput: {
                    questionBankId,
                },
            })
            const { chaptersGet } = result.data
            if (chaptersGet.isSuccess) {
                let newQuestionBank: QuestionBank = {
                    ...questionBank!,
                    chapters: chaptersGet.data || [],
                }
                setQuestionBank(newQuestionBank)
            } else {
                setQuestionBank(null)
            }
        } catch (error) {
            alert(JSON.stringify(error))
            setQuestionBank(null)
        }
    }

    // When QB change, all data refetched
    useEffect(() => {
        if (currentUserSetting.questionBank) {
            handleGetQuestionBank(currentUserSetting.questionBank.id, true)
        } else setQuestionBank(null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUserSetting.questionBank])

    // for loading effect or block when QB is not loaded
    if (questionBank === null) {
        return <FNoData description="No QuestionBank" />
    }

    return (
        <PageContainer
            header={
                <div className={styles.ContentHome_Header_Container}>
                    <FText
                        style={{
                            flexGrow: 1,
                        }}
                        font={FFontTypes.Title()}
                        children="QB Content"
                    />
                    <FButton
                        type="Primary"
                        onClick={() =>
                            onChapterCreatePopUpOpen(
                                {
                                    questionBankId: questionBank.id,
                                },
                                async () => {
                                    await handleGetChaptersByQuestionBankId(
                                        questionBank?.id
                                    )
                                }
                            )
                        }
                        label="New Chapter"
                    />
                    <FButton
                        type="Primary"
                        onClick={() =>
                            onFolderCreatePopUpOpen(questionBank, async () => {
                                await handleGetFoldersByQuestionBankId(
                                    questionBank?.id
                                )
                            })
                        }
                        label="New Folder"
                    />
                    <FButton
                        type="Primary"
                        onClick={() =>
                            handleGetQuestionBank(questionBank.id, true)
                        }
                        label="Refresh"
                    />
                </div>
            }
            body={
                <FScrollableContentWrapper>
                    <Fragment>
                        {totalFolders === 0 && totalChapters === 0 ? (
                            <FNoData />
                        ) : (
                            <div
                                className={styles.ContentHome_Content_Container}
                            >
                                <FolderList
                                    folders={questionBank.folders || []}
                                    questionBankId={questionBank.id || ''}
                                    onRefresh={async () =>
                                        await handleGetFoldersByQuestionBankId(
                                            questionBank?.id
                                        )
                                    }
                                />
                            </div>
                        )}
                        <ContentFloatingActionButton
                            questionBank={questionBank}
                            onRefresh={() =>
                                handleGetQuestionBank(questionBank.id)
                            }
                        />
                    </Fragment>
                </FScrollableContentWrapper>
            }
        />
    )
}

export default WithLoadingPageWrapper(
    WithFolderPopUpWrapper(WithChapterPopUpWrapper(ContentHome)),
    true
)
// export default WithLoadingPageWrapper(ContentHome,true)
