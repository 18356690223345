import { QuestionGroupRowProps } from './type'

import FListCard from '../../../../../Components/FListCard'
import { Fragment } from 'react'
import { FText } from '@fantaskticedtechlimited/fui-complib'
import { FIcon, FIconNames } from '@fantaskticedtechlimited/fui-iconlib'
import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'

function QuestionGroupRow(props: QuestionGroupRowProps) {
    const { questionGroup } = props

    // const history = useHistory();
    return (
        <FListCard
            isDisableToolBar={props.isDisableToolbar}
            index={props.index}
            content={
                <Fragment>
                    {/* data in middle top */}
                    {/* QuestionGroup Name */}
                    <FText maxRows={2}>{questionGroup.name}</FText>
                    {/* data in middle bottom */}
                </Fragment>
            }
            toolBar={
                <Fragment>
                    <FIcon
                        name={FIconNames.MORE}
                        color={() => FLegacyColorTypes.PRIMARY_GREY}
                        onClick={() => {
                            props.onMoreActionButtonClick &&
                                props.onMoreActionButtonClick()
                        }}
                    />
                </Fragment>
            }
        />
    )
}

export default QuestionGroupRow
