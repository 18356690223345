import { gql } from '@apollo/client'

export const GET_USERS_DETAIL_BY_FILTER_QUERY = gql`
    query getUsersDetailByFilter($userArrayFilterInput: UserArrayFilterInput) {
        umUserGets(userArrayFilterInput: $userArrayFilterInput) {
            code
            errorMessage
            isSuccess
            data {
                id
                email
                firebaseUID
                roles
                status
                createAt
                updateAt
                userProfile {
                    nickname
                    profilePic {
                        fileURL
                    }
                    darkMode
                    language
                    courseIdList
                    emailVerified
                    createAt
                    updateAt
                }
            }
        }
    }
`
