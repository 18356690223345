import useQBAPIService from '../../../../../apiService/questionBank'
import { DeletePopUp } from '../../../../../Components/DeletePopUp'
import { FolderDeletePopUpProps } from './types'

export const FolderDeletePopUp = (props: FolderDeletePopUpProps) => {
    const qbAPIService = useQBAPIService()
    const folderId = props.folder.id
    const folderName = props.folder.name

    const handlePopUpClose = async () => {
        props.onClose && (await props.onClose())
    }

    const handleFolderDelete = async (folderId: string) => {
        try {
            const result = await qbAPIService.qbFolderDeleteByIdMutation({
                variables: {
                    folderId,
                },
            })
            if (result.data?.qbFolderDeleteById.isSuccess) {
                props.onCallback &&
                    (await props.onCallback(
                        result.data?.qbFolderDeleteById.data!
                    ))
                handlePopUpClose()
            } else {
                alert(`Folder delete failed, Reason. ${JSON.stringify(result)}`)
            }
        } catch (error) {
            console.log('error', error)
            alert(`Folder delete failed, Error: ${JSON.stringify(error)}`)
        }
    }

    return (
        <DeletePopUp
            targetType="Folder"
            targetName={folderName}
            onClose={handlePopUpClose}
            onActionButtonClick={() => handleFolderDelete(folderId)}
        />
    )
}
