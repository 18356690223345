import { FButton, FText } from '@fantaskticedtechlimited/fui-complib'

import { getAuth, indexedDBLocalPersistence } from 'firebase/auth'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import useAuthAPIService from '../../../apiService/auth'
import { ImageAsset } from '../../../assets/images'
import { User } from '../../../assets/type/backend.type'
import firebaseAppWithConfig from '../../../config/firebaseConfig'
import { useAuthContext } from '../../../contexts/authContext'
import useFirebaseAuthService from '../../../utils/useFirebaseAuthService'

function CheckLogin() {
    const authAPIService = useAuthAPIService()
    const firebaseAuthService = useFirebaseAuthService()
    const navigate = useNavigate()
    let [searchParams] = useSearchParams()
    const [redirectURL] = useState(searchParams.get('redirectURL') ?? '/')
    const [oauthToken] = useState(searchParams.get('oauthToken') ?? undefined)
    const failURL = `/login?redirectURL=${redirectURL}`
    const { setSignin, setCurrentUser, signOut } = useAuthContext()
    const failAction = useCallback(() => {
        signOut()
        navigate(failURL, { replace: true })
    }, [signOut, navigate, failURL])

    const processCurrentUser = async (me: User) => {
        try {
            setCurrentUser(me)
            setSignin(true)

            let queryString = ''
            searchParams.forEach((value, key) => {
                if (key !== 'redirectURL' && key !== 'oauthToken') {
                    if (queryString === '') {
                        queryString += `?${key}=${value}`
                    } else {
                        queryString += `&${key}=${value}`
                    }
                }
            })
            navigate(`${redirectURL}${queryString}`, { replace: true })
        } catch (error) {
            failAction()
        }
    }
    const handleCheckLocalUser = async () => {
        try {
            // refresh firebase Token
            const firebaseAuth = getAuth(firebaseAppWithConfig)
            firebaseAuth.setPersistence(indexedDBLocalPersistence)
            await firebaseAuthService.refreshFirebaseToken(firebaseAuth)
            const meResult = await authAPIService.getMe({})
            if (meResult.data && meResult.data.me) {
                await processCurrentUser(meResult.data.me)
            }
        } catch (error) {
            console.log('error', error)
            failAction()
        }
    }
    const handleLoginAndGetServerUser = async (oauthToken: string) => {
        try {
            const meResult =
                await authAPIService.handleFirebaseTokenSignUpOrSignIn(
                    oauthToken
                )
            if (meResult.data && meResult.data.me) {
                await processCurrentUser(meResult.data.me)
            }
        } catch (error) {
            failAction()
        }
    }

    useEffect(() => {
        if (oauthToken) {
            handleLoginAndGetServerUser(oauthToken)
        } else {
            handleCheckLocalUser()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oauthToken])
    return (
        <Fragment>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <img src={ImageAsset.logo} alt="logo" />
                <FText>checking login status...</FText>
                <FButton type="Text" onClick={failAction} label="Cancel" />
            </div>
        </Fragment>
    )
}

export default CheckLogin
