import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { FOnClickFunction } from '@fantaskticedtechlimited/fui-complib'
import { FIcon, FIconNames } from '@fantaskticedtechlimited/fui-iconlib'
import { useState, useEffect } from 'react'
import { QuestionType } from '../../../../../assets/type/backend.type'
import { useQuestionGroupPopUpWrapperContext } from '../../../../QuestionBankContent/components/popUp/WithQuestionGroupPopUpWrapper'
import { useQuestionTypePopUpWrapperContext } from '../../../../QuestionBankContent/components/popUp/WithQuestionTypePopUpWrapper'
import { ArchiQuestionGroupCard } from '../ArchiQuestionGroupCard'
import { ArrowOpenCloseIcon } from '../ArrowOpenCloseIcon'

interface ArchiQuestionTypeCardProps {
    questionBankId: string
    questionType: QuestionType
    isRootOpen?: boolean
    onGlobalUpdate?: FOnClickFunction
}
export const ArchiQuestionTypeCard = (props: ArchiQuestionTypeCardProps) => {
    const { onQuestionGroupCreatePopUpOpen } =
        useQuestionGroupPopUpWrapperContext()
    const { onQuestionTypeMoreActionPopUpOpen } =
        useQuestionTypePopUpWrapperContext()
    const { questionType, isRootOpen, questionBankId } = props
    const [isOpenQuestionType, setIsOpenQuestionType] = useState(false)
    useEffect(() => {
        if (!isRootOpen) {
            setIsOpenQuestionType(false)
        } else {
            setIsOpenQuestionType(true)
        }
    }, [isRootOpen])
    return (
        <div>
            {/* questionType title */}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    columnGap: '10px',
                }}
            >
                <ArrowOpenCloseIcon
                    isOpen={isOpenQuestionType}
                    onOpen={() => {
                        console.log('open arrow')
                        setIsOpenQuestionType(true)
                    }}
                    onClose={() => {
                        console.log('close arrow')
                        setIsOpenQuestionType(false)
                    }}
                />
                <span>QuestionType:</span>
                {questionType.name}
                {!isOpenQuestionType &&
                    `(${questionType.questionGroups.length})`}
                <button
                    onClick={() =>
                        onQuestionGroupCreatePopUpOpen(
                            {
                                questionTypeId: questionType.id,
                                chapterId: questionType.chapterId,
                            },
                            async () =>
                                // handleGetQuestionTypesByChapterId(props?.chapter.id)
                                {
                                    props.onGlobalUpdate &&
                                        (await props.onGlobalUpdate())
                                }
                        )
                    }
                >
                    +
                </button>
                {/* More Action button */}
                <FIcon
                    name={FIconNames.MORE}
                    size="small"
                    color={() => FLegacyColorTypes.PRIMARY_GREY}
                    onClick={() => {
                        onQuestionTypeMoreActionPopUpOpen(questionType, {
                            onDelete: async () => {
                                props.onGlobalUpdate &&
                                    (await props.onGlobalUpdate())
                            },
                            onUpdate: async () => {
                                props.onGlobalUpdate &&
                                    (await props.onGlobalUpdate())
                            },
                        })
                    }}
                />
            </div>

            {/* QuestionGroup */}
            {isOpenQuestionType && (
                <div style={{ marginLeft: '72px' }}>
                    {questionType.questionGroups.map((questionGroup, i) => {
                        return (
                            <ArchiQuestionGroupCard
                                questionBankId={questionBankId}
                                isRootOpen={isRootOpen}
                                questionGroup={questionGroup}
                                key={i}
                                onGlobalUpdate={props.onGlobalUpdate}
                            />
                        )
                    })}
                </div>
            )}
        </div>
    )
}
