import { QuestionGroupListProps } from './type'
import * as styles from './styles'
import { QuestionGroup } from '../../../../../assets/type/backend.type'
import QuestionGroupRow from '../QuestionGroupRow'
import { useQuestionGroupPopUpWrapperContext } from '../../popUp/WithQuestionGroupPopUpWrapper'

function QuestionGroupList(props: QuestionGroupListProps) {
    const {
        onQuestionGroupMoreActionPopUpOpen: onQuestionGroupMoreActionPopUpOpen,
    } = useQuestionGroupPopUpWrapperContext()

    const handleMoreActionPopUpCallback = async (
        resultQuestionGroup: QuestionGroup
    ) => {
        await props.onRefresh()
    }
    return (
        <div className={styles.cardListDiv}>
            {/* QuestionGroupList List looping */}
            {(props.questionGroups || []).map(
                (questionGroup: QuestionGroup, index: number) => (
                    // <div>{QuestionGroup.id}</div>
                    <QuestionGroupRow
                        key={index}
                        index={index}
                        questionGroup={questionGroup}
                        onMoreActionButtonClick={() => {
                            onQuestionGroupMoreActionPopUpOpen(questionGroup, {
                                onDelete: handleMoreActionPopUpCallback,
                                onUpdate: handleMoreActionPopUpCallback,
                            })
                        }}
                    />
                )
            )}
        </div>
    )
}

export default QuestionGroupList
