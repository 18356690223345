import { FTag } from '@fantaskticedtechlimited/fui-complib'
import useQBAPIService from '../../../../apiService/questionBank'
import { Tag, TagFilterInput } from '../../../../assets/type/backend.type'
import FCreateSelect from '../../../../Components/FCreateSelect'
// ;
import useMount from '../../../../utils/useMount'
import { useStateSafe } from '../../../../utils/useStateSafe'
import { TagCreateSelectorProps } from './types'
import * as styles from './styles'

function TagCreateSelector(props: TagCreateSelectorProps) {
    const { selectedTags, onSelectedTagsChange } = props
    //
    const qbAPIService = useQBAPIService()
    const [tags, setTags] = useStateSafe<Tag[]>([])
    const [isTagCreateLoading, setIsTagCreateLoading] = useStateSafe(false)
    const handleGetTagsByFilter = async (_tagFilterInput?: TagFilterInput) => {
        try {
            const result = await qbAPIService.getTagsByFilter({
                tagFilterInput: _tagFilterInput,
            })
            const { tagsGet } = result.data
            if (tagsGet.isSuccess) {
                setTags(tagsGet.data! || null)
            }
        } catch (error) {
            console.log('error', error)
            setTags([])
        }
    }
    const handleTagCreate = async (value: string) => {
        try {
            setIsTagCreateLoading(true)
            const result = await qbAPIService.qbTagCreateMutation({
                variables: {
                    tagCreateInput: {
                        name: value,
                        questionBankId: props.questionBankId,
                    },
                },
            })
            if (
                result.data?.qbTagCreate.isSuccess &&
                result.data.qbTagCreate.data
            ) {
                onSelectedTagsChange([
                    ...selectedTags,
                    result.data?.qbTagCreate.data,
                ])
                if (props.tags && props.onTagsChange) {
                    props.onTagsChange([
                        ...props.tags,
                        result.data?.qbTagCreate.data,
                    ])
                } else {
                    setTags([...tags, result.data?.qbTagCreate.data])
                }
            }
        } catch (error) {
            console.error('error', error)
        }
        setIsTagCreateLoading(false)
    }
    const handleTagSelect = (newTag: Tag) => {
        const found = selectedTags.find((e) => e.id === newTag.id)
        if (!found) {
            onSelectedTagsChange([...selectedTags, newTag])
        }
    }
    const handleTagRemove = (newTag: Tag) => {
        const newSelectedTags = selectedTags.filter((e) => e.id !== newTag.id)
        onSelectedTagsChange(newSelectedTags)
    }
    const handleTagsClear = () => {
        onSelectedTagsChange([])
    }

    useMount(() => {
        if (!props.tags) {
            handleGetTagsByFilter(props.tagFilter)
        }
    })

    return (
        <>
            <FCreateSelect
                isLoading={isTagCreateLoading}
                isDisable={isTagCreateLoading}
                options={props.tags || tags || []}
                value={selectedTags}
                placeholder="Select Tags..."
                isMulti
                componentClassName={styles.CustomTag_Container}
                getOptionLabel={(data) => {
                    return <FTag tag={data} label={data.name} />
                }}
                getOptionKey={(data) => data.id}
                onClear={handleTagsClear}
                onSelect={(data) => handleTagSelect(data)}
                onRemove={(data) => handleTagRemove(data)}
                onCreate={(input) => handleTagCreate(input)}
                onCompareInputValue={(data, input) => {
                    return data.name.toLowerCase().includes(input.toLowerCase())
                }}
            />
        </>
    )
}

export default TagCreateSelector
