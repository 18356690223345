import { gql } from '@apollo/client'

export const MFA_OTP_CREATE_MUTATION = gql`
    mutation mfaOTPCreate {
        mfaOTPCreate {
            isSuccess
            errorMessage
            code
            data {
                id
                token
                event
                status
                createAt
                updateAt
                expireAt
            }
        }
    }
`
