import {
    createContext,
    ElementType,
    Fragment,
    useContext,
    useState,
} from 'react'
import { Question } from '../../../../../assets/type/backend.type'
import QuestionCreateForm from '../../form/QuestionCreateForm'
import { QuestionDeletePopUp } from '../QuestionDeletePopUp'
import { QuestionMoreActionPopUp } from '../QuestionMoreActionPopUp'

import { QuestionPopUpWrapperProps } from './type'
import { FConfirmPopUp } from '@fantaskticedtechlimited/fui-complib'
import { FOnSubmitFunction } from '@fantaskticedtechlimited/fui-complib'
import { QuestionUpdateForm } from '../../form/QuestionUpdateForm'

const QuestionPopUpWrapperContext = createContext<QuestionPopUpWrapperProps>({
    isQuestionMoreActionPopUpOpen: false,
    onQuestionMoreActionPopUpOpen: async () => {},

    isQuestionCreatePopUpOpen: false,
    onQuestionCreatePopUpOpen: async () => {},

    isQuestionUpdatePopUpOpen: false,
    onQuestionUpdatePopUpOpen: async () => {},

    isQuestionDeletePopUpOpen: false,
    onQuestionDeletePopUpOpen: async () => {},
})

export const useQuestionPopUpWrapperContext = () =>
    useContext(QuestionPopUpWrapperContext)

export const WithQuestionPopUpWrapper = (Component: ElementType) => {
    return function WithQuestionPopUpWrapper(props: any) {
        // More Action PopUp
        const [moreActionData, setMoreActionData] = useState<{
            question: Question
            onDeleteCallback: FOnSubmitFunction<Question>
            onUpdateCallback: FOnSubmitFunction<Question>
        } | null>(null)
        const [
            isQuestionMoreActionPopUpOpen,
            setIsQuestionMoreActionPopUpOpen,
        ] = useState(false)
        // Question Create
        const [questionCreateData, setQuestionCreateData] = useState<{
            questionBankId: string
            presetChapterId?: string
            presetQuestionTypeId?: string
            presetQuestionGroupId?: string
            callback: FOnSubmitFunction<Question>
        } | null>(null)
        const [isQuestionCreatePopUpOpen, setIsQuestionCreatePopUpOpen] =
            useState(false)
        // Question Update
        const [questionUpdateData, setQuestionUpdateData] = useState<{
            question: Question
            callback: FOnSubmitFunction<Question>
        } | null>(null)
        const [isQuestionUpdatePopUpOpen, setIsQuestionUpdatePopUpOpen] =
            useState(false)
        // Question Delete
        const [questionDeleteData, setQuestionDeleteData] = useState<{
            question: Question
            callback: FOnSubmitFunction<Question>
        } | null>(null)
        const [isQuestionDeletePopUpOpen, setIsQuestionDeletePopUpOpen] =
            useState(false)

        const handleQuestionMoreActionPopUpOpen = async (
            _question: Question,
            _callbackFunctions: {
                onUpdate: FOnSubmitFunction<Question>
                onDelete: FOnSubmitFunction<Question>
            }
        ) => {
            setIsQuestionMoreActionPopUpOpen(true)
            setMoreActionData({
                question: _question,
                onDeleteCallback: _callbackFunctions.onDelete,
                onUpdateCallback: _callbackFunctions.onUpdate,
            })
        }

        const handleQuestionMoreActionPopUpClose = () => {
            setIsQuestionMoreActionPopUpOpen(false)
            setMoreActionData(null)
        }

        const handleQuestionCreatePopUpOpen = async (
            data: {
                presetChapterId?: string
                presetQuestionTypeId?: string
                presetQuestionGroupId?: string
                questionBankId: string
            },
            _callbackFunction: FOnSubmitFunction<Question>
        ) => {
            setQuestionCreateData({
                ...data,
                callback: _callbackFunction,
            })
            setIsQuestionCreatePopUpOpen(true)
        }

        const handleQuestionCreatePopUpSubmit = async (
            newQuestion: Question
        ) => {
            questionCreateData &&
                (await questionCreateData.callback(newQuestion))
        }

        const handleQuestionCreatePopUpClose = () => {
            setIsQuestionCreatePopUpOpen(false)
            setQuestionCreateData(null)
        }

        const handleQuestionUpdatePopUpOpen = async (
            _question: Question,
            _callbackFunction: FOnSubmitFunction<Question>
        ) => {
            setQuestionUpdateData({
                question: _question,
                callback: _callbackFunction,
            })
            setIsQuestionUpdatePopUpOpen(true)
        }

        const handleQuestionUpdatePopUpSubmit = async (
            updatedQuestion: Question
        ) => {
            questionUpdateData &&
                (await questionUpdateData.callback(updatedQuestion))
        }

        const handleQuestionUpdatePopUpClose = () => {
            setIsQuestionUpdatePopUpOpen(false)
            setQuestionUpdateData(null)
        }

        const handleQuestionDeletePopUpOpen = async (
            _question: Question,
            _callbackFunction: FOnSubmitFunction<Question>
        ) => {
            setIsQuestionDeletePopUpOpen(true)
            setQuestionDeleteData({
                question: _question,
                callback: _callbackFunction,
            })
        }

        const handleQuestionDeletePopUpClose = () => {
            setIsQuestionDeletePopUpOpen(false)
            setQuestionDeleteData(null)
        }

        // Context
        const defaultContextValue: QuestionPopUpWrapperProps = {
            isQuestionMoreActionPopUpOpen,
            onQuestionMoreActionPopUpOpen: handleQuestionMoreActionPopUpOpen,

            isQuestionCreatePopUpOpen,
            onQuestionCreatePopUpOpen: handleQuestionCreatePopUpOpen,

            isQuestionUpdatePopUpOpen,
            onQuestionUpdatePopUpOpen: handleQuestionUpdatePopUpOpen,

            isQuestionDeletePopUpOpen,
            onQuestionDeletePopUpOpen: handleQuestionDeletePopUpOpen,
        }

        return (
            <QuestionPopUpWrapperContext.Provider value={defaultContextValue}>
                <Fragment>
                    <Component {...props} />

                    {/* More Action PopUp */}
                    {/* {isQuestionMoreActionPopUpOpen &&
                        moreActionData !== null && (
                            <QuestionMoreActionPopUp
                                onClose={() =>
                                    handleQuestionMoreActionPopUpClose()
                                }
                                question={moreActionData.question}
                                onUpdateCallback={async (data) =>
                                    await moreActionData.onUpdateCallback(data)
                                }
                                onDeleteCallback={async (data) =>
                                    await moreActionData.onDeleteCallback(data)
                                }
                            />
                        )} */}

                    {/* Question Create PopUp */}
                    {isQuestionCreatePopUpOpen && questionCreateData && (
                        <FConfirmPopUp
                            title="Create Question"
                            disableCloseWhenClickOutside
                            disableBottomBar
                        >
                            <QuestionCreateForm
                                chapterId={questionCreateData.presetChapterId}
                                questionTypeId={
                                    questionCreateData.presetQuestionTypeId
                                }
                                questionGroupId={
                                    questionCreateData.presetQuestionGroupId
                                }
                                onSubmitCallback={async (data) =>
                                    await handleQuestionCreatePopUpSubmit(data)
                                }
                                onClose={handleQuestionCreatePopUpClose}
                                questionBankId={
                                    questionCreateData.questionBankId
                                }
                                isAutoCloseAfterComplete={false}
                            />
                        </FConfirmPopUp>
                    )}

                    {/* Update Question PopUp*/}
                    {isQuestionUpdatePopUpOpen && questionUpdateData && (
                        <FConfirmPopUp
                            title="Edit Question"
                            disableBottomBar
                            disableCloseWhenClickOutside
                        >
                            <QuestionUpdateForm
                                onSubmitCallback={async (data) =>
                                    await handleQuestionUpdatePopUpSubmit(data)
                                }
                                onClose={handleQuestionUpdatePopUpClose}
                                questionBankId={
                                    questionUpdateData.question.questionBankId
                                }
                                questionId={questionUpdateData.question.id}
                            />
                        </FConfirmPopUp>
                    )}

                    {/* Question Delete PopUp */}
                    {isQuestionDeletePopUpOpen &&
                        questionDeleteData !== null && (
                            <QuestionDeletePopUp
                                onClose={handleQuestionDeletePopUpClose}
                                question={questionDeleteData?.question}
                                onCallback={async (data) =>
                                    await questionDeleteData.callback(data)
                                }
                            />
                        )}
                </Fragment>
            </QuestionPopUpWrapperContext.Provider>
        )
    }
}
