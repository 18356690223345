import { FText } from '@fantaskticedtechlimited/fui-complib'
import { FFontTypes } from '@fantaskticedtechlimited/fui-fontlib'
import { Outlet, useSearchParams } from 'react-router-dom'
import QBSelectDropDown from '../../Components/QBSelectDropDown'
import { useUserSettingContext } from '../../contexts/userSettingContext'
import { PageContainer } from '../../layout/PageContainer'

function QBAssetLibrary() {
    const [searchParams] = useSearchParams()
    const questionBankId = searchParams.get('questionBankId')
    const { currentUserSetting } = useUserSettingContext()
    if (!currentUserSetting.questionBank) {
        return (
            <PageContainer
                header={
                    <FText
                        font={FFontTypes.Large_Text()}
                        children="QuestionBank Asset Library"
                    />
                }
                body={
                    <>
                        <FText
                            font={FFontTypes.Title()}
                            children="Select Question Bank:"
                        />
                        <QBSelectDropDown
                            presetQuestionBankId={questionBankId || undefined}
                        />
                    </>
                }
            />
        )
    }
    return <Outlet />
}

export default QBAssetLibrary
