import {
    MCAnswerCreateInput,
    MCContent,
    Question,
    QuestionInputTypes,
    Tag,
} from '../../../../../assets/type/backend.type'

import { QuestionPreviewProps } from './types'
import * as styles from './styles'
import FMarkdownView from '../../../../../Components/FMarkdown/FMarkdownView'
import {
    FColorTypes,
    FLegacyColorTypes,
} from '@fantaskticedtechlimited/fui-colorlib'
import {
    FScrollBarStyle,
    FTag,
    FText,
} from '@fantaskticedtechlimited/fui-complib'
import { FFontTypes } from '@fantaskticedtechlimited/fui-fontlib'
import { Fragment } from 'react'
import FilledStar from '../../../../../assets/images/FilledStar.svg'
import EmptyStar from '../../../../../assets/images/EmptyStar.svg'

const McQuestionContentPreview = (question: Question, isEdit: boolean) => {
    const content = question.content as MCContent
    // const createStars = (difficulty: number) => {
    //   let arr: JSX.Element[] = []
    //   for (let i = 0; i < difficulty; i++) {
    //     arr.push(<img src={FilledStar} alt="Star" />)
    //   }
    //   return arr;
    // }

    return (
        <div className={styles.MCQuestionContentPreview_Container}>
            <FText style={{ textDecoration: 'underline' }}>
                Question Body:
            </FText>
            <FMarkdownView children={content.body} />
            <div
                className={
                    styles.MCQuestionContentPreview_Container_Section_Div
                }
            >
                <FText style={{ textDecoration: 'underline' }}>
                    MC Answers:
                </FText>
                {(content.answers || []).map((answer, i) => {
                    const isCorrect = isEdit
                        ? (answer as unknown as MCAnswerCreateInput).isCorrect
                        : content.correctAnswerIds.includes(answer.id)
                    const optionName = `${i + 1}. `
                    return (
                        <div
                            key={i}
                            className={styles.MCQuestionContentPreview_AnswerChoice_Div(
                                isCorrect
                            )}
                        >
                            <FText
                                font={FFontTypes.Large_Text()}
                                color={
                                    isCorrect
                                        ? FLegacyColorTypes.SECONDARY_GREEN
                                        : FLegacyColorTypes.PRIMARY_BLACK
                                }
                            >
                                {optionName}
                            </FText>
                            <FMarkdownView
                                color={
                                    isCorrect
                                        ? FLegacyColorTypes.SECONDARY_GREEN
                                        : FLegacyColorTypes.PRIMARY_BLACK
                                }
                            >
                                {answer.body}
                            </FMarkdownView>
                        </div>
                    )
                })}
            </div>
            <div
                className={
                    styles.MCQuestionContentPreview_Container_Section_Div
                }
            >
                <FText style={{ textDecoration: 'underline' }}>
                    Explanation:
                </FText>
                <FMarkdownView children={content.solution} />
            </div>
        </div>
    )
}

const DefaultQuestionContentPreview = (question: Question) => {
    return (
        <FText>
            {`Require Question Content Preview on inputType ${question.inputType}`}
        </FText>
    )
}

const QuestionContentPreviewPicker = (question: Question, isEdit: boolean) => {
    if (question.content === null) {
        return <FText>{`Loading Content...`}</FText>
    }
    switch (question.inputType) {
        case QuestionInputTypes.MULTIPLE_CHOICE:
            return McQuestionContentPreview(question, isEdit)
        default:
            return DefaultQuestionContentPreview(question)
    }
}

const QuestionBasicContentPreviewer = (question: Question) => {
    //
    return (
        <Fragment>
            <FText>Question Basic:</FText>
            <div className={styles.QuestionBasic_Container}>
                {/* Chapter */}
                <div className={styles.QuestionBasic_Container_Row_Div}>
                    <FText
                        font={FFontTypes.Large_Text()}
                        children="Chapters: "
                    />
                    <FText
                        key={0}
                        font={FFontTypes.Large_Text()}
                        children={
                            question.chapter ? question.chapter.name : null
                        }
                    />
                    {/* {question.chapters.map((t, i) => {
						if (i > 0) {
							return (
								<FText
									key={i}
									font={FFontTypes.Large_Text()}
									children={` / ${ls.getLocaleString(
										t.name
									)}`}
								/>
							);
						}
						return (
							<FText
								key={i}
								font={FFontTypes.Large_Text()}
								children={ls.getLocaleString(t.name)}
							/>
						);
					})} */}
                </div>

                {/* Question Type */}
                <FText
                    font={FFontTypes.Large_Text()}
                    children={`QuestionType: ${
                        question.questionType
                            ? question.questionType.name
                            : null
                    }`}
                />

                {/* QuestionGroup */}
                <FText
                    font={FFontTypes.Large_Text()}
                    children={`QuestionGroup: ${
                        question.questionGroup
                            ? question.questionGroup.name
                            : null
                    }`}
                />

                {/* Level */}
                <FText
                    font={FFontTypes.Large_Text()}
                    children={`Level: ${question.level}`}
                />

                {/* Concept Card */}
                <div className={styles.QuestionBasic_Container_Row_Div}>
                    <FText
                        font={FFontTypes.Large_Text()}
                        children="Concept Cards: "
                    />
                    {question.conceptCards.map((c, i) => {
                        if (i > 0) {
                            return (
                                <FText
                                    key={i}
                                    font={FFontTypes.Large_Text()}
                                    children={` / ${c.name}`}
                                />
                            )
                        }
                        return (
                            <FText
                                key={i}
                                font={FFontTypes.Large_Text()}
                                children={c.name}
                            />
                        )
                    })}
                </div>

                {/* Tags */}
                <div className={styles.QuestionBasic_Container_Row_Div}>
                    <FText font={FFontTypes.Large_Text()} children="Tags: " />

                    {question.tags.map((tag, tagIndex) => (
                        <FTag<Tag> key={tagIndex} tag={tag} label={tag.name} />
                    ))}
                </div>
            </div>
        </Fragment>
    )
}

function QuestionPreview(props: QuestionPreviewProps) {
    if (props.isLoading) {
        return (
            <div className={styles.previewContainer + ' ' + FScrollBarStyle()}>
                Loading
            </div>
        )
    }
    return (
        <div className={styles.previewContainer + ' ' + FScrollBarStyle()}>
            {props.showBasicInfo &&
                QuestionBasicContentPreviewer(props.question)}
            {QuestionContentPreviewPicker(
                props.question,
                props.isEdit ?? false
            )}
        </div>
    )
}

export default QuestionPreview
