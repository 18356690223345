import { QBAssetListCardQuestionsContentProps } from './types'
import { FText } from '@fantaskticedtechlimited/fui-complib'
import { FFontTypes } from '@fantaskticedtechlimited/fui-fontlib'
import { MCContent } from '../../../../../assets/type/backend.type'

export const QBAssetListCardQuestionsContent = (
    props: QBAssetListCardQuestionsContentProps
) => {
    const data = props.data
    const questionContent = data.content as MCContent

    return (
        <div style={{ flex: 1 }} onClick={props.onClick}>
            <FText
                font={FFontTypes.Large_Text()}
                maxRows={5}
                overflowHidden
                children={questionContent.body}
            />
        </div>
    )
}
