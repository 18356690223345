import { Fragment, useEffect, useState } from 'react'
import useQBAPIService from '../../../../apiService/questionBank'
import {
    QuestionGroup,
    QuestionGroupFilterInput,
    QuestionType,
    QuestionTypeFilterInput,
} from '../../../../assets/type/backend.type'
import { QuestionGroupsContainerProps } from './types'
import * as styles from './styles'
import { useLoadingPageWrapperContext } from '../../../../layout/WithLoadingPageWrapper'
import { PageContainer } from '../../../../layout/PageContainer'
import {
    FButton,
    FNoData,
    FScrollableContentWrapper,
    FSelect,
    FText,
} from '@fantaskticedtechlimited/fui-complib'

import { useQuestionGroupPopUpWrapperContext } from '../../components/popUp/WithQuestionGroupPopUpWrapper'
import QuestionGroupList from '../../components/QuestionGroup/QuestionGroupList'

function QuestionGroupsContainer(props: QuestionGroupsContainerProps) {
    const { onQuestionGroupCreatePopUpOpen: onQuestionGroupCreatePopUpOpen } =
        useQuestionGroupPopUpWrapperContext()
    const { setLoadingStart, setLoadingEnd } = useLoadingPageWrapperContext()

    const qbAPIService = useQBAPIService()

    // Filter
    const [isFilterLoading, setIsFilterLoading] = useState(false)
    const [questionTypes, setQuestionTypes] = useState<QuestionType[]>([])
    const [selectedQuestionType, setSelectedQuestionType] =
        useState<QuestionType | null>(null)
    const handleGetQuestionTypesByFilter = async (
        questionTypeFilterInput: QuestionTypeFilterInput,
        withLoadingScreen?: boolean
    ) => {
        withLoadingScreen && setLoadingStart()
        try {
            const result = await qbAPIService.getQuestionTypesDetailByFilter({
                questionTypeFilterInput,
            })
            const { questionTypesGet } = result.data

            setQuestionTypes(
                questionTypesGet.isSuccess ? questionTypesGet.data || [] : []
            )
        } catch (error) {
            console.log('error', error)
            setQuestionTypes([])
        }
        withLoadingScreen && setLoadingEnd()
    }

    const [questionGroups, setQuestionGroups] = useState<QuestionGroup[]>([])
    const totalQuestionGroups =
        questionGroups !== null ? questionGroups.length : 0
    const handleGetQuestionGroupsByFilter = async (
        QuestionGroupFilterInput: QuestionGroupFilterInput,
        withLoadingScreen?: boolean
    ) => {
        withLoadingScreen && setLoadingStart()
        try {
            const result = await qbAPIService.getQuestionGroupsByFilter({
                questionGroupFilterInput: QuestionGroupFilterInput,
            })
            const { questionGroupsGet: QuestionGroupsGet } = result.data
            setQuestionGroups(
                QuestionGroupsGet.isSuccess ? QuestionGroupsGet.data || [] : []
            )
        } catch (error) {
            console.log('error', error)
            setQuestionGroups([])
        }
        withLoadingScreen && setLoadingEnd()
    }

    const handleRefresh = async () => {
        try {
            setLoadingStart()
            await handleGetQuestionTypesByFilter({
                chapterId: props.chapterId ?? undefined,
            })
            setSelectedQuestionType(null)
            setQuestionGroups([])
        } catch (error) {
            console.log('error', error)
        }
        setLoadingEnd()
    }

    useEffect(() => {
        if (props.chapterId) {
            handleGetQuestionTypesByFilter({
                chapterId: props.chapterId ?? undefined,
            })
        } else {
            setQuestionGroups([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.chapterId])

    return (
        <PageContainer
            header={
                <div className={styles.toolbarContainer}>
                    {/* QuestionGroups Filters */}
                    {/* Question Type Filter */}
                    <FSelect
                        wrapperStyle={{ width: '200px' }}
                        label={`Question Types`}
                        showLabelOnly
                        options={questionTypes || []}
                        onSelect={(data) => setSelectedQuestionType(data)}
                        selectedOptions={selectedQuestionType}
                        renderOptionNameOnly={(qb) => qb.name}
                        onClear={() => {
                            setSelectedQuestionType(null)
                        }}
                    />

                    {/* Search button */}
                    <FButton
                        type="Primary"
                        onClick={async () => {
                            try {
                                setIsFilterLoading(true)
                                await handleGetQuestionGroupsByFilter({
                                    chapterId: props.chapterId ?? undefined,
                                    questionTypeId: selectedQuestionType
                                        ? selectedQuestionType.id
                                        : undefined,
                                })
                            } catch (error) {
                                alert(`Error ${JSON.stringify(error)}`)
                            }
                            setIsFilterLoading(false)
                        }}
                        label="Search"
                    />
                    {isFilterLoading && <FText>Searching...</FText>}

                    <div
                        style={{
                            flexGrow: 1,
                        }}
                    />

                    {/* New QuestionGroups button */}
                    <FButton
                        type="Primary"
                        onClick={() =>
                            onQuestionGroupCreatePopUpOpen(
                                {
                                    chapterId: props.chapterId,
                                    questionTypeId: selectedQuestionType?.id,
                                },
                                (newQuestionGroup: QuestionGroup) => {
                                    // change filter panel if not same questionType
                                    const newQuestionType = questionTypes.find(
                                        (e) =>
                                            e.id ===
                                            newQuestionGroup.questionTypeId
                                    )
                                    if (
                                        newQuestionType &&
                                        newQuestionType.id !==
                                            selectedQuestionType?.id
                                    ) {
                                        setSelectedQuestionType(newQuestionType)
                                        handleGetQuestionGroupsByFilter({
                                            questionTypeId:
                                                newQuestionType?.id ?? null,
                                            chapterId: props.chapterId,
                                        })
                                    } else {
                                        handleGetQuestionGroupsByFilter({
                                            questionTypeId:
                                                selectedQuestionType?.id ??
                                                null,
                                            chapterId: props.chapterId,
                                        })
                                    }
                                }
                            )
                        }
                        label="New QuestionGroup"
                    />

                    {/* refresh button */}
                    <FButton
                        type="Primary"
                        onClick={async () => {
                            await handleRefresh()
                        }}
                        label="Refresh"
                    />
                </div>
            }
            body={
                <FScrollableContentWrapper>
                    {totalQuestionGroups === 0 ? (
                        <FNoData description="Use filter to search question groups" />
                    ) : (
                        <Fragment>
                            <QuestionGroupList
                                questionGroups={questionGroups || []}
                                onRefresh={() =>
                                    handleGetQuestionGroupsByFilter({
                                        chapterId: props.chapterId,
                                        questionTypeId:
                                            selectedQuestionType?.id ?? null,
                                    })
                                }
                            />
                        </Fragment>
                    )}
                </FScrollableContentWrapper>
            }
        />
    )
}

export default QuestionGroupsContainer
