import { FScrollBarStyle } from "@fantaskticedtechlimited/fui-complib";
import { styles } from "./styles";
import { PageBodyProps } from "./types";

export const BodyDiv = (props: PageBodyProps) => {
	return (
		<div
			className={
				styles.PageBody_Container +
				" " +
				FScrollBarStyle() +
				" " +
				props.className
			}
			style={props.style}
		>
			{props.children}
		</div>
	);
};
