import { gql } from '@apollo/client'

export const QB_TAG_CREATE_MUTATION = gql`
    mutation qbTagCreate($tagCreateInput: TagCreateInput!) {
        qbTagCreate(tagCreateInput: $tagCreateInput) {
            code
            isSuccess
            errorMessage
            data {
                id
                name
            }
        }
    }
`
