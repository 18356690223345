import { FText } from '@fantaskticedtechlimited/fui-complib'
import { FFontTypes } from '@fantaskticedtechlimited/fui-fontlib'
import { Outlet } from 'react-router'
import QBSelectDropDown from '../../Components/QBSelectDropDown'
import { useUserSettingContext } from '../../contexts/userSettingContext'
import { PageContainer } from '../../layout/PageContainer'
import { QBContentProps } from './types'

function QuestionBankContent(props: QBContentProps) {
    const { currentUserSetting } = useUserSettingContext()
    if (!currentUserSetting.questionBank) {
        return (
            <PageContainer
                header={
                    <FText
                        font={FFontTypes.Large_Text()}
                        children="QuestionBank Content"
                    />
                }
                body={
                    <>
                        <FText font={FFontTypes.Title()}>
                            Select Question Bank:
                        </FText>
                        <QBSelectDropDown />
                    </>
                }
                // maxWidth={"100%"}
            />
        )
    }
    return <Outlet />
}

export default QuestionBankContent
