import * as styles from './style'
import React, { useState } from 'react'
import { FFloatingActionButtonProps } from './type'
import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'

export const FFloatingActionButton = (props: FFloatingActionButtonProps) => {
    const [isLocalOpen, setIsLocalOpen] = useState(false)

    const isFinalOpen = props.isOpen !== undefined ? props.isOpen : isLocalOpen

    const handleOpenActionButton = () => {
        if (props.isDisable) {
            return
        }
        if (props.isOpen !== undefined) {
            props.onOpen && props.onOpen()
        } else {
            setIsLocalOpen(true)
        }
    }
    const handleCloseActionButton = () => {
        if (props.isDisable) {
            return
        }
        if (props.isOpen !== undefined) {
            props.onClose && props.onClose()
        } else {
            setIsLocalOpen(false)
        }
    }
    return (
        <div className={styles.FFloatingAddButtonContainer(props, isFinalOpen)}>
            {isFinalOpen ? props.children : undefined}
            <svg
                className={styles.FFloatingAddButtonDiv(props, isFinalOpen)}
                onClick={
                    isFinalOpen
                        ? handleCloseActionButton
                        : handleOpenActionButton
                }
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g filter="url(#filter0_d)">
                    <rect
                        x="4"
                        y="4"
                        width="56"
                        height="56"
                        rx="28"
                        fill={
                            isFinalOpen
                                ? FLegacyColorTypes.PRIMARY_WHITE
                                : FLegacyColorTypes.DEFAULT_MAIN_THEME
                        }
                    />
                    <path
                        d="M32 25V39"
                        stroke={
                            isFinalOpen
                                ? FLegacyColorTypes.DEFAULT_MAIN_THEME
                                : FLegacyColorTypes.PRIMARY_WHITE
                        }
                        strokeWidth={isFinalOpen ? '2' : '1.6'}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M25 32H39"
                        stroke={
                            isFinalOpen
                                ? FLegacyColorTypes.DEFAULT_MAIN_THEME
                                : FLegacyColorTypes.PRIMARY_WHITE
                        }
                        strokeWidth={isFinalOpen ? '2' : '1.6'}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
                <defs>
                    <filter
                        id="filter0_d"
                        x="0"
                        y="0"
                        width="64"
                        height="64"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset />
                        <feGaussianBlur stdDeviation="2" />
                        <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0.282341 0 0 0 0 0.24776 0 0 0 0 0.8875 0 0 0 0.08 0"
                        />
                        <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow"
                        />
                        <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow"
                            result="shape"
                        />
                    </filter>
                </defs>
            </svg>
        </div>
    )
}
