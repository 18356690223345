import { stylesheet } from 'typestyle'

export const styles = stylesheet({
    TogglePopUp_ContentDiv: {
        display: 'flex',
        flexFlow: 'row wrap',
        alignItems: 'center',
        columnGap: '8px',
    },
})
