import { useMutation } from '@apollo/client'
import {
    FolderChapterCreateInput,
    ChapterUpdateInput,
    FolderAddOrRemoveChapterInput,
    ChapterFilterInput,
    FolderCreateInput,
    FolderFilterInput,
    FolderUpdateInput,
    ConceptCardCreateInput,
    ConceptCardUpdateInput,
    ConceptCardFilterInput,
    TagFilterInput,
    TagCreateInput,
    QuestionCreateInput,
    QuestionFilterInput,
    QuestionUpdateInput,
    ChapterOutput,
    FolderOutput,
    ConceptCardOutput,
    TagOutput,
    QuestionOutput,
    QuestionArrayOutput,
    TagArrayOutput,
    ConceptCardArrayOutput,
    FolderArrayOutput,
    ChapterArrayOutput,
    QuestionBankArrayOutput,
    QuestionBankFilterInput,
    QuestionBankOutput,
    QuestionBankCreateInput,
    QuestionTypeArrayOutput,
    QuestionTypeFilterInput,
    QuestionTypeCreateInput,
    QuestionTypeOutput,
    QuestionTypeUpdateInput,
    QuestionGroupArrayOutput,
    QuestionGroupCreateInput,
    QuestionGroupFilterInput,
    QuestionGroupOutput,
    QuestionGroupUpdateInput,
    QBChapterMoveToFolderInput,
    QuestionTypesRearrangeInput,
    FoldersRearrangeInput,
    ChaptersRearrangeInput,
    ConceptCardsRearrangeInput,
    QuestionGroupsRearrangeInput,
} from '../../assets/type/backend.type'
import { useCustomLazyQuery } from '../../utils/useCustomLazyQuery'
import { QB_CONCEPT_CARD_CREATE_MUTATION } from './mutation/qbConceptCardCreate.gql'
import { QB_CONCEPT_CARD_DELETE_BY_ID_MUTATION } from './mutation/qbConceptCardDeleteById.gql'
import { QB_CONCEPT_CARD_UPDATE_BY_ID_MUTATION } from './mutation/qbConceptCardUpdateById.gql'
import { QB_FOLDER_ADD_OR_REMOVE_CHAPTER_BY_ID_MUTATION } from './mutation/qbFolderAddOrRemoveChapterById.gql'
import { QB_FOLDER_CREATE_MUTATION } from './mutation/qbFolderCreate.gql'
import { QB_FOLDER_DELETE_BY_ID_MUTATION } from './mutation/qbFolderDeleteById.gql'
import { QB_FOLDER_UPDATE_BY_ID_MUTATION } from './mutation/qbFolderUpdateById.gql'
import { QB_GROUP_CREATE_MUTATION } from './mutation/qbQuestionGroupCreate.gql'
import { QB_GROUP_DELETE_BY_ID_MUTATION } from './mutation/qbQuestionGroupDeleteById.gql'
import { QB_GROUP_UPDATE_BY_ID_MUTATION } from './mutation/qbQuestionGroupUpdateById.gql'
import { QB_QUESTION_BANK_CREATE_MUTATION } from './mutation/qbQuestionBankCreate.gql'
import { QB_QUESTION_CREATE_MUTATION } from './mutation/qbQuestionCreate.gql'
import { QB_QUESTION_DELETE_BY_ID_MUTATION } from './mutation/qbQuestionDeleteById.gql'
import { QB_QUESTION_TYPE_CREATE_MUTATION } from './mutation/qbQuestionTypeCreate.gql'
import { QB_QUESTION_TYPE_DELETE_BY_ID_MUTATION } from './mutation/qbQuestionTypeDeleteById.gql'
import { QB_QUESTION_TYPE_UPDATE_BY_ID_MUTATION } from './mutation/qbQuestionTypeUpdateById.gql'
import { QB_QUESTION_UPDATE_BY_ID_MUTATION } from './mutation/qbQuestionUpdate.gql'
import { QB_TAG_CREATE_MUTATION } from './mutation/qbTagCreate.gql'
import { QB_CHAPTER_CREATE_MUTATION } from './mutation/qbChapterCreate.gql'
import { QB_CHAPTER_DELETE_BY_ID_MUTATION } from './mutation/qbChapterDeleteById.gql'
import { QB_CHAPTER_UPDATE_BY_ID_MUTATION } from './mutation/qbChapterUpdateById.gql'
import { GET_CONCEPT_CARD_DETAIL_BY_ID_QUERY } from './query/getConceptCardDetailById.gql'
import { GET_CONCEPT_CARDS_BY_FILTER } from './query/getConceptCardsByFilter.gql'
import { GET_CONCEPT_CARDS_DETAIL_BY_FILTER } from './query/getConceptCardsDetailByFilter.gql'
import { GET_FOLDER_DETAIL_BY_ID_QUERY } from './query/getFolderDetailById.gql'
import { GET_FOLDERS_BY_FILTER } from './query/getFoldersByFilter.gql'
import { GET_GROUP_DETAIL_BY_ID_QUERY } from './query/getQuestionGroupDetailById.gql'
import { GET_GROUPS_BY_FILTER } from './query/getQuestionGroupsByFilter.gql'
import { GET_QUESTIONBANK_ARCHITECTURE_QUERY } from './query/getQuestionBankArchitectureById.gql'
import { GET_QUESTIONBANK_CONTENT_QUERY } from './query/getQuestionBankContentById.gql'
import { GET_QUESTIONBANK_CONTENT_DETAIL_QUERY } from './query/getQuestionBankContentDetailById.gql'
import { GET_QUESTIONBANK_BY_FILTER } from './query/getQuestionBanksByFilter.gql'
import { GET_QUESTIONBANK_DETAIL_BY_FILTER } from './query/getQuestionBanksDetailByFilter.gql'
import { GET_QUESTION_CREATE_QUESTIONBANK_CONTENT_DETAIL_QUERY } from './query/getQuestionCreate_QuestionBankContentDetailById.gql'
import { GET_QUESTION_DETAIL_BY_ID_QUERY } from './query/getQuestionDetailById'
import { GET_QUESTIONS_BY_FILTER } from './query/getQuestionsByFilter.gql'
import { GET_QUESTIONS_DETAIL_BY_FILTER } from './query/getQuestionsDetailByFilter.gql'
import { GET_QUESTION_TYPE_DETAIL_BY_ID_QUERY } from './query/getQuestionTypeDetailById.gql'
import { GET_QUESTION_TYPES_BY_FILTER } from './query/getQuestionTypesByFilter.gql'
import { GET_QUESTION_TYPES_DETAIL_BY_FILTER } from './query/getQuestionTypesDetailByFilter.gql'
import { GET_QUESTION_UPDATE_QUESTION_DETAIL_BY_ID_QUERY } from './query/getQuestionUpdate_QuestionDetailById'
import { GET_TAG_DETAIL_BY_ID_QUERY } from './query/getTagDetailById'
import { GET_TAGS_BY_FILTER } from './query/getTagsByFilter.gql'
import { GET_CHAPTER_DETAIL_BY_ID_QUERY } from './query/getChapterDetailById.gql'
import { GET_CHAPTERS_BY_FILTER } from './query/getChaptersByFilter.gql'
import { QB_CHAPTER_MOVE_TO_FOLDER_BY_ID_MUTATION } from './mutation/qbChapterMoveToFolderById.gql'
import { GET_QUESTIONBANKS_ARCHITECTURE_BY_FILTER } from './query/getQuestionBanksArchitectureByFilter.gql'
import { QB_QUESTIONTYPES_REARRANGE_BY_IDS_MUTATION } from './mutation/qbQuestionTypesRearrangeByIds.gql'
import { QB_FOLDERS_REARRANGE_BY_IDS_MUTATION } from './mutation/qbFoldersRearrangeByIds.gql'
import { QB_CHAPTERS_REARRANGE_BY_IDS_MUTATION } from './mutation/qbChaptersRearrangeByIds.gql'
import { QB_CONCEPTCARDS_REARRANGE_BY_IDS_MUTATION } from './mutation/qbConceptCardsRearrangeByIds.gql'
import { QB_QUESTIONGROUPS_REARRANGE_BY_IDS_MUTATION } from './mutation/qbQuestionGroupRearrangeByIds.gql'

// useQBGQLAPIService
const useQBAPIService = () => {
    // Get QuestionBank Content By Id
    const getQuestionBankContentById = useCustomLazyQuery<
        { questionBankGet: QuestionBankOutput },
        { id: string }
    >(GET_QUESTIONBANK_CONTENT_QUERY)

    // Get QuestionBank Content Detail By Id
    const getQuestionBankContentDetailById = useCustomLazyQuery<
        { questionBankGet: QuestionBankOutput },
        { id: string }
    >(GET_QUESTIONBANK_CONTENT_DETAIL_QUERY)

    // Get QuestionBank Architecture By Id
    const getQuestionBankArchitectureById = useCustomLazyQuery<
        { questionBankGet: QuestionBankOutput },
        { questionBankId: string }
    >(GET_QUESTIONBANK_ARCHITECTURE_QUERY)

    const getQuestionCreate_QuestionBankContentDetailById = useCustomLazyQuery<
        { questionBankGet: QuestionBankOutput },
        { id: string }
    >(GET_QUESTION_CREATE_QUESTIONBANK_CONTENT_DETAIL_QUERY)

    // Get QuestionBanks By Filter
    const getQuestionBanksByFilter = useCustomLazyQuery<
        { questionBanksGet: QuestionBankArrayOutput },
        { questionBankFilterInput?: QuestionBankFilterInput }
    >(GET_QUESTIONBANK_BY_FILTER)

    const getQuestionBanksArchitectureByFilter = useCustomLazyQuery<
        { questionBanksGet: QuestionBankArrayOutput },
        { questionBankFilterInput?: QuestionBankFilterInput }
    >(GET_QUESTIONBANKS_ARCHITECTURE_BY_FILTER)

    // Get QuestionBanks Detail By Filter
    const getQuestionBanksDetailByFilter = useCustomLazyQuery<
        { questionBanksGet: QuestionBankArrayOutput },
        { questionBankFilterInput?: QuestionBankFilterInput }
    >(GET_QUESTIONBANK_DETAIL_BY_FILTER)

    // QuestionBank Create
    const [qbQuestionBankCreateMutation] = useMutation<
        { qbQuestionBankCreate: QuestionBankOutput },
        { questionBankCreateInput: QuestionBankCreateInput }
    >(QB_QUESTION_BANK_CREATE_MUTATION)

    // Folder

    // Folder Add or Remove Chapter By Id
    const [qbFolderAddOrRemoveChapterByIdMutation] = useMutation<
        { qbFolderAddOrRemoveChapterById: FolderOutput },
        { folderAddOrRemoveChapterInput: FolderAddOrRemoveChapterInput }
    >(QB_FOLDER_ADD_OR_REMOVE_CHAPTER_BY_ID_MUTATION)

    // Folder Move To Chapter By Id
    const [qbChapterMoveToFolderByIdMutation] = useMutation<
        { qbChapterMoveToFolderById: FolderOutput },
        { qbChapterMoveToFolderInput: QBChapterMoveToFolderInput }
    >(QB_CHAPTER_MOVE_TO_FOLDER_BY_ID_MUTATION)
    // Get Folders By Filter
    const getFoldersByFilter = useCustomLazyQuery<
        { foldersGet: FolderArrayOutput },
        { folderFilterInput?: FolderFilterInput }
    >(GET_FOLDERS_BY_FILTER)

    // Get Folder Detail By Id
    const getFolderDetailById = useCustomLazyQuery<
        { folderGet: FolderOutput },
        { folderId: string }
    >(GET_FOLDER_DETAIL_BY_ID_QUERY)

    // Folder Create
    const [qbFolderCreateMutation] = useMutation<
        { qbFolderCreate: FolderOutput },
        { folderCreateInput: FolderCreateInput }
    >(QB_FOLDER_CREATE_MUTATION)

    // Folder Update By Id
    const [qbFolderUpdateByIdMutation] = useMutation<
        { qbFolderUpdateById: FolderOutput },
        { folderUpdateInput: FolderUpdateInput }
    >(QB_FOLDER_UPDATE_BY_ID_MUTATION)

    // Folder Delete By Id
    const [qbFolderDeleteByIdMutation] = useMutation<
        { qbFolderDeleteById: FolderOutput },
        { folderId: string }
    >(QB_FOLDER_DELETE_BY_ID_MUTATION)

    // Folder Rearrange By Ids
    const [qbFoldersRearrangeByIds] = useMutation<
        { qbFoldersRearrangeByIds: FolderArrayOutput },
        { foldersRearrangeInput: FoldersRearrangeInput }
    >(QB_FOLDERS_REARRANGE_BY_IDS_MUTATION)

    // Chapter

    // Get Chapters By Filter
    const getChaptersByFilter = useCustomLazyQuery<
        { chaptersGet: ChapterArrayOutput },
        { chapterFilterInput?: ChapterFilterInput }
    >(GET_CHAPTERS_BY_FILTER)

    // Get Chapter Detail By Id
    const getChapterDetailById = useCustomLazyQuery<
        { chapterGet: ChapterOutput },
        { chapterId: string }
    >(GET_CHAPTER_DETAIL_BY_ID_QUERY)

    // Chapter Create
    const [qbChapterCreateMutation] = useMutation<
        { qbChapterCreate: ChapterOutput },
        { folderChapterCreateInput: FolderChapterCreateInput }
    >(QB_CHAPTER_CREATE_MUTATION)

    // Chapter Update By Id
    const [qbChapterUpdateByIdMutation] = useMutation<
        { qbChapterUpdateById: ChapterOutput },
        { chapterUpdateInput: ChapterUpdateInput }
    >(QB_CHAPTER_UPDATE_BY_ID_MUTATION)

    // Chapter Delete By Id
    const [qbChapterDeleteByIdMutation] = useMutation<
        { qbChapterDeleteById: ChapterOutput },
        { chapterId: string }
    >(QB_CHAPTER_DELETE_BY_ID_MUTATION)

    // Chapter Rearrange By Ids
    const [qbChaptersRearrangeByIds] = useMutation<
        { qbChaptersRearrangeByIds: ChapterArrayOutput },
        { chaptersRearrangeInput: ChaptersRearrangeInput }
    >(QB_CHAPTERS_REARRANGE_BY_IDS_MUTATION)

    // Concept Cards

    // Get ConceptCards By Filter
    const getConceptCardsByFilter = useCustomLazyQuery<
        { conceptCardsGet: ConceptCardArrayOutput },
        { conceptCardFilterInput?: ConceptCardFilterInput }
    >(GET_CONCEPT_CARDS_BY_FILTER)

    // Get ConceptCards Detail By Filter
    const getConceptCardsDetailByFilter = useCustomLazyQuery<
        { conceptCardsGet: ConceptCardArrayOutput },
        { conceptCardFilterInput?: ConceptCardFilterInput }
    >(GET_CONCEPT_CARDS_DETAIL_BY_FILTER)

    // Get ConceptCard Detail By Id
    const getConceptCardDetailById = useCustomLazyQuery<
        { conceptCardGet: ConceptCardOutput },
        { conceptCardId: string }
    >(GET_CONCEPT_CARD_DETAIL_BY_ID_QUERY)

    // ConceptCard Create
    const [qbConceptCardCreateMutation] = useMutation<
        { qbConceptCardCreate: ConceptCardOutput },
        { conceptCardCreateInput: ConceptCardCreateInput }
    >(QB_CONCEPT_CARD_CREATE_MUTATION)

    // ConceptCard Update By Id
    const [qbConceptCardUpdateByIdMutation] = useMutation<
        { qbConceptCardUpdateById: ConceptCardOutput },
        { conceptCardUpdateInput: ConceptCardUpdateInput }
    >(QB_CONCEPT_CARD_UPDATE_BY_ID_MUTATION)

    // ConceptCard Delete By Id
    const [qbConceptCardDeleteByIdMutation] = useMutation<
        { qbConceptCardDeleteById: ConceptCardOutput },
        { conceptCardId: string }
    >(QB_CONCEPT_CARD_DELETE_BY_ID_MUTATION)

    // Chapter Rearrange By Ids
    const [qbConceptCardsRearrangeByIds] = useMutation<
        { qbConceptCardsRearrangeByIds: ConceptCardArrayOutput },
        { conceptCardsRearrangeInput: ConceptCardsRearrangeInput }
    >(QB_CONCEPTCARDS_REARRANGE_BY_IDS_MUTATION)

    // Tags

    // Get Tags By Filter
    const getTagsByFilter = useCustomLazyQuery<
        { tagsGet: TagArrayOutput },
        { tagFilterInput?: TagFilterInput }
    >(GET_TAGS_BY_FILTER)

    // Get Tag Detail By Id
    const getTagDetailById = useCustomLazyQuery<
        { tagGet: TagOutput },
        { tagId: string }
    >(GET_TAG_DETAIL_BY_ID_QUERY)

    // Tag Create
    const [qbTagCreateMutation] = useMutation<
        { qbTagCreate: TagOutput },
        { tagCreateInput: TagCreateInput }
    >(QB_TAG_CREATE_MUTATION)

    // // Tag Update By Id
    // const [qbTagUpdateByIdMutation] = useMutation<
    // 	{ qbTagUpdateById: QBTagUpdateOutput },
    // 	{ tagUpdateInput: TagUpdateInput }
    // >(QB_TAG_UPDATE_BY_ID_MUTATION);

    // // Tag Delete By Id
    // const [qbTagDeleteByIdMutation] = useMutation<
    // 	{ qbTagDeleteById: QBTagDeleteOutput },
    // 	{ tagId: string }
    // >(QB_TAG_DELETE_BY_ID_MUTATION);

    // Question CRUD

    // Get Questions By Filter
    const getQuestionsByFilter = useCustomLazyQuery<
        { questionsGet: QuestionArrayOutput },
        { questionFilterInput?: QuestionFilterInput }
    >(GET_QUESTIONS_BY_FILTER)

    // Get Questions Detail By Filter
    const getQuestionsDetailByFilter = useCustomLazyQuery<
        { questionsGet: QuestionArrayOutput },
        { questionFilterInput?: QuestionFilterInput }
    >(GET_QUESTIONS_DETAIL_BY_FILTER)

    // Get Question Detail By Id
    const getQuestionDetailById = useCustomLazyQuery<
        { questionGet: QuestionOutput },
        { questionId: string }
    >(GET_QUESTION_DETAIL_BY_ID_QUERY)

    const getQuestionUpdate_QuestionDetailById = useCustomLazyQuery<
        { questionGet: QuestionOutput },
        { questionId: string }
    >(GET_QUESTION_UPDATE_QUESTION_DETAIL_BY_ID_QUERY)

    // Question Create
    const [qbQuestionCreateMutation] = useMutation<
        { qbQuestionCreate: QuestionOutput },
        { questionCreateInput: QuestionCreateInput }
    >(QB_QUESTION_CREATE_MUTATION)

    // Question Update By Id
    const [qbQuestionUpdateByIdMutation] = useMutation<
        { qbQuestionUpdateById: QuestionOutput },
        { questionUpdateInput: QuestionUpdateInput }
    >(QB_QUESTION_UPDATE_BY_ID_MUTATION)

    // Question Delete By Id
    const [qbQuestionDeleteByIdMutation] = useMutation<
        { qbQuestionDeleteById: QuestionOutput },
        { questionId: string }
    >(QB_QUESTION_DELETE_BY_ID_MUTATION)

    // QuestionType

    // Get ConceptCards By Filter
    const getQuestionTypesByFilter = useCustomLazyQuery<
        { questionTypesGet: QuestionTypeArrayOutput },
        { questionTypeFilterInput?: QuestionTypeFilterInput }
    >(GET_QUESTION_TYPES_BY_FILTER)

    // Get QuestionTypes Detail By Filter
    const getQuestionTypesDetailByFilter = useCustomLazyQuery<
        { questionTypesGet: QuestionTypeArrayOutput },
        { questionTypeFilterInput?: QuestionTypeFilterInput }
    >(GET_QUESTION_TYPES_DETAIL_BY_FILTER)

    // Get QuestionType Detail By Id
    const getQuestionTypeDetailById = useCustomLazyQuery<
        { questionTypeGet: QuestionTypeOutput },
        { questionTypeId: string }
    >(GET_QUESTION_TYPE_DETAIL_BY_ID_QUERY)

    // QuestionType Create
    const [qbQuestionTypeCreateMutation] = useMutation<
        { qbQuestionTypeCreate: QuestionTypeOutput },
        { questionTypeCreateInput: QuestionTypeCreateInput }
    >(QB_QUESTION_TYPE_CREATE_MUTATION)

    // QuestionType Update By Id
    const [qbQuestionTypeUpdateByIdMutation] = useMutation<
        { qbQuestionTypeUpdateById: QuestionTypeOutput },
        { questionTypeUpdateInput: QuestionTypeUpdateInput }
    >(QB_QUESTION_TYPE_UPDATE_BY_ID_MUTATION)

    // QuestionType Delete By Id
    const [qbQuestionTypeDeleteByIdMutation] = useMutation<
        { qbQuestionTypeDeleteById: QuestionTypeOutput },
        { questionTypeId: string }
    >(QB_QUESTION_TYPE_DELETE_BY_ID_MUTATION)

    // QuestionTypes Rearrange By Ids
    const [qbQuestionTypesRearrangeByIds] = useMutation<
        { qbQuestionTypesRearrangeByIds: QuestionTypeArrayOutput },
        { questionTypesRearrangeInput: QuestionTypesRearrangeInput }
    >(QB_QUESTIONTYPES_REARRANGE_BY_IDS_MUTATION)

    // QuestionGroup

    // Get QuestionGroups By Filter
    const getQuestionGroupsByFilter = useCustomLazyQuery<
        { questionGroupsGet: QuestionGroupArrayOutput },
        { questionGroupFilterInput?: QuestionGroupFilterInput }
    >(GET_GROUPS_BY_FILTER)

    // Get QuestionGroups Detail By Id
    const getQuestionGroupDetailById = useCustomLazyQuery<
        { questionGroupGet: QuestionGroupOutput },
        { questionGroupId: string }
    >(GET_GROUP_DETAIL_BY_ID_QUERY)

    // QuestionGroup Create
    const [qbQuestionGroupCreateMutation] = useMutation<
        { qbQuestionGroupCreate: QuestionGroupOutput },
        { questionGroupCreateInput: QuestionGroupCreateInput }
    >(QB_GROUP_CREATE_MUTATION)

    // QuestionGroup Update By Id
    const [qbQuestionGroupUpdateByIdMutation] = useMutation<
        { qbQuestionGroupUpdateById: QuestionGroupOutput },
        { questionGroupUpdateInput: QuestionGroupUpdateInput }
    >(QB_GROUP_UPDATE_BY_ID_MUTATION)

    // QuestionGroup Delete By Id
    const [qbQuestionGroupDeleteByIdMutation] = useMutation<
        { qbQuestionGroupDeleteById: QuestionGroupOutput },
        { questionGroupId: string }
    >(QB_GROUP_DELETE_BY_ID_MUTATION)

    // QuestionGroups Rearrange By Ids
    const [qbQuestionGroupsRearrangeByIds] = useMutation<
        { qbQuestionGroupsRearrangeByIds: QuestionGroupArrayOutput },
        { questionGroupsRearrangeInput: QuestionGroupsRearrangeInput }
    >(QB_QUESTIONGROUPS_REARRANGE_BY_IDS_MUTATION)

    return {
        // QuestionBank
        getQuestionBankContentById,
        getQuestionBankContentDetailById,
        getQuestionBankArchitectureById,
        getQuestionCreate_QuestionBankContentDetailById,
        getQuestionBanksArchitectureByFilter,
        getQuestionBanksByFilter,
        getQuestionBanksDetailByFilter,
        qbQuestionBankCreateMutation,

        // Folder
        getFoldersByFilter,
        getFolderDetailById,
        qbFolderCreateMutation,
        qbFolderUpdateByIdMutation,
        qbFolderDeleteByIdMutation,
        qbFoldersRearrangeByIds,

        // Chapter
        getChaptersByFilter,
        getChapterDetailById,
        qbChapterCreateMutation,
        qbChapterUpdateByIdMutation,
        qbChapterDeleteByIdMutation,
        qbFolderAddOrRemoveChapterByIdMutation,
        qbChapterMoveToFolderByIdMutation,
        qbChaptersRearrangeByIds,

        // Concept Card
        getConceptCardsByFilter,
        getConceptCardsDetailByFilter,
        getConceptCardDetailById,
        qbConceptCardCreateMutation,
        qbConceptCardUpdateByIdMutation,
        qbConceptCardDeleteByIdMutation,
        qbConceptCardsRearrangeByIds,

        // Tag
        getTagsByFilter,
        getTagDetailById,
        qbTagCreateMutation,
        // qbTagUpdateByIdMutation,
        // qbTagDeleteByIdMutation,

        // Question
        getQuestionsByFilter,
        getQuestionsDetailByFilter,
        getQuestionDetailById,
        getQuestionUpdate_QuestionDetailById,
        qbQuestionCreateMutation,
        qbQuestionUpdateByIdMutation,
        qbQuestionDeleteByIdMutation,

        // QuestionType
        getQuestionTypesByFilter,
        getQuestionTypesDetailByFilter,
        getQuestionTypeDetailById,
        qbQuestionTypeCreateMutation,
        qbQuestionTypeUpdateByIdMutation,
        qbQuestionTypeDeleteByIdMutation,
        qbQuestionTypesRearrangeByIds,

        // QuestionGroup
        getQuestionGroupsByFilter,
        getQuestionGroupDetailById,
        qbQuestionGroupCreateMutation,
        qbQuestionGroupUpdateByIdMutation,
        qbQuestionGroupDeleteByIdMutation,
        qbQuestionGroupsRearrangeByIds,
    }
}

export default useQBAPIService
