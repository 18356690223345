import { Fragment } from 'react'
import { FolderRowProps } from './type'

import { useNavigate } from 'react-router-dom'
import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { FText, FSwitchButton } from '@fantaskticedtechlimited/fui-complib'
import { FIcon, FIconNames } from '@fantaskticedtechlimited/fui-iconlib'
import FListCard from '../../../../../Components/FListCard'
import { ImageAsset } from '../../../../../assets/images'
import { FFontTypes } from '@fantaskticedtechlimited/fui-fontlib'

function FolderRow(props: FolderRowProps) {
    const { folder: folderData } = props

    const navigate = useNavigate()
    return (
        <FListCard
            index={props.index}
            isDisableToolBar={props.isDisableToolbar}
            icon={
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        columnGap: '10px',
                    }}
                >
                    <FIcon name={FIconNames.BOOK} />
                    <img
                        alt="icon"
                        src={props.folder.icon?.fileURL ?? ImageAsset.noImage}
                        style={{
                            width: '100px',
                            height: '100px',
                        }}
                    />
                </div>
            }
            onClick={() =>
                !props.disableCardOnClick &&
                navigate(`/questionBankContent/folder/${folderData.id}`)
            }
            content={
                <Fragment>
                    {/* data in middle top */}
                    <FText maxRows={2} font={FFontTypes.Title()}>
                        {folderData.name}
                    </FText>

                    {/* data in middle bottom */}
                    {/* number of question inside */}
                    <FText
                        color={FLegacyColorTypes.PRIMARY_GREY}
                        font={FFontTypes.Text()}
                    >{`${props.folder.chapters.length || 0} chapter(s)`}</FText>
                </Fragment>
            }
            toolBar={
                <Fragment>
                    {/* Button on Right side */}
                    <FSwitchButton
                        checked={props.folder.isEnable}
                        // update with open flag later
                        onClick={() => {
                            props.onToggleEnableButtonClick &&
                                props.onToggleEnableButtonClick()
                        }}
                    />

                    {/* More Action button */}
                    <FIcon
                        name={FIconNames.MORE}
                        size="large"
                        color={() => FLegacyColorTypes.PRIMARY_GREY}
                        onClick={() => {
                            props.onMoreActionButtonClick &&
                                props.onMoreActionButtonClick()
                        }}
                    />
                </Fragment>
            }
        />
    )
}

export default FolderRow
