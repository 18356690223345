import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { QBDirectoryViewHeader } from '..'
import { useQBAssetLibraryContext } from '../../../../../contexts/qbAssetLibraryContext'
import { QBDirectoryViewMode } from '../../../../../contexts/qbAssetLibraryContext/types'
import { useLoadingPageWrapperContext } from '../../../../../layout/WithLoadingPageWrapper'
import { useQuestionGroupPopUpWrapperContext } from '../../../../QuestionBankContent/components/popUp/WithQuestionGroupPopUpWrapper'
import QBAssetLibraryURLGenerator from '../../../components/QBAssetLibraryURLGenerator'

export const QBDirectoryViewQuestionGroupsHeader = () => {
    const { questionType, onRefresh } = useQBAssetLibraryContext()
    const { onQuestionGroupCreatePopUpOpen } =
        useQuestionGroupPopUpWrapperContext()
    const { setLoadingStart, setLoadingEnd } = useLoadingPageWrapperContext()
    const navigate = useNavigate()

    useEffect(() => {
        if (questionType === null) setLoadingStart()
        else setLoadingEnd()
    }, [questionType])

    return (
        <QBDirectoryViewHeader
            onBackButtonClick={() =>
                navigate(
                    QBAssetLibraryURLGenerator({
                        viewMode: QBDirectoryViewMode.QUESTION_TYPES,
                        questionBankId: questionType?.questionBankId!,
                        chapterId: questionType?.chapterId,
                    })
                )
            }
            title={questionType?.name!}
            CTAButtonLabel="+  Add Question Group"
            onCTAButtonClick={() =>
                onQuestionGroupCreatePopUpOpen(
                    {
                        questionTypeId: questionType?.id,
                        chapterId: questionType?.chapterId!,
                    },
                    () => onRefresh()
                )
            }
        />
    )
}
