import { ChapterDetailHeaderProps } from './types'
import ChapterRow from '../../components/Chapter/ChapterRow'
import { useChapterPopUpWrapperContext } from '../../components/popUp/WithChapterPopUpWrapper'
import { FHeaderButton } from '@fantaskticedtechlimited/fui-complib'
import * as styles from './styles' 
import { useNavigate } from 'react-router-dom'

function ChapterDetailHeader(props: ChapterDetailHeaderProps) {
    const navigate = useNavigate()
    const { chapter } = props
    const { onChapterMoreActionPopUpOpen, onChapterToggleEnablePopUpOpen } =
        useChapterPopUpWrapperContext()

    return (
        <div className={styles.ChapterDetailHeader_Container}>
            <FHeaderButton
                type="Back"
                onClick={() => {
                    if (props.folderId && props.folderId.length > 0)
                        navigate(
                            `/questionBankContent/folder/${props.folderId}`
                        )
                    else navigate('/questionBankContent')
                }}
            />
            <div style={{ flex: 1 }}>
                <ChapterRow
                    chapter={chapter}
                    disableCardOnClick
                    onMoreActionButtonClick={() =>
                        onChapterMoreActionPopUpOpen(chapter, props.onRefresh)
                    }
                    onToggleEnableButtonClick={() =>
                        onChapterToggleEnablePopUpOpen(chapter, props.onRefresh)
                    }
                />
            </div>
        </div>
    )
}

export default ChapterDetailHeader
