import {
    createContext,
    ElementType,
    Fragment,
    useContext,
    useState,
} from 'react'
import { QuestionType, Chapter } from '../../../../../assets/type/backend.type'
import { QuestionTypeCreateForm } from '../../form/QuestionTypeCreateForm'
import { QuestionTypeDeletePopUp } from '../QuestionTypeDeletePopUp'
import QuestionTypeMoreActionPopUp from '../QuestionTypeMoreActionPopUp'
import { QuestionTypeUpdateForm } from '../../form/QuestionTypeUpdateForm'
// import QuestionTypeDeletePopUp from "../QuestionTypeDeletePopUp"
// import QuestionTypeMoreActionPopUp from "../QuestionTypeMoreActionPopUp"

import { QuestionTypePopUpWrapperProps } from './type'
import { FConfirmPopUp } from '@fantaskticedtechlimited/fui-complib'
import { FOnSubmitFunction } from '@fantaskticedtechlimited/fui-complib'

const QuestionTypePopUpWrapperContext =
    createContext<QuestionTypePopUpWrapperProps>({
        isQuestionTypeMoreActionPopUpOpen: false,
        onQuestionTypeMoreActionPopUpOpen: async () => {},

        isQuestionTypeCreatePopUpOpen: false,
        onQuestionTypeCreatePopUpOpen: async () => {},

        isQuestionTypeUpdatePopUpOpen: false,
        onQuestionTypeUpdatePopUpOpen: async () => {},

        isQuestionTypeDeletePopUpOpen: false,
        onQuestionTypeDeletePopUpOpen: async () => {},
    })
export const useQuestionTypePopUpWrapperContext = () =>
    useContext(QuestionTypePopUpWrapperContext)
export const WithQuestionTypePopUpWrapper = (Component: ElementType) => {
    return function WithQuestionTypePopUpWrapper(props: any) {
        // More Action PopUp
        const [moreActionData, setMoreActionData] = useState<{
            questionType: QuestionType
            onDeleteCallback: FOnSubmitFunction<QuestionType>
            onUpdateCallback: FOnSubmitFunction<QuestionType>
        } | null>(null)
        const [
            isQuestionTypeMoreActionPopUpOpen,
            setIsQuestionTypeMoreActionPopUpOpen,
        ] = useState(false)
        const handleQuestionTypeMoreActionPopUpOpen = async (
            _questionType: QuestionType,
            _callbackFunctions: {
                onUpdate: FOnSubmitFunction<QuestionType>
                onDelete: FOnSubmitFunction<QuestionType>
            }
        ) => {
            setIsQuestionTypeMoreActionPopUpOpen(true)
            setMoreActionData({
                questionType: _questionType,
                onDeleteCallback: _callbackFunctions.onDelete,
                onUpdateCallback: _callbackFunctions.onUpdate,
            })
        }
        const handleQuestionTypeMoreActionPopUpClose = () => {
            setIsQuestionTypeMoreActionPopUpOpen(false)
            setMoreActionData(null)
        }

        // QuestionType Create
        const [questionTypeCreateData, setQuestionTypeCreateData] = useState<{
            chapter: Chapter
            callback: FOnSubmitFunction<QuestionType>
        } | null>(null)
        const [
            isQuestionTypeCreatePopUpOpen,
            setIsQuestionTypeCreatePopUpOpen,
        ] = useState(false)
        const handleQuestionTypeCreatePopUpOpen = async (
            _chapter: Chapter,
            _callbackFunction: FOnSubmitFunction<QuestionType>
        ) => {
            setQuestionTypeCreateData({
                chapter: _chapter,
                callback: _callbackFunction,
            })
            setIsQuestionTypeCreatePopUpOpen(true)
        }
        const handleQuestionTypeCreatePopUpSubmit = async (
            newQuestionType: QuestionType
        ) => {
            questionTypeCreateData &&
                (await questionTypeCreateData.callback(newQuestionType))
        }
        const handleQuestionTypeCreatePopUpClose = () => {
            setIsQuestionTypeCreatePopUpOpen(false)
            setQuestionTypeCreateData(null)
        }

        // QuestionType Update
        const [questionTypeUpdateData, setQuestionTypeUpdateData] = useState<{
            questionType: QuestionType
            callback: FOnSubmitFunction<QuestionType>
        } | null>(null)
        const [
            isQuestionTypeUpdatePopUpOpen,
            setIsQuestionTypeUpdatePopUpOpen,
        ] = useState(false)
        const handleQuestionTypeUpdatePopUpOpen = async (
            _questionType: QuestionType,
            _callbackFunction: FOnSubmitFunction<QuestionType>
        ) => {
            setQuestionTypeUpdateData({
                questionType: _questionType,
                callback: _callbackFunction,
            })
            setIsQuestionTypeUpdatePopUpOpen(true)
        }
        const handleQuestionTypeUpdatePopUpSubmit = async (
            updatedQuestionType: QuestionType
        ) => {
            questionTypeUpdateData &&
                (await questionTypeUpdateData.callback(updatedQuestionType))
        }
        const handleQuestionTypeUpdatePopUpClose = () => {
            setIsQuestionTypeUpdatePopUpOpen(false)
            setQuestionTypeUpdateData(null)
        }

        // QuestionType Delete
        const [questionTypeDeleteData, setQuestionTypeDeleteData] = useState<{
            questionType: QuestionType
            callback: FOnSubmitFunction<QuestionType>
        } | null>(null)
        const [
            isQuestionTypeDeletePopUpOpen,
            setIsQuestionTypeDeletePopUpOpen,
        ] = useState(false)
        const handleQuestionTypeDeletePopUpOpen = async (
            _questionType: QuestionType,
            _callbackFunction: FOnSubmitFunction<QuestionType>
        ) => {
            setIsQuestionTypeDeletePopUpOpen(true)
            setQuestionTypeDeleteData({
                questionType: _questionType,
                callback: _callbackFunction,
            })
        }
        const handleQuestionTypeDeletePopUpClose = () => {
            setIsQuestionTypeDeletePopUpOpen(false)
            setQuestionTypeDeleteData(null)
        }

        // Context
        const defaultContextValue: QuestionTypePopUpWrapperProps = {
            isQuestionTypeMoreActionPopUpOpen,
            onQuestionTypeMoreActionPopUpOpen:
                handleQuestionTypeMoreActionPopUpOpen,

            isQuestionTypeCreatePopUpOpen,
            onQuestionTypeCreatePopUpOpen: handleQuestionTypeCreatePopUpOpen,

            isQuestionTypeUpdatePopUpOpen,
            onQuestionTypeUpdatePopUpOpen: handleQuestionTypeUpdatePopUpOpen,

            isQuestionTypeDeletePopUpOpen,
            onQuestionTypeDeletePopUpOpen: handleQuestionTypeDeletePopUpOpen,
        }

        return (
            <QuestionTypePopUpWrapperContext.Provider
                value={defaultContextValue}
            >
                <Fragment>
                    <Component {...props} />

                    {/* More Action PopUp */}
                    {/* {isQuestionTypeMoreActionPopUpOpen &&
                        moreActionData !== null && (
                            <QuestionTypeMoreActionPopUp 
                                onClose={() =>
                                    handleQuestionTypeMoreActionPopUpClose()
                                }
                                questionType={moreActionData.questionType}
                                onCallback={async (data) =>
                                    await moreActionData.onDeleteCallback(data)
                                }
                            />
                        )} */}

                    {/* QuestionType Create PopUp */}
                    {isQuestionTypeCreatePopUpOpen && questionTypeCreateData && (
                        <FConfirmPopUp
                            title="Create QuestionType"
                            disableCloseWhenClickOutside
                            disableBottomBar
                            style={{ width: '30%', height: 'fit-content' }}
                        >
                            <QuestionTypeCreateForm
                                chapter={questionTypeCreateData.chapter}
                                onSubmitCallback={async (data) =>
                                    await handleQuestionTypeCreatePopUpSubmit(
                                        data
                                    )
                                }
                                onClose={handleQuestionTypeCreatePopUpClose}
                            />
                        </FConfirmPopUp>
                    )}

                    {/* Update QuestionType PopUp*/}
                    {isQuestionTypeUpdatePopUpOpen && questionTypeUpdateData && (
                        <FConfirmPopUp
                            title="Edit QuestionType"
                            disableCloseWhenClickOutside
                            disableBottomBar
                            style={{ width: '30%', height: 'fit-content' }}
                        >
                            <QuestionTypeUpdateForm
                                questionType={
                                    questionTypeUpdateData.questionType
                                }
                                onClose={handleQuestionTypeUpdatePopUpClose}
                                onSubmitCallback={async (data) =>
                                    await handleQuestionTypeUpdatePopUpSubmit(
                                        data
                                    )
                                }
                            />
                        </FConfirmPopUp>
                    )}

                    {/* QuestionType Delete PopUp */}
                    {isQuestionTypeDeletePopUpOpen &&
                        questionTypeDeleteData !== null && (
                            <QuestionTypeDeletePopUp
                                onClose={handleQuestionTypeDeletePopUpClose}
                                questionType={
                                    questionTypeDeleteData?.questionType
                                }
                                onCallback={async (data) =>
                                    await questionTypeDeleteData.callback(data)
                                }
                            />
                        )}
                </Fragment>
            </QuestionTypePopUpWrapperContext.Provider>
        )
    }
}
