import { gql } from '@apollo/client'

export const QB_CONCEPT_CARD_CREATE_MUTATION = gql`
    mutation qbConceptCardCreate(
        $conceptCardCreateInput: ConceptCardCreateInput!
    ) {
        qbConceptCardCreate(conceptCardCreateInput: $conceptCardCreateInput) {
            isSuccess
            code
            errorMessage
            data {
                id
                name
            }
        }
    }
`
