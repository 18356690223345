import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { style } from 'typestyle'
import { FFloatingActionButtonProps } from './type'

export const FFloatingAddButtonContainer = (
    props: FFloatingActionButtonProps,
    isClicked: boolean
) =>
    style({
        backgroundColor:
            isClicked && !props.isDisable
                ? FLegacyColorTypes.DEFAULT_SUB_THEME
                : 'transparent',
        backdropFilter: isClicked && !props.isDisable ? 'blur(1.5rem)' : 'none',
        borderRadius: isClicked && !props.isDisable ? '0.75rem' : undefined,

        // position
        position: 'fixed',

        // vertical position
        top:
            props.veticalfloat === 'top'
                ? 0
                : props.veticalfloat === 'center'
                ? '50%'
                : undefined,
        bottom: props.veticalfloat === 'bottom' ? 0 : undefined,

        // horizontal position
        left:
            props.horizontalfloat === 'left'
                ? 0
                : props.horizontalfloat === 'center'
                ? '50%'
                : undefined,
        right: props.horizontalfloat === 'right' ? 0 : undefined,

        boxSizing: 'border-box',

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-end',

        margin: props.margin ? props.margin : '0 1.5rem 2.75rem 0',
        padding: isClicked && !props.isDisable ? '0.75rem' : 0,

        rowGap: isClicked && !props.isDisable ? '1.5rem' : 0,
        transition: 'all 0.2s ease-in-out',
        width: isClicked && !props.isDisable ? '13.5rem' : '4rem',
    })

export const FFloatingAddButtonDiv = (
    props: FFloatingActionButtonProps,
    isClicked: boolean
) =>
    style({
        display: 'flex',
        // justifyContent:"center",
        // alignItems: "flex-end" ,
        width: '4rem',
        height: '4rem',
        cursor: props.isDisable ? 'not-allowed' : 'pointer',
        opacity: props.isDisable ? 0.67 : 1,
        transform: isClicked && !props.isDisable ? 'rotate(45deg)' : undefined,
        transition: 'all 0.2s ease-in-out',
        $nest: {
            path: {
                stroke:
                    isClicked && !props.isDisable
                        ? FLegacyColorTypes.DEFAULT_MAIN_THEME
                        : FLegacyColorTypes.PRIMARY_WHITE,
            },
        },
    })
