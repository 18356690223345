import { style } from 'typestyle'

export const FolderUpdateForm_IconRow_Div = style({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: '24px',
    alignSelf: 'flex-start',
    width: '100%',
})

export const FolderUpdateForm_IconDisplay_Div = style({
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
})
