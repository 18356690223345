import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { FTheme } from '@fantaskticedtechlimited/fui-complib'
import { FFontTypes } from '@fantaskticedtechlimited/fui-fontlib'
import { style } from 'typestyle'

export const DefaultDNDUploader_Container = (
    isDropEnter: boolean,
    iconUploaded: boolean
) =>
    style({
        width: '100%',
        border: '2px solid ' + FLegacyColorTypes.PRIMARY_BLACK,
        boxSizing: 'border-box',
        padding: '16px',
        opacity: isDropEnter ? 0.6 : 1,
        borderRadius: 8,
        display: iconUploaded ? 'none' : 'flex',
        flexDirection: 'column',
        rowGap: '8px',
        $nest: {
            div: {
                font: FFontTypes.Large_Text(),
                color: FLegacyColorTypes.SECONDARY_RED,
            },
        },
    })
