import { stylesheet } from 'typestyle'

export const styles = stylesheet({
    PageContainer_Container: {
        width: '100%',
        maxWidth: '960px',
        height: '100vh',
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '16px',
        position: 'relative',
        overflowY: 'auto',
    },
})
