import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { QBDirectoryViewHeader } from '..'
import { useQBAssetLibraryContext } from '../../../../../contexts/qbAssetLibraryContext'
import { useLoadingPageWrapperContext } from '../../../../../layout/WithLoadingPageWrapper'
import { useFolderPopUpWrapperContext } from '../../../../QuestionBankContent/components/popUp/WithFolderPopUpWrapper'

export const QBDirectoryViewFoldersHeader = () => {
    const { questionBank, onRefresh } = useQBAssetLibraryContext()
    const { onFolderCreatePopUpOpen } = useFolderPopUpWrapperContext()
    const { setLoadingStart, setLoadingEnd } = useLoadingPageWrapperContext()
    const navigate = useNavigate()

    useEffect(() => {
        if (questionBank === null) setLoadingStart()
        else setLoadingEnd()
    }, [questionBank])

    return (
        <QBDirectoryViewHeader
            onBackButtonClick={() => navigate('/')}
            title={questionBank?.name!}
            CTAButtonLabel="+  Add Folder"
            onCTAButtonClick={() =>
                onFolderCreatePopUpOpen(questionBank!, () => onRefresh())
            }
        />
    )
}
