import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import {
    FButton,
    FScrollBarStyle,
    FText,
} from '@fantaskticedtechlimited/fui-complib'
import { FFontTypes } from '@fantaskticedtechlimited/fui-fontlib'
import { FIcon, FIconNames } from '@fantaskticedtechlimited/fui-iconlib'
import { MCAnswerCreateInput } from '../../../../../assets/type/backend.type'
import FMarkdownEditor from '../../../../../Components/FMarkdown/FMarkdownEditor'

import { InputPanel_MCAnswerCorrectButton_Div, styles } from './styles'
import { MCContentInputPanelProps } from './types'

export const MCContentInputPanel = (props: MCContentInputPanelProps) => {
    const handleAddMCAnswer = () => {
        const newAnswers = [...props.content.answers]
        const newAnswer: MCAnswerCreateInput = {
            isCorrect: false,
            body: '',
        }
        newAnswers.push(newAnswer)
        props.onContentChange({
            ...props.content,
            answers: newAnswers,
        })
    }

    const handleRemoveMCAnswerAt = (index: number) => {
        const newAnswers = props.content.answers.filter((_, i) => i !== index)
        props.onContentChange({
            ...props.content,
            answers: newAnswers,
        })
    }

    return (
        <div className={styles.InputPanel_Container + ' ' + FScrollBarStyle()}>
            <div className={styles.InputPanel_Section_Container}>
                <FText>Question Body:</FText>
                <FMarkdownEditor
                    minRows={4}
                    maxRows={16}
                    mdValue={props.content.body}
                    onChange={(v) =>
                        props.onContentChange({
                            ...props.content,
                            body: v,
                        })
                    }
                />
            </div>

            {/* answer */}
            <div className={styles.InputPanel_Section_Container}>
                <FText>Question Answers:</FText>
                {props.content.answers.map((answer, i) => {
                    return (
                        <div
                            key={i}
                            className={styles.InputPanel_MCAnswerRow_Div}
                        >
                            <FButton
                                type="Outline"
                                label={answer.isCorrect ? 'True' : 'False'}
                                labelStyle={() => ({
                                    font: FFontTypes.Large_Text(),
                                    color: answer.isCorrect
                                        ? FLegacyColorTypes.SECONDARY_GREEN
                                        : FLegacyColorTypes.SECONDARY_RED,
                                    wordBreak: 'normal',
                                })}
                                className={() =>
                                    InputPanel_MCAnswerCorrectButton_Div(
                                        answer.isCorrect
                                    )
                                }
                                onClick={() => {
                                    const newAnswer: MCAnswerCreateInput = {
                                        ...answer,
                                        isCorrect: !answer.isCorrect,
                                    }
                                    const newAnswers = [
                                        ...props.content.answers,
                                    ]
                                    newAnswers[i] = newAnswer
                                    props.onContentChange({
                                        ...props.content,
                                        answers: newAnswers,
                                    })
                                }}
                            />
                            <FMarkdownEditor
                                minRows={2}
                                maxRows={12}
                                wrapperStyle={{ flex: 1, width: undefined }}
                                mdValue={answer.body}
                                onChange={(v) => {
                                    const newAnswer: MCAnswerCreateInput = {
                                        ...answer,
                                        body: v,
                                    }
                                    const newAnswers = [
                                        ...props.content.answers,
                                    ]
                                    newAnswers[i] = newAnswer
                                    props.onContentChange({
                                        ...props.content,
                                        answers: newAnswers,
                                    })
                                }}
                            />
                            <div style={{ width: '36px' }}>
                                <FIcon
                                    name={FIconNames.DELETE}
                                    color={() =>
                                        FLegacyColorTypes.SECONDARY_RED
                                    }
                                    // className={styles.InputPanel_DeleteIcon_Div}
                                    onClick={() => handleRemoveMCAnswerAt(i)}
                                />
                            </div>
                        </div>
                    )
                })}
                <FButton
                    type="Primary"
                    label="Add Answer"
                    onClick={handleAddMCAnswer}
                    style={() => ({ alignSelf: 'flex-end' })}
                />
            </div>

            {/* Explaination */}
            <div className={styles.InputPanel_Section_Container}>
                <FText>Question Solution:</FText>
                <FMarkdownEditor
                    minRows={2}
                    maxRows={12}
                    mdValue={props.content.solution}
                    onChange={(v) =>
                        props.onContentChange({
                            ...props.content,
                            solution: v,
                        })
                    }
                />
            </div>
        </div>
    )
}
