import { Fragment, useEffect, useState } from 'react'
import useQBAPIService from '../../../../apiService/questionBank'
import { QuestionType } from '../../../../assets/type/backend.type'
import { QuestionTypesContainerProps } from './types'
import * as styles from './styles'
import { useLoadingPageWrapperContext } from '../../../../layout/WithLoadingPageWrapper'
import { PageContainer } from '../../../../layout/PageContainer'
import {
    FButton,
    FNoData,
    FScrollableContentWrapper,
} from '@fantaskticedtechlimited/fui-complib'

import QuestionTypeList from '../../components/QuestionType/QuestionTypeList'
import { useQuestionTypePopUpWrapperContext } from '../../components/popUp/WithQuestionTypePopUpWrapper'

function QuestionTypesContainer(props: QuestionTypesContainerProps) {
    const { setLoadingStart, setLoadingEnd } = useLoadingPageWrapperContext()
    const qbAPIService = useQBAPIService()
    const [questionTypes, setQuestionTypes] = useState<QuestionType[]>([])
    const totalQuestionTypes = questionTypes !== null ? questionTypes.length : 0
    const handleGetQuestionTypesByChapterId = async (
        chapterId: string,
        withLoadingScreen?: boolean
    ) => {
        withLoadingScreen && setLoadingStart()
        try {
            const result = await qbAPIService.getQuestionTypesByFilter({
                questionTypeFilterInput: {
                    chapterId,
                },
            })
            const { questionTypesGet } = result.data
            setQuestionTypes(
                questionTypesGet.isSuccess ? questionTypesGet.data || [] : []
            )
        } catch (error) {
            console.log('error', error)
            setQuestionTypes([])
        }
        withLoadingScreen && setLoadingEnd()
    }

    const { onQuestionTypeCreatePopUpOpen } =
        useQuestionTypePopUpWrapperContext()

    useEffect(() => {
        handleGetQuestionTypesByChapterId(props.chapter.id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.chapter])

    return (
        <PageContainer
            header={
                <div className={styles.toolbarContainer}>
                    {/* New Concept Card button */}
                    <FButton
                        type="Primary"
                        onClick={() =>
                            onQuestionTypeCreatePopUpOpen(props.chapter, () =>
                                handleGetQuestionTypesByChapterId(
                                    props?.chapter.id
                                )
                            )
                        }
                        label="New Question Type"
                    />

                    {/* refresh button */}
                    <FButton
                        type="Primary"
                        onClick={async () =>
                            await handleGetQuestionTypesByChapterId(
                                props.chapter.id,
                                true
                            )
                        }
                        label="Refresh"
                    />
                </div>
            }
            body={
                <FScrollableContentWrapper>
                    {totalQuestionTypes === 0 ? (
                        <FNoData />
                    ) : (
                        <Fragment>
                            <QuestionTypeList
                                questionTypes={questionTypes || []}
                                onRefresh={() =>
                                    handleGetQuestionTypesByChapterId(
                                        props.chapter.id
                                    )
                                }
                            />
                        </Fragment>
                    )}
                </FScrollableContentWrapper>
            }
        />
    )
}

export default QuestionTypesContainer
