import { FColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { FHeaderButton, FText } from '@fantaskticedtechlimited/fui-complib'
import { FFontTypes } from '@fantaskticedtechlimited/fui-fontlib'
import { CTAButton } from '../../../../Components/CTAButton'
import { HeaderButton_Div, styles } from './styles'
import { QBDirectoryViewHeaderProps } from './types'

export const QBDirectoryViewHeader = (props: QBDirectoryViewHeaderProps) => {
    return (
        <>
            <div className={styles.TitleDiv}>
                {props.disableBackButton ? null : (
                    <FHeaderButton
                        type="Back"
                        iconProps={{
                            color: (isHover) =>
                                isHover
                                    ? FColorTypes.FPrimaryColors.BLACK.LM
                                    : FColorTypes.FPrimaryColors.GREY.LM,
                        }}
                        className={(isHover) => HeaderButton_Div(isHover)}
                        onClick={props.onBackButtonClick}
                    />
                )}
                <FText
                    font={FFontTypes.Title()}
                    style={{ flex: 1 }}
                    children={props.title}
                />
            </div>
            <CTAButton
                label={props.CTAButtonLabel}
                onClick={props.onCTAButtonClick}
            />
        </>
    )
}
