import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { style } from 'typestyle'

export const OptionCard_Container = style({
    display: 'flex',
    flexDirection: 'row',
    padding: '8px',
    alignItems: 'center',
    columnGap: '8px',

    $nest: {
        '&:hover': {
            background: FLegacyColorTypes.PRIMARY_LIGHT,
        },
    },
})
