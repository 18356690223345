import fantasktic from './fantasktic.svg'
import backBtn from './back-button.svg'
import logout from './logout.png'
import noImage from './noImage.svg'
import DragIcon from './drag-icon.svg'

// QB Icons
import HomeTopIcon from './HomeTopIcon.svg'
import QuestionBankTopIcon from './QuestionBankTopIcon.svg'
import FolderTopIcon from './FolderTopIcon.svg'
import ChapterTopIcon from './ChapterTopIcon.svg'
import QTypeTopIcon from './QTypeTopIcon.svg'
import QuestionGroupTopIcon from './QuestionGroupTopIcon.svg'
import QuestionBankIcon from './QuestionBankIcon.svg'
import FolderIcon from './FolderIcon.svg'
import ChapterIcon from './ChapterIcon.svg'
import QuestionTypeIcon from './QuestionTypeIcon.svg'
import QuestionGroupIcon from './QuestionGroupIcon.svg'
import QuestionIcon from './QuestionIcon.svg'

// Dropdown Icons
import EditIcon from './EditIcon.svg'
import MoveIcon from './MoveIcon.svg'
import DuplicateIcon from './DuplicateIcon.svg'
import EditIconHover from './EditIconHover.svg'
import MoveIconHover from './MoveIconHover.svg'
import DuplicateIconHover from './DuplicateIconHover.svg'

export const ImageAsset = {
    logo: fantasktic,
    backBtn,
    logout,
    noImage,
    DragIcon,

    // QB Icons
    HomeTopIcon,
    QuestionBankTopIcon,
    FolderTopIcon,
    ChapterTopIcon,
    QTypeTopIcon,
    QuestionGroupTopIcon,
    QuestionBankIcon,
    FolderIcon,
    ChapterIcon,
    QuestionTypeIcon,
    QuestionGroupIcon,
    QuestionIcon,

    // Dropdown Icons
    EditIcon,
    MoveIcon,
    DuplicateIcon,
    EditIconHover,
    MoveIconHover,
    DuplicateIconHover,
}
