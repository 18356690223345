import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { FText } from '@fantaskticedtechlimited/fui-complib'
import { FFontTypes } from '@fantaskticedtechlimited/fui-fontlib'
import { getAuth, indexedDBLocalPersistence } from 'firebase/auth'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useAuthAPIService from '../../../apiService/auth'
import backBtn from '../../../assets/images/back-button.svg'
import firebaseAppWithConfig from '../../../config/firebaseConfig'
import useFirebaseAuthService from '../../../utils/useFirebaseAuthService'
import * as styles from './styles'
function VerifyEmailMagicLink() {
    const navigate = useNavigate()
    const authAPIService = useAuthAPIService()
    const firebaseAuthService = useFirebaseAuthService()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const handleVerifyMagicLink = async () => {
            try {
                let auth = getAuth(firebaseAppWithConfig)
                auth.setPersistence(indexedDBLocalPersistence)
                const { isSuccess, firebaseToken } =
                    await firebaseAuthService.handleSignInWithEmailLink(
                        auth,
                        window.location.href
                    )
                alert(`IsSuccess:${isSuccess}, FirebaseToken:${firebaseToken}`)
                if (isSuccess) {
                    await authAPIService.handleSignInSuccessRedirect(
                        '/',
                        firebaseToken,
                        '/'
                    )
                }
            } catch (error) {
                alert('Failed Verify Magic Link')
            }
            setIsLoading(false)
        }
        handleVerifyMagicLink()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <div className={styles.loginBoxContainer}>
            <img
                src={backBtn}
                className={styles.backButton}
                onClick={() => navigate('/login', { replace: true })}
                alt="backBtn"
            />
            <FText font={FFontTypes.Title()}>Verify Magic Link</FText>
            {isLoading ? (
                <FText
                    font={FFontTypes.Large_Text()}
                    color={FLegacyColorTypes.PRIMARY_GREY}
                >
                    Verifying
                </FText>
            ) : (
                <FText
                    font={FFontTypes.Large_Text()}
                    color={FLegacyColorTypes.SECONDARY_RED}
                >
                    Failed to verify magic link
                </FText>
            )}
        </div>
    )
}

export default VerifyEmailMagicLink
