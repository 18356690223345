import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { style } from 'typestyle'

export const GlobalContainer = (isMobile: boolean) =>
    style({
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
    })

export const RootPageContainer = style({
    width: '100vw',
    height: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    boxSizing: 'border-box',
    overflow: 'hidden',
})

export const ContentContainer = style({
    boxSizing: 'border-box',
    maxWidth: '1280px',
    width: '100%',
    height: 'inherit',
    padding: '0 16px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
})

export const FullPageContainer = style({
    width: '100vw',
    height: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    boxSizing: 'border-box',
    overflow: 'hidden',
    backgroundColor: FLegacyColorTypes.PRIMARY_WHITE,
})
