import { FScrollBarStyle } from '@fantaskticedtechlimited/fui-complib'
import { BodyDiv } from './BodyDiv'
import { HeaderDiv } from './HeaderDiv'
import { styles } from './styles'
import { PageContainerProps } from './types'

export const PageContainer = ({
    showBreadcrumb = true,
    ...props
}: PageContainerProps) => {
    return (
        <div
            className={
                styles.PageContainer_Container +
                ' ' +
                FScrollBarStyle() +
                ' ' +
                props.className
            }
            style={props.style}
        >
            <HeaderDiv
                showBreadcrumb={showBreadcrumb}
                customBreadcrumb={props.customBreadcrumb}
                disableBottomBorder={props.disableHeaderBottomBorder}
                children={props.header}
                style={props.headerStyle}
                className={props.headerClassName}
            />
            <BodyDiv
                children={props.body}
                style={props.bodyStyle}
                className={props.bodyClassName}
            />
        </div>
    )
}
