import { gql } from '@apollo/client'

export const GET_QUESTION_TYPES_BY_FILTER = gql`
    query getQuestionTypesByFilter(
        $questionTypeFilterInput: QuestionTypeFilterInput
    ) {
        questionTypesGet(questionTypeFilterInput: $questionTypeFilterInput) {
            code
            errorMessage
            isSuccess
            data {
                id
                name
                chapterId
                questionBankId
                createAt
                updateAt
            }
        }
    }
`
