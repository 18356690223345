import { FIconNames } from '@fantaskticedtechlimited/fui-iconlib'
import CheckLogin from './Pages/Login/CheckLogin'
import Home from './Pages/Home'
import Login from './Pages/Login'
import LoginHome from './Pages/Login/LoginHome'
import MagicLink from './Pages/Login/MagicLink'
import Register from './Pages/Login/Register'
import SentEmailMagicLink from './Pages/Login/SentEmailMagicLink'
import VerifyEmailMagicLink from './Pages/Login/VerifyEmailMagicLink'
import NotFound from './Pages/NotFound'
import Setting from './Pages/Setting'
import { FRouteConfig } from './utils/useRouteService/types'
import TestUpload from './Pages/TestUpload'
import Sub, { Sub1, SubHome } from './Pages/Sub'
import QuestionBankContent from './Pages/QuestionBankContent'
import ContentHome from './Pages/QuestionBankContent/ContentHome'
import ChapterDetail from './Pages/QuestionBankContent/ChapterDetail'
import FolderDetail from './Pages/QuestionBankContent/FolderDetail'
import TestForm from './Pages/TestForm'
import QuestionBankHome from './Pages/QuestionBankView/QuestionBankHome'
import QuestionBankView from './Pages/QuestionBankView'
import QBArchitecture from './Pages/QBArchitecture'
import QBArchitectureDetail from './Pages/QBArchitecture/QBArchitectureDetail'
import QBAssetLibrary from './Pages/QBAssetLibrary'
import QBAssetLibraryView from './Pages/QBAssetLibrary/QBAssetLibraryView'
import { UserRole } from './assets/type/backend.type'
import AdminUserManagement from './Pages/AdminUserManagement'

export const BeforeAuthRoutes: FRouteConfig[] = [
    {
        path: '/',
        component: Login,
        exact: true,
        menuName: 'Login',
        breadcrumbName: 'Login',
        hideInMenu: true,
        routes: [
            {
                component: CheckLogin,
                breadcrumbName: 'Check Login',
                hideInMenu: true,
                index: true,
            },
            {
                path: 'login',
                component: LoginHome,
                breadcrumbName: 'LoginHome',
            },
            {
                path: 'register',
                component: Register,
                breadcrumbName: 'Register',
            },
            {
                path: 'magicLink',
                component: MagicLink,
                breadcrumbName: 'Magic Link',
            },
            {
                path: 'sentMagicLink',
                component: SentEmailMagicLink,
                breadcrumbName: 'Sent Magic Link',
            },
            {
                path: 'verifyEmailMagicLink',
                component: VerifyEmailMagicLink,
                breadcrumbName: 'Verify Magic Link',
            },
        ],
    },
]
export const MainMenuRoutes: FRouteConfig[] = [
    // {
    // 	path: "/",
    // 	component: Home,
    // 	menuName: "Home",
    // 	breadcrumbName: "Home",
    // 	exact: true,
    // 	iconName: FIconNames.HOME,
    // },
    {
        // for redirect purpose
        path: '/login',
        component: NotFound,
        breadcrumbName: 'Home',
        exact: true,
        hideInMenu: true,
        routes: [
            {
                path: 'register',
                component: NotFound,
                breadcrumbName: 'Register',
            },
            {
                path: 'magicLink',
                component: NotFound,
                breadcrumbName: 'Magic Link',
            },
            {
                path: 'sentMagicLink',
                component: NotFound,
                breadcrumbName: 'Sent Magic Link',
            },
            {
                path: 'verifyEmailMagicLink',
                component: NotFound,
                breadcrumbName: 'Verify Magic Link',
            },
        ],
    },
    {
        path: '/',
        component: QuestionBankView,
        menuName: 'Home',
        breadcrumbName: 'Home',
        exact: true,
        iconName: FIconNames.HOME,
        routes: [
            {
                component: QuestionBankHome,
                breadcrumbName: 'QuestionBankHome',
                hideInMenu: true,
                index: true,
            },
        ],
    },
    {
        path: '/questionBankContent',
        component: QuestionBankContent,
        menuName: 'QB Content',
        breadcrumbName: 'QB Content',
        exact: true,
        iconName: FIconNames.HOME,
        hideInMenu: true,
        routes: [
            {
                component: ContentHome,
                breadcrumbName: '',
                hideInMenu: true,
                index: true,
            },
            {
                path: 'chapter/:chapterId',
                component: ChapterDetail,
                breadcrumbName: '',
                hideInMenu: true,
                exact: true,
            },
            {
                path: 'folder/:folderId',
                component: FolderDetail,
                breadcrumbName: '',
                hideInMenu: true,
                exact: true,
            },
            {
                path: 'folder/:folderId/chapter/:chapterId',
                component: ChapterDetail,
                breadcrumbName: '',
                hideInMenu: true,
                exact: true,
            },
        ],
    },
    {
        path: '/qbArchitecture',
        component: QBArchitecture,
        menuName: 'QB Archi',
        breadcrumbName: 'QB Archi',
        exact: true,
        iconName: FIconNames.HOME,
        hideInMenu: true,
        routes: [
            {
                component: QBArchitectureDetail,
                breadcrumbName: '',
                hideInMenu: true,
                index: true,
            },
        ],
    },
    {
        path: '/qbAssetLibrary',
        component: QBAssetLibrary,
        menuName: 'QB Asset',
        breadcrumbName: 'QB Asset',
        exact: true,
        iconName: FIconNames.HOME,
        routes: [
            {
                component: QBAssetLibraryView,
                breadcrumbName: '',
                hideInMenu: true,
                index: true,
            },
        ],
    },
    {
        path: '/testUpload',
        component: TestUpload,
        menuName: 'Issue Questions',
        breadcrumbName: 'Issue Questions',
        exact: true,
        iconName: FIconNames.FLAG,
    },
    {
        path: '/testForm',
        component: TestForm,
        menuName: 'Analysis',
        breadcrumbName: 'Analysis',
        exact: true,
        iconName: FIconNames.BAR_CHART,
    },
    {
        path: '/notFound',
        component: NotFound,
        menuName: 'NotFound',
        breadcrumbName: 'NotFound',
        exact: true,
        hideInMenu: true,
    },
]

export const AdminMenuRoutes: FRouteConfig[] = [
    {
        path: '/adminUserManagement',
        component: AdminUserManagement,
        menuName: 'AdminUserManagement',
        breadcrumbName: 'AdminUserManagement',
        exact: true,
        iconName: FIconNames.SETTING,
    },
]

export const FooterMenuRoutes: FRouteConfig[] = [
    {
        path: '/settings',
        component: Setting,
        menuName: 'Settings',
        breadcrumbName: 'Settings',
        exact: true,
        iconName: FIconNames.SETTING,
    },
]

export const AfterAuthMainMenuRoutes = (userRoles: UserRole[]) => {
    const finalRoutes = []
    finalRoutes.push(...MainMenuRoutes)
    if (userRoles.includes(UserRole.ADMIN)) {
        finalRoutes.push(...AdminMenuRoutes)
    }
    return finalRoutes
}

export const AfterAuthRoutes = (userRoles: UserRole[]) => {
    const finalRoutes = []
    if (userRoles.includes(UserRole.ADMIN)) {
        finalRoutes.push(...AdminMenuRoutes)
    }
    finalRoutes.push(...MainMenuRoutes, ...FooterMenuRoutes)
    return finalRoutes
}
