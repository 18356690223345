import { gql } from '@apollo/client'

export const QB_GROUP_CREATE_MUTATION = gql`
    mutation qbQuestionGroupCreate(
        $questionGroupCreateInput: QuestionGroupCreateInput!
    ) {
        qbQuestionGroupCreate(
            questionGroupCreateInput: $questionGroupCreateInput
        ) {
            code
            isSuccess
            errorMessage
            data {
                id
                name
                questionTypeId
            }
        }
    }
`
