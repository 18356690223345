import { ConceptCardListProps } from './type'
import * as styles from './styles'
import { ConceptCard } from '../../../../../assets/type/backend.type'
import ConceptCardRow from '../ConceptCardRow'
import { useConceptCardPopUpContext } from '../../popUp/WithConceptCardPopUpWrapper'

function ConceptCardList(props: ConceptCardListProps) {
    const { onConceptCardMoreActionPopUpOpen } = useConceptCardPopUpContext()

    const handleMoreActionPopUpCallback = async () => {
        // console.log("handleMoreActionPopUpCallback")
        await props.onRefresh()
    }
    return (
        <div className={styles.cardListDiv}>
            {/* ConceptCardList List looping */}
            {(props.conceptCards || []).map(
                (conceptCard: ConceptCard, index: number) => (
                    // <div>{conceptCard.id}</div>
                    <ConceptCardRow
                        key={index}
                        index={index}
                        conceptCard={conceptCard}
                        onMoreActionButtonClick={() => {
                            console.log(
                                `onMoreActionButtonClick conceptCard ${conceptCard.id}`
                            )
                            onConceptCardMoreActionPopUpOpen(conceptCard, {
                                onDelete: () => handleMoreActionPopUpCallback(),
                                onUpdate: () => handleMoreActionPopUpCallback(),
                            })
                        }}
                    />
                )
            )}
        </div>
    )
}

export default ConceptCardList
