import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { FText } from '@fantaskticedtechlimited/fui-complib'
import { FOnClickFunction } from '@fantaskticedtechlimited/fui-complib'
import { FFontTypes } from '@fantaskticedtechlimited/fui-fontlib'
import { Fragment, ReactChild } from 'react'
import { useNavigate } from 'react-router-dom'
import { ImageAsset } from '../../../../assets/images'
import { useQBAssetLibraryContext } from '../../../../contexts/qbAssetLibraryContext'
import { QBDirectoryViewMode } from '../../../../contexts/qbAssetLibraryContext/types'
import QBAssetLibraryURLGenerator from '../QBAssetLibraryURLGenerator'

const QBDirectoryViewBreadcrumb = () => {
    //
    const {
        viewMode,
        questionBank,
        folder,
        chapter,
        questionType,
        questionGroup,
        question,
    } = useQBAssetLibraryContext()
    const navigate = useNavigate()
    const qbAssetViewModeOrders = [
        QBDirectoryViewMode.FOLDERS,
        QBDirectoryViewMode.CHAPTERS,
        QBDirectoryViewMode.QUESTION_TYPES,
        QBDirectoryViewMode.ROOT_CHAPTER,
        QBDirectoryViewMode.QUESTION_GROUPS,
        QBDirectoryViewMode.QUESTIONS,
        QBDirectoryViewMode.QUESTION,
    ]
    const isBreadcrumbAllowToShow = (
        currentViewMode: QBDirectoryViewMode,
        targetViewMode: QBDirectoryViewMode
    ): boolean => {
        let cIndex = qbAssetViewModeOrders.indexOf(currentViewMode)
        let tIndex = qbAssetViewModeOrders.indexOf(targetViewMode)
        return tIndex <= cIndex ? true : false
    }
    interface BreadcrumbButtonProps {
        name: string
        url?: string
        onClick?: FOnClickFunction
        icon?: ReactChild
        disabled?: boolean
    }
    const QBAssetBreadcrumbButton = (props: BreadcrumbButtonProps) => {
        const { name, url, onClick, icon, disabled } = props
        return (
            <span
                style={{
                    color: !disabled
                        ? FLegacyColorTypes.PRIMARY_GREY
                        : undefined,
                    cursor: disabled ? 'default' : 'pointer',
                }}
                onClick={(e) => {
                    if (!disabled) {
                        onClick ? onClick() : navigate(url ?? '/')
                    } else {
                        e.preventDefault()
                    }
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        columnGap: '8px',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            columnGap: '4px',
                        }}
                    >
                        {icon}
                        <FText
                            color={
                                !disabled
                                    ? FLegacyColorTypes.PRIMARY_GREY
                                    : undefined
                            }
                            font={FFontTypes.Large_Text()}
                        >
                            {name.length > 24
                                ? name.slice(0, 24) + '...'
                                : name}
                        </FText>
                    </div>
                    {!props.disabled ? <span>/</span> : null}
                </div>
            </span>
        )
    }
    const isAllowToShowFolderBreadcrumb = () => {
        if (viewMode === QBDirectoryViewMode.CHAPTERS) {
            return true
        }
        if (folder !== null && chapter === null) {
            return true
        }
        if (folder !== null && chapter !== null) {
            const found = folder.chapters.find((e) => e.id === chapter.id)
            if (found) {
                return true
            }
        }
        return false
    }
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                columnGap: '6px',
            }}
        >
            {/* Root */}
            <QBAssetBreadcrumbButton
                name="Home"
                url="/"
                //disabled={viewMode === QBDirectoryViewMode.BASIC}
                icon={<img src={ImageAsset.HomeTopIcon} alt="Home" />}
            />
            <QBAssetBreadcrumbButton
                name={questionBank?.name ?? ''}
                url={QBAssetLibraryURLGenerator({
                    viewMode: QBDirectoryViewMode.FOLDERS,
                    questionBankId: questionBank?.id!,
                })}
                disabled={viewMode === QBDirectoryViewMode.FOLDERS}
                icon={
                    <img
                        src={
                            viewMode === QBDirectoryViewMode.FOLDERS
                                ? ImageAsset.QuestionBankIcon
                                : ImageAsset.QuestionBankTopIcon
                        }
                        alt="QB"
                    />
                }
            />

            {folder !== null &&
                isBreadcrumbAllowToShow(
                    viewMode,
                    QBDirectoryViewMode.CHAPTERS
                ) &&
                isAllowToShowFolderBreadcrumb() && (
                    <Fragment>
                        <QBAssetBreadcrumbButton
                            name={folder.name}
                            url={QBAssetLibraryURLGenerator({
                                viewMode: QBDirectoryViewMode.CHAPTERS,
                                questionBankId: questionBank?.id!,
                                folderId: folder.id,
                            })}
                            disabled={viewMode === QBDirectoryViewMode.CHAPTERS}
                            icon={
                                <img
                                    src={
                                        viewMode ===
                                        QBDirectoryViewMode.CHAPTERS
                                            ? ImageAsset.FolderTopIcon
                                            : ImageAsset.FolderIcon
                                    }
                                    alt="Folder"
                                />
                            }
                        />
                    </Fragment>
                )}

            {chapter !== null &&
                isBreadcrumbAllowToShow(
                    viewMode,
                    QBDirectoryViewMode.QUESTION_TYPES
                ) && (
                    <Fragment>
                        <QBAssetBreadcrumbButton
                            name={chapter.name}
                            url={QBAssetLibraryURLGenerator({
                                viewMode:
                                    folder !== null
                                        ? QBDirectoryViewMode.QUESTION_TYPES
                                        : QBDirectoryViewMode.ROOT_CHAPTER,
                                questionBankId: questionBank?.id!,
                                chapterId: chapter.id,
                            })}
                            disabled={
                                viewMode ===
                                    QBDirectoryViewMode.QUESTION_TYPES ||
                                viewMode === QBDirectoryViewMode.ROOT_CHAPTER
                            }
                            icon={
                                <img
                                    src={
                                        viewMode ===
                                        QBDirectoryViewMode.QUESTION_TYPES
                                            ? ImageAsset.ChapterTopIcon
                                            : ImageAsset.ChapterIcon
                                    }
                                    alt="Chapter"
                                />
                            }
                        />
                    </Fragment>
                )}

            {questionType !== null &&
                isBreadcrumbAllowToShow(
                    viewMode,
                    QBDirectoryViewMode.QUESTION_GROUPS
                ) && (
                    <Fragment>
                        <QBAssetBreadcrumbButton
                            name={questionType.name}
                            url={QBAssetLibraryURLGenerator({
                                viewMode: QBDirectoryViewMode.QUESTION_GROUPS,
                                questionBankId: questionBank?.id!,
                                questionTypeId: questionType.id,
                            })}
                            disabled={
                                viewMode === QBDirectoryViewMode.QUESTION_GROUPS
                            }
                            icon={
                                <img
                                    src={
                                        viewMode ===
                                        QBDirectoryViewMode.QUESTION_GROUPS
                                            ? ImageAsset.QTypeTopIcon
                                            : ImageAsset.QuestionTypeIcon
                                    }
                                    alt="Type"
                                />
                            }
                        />
                    </Fragment>
                )}

            {questionGroup !== null &&
                isBreadcrumbAllowToShow(
                    viewMode,
                    QBDirectoryViewMode.QUESTIONS
                ) && (
                    <Fragment>
                        <QBAssetBreadcrumbButton
                            name={questionGroup.name}
                            url={QBAssetLibraryURLGenerator({
                                viewMode: QBDirectoryViewMode.QUESTIONS,
                                questionBankId: questionBank?.id!,
                                questionGroupId: questionGroup.id,
                            })}
                            disabled={
                                viewMode === QBDirectoryViewMode.QUESTIONS
                            }
                            icon={
                                <img
                                    src={
                                        viewMode ===
                                        QBDirectoryViewMode.QUESTIONS
                                            ? ImageAsset.QuestionGroupTopIcon
                                            : ImageAsset.QuestionGroupIcon
                                    }
                                    alt="Question Group"
                                />
                            }
                        />
                    </Fragment>
                )}
            {question !== null &&
                isBreadcrumbAllowToShow(
                    viewMode,
                    QBDirectoryViewMode.QUESTION
                ) && (
                    <Fragment>
                        <QBAssetBreadcrumbButton
                            name={question.id}
                            url={QBAssetLibraryURLGenerator({
                                viewMode: QBDirectoryViewMode.QUESTION,
                                questionBankId: questionBank?.id!,
                                questionId: question.id,
                            })}
                            disabled={viewMode === QBDirectoryViewMode.QUESTION}
                            icon={
                                <img
                                    src={ImageAsset.QuestionIcon}
                                    alt="Question"
                                />
                            }
                        />
                    </Fragment>
                )}
        </div>
    )
}

export default QBDirectoryViewBreadcrumb
