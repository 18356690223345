import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { FForm, FInputField, FText } from '@fantaskticedtechlimited/fui-complib'
import { FFontTypes } from '@fantaskticedtechlimited/fui-fontlib'
import { FIcon, FIconNames } from '@fantaskticedtechlimited/fui-iconlib'
import { useState } from 'react'
import useQBAPIService from '../../../../../apiService/questionBank'
import {
    AttachmentTypes,
    Folder,
    FolderCreateInput,
} from '../../../../../assets/type/backend.type'
import { FolderCreateFormProps } from './types'
import { styles } from './styles'
import { ReactFileUploader } from '@files-drop/react-uploader/lib/cjs'

export const FolderCreateForm = (props: FolderCreateFormProps) => {
    const qbAPIService = useQBAPIService()
    let defaultValue: FolderCreateInput = {
        questionBankId: props.questionBankId,
        name: 'Name',
        icon: null,
        orderSequence: 0,
    }
    const [folderCreateInput, setFolderCreateInput] =
        useState<FolderCreateInput>({
            ...defaultValue,
        })

    const handleFolderCreate = async (folderCreateInput: FolderCreateInput) => {
        try {
            const result = await qbAPIService.qbFolderCreateMutation({
                variables: {
                    folderCreateInput,
                },
            })
            if (result.data?.qbFolderCreate.isSuccess) {
                props.onSubmit &&
                    (await props.onSubmit(
                        result.data.qbFolderCreate.data as Folder
                    ))
                props.onClose && (await props.onClose())
            } else {
                console.error(
                    `Folder Create failed, Reason. ${JSON.stringify(result)}`
                )
                alert(
                    `Folder Create failed, Reason.  ${result.data?.qbFolderCreate.errorMessage}`
                )
            }
        } catch (error) {
            console.log('error', error)
            alert(`Folder Create failed, Error: ${JSON.stringify(error)}`)
        }
    }

    return (
        <FForm
            onClose={props.onClose}
            onSubmit={() => handleFolderCreate(folderCreateInput)}
            contentContainerStyle={{ alignItems: 'flex-start' }}
        >
            <FInputField
                autoFocus
                label="Folder Name"
                value={folderCreateInput.name}
                wordCount={100}
                onInput={(v) =>
                    setFolderCreateInput({
                        ...folderCreateInput,
                        name: v,
                    })
                }
            />
            <FInputField
                label="Order Sequence"
                value={String(folderCreateInput.orderSequence)}
                onInput={(v) =>
                    setFolderCreateInput({
                        ...folderCreateInput,
                        orderSequence: Number(v),
                    })
                }
            />
            <div className={styles.FolderCreateForm_IconRow_Div}>
                <FText font={FFontTypes.Large_Text()} children="Icon: " />
                {folderCreateInput.icon !== null ? (
                    <div className={styles.FolderCreateForm_IconDisplay_Div}>
                        <img
                            alt="icon"
                            src={folderCreateInput.icon?.fileURL}
                            style={{
                                width: '100px',
                                height: '100px',
                            }}
                        />
                        <FIcon
                            name={FIconNames.DELETE}
                            color={() => FLegacyColorTypes.SECONDARY_RED}
                            onClick={() => {
                                setFolderCreateInput({
                                    ...folderCreateInput,
                                    icon: null,
                                })
                            }}
                        />
                    </div>
                ) : (
                    <FText
                        font={FFontTypes.Text()}
                        color={FLegacyColorTypes.SECONDARY_RED}
                        style={{ fontStyle: 'italic' }}
                        children="No Icon"
                    />
                )}
            </div>
            <ReactFileUploader
                url={process.env.REACT_APP_UPLOAD_URL ?? ''}
                apiKey={process.env.REACT_APP_UPLOAD_TOKEN ?? ''}
                multiple={false}
                maxNumberOfFiles={1}
                accept=".jpg,.jpeg,.png,.gif"
                iconUploaded={folderCreateInput.icon !== null}
                onUploadedFiles={(files) => {
                    if (files.length === 1) {
                        const file = files[0]
                        setFolderCreateInput({
                            ...folderCreateInput,
                            icon: {
                                fileURL: file.fileURL,
                                filePath: file.filePath,
                                fileName: file.fileName,
                                originalFileName: file.originalFileName,
                                attachmentFileType: AttachmentTypes.ICON,
                                size: 0,
                                contentType: file.contentType,
                            },
                        })
                    }
                }}
                withDND
                withStatus
            />
        </FForm>
    )
}
