import { gql } from '@apollo/client'

export const QB_GROUP_UPDATE_BY_ID_MUTATION = gql`
    mutation qbQuestionGroupUpdateById(
        $questionGroupUpdateInput: QuestionGroupUpdateInput!
    ) {
        qbQuestionGroupUpdateById(
            questionGroupUpdateInput: $questionGroupUpdateInput
        ) {
            code
            isSuccess
            errorMessage
            data {
                id
                name
                questionTypeId
            }
        }
    }
`
