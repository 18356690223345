import { createContext, ElementType, useContext, useState } from 'react'
import { QuestionBank } from '../../assets/type/backend.type'
import { Language } from '../../assets/type/frontend.type'
import { UserSetting, UserSettingContextProps } from './type'

const UserSettingContext = createContext<UserSettingContextProps>({
    defaultLanguage: Language.en_US,
    currentUserSetting: {
        language: Language.en_US,
        questionBank: null,
    },
    setCurrentUserSetting: () => {},
    setLanguage: () => {},
    setQuestionBank: () => {},
})
export const useUserSettingContext = () => useContext(UserSettingContext)
export const WithUserSettingContext = (Component: ElementType) => {
    return function WithUserSettingContext(props: any) {
        const [currentUserSetting, setCurrentUserSetting] =
            useState<UserSetting>({
                language: Language.en_US,
                questionBank: null,
            })
        const setLanguage = (language: Language) => {
            let newSetting = { ...currentUserSetting }
            newSetting.language = language
            setCurrentUserSetting(newSetting)
        }
        const setQuestionBank = (questionBank: QuestionBank | null) => {
            let newSetting = { ...currentUserSetting }
            newSetting.questionBank = questionBank
            setCurrentUserSetting(newSetting)
        }
        const defaultContextValue: UserSettingContextProps = {
            defaultLanguage: Language.en_US,
            currentUserSetting: currentUserSetting,
            setCurrentUserSetting,
            setLanguage,
            setQuestionBank,
        }
        return (
            <UserSettingContext.Provider value={defaultContextValue}>
                <Component {...props} />
            </UserSettingContext.Provider>
        )
    }
}
