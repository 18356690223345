import { gql } from '@apollo/client'

export const GET_QUESTIONBANK_ARCHITECTURE_QUERY = gql`
    query getQuestionBankArchitectureById($questionBankId: String!) {
        questionBankGet(id: $questionBankId) {
            isSuccess
            errorMessage
            code
            data {
                id
                name
                subject
                examSystem
                folders {
                    id
                    orderSequence
                    name
                    isEnable
                    isFlagged
                    chapters {
                        id
                        orderSequence
                        name
                        questionTypes {
                            id
                            orderSequence
                            chapterId
                            name
                            questionGroups {
                                id
                                orderSequence
                                questionTypeId
                                name
                                chapterId
                                createAt
                                updateAt
                            }
                            createAt
                            updateAt
                        }
                        createAt
                        updateAt
                    }
                    createAt
                    updateAt
                }
                createAt
                updateAt
            }
        }
    }
`
