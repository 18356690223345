import { useStateSafe } from '../../../utils/useStateSafe'
import { UploadRuleCheck } from '../utils/DefaultRuleChecker'
import { UploaderComponentProps } from '../types'
import * as styles from './styles'

export const DefaultUploader = (props: UploaderComponentProps) => {
    const {
        // set default value
        multiple,
        accept,
        fileNamePrefix,
        onBeforeUpload,
        uploadHandler,
        onUploadedFiles,
        uploadStatusDisplayer,
        style,
        className,
        iconUploaded,
    } = props

    // Input related
    const [uploadInputKeyToRefresh, setUploadInputKeyToRefresh] =
        useStateSafe('')
    // Input Upload
    const forceRefreshInput = () => {
        setUploadInputKeyToRefresh(Math.random().toString(36))
    }

    const [isLoading, setIsLoading] = useStateSafe(false)
    const handleUpload = async (files: File[], fileNamePrefix: string) => {
        props.uploadStatus && setIsLoading(true)
        try {
            onBeforeUpload && onBeforeUpload(files)
            const finalList = await uploadHandler(files, fileNamePrefix)
            onUploadedFiles && (await onUploadedFiles(finalList))
        } catch (error) {
            console.error("handleUpload error", error)
        }
        setIsLoading(false)
    }
    const localIsLoading = props.uploadStatus
        ? props.uploadStatus.isUploading
        : isLoading

    //set file list after user select files, will prevent user upload more than max no. of files
    const handleInputChange = async (e: React.FormEvent<HTMLInputElement>) => {
        try {
            const { isPass, checkedFiles, errorMessage } = UploadRuleCheck(
                e.currentTarget.files,
                props
            )
            if (isPass) {
                await handleUpload(checkedFiles, fileNamePrefix)
            } else {
                return alert(errorMessage)
            }
            forceRefreshInput()
        } catch (error) {
            console.error('error', error)
        }
        forceRefreshInput()
    }

    return (
        <div
            style={style}
            className={
                styles.DefaultUploader_Container(iconUploaded!) +
                ' ' +
                className
            }
        >
            <input
                ref={props.innerRef}
                type="file"
                onChange={handleInputChange}
                // ref={fileInputRef}
                key={uploadInputKeyToRefresh || ''}
                multiple={multiple}
                accept={accept}
                disabled={localIsLoading}
                className={styles.DefaultUploader_Input_Container}
            />
            {uploadStatusDisplayer &&
                props.uploadStatus &&
                uploadStatusDisplayer(props.uploadStatus)}
        </div>
    )
}
