import { FOverrideStyle } from '@fantaskticedtechlimited/fui-complib'
import { style, stylesheet } from 'typestyle'

export const styles = stylesheet({
    TitleDiv: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
})

export const HeaderButton_Div = (isHover?: boolean) =>
    style({
        marginRight: '8px',
        backgroundColor: FOverrideStyle(
            isHover ? 'transparent' : 'transparent'
        ),
    })
