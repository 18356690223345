import { gql } from '@apollo/client'
import { PHOTO_DETAIL_FIELDS } from '../fragments/photoDetail.gql'

export const GET_CHAPTER_DETAIL_BY_ID_QUERY = gql`
    query getChapterDetailById($chapterId: String!) {
        chapterGet(id: $chapterId) {
            isSuccess
            code
            errorMessage
            data {
                id
                name
                questionBankId
                description
                isEnable
                isFlagged
                subject
                orderSequence
                icon {
                    ...photoDetail
                }
                questionTypes {
                    id
                    orderSequence
                    name
                    questionGroups {
                        id
                        orderSequence
                    }
                    createAt
                    updateAt
                }
                createAt
                updateAt
            }
        }
    }

    ${PHOTO_DETAIL_FIELDS}
`
