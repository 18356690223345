import {
    FScrollBarStyle,
    FDragAndDrop,
} from '@fantaskticedtechlimited/fui-complib'
import { useNavigate } from 'react-router-dom'
import { useQBAssetLibraryContext } from '../../../../../contexts/qbAssetLibraryContext'
import { QBDirectoryViewMode } from '../../../../../contexts/qbAssetLibraryContext/types'
import { useQuestionTypePopUpWrapperContext } from '../../../../QuestionBankContent/components/popUp/WithQuestionTypePopUpWrapper'
import { QBAssetDropdownMenu } from '../../../components/QBAssetDropdownMenu'
import QBAssetLibraryURLGenerator from '../../../components/QBAssetLibraryURLGenerator'
import { QBAssetListCardQuestionTypesContent } from '../../../components/QBAssetListCardContent/QuestionTypes'
import { styles } from '../styles'

export const QBDirectoryViewQuestionTypesBody = () => {
    const { chapter, onRefresh, handleRearrangeQuestionTypes } =
        useQBAssetLibraryContext()
    const navigate = useNavigate()
    const { onQuestionTypeUpdatePopUpOpen, onQuestionTypeDeletePopUpOpen } =
        useQuestionTypePopUpWrapperContext()

    return (
        <div
            className={
                styles.QBDirectoryView_ListWrapper + ' ' + FScrollBarStyle()
            }
        >
            <FDragAndDrop
                data={[...chapter!.questionTypes]}
                onUpdateData={async (newDatas) => {
                    await handleRearrangeQuestionTypes(newDatas)
                }}
                children={(questionType) => (
                    <>
                        <QBAssetListCardQuestionTypesContent
                            data={questionType}
                            onClick={() => {
                                navigate(
                                    QBAssetLibraryURLGenerator({
                                        viewMode:
                                            QBDirectoryViewMode.QUESTION_GROUPS,
                                        questionTypeId: questionType.id,
                                        questionBankId: chapter!.questionBankId,
                                    })
                                )
                            }}
                        />
                        <QBAssetDropdownMenu 
                            onEditInfo={() => {
                                onQuestionTypeUpdatePopUpOpen(
                                    questionType,
                                    async () => {
                                        await onRefresh()
                                    }
                                )
                            }} 
                            onDelete={() => {
                                onQuestionTypeDeletePopUpOpen(
                                    questionType,
                                    async () => {
                                        await onRefresh()
                                    }
                                )
                            }}
                        />
                    </>
                )}
            />
        </div>
    )
}
