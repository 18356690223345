import {
    createContext,
    ElementType,
    Fragment,
    useContext,
    useState,
} from 'react'
import { QuestionGroup } from '../../../../../assets/type/backend.type'
import { QuestionGroupCreateForm } from '../../form/QuestionGroupCreateForm'
import { QuestionGroupDeletePopUp } from '../QuestionGroupDeletePopUp'
import QuestionGroupMoreActionPopUp from '../QuestionGroupMoreActionPopUp'
import { QuestionGroupUpdateForm } from '../../form/QuestionGroupUpdateForm'
import { QuestionGroupPopUpWrapperProps } from './type'
import {
    FConfirmPopUp,
    FOnSubmitFunction,
} from '@fantaskticedtechlimited/fui-complib'

const QuestionGroupPopUpWrapperContext =
    createContext<QuestionGroupPopUpWrapperProps>({
        isQuestionGroupMoreActionPopUpOpen: false,
        onQuestionGroupMoreActionPopUpOpen: async () => {},

        isQuestionGroupCreatePopUpOpen: false,
        onQuestionGroupCreatePopUpOpen: async () => {},

        isQuestionGroupUpdatePopUpOpen: false,
        onQuestionGroupUpdatePopUpOpen: async () => {},

        isQuestionGroupDeletePopUpOpen: false,
        onQuestionGroupDeletePopUpOpen: async () => {},
    })
export const useQuestionGroupPopUpWrapperContext = () =>
    useContext(QuestionGroupPopUpWrapperContext)
export const WithQuestionGroupPopUpWrapper = (Component: ElementType) => {
    return function WithQuestionGroupPopUpWrapper(props: any) {
        // More Action PopUp
        const [moreActionData, setMoreActionData] = useState<{
            questionGroup: QuestionGroup
            onDeleteCallback: FOnSubmitFunction<QuestionGroup>
            onUpdateCallback: FOnSubmitFunction<QuestionGroup>
        } | null>(null)
        const [
            isQuestionGroupMoreActionPopUpOpen,
            setIsQuestionGroupMoreActionPopUpOpen,
        ] = useState(false)
        const handleQuestionGroupMoreActionPopUpOpen = async (
            _QuestionGroup: QuestionGroup,
            _callbackFunctions: {
                onUpdate: FOnSubmitFunction<QuestionGroup>
                onDelete: FOnSubmitFunction<QuestionGroup>
            }
        ) => {
            setIsQuestionGroupMoreActionPopUpOpen(true)
            setMoreActionData({
                questionGroup: _QuestionGroup,
                onDeleteCallback: _callbackFunctions.onDelete,
                onUpdateCallback: _callbackFunctions.onUpdate,
            })
        }
        const handleQuestionGroupMoreActionPopUpClose = () => {
            setIsQuestionGroupMoreActionPopUpOpen(false)
            setMoreActionData(null)
        }

        // QuestionGroup Create
        const [QuestionGroupCreateData, setQuestionGroupCreateData] = useState<{
            questionTypeId?: string
            chapterId: string
            callback: FOnSubmitFunction<QuestionGroup>
        } | null>(null)
        const [
            isQuestionGroupCreatePopUpOpen,
            setIsQuestionGroupCreatePopUpOpen,
        ] = useState(false)
        const handleQuestionGroupCreatePopUpOpen = async (
            data: { questionTypeId?: string; chapterId: string },
            _callbackFunction: FOnSubmitFunction<QuestionGroup>
        ) => {
            setQuestionGroupCreateData({
                ...data,
                callback: _callbackFunction,
            })
            setIsQuestionGroupCreatePopUpOpen(true)
        }
        const handleQuestionGroupCreatePopUpSubmit = async (
            newQuestionGroup: QuestionGroup
        ) => {
            QuestionGroupCreateData &&
                (await QuestionGroupCreateData.callback(newQuestionGroup))
        }
        const handleQuestionGroupCreatePopUpClose = () => {
            setIsQuestionGroupCreatePopUpOpen(false)
            setQuestionGroupCreateData(null)
        }

        // QuestionGroup Update
        const [questionGroupUpdateData, setQuestionGroupUpdateData] = useState<{
            questionGroup: QuestionGroup
            callback: FOnSubmitFunction<QuestionGroup>
        } | null>(null)
        const [
            isQuestionGroupUpdatePopUpOpen,
            setIsQuestionGroupUpdatePopUpOpen,
        ] = useState(false)
        const handleQuestionGroupUpdatePopUpOpen = async (
            _QuestionGroup: QuestionGroup,
            _callbackFunction: FOnSubmitFunction<QuestionGroup>
        ) => {
            setQuestionGroupUpdateData({
                questionGroup: _QuestionGroup,
                callback: _callbackFunction,
            })
            setIsQuestionGroupUpdatePopUpOpen(true)
        }
        const handleQuestionGroupUpdatePopUpSubmit = async (
            updatedQuestionGroup: QuestionGroup
        ) => {
            questionGroupUpdateData &&
                (await questionGroupUpdateData.callback(updatedQuestionGroup))
        }
        const handleQuestionGroupUpdatePopUpClose = () => {
            setIsQuestionGroupUpdatePopUpOpen(false)
            setQuestionGroupUpdateData(null)
        }

        // QuestionGroup Delete
        const [QuestionGroupDeleteData, setQuestionGroupDeleteData] = useState<{
            questionGroup: QuestionGroup
            callback: FOnSubmitFunction<QuestionGroup>
        } | null>(null)
        const [
            isQuestionGroupDeletePopUpOpen,
            setIsQuestionGroupDeletePopUpOpen,
        ] = useState(false)
        const handleQuestionGroupDeletePopUpOpen = async (
            _QuestionGroup: QuestionGroup,
            _callbackFunction: FOnSubmitFunction<QuestionGroup>
        ) => {
            setIsQuestionGroupDeletePopUpOpen(true)
            setQuestionGroupDeleteData({
                questionGroup: _QuestionGroup,
                callback: _callbackFunction,
            })
        }
        const handleQuestionGroupDeletePopUpClose = () => {
            setIsQuestionGroupDeletePopUpOpen(false)
            setQuestionGroupDeleteData(null)
        }

        // Context
        const defaultContextValue: QuestionGroupPopUpWrapperProps = {
            isQuestionGroupMoreActionPopUpOpen:
                isQuestionGroupMoreActionPopUpOpen,
            onQuestionGroupMoreActionPopUpOpen:
                handleQuestionGroupMoreActionPopUpOpen,

            isQuestionGroupCreatePopUpOpen: isQuestionGroupCreatePopUpOpen,
            onQuestionGroupCreatePopUpOpen: handleQuestionGroupCreatePopUpOpen,

            isQuestionGroupUpdatePopUpOpen: isQuestionGroupUpdatePopUpOpen,
            onQuestionGroupUpdatePopUpOpen: handleQuestionGroupUpdatePopUpOpen,

            isQuestionGroupDeletePopUpOpen: isQuestionGroupDeletePopUpOpen,
            onQuestionGroupDeletePopUpOpen: handleQuestionGroupDeletePopUpOpen,
        }

        return (
            <QuestionGroupPopUpWrapperContext.Provider
                value={defaultContextValue}
            >
                <Fragment>
                    <Component {...props} />

                    {/* More Action PopUp */}
                    {/* {isQuestionGroupMoreActionPopUpOpen &&
                        moreActionData !== null && (
                            <QuestionGroupMoreActionPopUp
                                onClose={() =>
                                    handleQuestionGroupMoreActionPopUpClose()
                                }
                                questionGroup={moreActionData.questionGroup}
                                onCallback={async (data) =>
                                    await moreActionData.onDeleteCallback(data)
                                }
                            />
                        )} */}

                    {/* QuestionGroup Create PopUp */}
                    {isQuestionGroupCreatePopUpOpen && QuestionGroupCreateData && (
                        <FConfirmPopUp
                            title="New QuestionGroup"
                            disableCloseWhenClickOutside
                            disableBottomBar
                            style={{ width: '30%', height: 'fit-content' }}
                        >
                            <QuestionGroupCreateForm
                                chapterId={QuestionGroupCreateData.chapterId}
                                questionTypeId={
                                    QuestionGroupCreateData.questionTypeId
                                }
                                onSubmitCallback={async (data) =>
                                    await handleQuestionGroupCreatePopUpSubmit(
                                        data
                                    )
                                }
                                onClose={handleQuestionGroupCreatePopUpClose}
                            />
                        </FConfirmPopUp>
                    )}

                    {/* Update QuestionGroup PopUp*/}
                    {isQuestionGroupUpdatePopUpOpen && questionGroupUpdateData && (
                        <FConfirmPopUp
                            title="Edit QuestionGroup"
                            disableCloseWhenClickOutside
                            disableBottomBar
                            style={{ width: '30%', height: 'fit-content' }}
                        >
                            <QuestionGroupUpdateForm
                                questionGroup={
                                    questionGroupUpdateData.questionGroup
                                }
                                onClose={handleQuestionGroupUpdatePopUpClose}
                                onSubmitCallback={async (data) =>
                                    await handleQuestionGroupUpdatePopUpSubmit(
                                        data
                                    )
                                }
                            />
                        </FConfirmPopUp>
                    )}

                    {/* QuestionGroup Delete PopUp */}
                    {isQuestionGroupDeletePopUpOpen &&
                        QuestionGroupDeleteData !== null && (
                            <QuestionGroupDeletePopUp
                                onClose={handleQuestionGroupDeletePopUpClose}
                                questionGroup={
                                    QuestionGroupDeleteData?.questionGroup
                                }
                                onCallback={async (data) =>
                                    await QuestionGroupDeleteData.callback(data)
                                }
                            />
                        )}
                </Fragment>
            </QuestionGroupPopUpWrapperContext.Provider>
        )
    }
}
