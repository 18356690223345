import { gql } from '@apollo/client'

export const GET_CONCEPT_CARDS_BY_FILTER = gql`
    query getConceptCardsByFilter(
        $conceptCardFilterInput: ConceptCardFilterInput
    ) {
        conceptCardsGet(conceptCardFilterInput: $conceptCardFilterInput) {
            isSuccess
            code
            errorMessage
            data {
                id
                name
                chapterId
                questionBankId
                subject
                orderSequence
                createAt
                updateAt
            }
        }
    }
`
