import { useMutation } from '@apollo/client'
import {
    UMFirebaseEmailPasswordUserCreateInput,
    UserArrayFilterInput,
    UserSafeArrayOutput,
    UserSafeOutput,
} from '../../assets/type/backend.type'
import { useCustomLazyQuery } from '../../utils/useCustomLazyQuery'
import { UM_FIREBASE_EMAIL_PASSWORD_USER_CREATE_MUTATION } from './mutation/umFirebaseEmailPasswordUserCreate.gql'
import { UM_USER_DELETE_BY_ID_MUTATION } from './mutation/umUserDeleteById.gql'
import { GET_USERS_DETAIL_BY_FILTER_QUERY } from './query/getUsersByFilter.gql'

const useUserManagementAPIService = () => {
    // Get Users By Filter
    const getUsersDetailByFilter = useCustomLazyQuery<
        { umUserGets: UserSafeArrayOutput },
        { userArrayFilterInput: UserArrayFilterInput }
    >(GET_USERS_DETAIL_BY_FILTER_QUERY)

    // Firebase Email Password User Create
    const [umFirebaseEmailPasswordUserCreate] = useMutation<
        { umFirebaseEmailPasswordUserCreate: UserSafeOutput },
        {
            umFirebaseEmailPasswordUserCreateInput: UMFirebaseEmailPasswordUserCreateInput
        }
    >(UM_FIREBASE_EMAIL_PASSWORD_USER_CREATE_MUTATION)

    // Delete User By Id
    const [umUserDeleteById] = useMutation<
        { umUserDeleteById: UserSafeOutput },
        {
            userId: string
        }
    >(UM_USER_DELETE_BY_ID_MUTATION)

    return {
        // user management
        getUsersDetailByFilter,
        umFirebaseEmailPasswordUserCreate,
        umUserDeleteById,
    }
}

export default useUserManagementAPIService
