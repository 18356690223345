import { QBAssetListCardQBContentProps } from './types'
import { styles } from './styles'
import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { FText } from '@fantaskticedtechlimited/fui-complib'
import { FFontTypes } from '@fantaskticedtechlimited/fui-fontlib'
import QBAssetListCardIconBar from '../QBAssetListCardIconBar'
import { QBAssetListCardIconBarProps } from '../QBAssetListCardIconBar/types'

export const QBAssetListCardQBContent = (
    props: QBAssetListCardQBContentProps
) => {
    const data = props.data
    const folderData = data.folders
    const iconBarProps: Partial<QBAssetListCardIconBarProps> = {
        folderNumber: folderData.length,
        chapterNumber: folderData.reduce(
            (total, currentFolder) =>
                (total = total + currentFolder.chapters.length),
            0
        ),
        questionTypeNumber: folderData.reduce(
            (total, currentFolder) =>
                (total =
                    total +
                    currentFolder.chapters.reduce(
                        (final, currentChapter) =>
                            (final =
                                final + currentChapter.questionTypes.length),
                        0
                    )),
            0
        ),
        questionGroupsNumber: folderData.reduce(
            (total, currentFolder) =>
                (total =
                    total +
                    currentFolder.chapters.reduce(
                        (final, currentChapter) =>
                            (final =
                                final +
                                currentChapter.questionTypes.reduce(
                                    (total2, currentTypes) =>
                                        (total2 =
                                            total2 +
                                            currentTypes.questionGroups.length),
                                    0
                                )),
                        0
                    )),
            0
        ),
        questionsNumber: 0,
    }

    return (
        <div className={styles.CardContent_Container} onClick={props.onClick}>
            <div className={styles.CardContent_TitleDiv}>
                <FText
                    maxRows={2}
                    overflowHidden
                    font={FFontTypes.Large_Text()}
                >
                    {data.name}
                </FText>
                <div className={styles.CardContent_InfoDiv}>
                    <FText
                        font={FFontTypes.Small_Text({ fontWeight: 500 })}
                        color={FLegacyColorTypes.PRIMARY_GREY}
                    >
                        {`${data.examSystem}-${data.subject}`}
                    </FText>
                    <FText
                        font={FFontTypes.Small_Text({ fontWeight: 500 })}
                        color={FLegacyColorTypes.PRIMARY_GREY}
                    >
                        {data.examSystem}
                    </FText>
                    <FText
                        font={FFontTypes.Small_Text({ fontWeight: 500 })}
                        color={FLegacyColorTypes.PRIMARY_GREY}
                    >
                        {data.subject}
                    </FText>
                </div>
            </div>
            <QBAssetListCardIconBar {...iconBarProps} />
        </div>
    )
}
