import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { FOnClickFunction } from '@fantaskticedtechlimited/fui-complib'
import { FIcon, FIconNames } from '@fantaskticedtechlimited/fui-iconlib'
import { useEffect, useState } from 'react'
import useQBAPIService from '../../../../../apiService/questionBank'
import { Question } from '../../../../../assets/type/backend.type'

import { useQuestionPopUpWrapperContext } from '../../../../QuestionBankContent/components/popUp/WithQuestionPopUpWrapper'
import QuestionPreview from '../../../../QuestionBankContent/components/Question/QuestionPreview'
import { ArrowOpenCloseIcon } from '../ArrowOpenCloseIcon'

interface ArchiQuestionCardProps {
    questionSimple: Question
    questionBankId: string
    isRootOpen?: boolean
    onGlobalUpdate?: FOnClickFunction
}
export const ArchiQuestionCard = (props: ArchiQuestionCardProps) => {
    const { onQuestionMoreActionPopUpOpen } = useQuestionPopUpWrapperContext()

    const [isLoading, setIsLoading] = useState(false)
    const qbAPIService = useQBAPIService()
    const { questionSimple, isRootOpen } = props
    const [isOpenQuestion, setIsOpenQuestion] = useState(false)
    useEffect(() => {
        if (!isRootOpen) {
            setIsOpenQuestion(false)
        } else {
            setIsOpenQuestion(true)
        }
    }, [isRootOpen])

    const [questionDetail, setQuestionDetail] = useState<Question | null>(null)
    const handleGetQuestionDetailById = async (questionId: string) => {
        setIsLoading(true)
        try {
            const result = await qbAPIService.getQuestionDetailById({
                questionId,
            })
            const { questionGet } = result.data
            setQuestionDetail(
                questionGet.isSuccess ? questionGet.data || null : null
            )
        } catch (error) {
            console.log('error', error)
            setQuestionDetail(null)
        }
        setIsLoading(false)
    }
    return (
        <div>
            {/* question title */}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    columnGap: '10px',
                }}
            >
                <ArrowOpenCloseIcon
                    isOpen={isOpenQuestion}
                    onOpen={() => {
                        console.log('open arrow')
                        setIsOpenQuestion(true)
                        if (questionDetail === null) {
                            handleGetQuestionDetailById(questionSimple.id)
                        }
                    }}
                    onClose={() => {
                        console.log('close arrow')
                        setIsOpenQuestion(false)
                    }}
                />
                {`Question: `}
                {questionSimple.id}

                {/* More Action button */}
                <FIcon
                    name={FIconNames.MORE}
                    size="small"
                    color={() => FLegacyColorTypes.PRIMARY_GREY}
                    onClick={() => {
                        onQuestionMoreActionPopUpOpen(questionSimple, {
                            onDelete: async () => {
                                props.onGlobalUpdate &&
                                    (await props.onGlobalUpdate())
                            },
                            onUpdate: async () => {
                                props.onGlobalUpdate &&
                                    (await props.onGlobalUpdate())
                            },
                        })
                    }}
                />
                {isLoading && `   Loading ...`}
            </div>

            {/* Chapters */}
            {isOpenQuestion && questionDetail && (
                <div style={{ marginLeft: '96px' }}>
                    <QuestionPreview question={questionDetail} />
                </div>
            )}
        </div>
    )
}
