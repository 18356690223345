import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { QBDirectoryViewHeader } from '..'
import { useQBAssetLibraryContext } from '../../../../../contexts/qbAssetLibraryContext'
import { QBDirectoryViewMode } from '../../../../../contexts/qbAssetLibraryContext/types'
import { useLoadingPageWrapperContext } from '../../../../../layout/WithLoadingPageWrapper'
import { useQuestionPopUpWrapperContext } from '../../../../QuestionBankContent/components/popUp/WithQuestionPopUpWrapper'
import QBAssetLibraryURLGenerator from '../../../components/QBAssetLibraryURLGenerator'

export const QBDirectoryViewQuestionsHeader = () => {
    const { questionGroup, onRefresh } = useQBAssetLibraryContext()
    const { onQuestionCreatePopUpOpen } = useQuestionPopUpWrapperContext()
    const { setLoadingStart, setLoadingEnd } = useLoadingPageWrapperContext()
    const navigate = useNavigate()

    useEffect(() => {
        if (questionGroup === null) setLoadingStart()
        else setLoadingEnd()
    }, [questionGroup])

    return (
        <QBDirectoryViewHeader
            onBackButtonClick={() =>
                navigate(
                    QBAssetLibraryURLGenerator({
                        viewMode: QBDirectoryViewMode.QUESTION_GROUPS,
                        questionBankId: questionGroup?.questionBankId!,
                        questionTypeId: questionGroup?.questionTypeId,
                    })
                )
            }
            title={questionGroup?.name!}
            CTAButtonLabel="+  Add Question"
            onCTAButtonClick={() =>
                onQuestionCreatePopUpOpen(
                    {
                        questionBankId: questionGroup?.questionBankId!,
                        presetChapterId: questionGroup?.chapterId,
                        presetQuestionTypeId: questionGroup?.questionTypeId,
                        presetQuestionGroupId: questionGroup?.id,
                    },
                    async () => {
                        await onRefresh()
                    }
                )
            }
        />
    )
}
