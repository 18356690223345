import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { FOnClickFunction } from '@fantaskticedtechlimited/fui-complib'
import { FIcon, FIconNames } from '@fantaskticedtechlimited/fui-iconlib'
// import { group } from "console";
import { useState, useEffect } from 'react'
import useQBAPIService from '../../../../../apiService/questionBank'
import {
    QuestionGroup,
    Question,
} from '../../../../../assets/type/backend.type'
import { useQuestionGroupPopUpWrapperContext } from '../../../../QuestionBankContent/components/popUp/WithQuestionGroupPopUpWrapper'
import { useQuestionPopUpWrapperContext } from '../../../../QuestionBankContent/components/popUp/WithQuestionPopUpWrapper'
import { ArchiQuestionCard } from '../ArchiQuestionCard'
import { ArrowOpenCloseIcon } from '../ArrowOpenCloseIcon'

interface ArchiQuestionGroupCardProps {
    questionBankId: string
    questionGroup: QuestionGroup
    isRootOpen?: boolean
    onGlobalUpdate?: FOnClickFunction
}
export const ArchiQuestionGroupCard = (props: ArchiQuestionGroupCardProps) => {
    const { onQuestionCreatePopUpOpen } = useQuestionPopUpWrapperContext()
    const { onQuestionGroupMoreActionPopUpOpen } =
        useQuestionGroupPopUpWrapperContext()
    const [isLoading, setIsLoading] = useState(false)
    const qbAPIService = useQBAPIService()
    const { questionGroup, isRootOpen, questionBankId } = props
    const [isOpenQuestionGroup, setIsOpenQuestionGroup] = useState(false)
    const [questions, setQuestions] = useState<Question[]>([])
    const handleGetQuestionByQuestionGroup = async (id: string) => {
        setIsLoading(true)
        try {
            const result = await qbAPIService.getQuestionsByFilter({
                questionFilterInput: {
                    questionGroupId: id,
                },
            })
            const { questionsGet } = result.data
            setQuestions(questionsGet.isSuccess ? questionsGet.data! : [])
        } catch (error) {
            alert(JSON.stringify(error))
            setQuestions([])
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (!isRootOpen) {
            setIsOpenQuestionGroup(false)
        } else {
            setIsOpenQuestionGroup(true)
        }
    }, [isRootOpen])

    useEffect(() => {
        if (isOpenQuestionGroup && questionGroup && questionGroup.id) {
            handleGetQuestionByQuestionGroup(questionGroup.id)
        }
    }, [isOpenQuestionGroup, questionGroup])
    return (
        <div>
            {/* QuestionGroup title */}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    columnGap: '10px',
                }}
            >
                <ArrowOpenCloseIcon
                    isOpen={isOpenQuestionGroup}
                    onOpen={() => {
                        console.log('open arrow')
                        setIsOpenQuestionGroup(true)
                    }}
                    onClose={() => {
                        console.log('close arrow')
                        setIsOpenQuestionGroup(false)
                    }}
                />
                <span>QuestionGroup:</span>
                {questionGroup.name}
                {/* {!isOpenQuestionGroup && `	(${QuestionGroup.questions?.length ?? 0})`} */}
                <button
                    onClick={() => {
                        onQuestionCreatePopUpOpen(
                            {
                                questionBankId: props.questionBankId,
                                presetChapterId: questionGroup.chapterId,
                                presetQuestionGroupId: questionGroup.id,
                                presetQuestionTypeId:
                                    questionGroup.questionTypeId,
                            },
                            async () =>
                                // handleGetQuestionTypesByChapterId(props?.chapter.id)
                                {
                                    if (
                                        isOpenQuestionGroup &&
                                        questionGroup &&
                                        questionGroup.id
                                    ) {
                                        handleGetQuestionByQuestionGroup(
                                            questionGroup.id
                                        )
                                    }
                                }
                        )
                    }}
                >
                    +
                </button>
                {/* More Action button */}
                <FIcon
                    name={FIconNames.MORE}
                    size="small"
                    color={() => FLegacyColorTypes.PRIMARY_GREY}
                    onClick={() => {
                        onQuestionGroupMoreActionPopUpOpen(questionGroup, {
                            onDelete: async () => {
                                props.onGlobalUpdate &&
                                    (await props.onGlobalUpdate())
                            },
                            onUpdate: async () => {
                                props.onGlobalUpdate &&
                                    (await props.onGlobalUpdate())
                            },
                        })
                    }}
                />
                {isLoading && `   Loading ...`}
            </div>

            {/* Question */}
            {isOpenQuestionGroup && (
                <div style={{ marginLeft: '48px' }}>
                    {(questions ?? []).map((question, i) => {
                        return (
                            <ArchiQuestionCard
                                questionBankId={questionBankId}
                                isRootOpen={isRootOpen}
                                questionSimple={question}
                                key={i}
                                onGlobalUpdate={() => {
                                    handleGetQuestionByQuestionGroup(
                                        questionGroup.id
                                    )
                                }}
                            />
                        )
                    })}
                </div>
            )}
        </div>
    )
}
