import { gql } from '@apollo/client'

export const QB_CHAPTERS_REARRANGE_BY_IDS_MUTATION = gql`
    mutation qbChaptersRearrangeByIds(
        $chaptersRearrangeInput: ChaptersRearrangeInput!
    ) {
        qbChaptersRearrangeByIds(
            chaptersRearrangeInput: $chaptersRearrangeInput
        ) {
            isSuccess
            code
            errorMessage
            data {
                id
                orderSequence
            }
        }
    }
`
