import { style } from 'typestyle'
import { SpecifiedClassNames } from '../../../../Components/FCreateSelect/types'

export const CustomTag_Container = style({
    $nest: {
        [SpecifiedClassNames.RENDERED_COMPONENT_WRAPPER]: {
            boxShadow: 'none',
            columnGap: 0,
            padding: 0,
        },
        [SpecifiedClassNames.RENDERED_COMPONENT_CONTENT_CONTAINER]: {
            $nest: {
                div: {
                    padding: '4px 8px',
                    $nest: {
                        div: {
                            padding: 0,
                        },
                    },
                },
            },
        },
    },
})
