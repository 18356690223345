import { FSelect } from '@fantaskticedtechlimited/fui-complib'
import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'
import useQBAPIService from '../../apiService/questionBank'
import { QuestionBank } from '../../assets/type/backend.type'
import { useUserSettingContext } from '../../contexts/userSettingContext'

import useMount from '../../utils/useMount'
import { useStateSafe } from '../../utils/useStateSafe'
import { QBSelectDropDownProps } from './type'

function QBSelectDropDown(props: QBSelectDropDownProps) {
    const QBAPI = useQBAPIService()
    const [searchParams, setSearchParams] = useSearchParams()
    const { currentUserSetting, setQuestionBank } = useUserSettingContext()
    const [questionBankBasicList, setQuestionBankBasicList] = useStateSafe<
        QuestionBank[]
    >([])

    const handleSelectedQuestionBank = (value: QuestionBank | null) =>
        setQuestionBank(value)

    const handleGetQuestionBank = useCallback(async () => {
        try {
            const QBResult = await QBAPI.getQuestionBanksByFilter({})
            if (QBResult.data.questionBanksGet.isSuccess) {
                const _questionBanks = QBResult.data.questionBanksGet.data || []
                setQuestionBankBasicList(_questionBanks)
                if (props.presetQuestionBankId) {
                    const found = _questionBanks.find(
                        (e) => e.id === props.presetQuestionBankId
                    )
                    if (found) {
                        handleSelectedQuestionBank(found)
                    } else {
                        searchParams.delete('questionBankId')
                        setSearchParams(searchParams, { replace: true })
                    }
                }
            }
        } catch (error) {}
    }, [QBAPI, setQuestionBankBasicList])

    useMount(() => {
        handleGetQuestionBank()
    })

    return (
        <FSelect
            label={`QuestionBank`}
            showLabelOnly
            // placeholder={"sadsads"}
            options={questionBankBasicList || []}
            onSelect={handleSelectedQuestionBank}
            selectedOptions={currentUserSetting.questionBank}
            renderOptionNameOnly={(qb) => qb.name}
            // renderCustomizedSelectedOption={(qb: QuestionBank) => {
            // 	return (
            // 		<FText font={FFontTypes.Text()}>{`${ls.getLocaleString(qb.name)}13221321`}</FText>
            // 	);
            // }}
            onClear={() => handleSelectedQuestionBank(null)}
        />
    )
}

export default QBSelectDropDown
