import {
    FScrollBarStyle,
    FDragAndDrop,
} from '@fantaskticedtechlimited/fui-complib'
import { useNavigate } from 'react-router-dom'
import { useQBAssetLibraryContext } from '../../../../../contexts/qbAssetLibraryContext'
import { QBDirectoryViewMode } from '../../../../../contexts/qbAssetLibraryContext/types'
import { useQuestionGroupPopUpWrapperContext } from '../../../../QuestionBankContent/components/popUp/WithQuestionGroupPopUpWrapper'
import { QBAssetDropdownMenu } from '../../../components/QBAssetDropdownMenu'
import QBAssetLibraryURLGenerator from '../../../components/QBAssetLibraryURLGenerator'
import { QBAssetListCardQuestionGroupsContent } from '../../../components/QBAssetListCardContent/QuestionGroups'
import { styles } from '../styles'

export const QBDirectoryViewQuestionGroupsBody = () => {
    const { questionType, onRefresh, handleRearrangeQuestionGroups } =
        useQBAssetLibraryContext()
    const navigate = useNavigate()
    const { onQuestionGroupUpdatePopUpOpen, onQuestionGroupDeletePopUpOpen } =
        useQuestionGroupPopUpWrapperContext()

    return (
        <div
            className={
                styles.QBDirectoryView_ListWrapper + ' ' + FScrollBarStyle()
            }
        >
            <FDragAndDrop
                data={[...questionType!.questionGroups]}
                onUpdateData={async (newDatas) =>
                    await handleRearrangeQuestionGroups(newDatas)
                }
                children={(questionGroup) => (
                    <>
                        <QBAssetListCardQuestionGroupsContent
                            data={questionGroup}
                            onClick={() => {
                                navigate(
                                    QBAssetLibraryURLGenerator({
                                        viewMode: QBDirectoryViewMode.QUESTIONS,
                                        questionGroupId: questionGroup.id,
                                        questionBankId:
                                            questionType!.questionBankId!,
                                    })
                                )
                            }}
                        />
                        <QBAssetDropdownMenu 
                            onEditInfo={() => {
                                onQuestionGroupUpdatePopUpOpen(
                                    questionGroup,
                                    async () => {
                                        await onRefresh()
                                    }
                                )
                            }} 
                            onDelete={() => {
                                onQuestionGroupDeletePopUpOpen(
                                    questionGroup,
                                    async () => {
                                        await onRefresh()
                                    }
                                )
                            }}
                        />
                    </>
                )}
            />
        </div>
    )
}
