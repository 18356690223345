import { gql } from '@apollo/client'

export const GET_QUESTION_TYPE_DETAIL_BY_ID_QUERY = gql`
    query getQuestionTypeDetailById($questionTypeId: String!) {
        questionTypeGet(id: $questionTypeId) {
            code
            errorMessage
            isSuccess
            data {
                id
                orderSequence
                name
                questions {
                    id
                }
                chapterId
                chapter {
                    id
                    name
                }
                questionGroups {
                    id
                    orderSequence
                    name
                    questions {
                        id
                    }
                    createAt
                    updateAt
                }
                questionBankId
                createAt
                updateAt
            }
        }
    }
`
