import * as globalStyles from './assets/styles/global.styles'
import './assets/styles/global.styles.css'
import ScreenSize from './utils/screensize'
import { BrowserRouter as Router } from 'react-router-dom'
import {
    MainMenuRoutes,
    FooterMenuRoutes,
    AfterAuthRoutes,
    BeforeAuthRoutes,
    AfterAuthMainMenuRoutes,
} from './route'
import { PageRoutesRender } from './layout/PageRoutesRender'
import { Fragment } from 'react'
import { WithApolloGQLContext } from './contexts/apolloGQLContext'
import { useAuthContext, WithAuthContext } from './contexts/authContext'
import { WithUserSettingContext } from './contexts/userSettingContext'
import RWDNavBar from './layout/SideBar'
import {
    FRWDMode,
    FTheme,
    FThemeMode,
    WithFUIThemeContext,
} from '@fantaskticedtechlimited/fui-complib'
import { WithFirebaseMessagingContext } from './contexts/firebaseMessagingContext'
import { WithPasswordProtectorWrapper } from './layout/WithPasswordProtector'
import 'antd/dist/antd.css'
import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'

function App() {
    const { isSignin, currentUser } = useAuthContext()
    const [width] = ScreenSize() 
    const rwdMode =
        width < 720
            ? FRWDMode.MOBILE
            : width < 1280
            ? FRWDMode.TABLET
            : FRWDMode.FULL

    return (
        <Fragment>
            <Router>
                <div
                    className={globalStyles.GlobalContainer(
                        rwdMode !== FRWDMode.FULL
                    )}
                >
                    {isSignin ? (
                        <Fragment>
                            <RWDNavBar
                                rwdMode={rwdMode}
                                mainMenuRoutes={AfterAuthMainMenuRoutes(
                                    currentUser?.roles ?? []
                                )}
                                footerMenuRoutes={FooterMenuRoutes}
                            />
                            <div className={globalStyles.RootPageContainer}>
                                <div className={globalStyles.ContentContainer}>
                                    {/* <TopNav routes={AfterAuthRoutes} /> */}
                                    <PageRoutesRender
                                        routes={AfterAuthRoutes(
                                            currentUser?.roles ?? []
                                        )}
                                        redirectPathIfNotExist="/notFound"
                                    />
                                </div>
                            </div>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <div className={globalStyles.FullPageContainer}>
                                <PageRoutesRender routes={BeforeAuthRoutes} />
                            </div>
                        </Fragment>
                    )}
                </div>
            </Router>
        </Fragment>
    )
}

const projectTheme: FTheme = {
    themeMode: FThemeMode.LIGHT,
    themeColors: {
        mainThemeColor: {
            lightMode: '#483FE2',
            darkMode: '',
        },
        subThemeColor: {
            lightMode: FLegacyColorTypes.DEFAULT_SUB_THEME,
            darkMode: '',
        },
    },
}

export default WithPasswordProtectorWrapper(
    WithFirebaseMessagingContext(
        WithApolloGQLContext(
            WithAuthContext(
                WithUserSettingContext(WithFUIThemeContext(App, projectTheme))
            )
        )
    )
)
