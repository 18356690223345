import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { FUseTheme } from '@fantaskticedtechlimited/fui-complib'
import { style } from 'typestyle'

export const MenuListDiv = (isAutoExpand: boolean) =>
    style({
        display: 'flex',
        flexDirection: 'column',
        flexGrow: isAutoExpand ? 1 : 0,
        justifyContent: 'center',
        rowGap: '0.25rem',
    })

export const SubMenuList = (isIncluded: boolean, depth: number) =>
    style({
        paddingLeft: `${0.75 * depth}rem`,
        display: isIncluded || depth === 0 ? 'visible' : 'none',
    })

export const LinkButtonDiv = (isSelected: boolean, isFooter: boolean) =>
    style({
        width: isFooter ? 'fit-content' : '100%',
        padding: isFooter ? 0 : undefined,
        backgroundColor:
            isSelected && !isFooter
                ? FLegacyColorTypes.PRIMARY_WHITE
                : 'transparent  !important',
        borderRadius: isFooter ? 0 : undefined,
        borderBottom:
            isFooter && isSelected
                ? '0.125rem solid ' + FLegacyColorTypes.PRIMARY_WHITE
                : '0.125rem solid transparent',
        $nest: {
            '&:hover': {
                backgroundColor: !isFooter
                    ? isSelected
                        ? undefined
                        : 'rgba(255, 255, 255, 0.24) !important'
                    : undefined,
                borderBottom:
                    isFooter && !isSelected
                        ? '0.125rem solid ' +
                          FLegacyColorTypes.PRIMARY_WHITE +
                          ' !important'
                        : undefined,
            },
        },
    })

export const useMenuButtonStyle = (isSelected: boolean) => {
    const { theme } = FUseTheme()
    return style({
        padding: '8px !important',
        backgroundColor: isSelected
            ? `${theme.themeColors.subThemeColor.lightMode} !important`
            : '',
    })
}

export const iconButton = (isSelected: boolean, isHover: boolean) => {
    const color =
        isSelected || isHover
            ? FLegacyColorTypes.DEFAULT_MAIN_THEME
            : FLegacyColorTypes.PRIMARY_GREY
    return color
}

export const labelButton = style({
    fontSize: '14px !important',
    fontFamily: 'Montserrat !important',
    fontWeight: 500,
    $nest: {
        '&:hover': {
            // backgroundColor: "#8095ff"
            color: `${FLegacyColorTypes.DEFAULT_MAIN_THEME} !important`,
        },
    },
})
