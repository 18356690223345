import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { FTheme } from '@fantaskticedtechlimited/fui-complib'
import { FFontTypes } from '@fantaskticedtechlimited/fui-fontlib'
import { style } from 'typestyle'

export const FMarkdownEditorCore_Wrapper = style({
    display: 'flex',
    flexDirection: 'column',
    rowGap: '8px',
    // height: '100%',
    width: '100%',
})

export const FMarkdownEditorCore_ToolBar_Container = style({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: '6px',
    width: '100%',
})

export const FMarkdownEditorCore_InputField_Container = (theme: FTheme) =>
    style({
        backgroundColor: 'transparent',
        border: '2px solid ' + theme.themeColors.mainThemeColor.lightMode,
        borderRadius: 8,
        color: FLegacyColorTypes.PRIMARY_BLACK,
        font: FFontTypes.Large_Text({
            fontWeight: 300,
        }),
        outline: 'none',
        overflowX: 'hidden',
        padding: '8px',
        resize: 'none',
        wordBreak: 'break-all',
        $nest: {
            '&::placeholder': {
                color: FLegacyColorTypes.PRIMARY_GREY,
            },
            '&::-webkit-scrollbar': {
                display: 'block',
                width: '0.25rem',
                height: '0.25rem',
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: '0.625rem',
                backgroundColor: 'transparent',
                borderWidth: '0.0625rem',
                borderStyle: 'solid',
                borderColor: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: '0.625rem',
                backgroundColor: FLegacyColorTypes.PRIMARY_GREY,
                borderWidth: '0.0625rem',
                borderStyle: 'solid',
                borderColor: FLegacyColorTypes.PRIMARY_GREY,
            },
            '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: FLegacyColorTypes.PRIMARY_DARK,
                borderWidth: '0.0625rem',
                borderStyle: 'solid',
                borderColor: FLegacyColorTypes.PRIMARY_DARK,
            },
        },
    })
