import {
    FButton,
    FRWDMode,
    FRWDNavBar,
    FText,
} from '@fantaskticedtechlimited/fui-complib'
import { FFontTypes } from '@fantaskticedtechlimited/fui-fontlib'
import { Fragment } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { MenuButtonList } from './menuButtonList'
import { SideBarProps } from './types'
import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import * as styles from './styles'
import { ImageAsset } from '../../assets/images'
import { FIcon, FIconNames } from '@fantaskticedtechlimited/fui-iconlib'
import QBSelectDropDown from '../../Components/QBSelectDropDown'
import { useAuthContext } from '../../contexts/authContext'

function RWDNavBar(props: SideBarProps) {
    const { signOut } = useAuthContext()
    const navigate = useNavigate()
    return (
        <Fragment>
            <FRWDNavBar
                rwdMode={props.rwdMode}
                overlayEnterSide="right"
                visible={true}
                logo={
                    <Link
                        to="/"
                        style={{
                            textDecoration: 'none',
                            display: 'flex',
                            flexDirection:
                                props.rwdMode === FRWDMode.FULL
                                    ? 'column'
                                    : 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            rowGap:
                                props.rwdMode === FRWDMode.FULL
                                    ? '8px'
                                    : undefined,
                            columnGap:
                                props.rwdMode !== FRWDMode.FULL
                                    ? '8px'
                                    : undefined,
                        }}
                    >
                        <img
                            src={ImageAsset.logo}
                            style={{
                                width: '75px',
                            }}
                            alt="logo"
                        />
                        <FText
                            font={FFontTypes.Small_Title({
                                fontWeight: 300,
                                fontFamily: 'Montserrat',
                            })}
                            color={FLegacyColorTypes.PRIMARY_BLACK}
                            style={{}}
                        >
                            QB Portal
                        </FText>
                    </Link>
                }
                topBarStyle={{
                    height: '3.5rem',
                    backgroundColor: FLegacyColorTypes.PRIMARY_WHITE,
                }}
                containerClassName={styles.sideNavbar}
                menuIconProps={{
                    color: () => FLegacyColorTypes.SECONDARY_ORANGE,
                    style: () => ({
                        backgroundColor: 'transparent',
                        padding: 0,
                    }),
                }}
                closeIconProps={{
                    color: () => FLegacyColorTypes.SECONDARY_ORANGE,
                    style: () => ({
                        backgroundColor: 'transparent',
                        padding: 0,
                        alignSelf: 'flex-end',
                    }),
                }}
            >
                {/* <QBSelectDropDown /> */}
                <MenuButtonList routes={props.mainMenuRoutes} autoExpand />
                <MenuButtonList routes={props.footerMenuRoutes} isFooter />
                <FButton
                    onClick={() => {
                        signOut()
                        navigate('/login', { replace: true })
                    }}
                    label="Logout"
                    labelStyle={() => ({
                        color: FLegacyColorTypes.PRIMARY_GREY,
                        fontSize: '14px',
                    })}
                    style={() => ({
                        alignSelf: 'flex-start',
                        width: '100%',
                        padding: '8px',
                        columnGap: '8px',
                        justifyContent: 'flex-start',
                    })}
                    leadingComponents={
                        <FIcon
                            size="small"
                            color={() => FLegacyColorTypes.PRIMARY_GREY}
                            name={FIconNames.LOGOUT}
                        />
                    }
                />
            </FRWDNavBar>
        </Fragment>
    )
}

export default RWDNavBar
