import { gql } from '@apollo/client'

export const GET_QUESTIONBANK_BY_FILTER = gql`
    query getQuestionBanksByFilter(
        $questionBankFilterInput: QuestionBankFilterInput
    ) {
        questionBanksGet(questionBankFilterInput: $questionBankFilterInput) {
            isSuccess
            errorMessage
            code
            data {
                id
                name
                subject
                examSystem
                createAt
                updateAt
            }
        }
    }
`
