import { gql } from '@apollo/client'

export const GET_QUESTIONS_BY_FILTER = gql`
    query questionsByFilter($questionFilterInput: QuestionFilterInput) {
        questionsGet(questionFilterInput: $questionFilterInput) {
            isSuccess
            code
            errorMessage
            data {
                id
                questionBankId
                level
                questionGroupId
                questionTypeId
                isFlaged
                inputType
                content {
                    inputType
                    ... on MCContent {
                        body
                    }
                }
                createAt
                updateAt
            }
        }
    }
`
