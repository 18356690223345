import { QuestionTypeListProps } from './type'
import * as styles from './styles'
import { QuestionType } from '../../../../../assets/type/backend.type'
import QuestionTypeRow from '../QuestionTypeRow'
import { useQuestionTypePopUpWrapperContext } from '../../popUp/WithQuestionTypePopUpWrapper'

function QuestionTypeList(props: QuestionTypeListProps) {
    const { onQuestionTypeMoreActionPopUpOpen } =
        useQuestionTypePopUpWrapperContext()

    const handleMoreActionPopUpCallback = async () => {
        // console.log("handleMoreActionPopUpCallback")
        await props.onRefresh()
    }
    return (
        <div className={styles.cardListDiv}>
            {/* QuestionTypeList List looping */}
            {(props.questionTypes || []).map(
                (questionType: QuestionType, index: number) => (
                    // <div>{questionType.id}</div>
                    <QuestionTypeRow
                        key={index}
                        index={index}
                        questionType={questionType}
                        onMoreActionButtonClick={() => {
                            onQuestionTypeMoreActionPopUpOpen(questionType, {
                                onDelete: () => handleMoreActionPopUpCallback(),
                                onUpdate: () => handleMoreActionPopUpCallback(),
                            })
                        }}
                    />
                )
            )}
        </div>
    )
}

export default QuestionTypeList
