import { stylesheet } from 'typestyle'

export const styles = stylesheet({
    Questions_Container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        columnGap: '24px',
        width: '100%',
    },
    Questions_ContentDiv: {
        minWidth: '50%',
        maxWidth: '50%',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        rowGap: '12px',
    },
})
