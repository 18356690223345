import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { stylesheet } from 'typestyle'

export const styles = stylesheet({
    FListCard_Container: {
        boxSizing: 'border-box',
        padding: '12px 8px',
        border: '1.6px solid ' + FLegacyColorTypes.SPECIAL_STROKE,
        borderRadius: '8px',
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        columnGap: '16px',
        alignItems: 'center',
        $nest: {
            '&:hover': {
                backgroundColor: `${FLegacyColorTypes.PRIMARY_LIGHT}`,
            },
        },
    },
    FListCard_Icon_Div: {
        cursor: 'pointer',
        $nest: {
            '&:hover': {
                backgroundColor: FLegacyColorTypes.PRIMARY_LIGHT,
            },
        },
    },
    FListCard_Content_Div: {
        height: '100%',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        rowGap: '4px',
        justifyContent: 'center',
        cursor: 'pointer',
    },
})

// export const FListCard_ToolBar_Div = style({
//     maxWidth: '80px',
//     display: 'flex',
//     flexDirection: 'row',
//     alignItems: 'center',
//     justifyContent: 'space-between',
//     columnGap: '8px',
// })
