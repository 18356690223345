import { gql } from '@apollo/client'
export const QB_QUESTION_TYPE_CREATE_MUTATION = gql`
    mutation qbQuestionTypeCreate(
        $questionTypeCreateInput: QuestionTypeCreateInput!
    ) {
        qbQuestionTypeCreate(
            questionTypeCreateInput: $questionTypeCreateInput
        ) {
            isSuccess
            code
            errorMessage
            data {
                id
                name
            }
        }
    }
`
