import {
    FNoData,
    FTabs,
    FTabsPanel,
} from '@fantaskticedtechlimited/fui-complib'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useQBAPIService from '../../../apiService/questionBank'
import { Chapter } from '../../../assets/type/backend.type'
import { PageContainer } from '../../../layout/PageContainer'
import {
    useLoadingPageWrapperContext,
    WithLoadingPageWrapper,
} from '../../../layout/WithLoadingPageWrapper'
import { WithConceptCardPopUpWrapper } from '../components/popUp/WithConceptCardPopUpWrapper'
import { WithQuestionGroupPopUpWrapper } from '../components/popUp/WithQuestionGroupPopUpWrapper'
import { WithQuestionPopUpWrapper } from '../components/popUp/WithQuestionPopUpWrapper'
import { WithQuestionTypePopUpWrapper } from '../components/popUp/WithQuestionTypePopUpWrapper'
import { WithChapterPopUpWrapper } from '../components/popUp/WithChapterPopUpWrapper'
import ConceptCardsContainer from './ConceptCardsContainer'
import QuestionGroupsContainer from './QuestionGroupsContainer'
import QuestionsContainer from './QuestionsContainer'
import QuestionTypesContainer from './QuestionTypesContainer'
import ChapterDetailHeader from './ChapterDetailHeader'
import { ChapterDetailProps } from './types'

function ChapterDetail(props: ChapterDetailProps) {
    const param = useParams<{ folderId: string; chapterId: string }>()

    const qbAPIService = useQBAPIService()
    const { setLoadingEnd, setLoadingStart } = useLoadingPageWrapperContext()

    const [chapter, setChapter] = useState<Chapter | null>(null)
    const handleGetChapterDetail = async (
        chapterId: string,
        withLoading?: boolean
    ) => {
        withLoading && setLoadingStart()
        try {
            const result = await qbAPIService.getChapterDetailById({
                chapterId,
            })
            const { chapterGet } = result.data
            setChapter(chapterGet.data ?? null)
        } catch (error) {
            console.log('error', error)
            setChapter(null)
        }
        withLoading && setLoadingEnd()
    }

    useEffect(() => {
        if (param.chapterId) {
            handleGetChapterDetail(param.chapterId, true)
        } else {
            setChapter(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [param.chapterId])

    if (chapter === null) {
        return <FNoData description="No Chapter Data" />
    }

    return (
        <PageContainer
            header={
                <ChapterDetailHeader
                    chapter={chapter}
                    folderId={param.folderId}
                    onRefresh={() => handleGetChapterDetail(chapter.id)}
                />
            }
            body={
                <FTabs
                    wrapperStyle={{
                        overflowY: 'hidden',
                    }}
                >
                    <FTabsPanel label="Concept Cards">
                        <ConceptCardsContainer chapter={chapter} />
                    </FTabsPanel>
                    <FTabsPanel label="Question Types">
                        <QuestionTypesContainer chapter={chapter} />
                    </FTabsPanel>
                    <FTabsPanel label="Question Groups">
                        <QuestionGroupsContainer chapterId={chapter.id} />
                    </FTabsPanel>
                    <FTabsPanel label="Questions">
                        <QuestionsContainer
                            questionBankId={chapter.questionBankId}
                            chapterId={chapter.id}
                        />
                    </FTabsPanel>
                </FTabs>
            }
        />
    )
}

export default WithLoadingPageWrapper(
    WithChapterPopUpWrapper(
        WithQuestionGroupPopUpWrapper(
            WithQuestionTypePopUpWrapper(
                WithConceptCardPopUpWrapper(
                    WithQuestionPopUpWrapper(ChapterDetail)
                )
            )
        )
    ),
    true
)
