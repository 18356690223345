import { Fragment } from 'react'
import { UploadStatusDisplayer } from './types'

export const DefaultUploadStatusDisplayer: UploadStatusDisplayer = (props) => {
    return (
        <Fragment>
            {props.isUploading && props.currentFileUploadStatus !== null && (
                <Fragment>
                    <div>
                        Uploading{' '}
                        {`${props.currentFileUploadStatus.fileName}: ${props.currentFileUploadStatus.progress}%`}
                    </div>
                </Fragment>
            )}
        </Fragment>
    )
}
