import React from 'react'
import { Outlet } from 'react-router-dom'
import { WithQuestionBankPopUpWrapper } from '../QuestionBankContent/components/popUp/WithQuestionBankPopUpWrapper'
import { QuestionBankViewProps } from './types'

function QuestionBankView(props: QuestionBankViewProps) {
    return <Outlet />
}

export default WithQuestionBankPopUpWrapper(QuestionBankView)
