import { FListCardProps } from './types'
import { styles } from './styles'
import { FText } from '@fantaskticedtechlimited/fui-complib'
import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { FFontTypes } from '@fantaskticedtechlimited/fui-fontlib'
import { ImageAsset } from '../../assets/images'

function FListCard(props: FListCardProps) {
    return (
        <div className={`${props.className} ${styles.FListCard_Container}`}>
            {/* Drag icon on left hand side */}
            {/* {props.draggable ? (
                <div style={{ cursor: 'grab' }}>
                    <img src={ImageAsset.DragIcon} alt="Drag Icon" />
                </div>
            ) : null} */}
            {/* Number shows on top side */}
            {(props.index || props.index === 0) && (
                <FText
                    font={FFontTypes.Large_Text({ fontWeight: 400 })}
                    color={FLegacyColorTypes.PRIMARY_GREY}
                >
                    {props.index + 1}
                </FText>
            )}
            {/* show Icon and Image */}
            {props.image && (
                <img
                    src={props.image.fileURL}
                    alt="Icon"
                    width="100px"
                    height="75px"
                />
            )}
            {props.icon && (
                <div
                    className={styles.FListCard_Icon_Div}
                    onClick={props.onClick && props.onClick}
                >
                    {props.icon}
                </div>
            )}

            {/* Middle part, show Chapter Name */}
            <div
                className={styles.FListCard_Content_Div}
                // redirect to chapter detail page on clicks
                onClick={props.onClick && props.onClick}
            >
                {props.content}
            </div>
            {/* {!props.isDisableToolBar && (
                <div className={styles.FListCard_ToolBar_Div}>
                    {props.toolBar}
                </div>
            )} */}
        </div>
    )
}

export default FListCard
