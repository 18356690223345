import { stylesheet } from 'typestyle'

export const styles = stylesheet({
    CardContent_Container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: '16px',
    },
})
