import { gql } from '@apollo/client'

export const GET_TAGS_BY_FILTER = gql`
    query tags($tagFilterInput: TagFilterInput) {
        tagsGet(tagFilterInput: $tagFilterInput) {
            isSuccess
            code
            errorMessage
            data {
                id
                name
                questionBankId
                createAt
                updateAt
            }
        }
    }
`
