import { stylesheet } from 'typestyle'

export const styles = stylesheet({
    FormContent_Container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        columnGap: '12px',
    },
    FormContent_Input_Container: {
        flexBasis: '50%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        rowGap: '1rem', 
        height: '100%',
        overflowY: 'auto',
    },
    FormContent_Preview_Container: {
        flexBasis: '50%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        rowGap: '1rem', 
        height: '100%',
        overflowY: 'auto',
    },
})
