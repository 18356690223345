import {
    FileUploaderProps,
    StrictFileUploaderProps,
} from '../types'

export const DefaultPreProcessUploadProps = (
    props: FileUploaderProps
): StrictFileUploaderProps => {
    const {
        // set default value
        multiple = false,
        maxNumberOfFiles = 1,
        accept = '',
        fileNamePrefix = '',
    } = props
    const finalAccept = Array.isArray(accept) ? accept.join(',') : accept

    return {
        ...props,
        multiple,
        maxNumberOfFiles,
        fileNamePrefix,
        accept: finalAccept,
    }
}
