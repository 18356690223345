import useQBAPIService from '../../../../../apiService/questionBank'
import { QuestionTypeDeletePopUpProps } from './types'
import { DeletePopUp } from '../../../../../Components/DeletePopUp'

export const QuestionTypeDeletePopUp = (
    props: QuestionTypeDeletePopUpProps
) => {
    const qbAPIService = useQBAPIService()
    const questionTypeId = props.questionType.id
    const questionTypeName = props.questionType.name

    const handlePopUpClose = async () => {
        props.onClose && (await props.onClose())
    }

    const handleQuestionTypeDelete = async (questionTypeId: string) => {
        try {
            const result = await qbAPIService.qbQuestionTypeDeleteByIdMutation({
                variables: {
                    questionTypeId,
                },
            })
            if (result.data?.qbQuestionTypeDeleteById.isSuccess) {
                props.onCallback &&
                    (await props.onCallback(
                        result.data?.qbQuestionTypeDeleteById.data!
                    ))
                handlePopUpClose()
            } else {
                alert(
                    `QuestionType delete failed, Reason. ${JSON.stringify(
                        result
                    )}`
                )
            }
        } catch (error) {
            console.log('error', error)
            alert(`QuestionType delete failed, Error: ${JSON.stringify(error)}`)
        }
    }

    return (
        <DeletePopUp
            targetType="Question Type"
            targetName={questionTypeName}
            onClose={handlePopUpClose}
            onActionButtonClick={() => handleQuestionTypeDelete(questionTypeId)}
        />
    )
}
