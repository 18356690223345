import { gql } from '@apollo/client'

export const UM_FIREBASE_EMAIL_PASSWORD_USER_CREATE_MUTATION = gql`
    mutation umFirebaseEmailPasswordUserCreate(
        $umFirebaseEmailPasswordUserCreateInput: UMFirebaseEmailPasswordUserCreateInput!
    ) {
        umFirebaseEmailPasswordUserCreate(
            umFirebaseEmailPasswordUserCreateInput: $umFirebaseEmailPasswordUserCreateInput
        ) {
            isSuccess
            code
            errorMessage
            data {
                id
                email
                firebaseUID
                userProfile {
                    nickname
                    profilePic {
                        fileURL
                    }
                    darkMode
                    courseIdList
                    language
                    emailVerified
                }
            }
        }
    }
`
