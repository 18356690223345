import { FFontTypes } from '@fantaskticedtechlimited/fui-fontlib'
import { style } from 'typestyle'

export const DefaultUploader_Container = (iconUploaded: boolean) =>
    style({
        display: iconUploaded ? 'none' : 'flex',
    })

export const DefaultUploader_Input_Container = style({
    font: FFontTypes.Large_Text(),
    cursor: 'pointer',
})
