import { styles } from './styles'
import { QBAssetListCardIconBarProps } from './types'
import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { FFontTypes } from '@fantaskticedtechlimited/fui-fontlib'
import { FText } from '@fantaskticedtechlimited/fui-complib'
import { IsDefinedNotNull } from '../../../../../utils/isDefinedNotNull'
import { ImageAsset } from '../../../../../assets/images'

const QBAssetListCardIconBar = (props: QBAssetListCardIconBarProps) => {
    const textProps = { style: { marginTop: '1px' } }

    return (
        <div className={styles.IconBar_RowDiv}>
            {IsDefinedNotNull(props.folderNumber) && (
                <div className={styles.IconBar_ItemDiv}>
                    <img
                        src={ImageAsset.FolderIcon}
                        alt="Folder"
                        width="16px"
                        height="16px"
                    />
                    <FText
                        font={FFontTypes.Text({ fontWeight: 400 })}
                        color={FLegacyColorTypes.PRIMARY_GREY}
                        {...textProps}
                    >
                        {props.folderNumber}
                    </FText>
                </div>
            )}

            {IsDefinedNotNull(props.chapterNumber) && (
                <div className={styles.IconBar_ItemDiv}>
                    <img
                        src={ImageAsset.ChapterIcon}
                        alt="Chapter"
                        width="16px"
                        height="16px"
                    />
                    <FText
                        font={FFontTypes.Text({ fontWeight: 400 })}
                        color={FLegacyColorTypes.PRIMARY_GREY}
                        {...textProps}
                    >
                        {props.chapterNumber}
                    </FText>
                </div>
            )}

            {IsDefinedNotNull(props.questionTypeNumber) && (
                <div className={styles.IconBar_ItemDiv}>
                    <img
                        src={ImageAsset.QuestionTypeIcon}
                        alt="QuestionType"
                        width="16px"
                        height="16px"
                    />
                    <FText
                        font={FFontTypes.Text({ fontWeight: 400 })}
                        color={FLegacyColorTypes.PRIMARY_GREY}
                        {...textProps}
                    >
                        {props.questionTypeNumber}
                    </FText>
                </div>
            )}

            {IsDefinedNotNull(props.questionGroupsNumber) && (
                <div className={styles.IconBar_ItemDiv}>
                    <img
                        src={ImageAsset.QuestionGroupIcon}
                        alt="QuestionGroup"
                        width="16px"
                        height="16px"
                    />
                    <FText
                        font={FFontTypes.Text({ fontWeight: 400 })}
                        color={FLegacyColorTypes.PRIMARY_GREY}
                        {...textProps}
                    >
                        {props.questionGroupsNumber}
                    </FText>
                </div>
            )}

            {IsDefinedNotNull(props.questionsNumber) && (
                <div className={styles.IconBar_ItemDiv}>
                    <img
                        src={ImageAsset.QuestionIcon}
                        alt="Question"
                        width="16px"
                        height="16px"
                    />
                    <FText
                        font={FFontTypes.Text({ fontWeight: 400 })}
                        color={FLegacyColorTypes.PRIMARY_GREY}
                        {...textProps}
                    >
                        {props.questionsNumber}
                    </FText>
                </div>
            )}
        </div>
    )
}

export default QBAssetListCardIconBar
