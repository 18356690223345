import { gql } from '@apollo/client'
import { PHOTO_DETAIL_FIELDS } from '../fragments/photoDetail.gql'

export const GET_QUESTIONBANK_CONTENT_DETAIL_QUERY = gql`
    # Write your query or mutation here
    query getQuestionBankContentDetailById($id: String!) {
        questionBankGet(id: $id) {
            isSuccess
            errorMessage
            code
            data {
                id
                name
                subject
                examSystem
                folders {
                    id
                    orderSequence
                    name
                    isEnable
                    isFlagged
                    icon {
                        ...photoDetail
                    }
                    chapters {
                        id
                        orderSequence
                        name
                        questionGroups {
                            id
                            orderSequence
                            name
                        }
                    }
                }
                conceptCards {
                    id
                    name
                    photo {
                        ...photoDetail
                    }
                    chapterId
                    tags {
                        id
                        name
                    }
                }
                tags {
                    id
                    name
                }
            }
        }
    }

    ${PHOTO_DETAIL_FIELDS}
`
