import { Fragment } from 'react'
import { DefaultButtonUploader } from './DefaultButtonUploader'
import { DefaultDNDUploader } from './DefaultDNDUploader'
import { DefaultUploader } from './DefaultUploader'
import { DefaultUploadStatusDisplayer } from './DefaultUploadStatusDisplayer'
import { FileUploaderProps, UploadHandler } from './types'
import { DefaultPreProcessUploadProps } from './utils/DefaultPreprocessProps'
import axios, {} from "axios"
import { UploadedFile } from '../../utils/useFirebaseFirestoreService/type'
const instance = axios.create({
    baseURL: process.env.REACT_APP_UPLOAD_URL,
    headers:{
        'Content-Type': 'multipart/form-data',
        'upload_api_key':process.env.REACT_APP_UPLOAD_TOKEN ?? "",
    }
});
interface NormalUploadFile{
    filename:string
    mimetype:string
    originalname:string
    size:number
    url:string
}
const NormalFileUploader = (props: FileUploaderProps) => {
    const postProcessProps = DefaultPreProcessUploadProps(props)
    const DefaultUploadHandler: UploadHandler = async (
        files,
        fileNamePrefix
    ) =>{
        const uploadFiles:UploadedFile[] = []
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const form = new FormData();
            form.append('file', file);
            const result = await instance.post("/uploadSingleS3", form)
            const normalUploadFile:NormalUploadFile = result.data
            // console.log("result", result)
            uploadFiles.push({
                fileName:normalUploadFile.filename,
                fileURL:normalUploadFile.url,
                originalFileName:normalUploadFile.originalname,
                fileSize:normalUploadFile.size
            })
        }
        return uploadFiles;
    }


    const FinalUploader = props.isButtonUploader
        ? DefaultButtonUploader
        : DefaultUploader
    const FinalStatusDisplayer = !props.withStatus
        ? undefined
        : props.uploadStatusDisplayer ?? DefaultUploadStatusDisplayer
    if (props.withDND) {
        return (
            <Fragment>
                <DefaultDNDUploader
                    {...postProcessProps}
                    uploadHandler={DefaultUploadHandler}
                    uploadStatusDisplayer={FinalStatusDisplayer}
                    // uploadStatus={{
                    //     isUploading,
                    //     fileUploadStatusList: fileStatusList,
                    //     currentFileUploadStatus: currentFileStatus,
                    // }}
                    buttonUploader={FinalUploader}
                />
            </Fragment>
        )
    }
    return (
        <Fragment>
            <FinalUploader
                {...postProcessProps}
                uploadHandler={DefaultUploadHandler}
                uploadStatusDisplayer={FinalStatusDisplayer}
                // uploadStatus={{
                //     isUploading,
                //     fileUploadStatusList: fileStatusList,
                //     currentFileUploadStatus: currentFileStatus,
                // }}
            />
        </Fragment>
    )
}

export default NormalFileUploader
