import { UploaderComponentProps, UploadHandler } from '../types'
import { useRef, useState } from 'react'
import { DefaultUploader } from '../DefaultUploader'
import { FIcon, FIconNames } from '@fantaskticedtechlimited/fui-iconlib'
import { FButton, FUseTheme } from '@fantaskticedtechlimited/fui-complib'

import * as styles from './styles'

export const DefaultButtonUploader = (props: UploaderComponentProps) => {
    const { uploadHandler, ...restProps } = props
    // for button to trigger upload
    const uploaderRef = useRef<HTMLInputElement>(null)
    const { theme } = FUseTheme()
    const [isUploading, setIsUploading] = useState(false)
    const handleUpload: UploadHandler = async (files, prefix) => {
        setIsUploading(true)
        const result = await uploadHandler(files, prefix)
        setIsUploading(false)
        return result
    }
    return (
        <div style={props.style}>
            <FButton
                type="Outline"
                customChildren={
                    <FIcon
                        name={FIconNames.IMAGE}
                        color={() => theme.themeColors.mainThemeColor.lightMode}
                    />
                }
                onClick={() => {
                    if (uploaderRef.current) {
                        uploaderRef.current.click()
                    }
                }}
                disabled={isUploading}
                className={() => styles.DefaultButtonUploader_Icon_Div}
            />
            <div
                style={{
                    display: 'none',
                }}
            >
                <DefaultUploader
                    {...restProps}
                    uploadHandler={handleUpload}
                    innerRef={uploaderRef}
                />
            </div>
        </div>
    )
}
