import { useState } from 'react'
import {ReactFileUploader} from '@files-drop/react-uploader/lib/cjs/index'
import {UploadedFile} from '@files-drop/react-uploader/lib/cjs/types'

function TestUpload() {
    const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([])
    return (
        <div>
            <ReactFileUploader
                url={process.env.REACT_APP_UPLOAD_URL ?? ''}
                apiKey={process.env.REACT_APP_UPLOAD_TOKEN ?? ''}
                onUploadedFiles={(files) => {
                    console.log('upload', files)
                    setUploadedFiles(files)
                }}
                withDND
            />
            <br />
            <button onClick={() => setUploadedFiles([])}>Clear</button>
            {uploadedFiles.map((e, i) => {
                return (
                    <img
                        width={200}
                        height={200}
                        src={e.fileURL}
                        key={i}
                        alt={`file${i}`}
                    />
                )
            })}
        </div>
    )
}

export default TestUpload
