import { gql } from '@apollo/client'

export const QB_FOLDER_ADD_OR_REMOVE_CHAPTER_BY_ID_MUTATION = gql`
    mutation qbFolderAddOrRemoveChapterById(
        $folderAddOrRemoveChapterInput: FolderAddOrRemoveChapterInput!
    ) {
        qbFolderAddOrRemoveChapterById(
            folderAddOrRemoveChapterInput: $folderAddOrRemoveChapterInput
        ) {
            isSuccess
            errorMessage
            code
            data {
                id
            }
        }
    }
`
