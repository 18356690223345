import { ChapterListProps } from './types'
import * as styles from './styles'
import ChapterRow from '../ChapterRow'
import { Chapter } from '../../../../../assets/type/backend.type'
import { useChapterPopUpWrapperContext } from '../../popUp/WithChapterPopUpWrapper'

function ChapterList(props: ChapterListProps) {
    const { onChapterMoreActionPopUpOpen, onChapterToggleEnablePopUpOpen } =
        useChapterPopUpWrapperContext()

    const handleMoreActionPopUpCallback = async () => {
        await props.onRefresh()
    }
    return (
        <div className={styles.chapterListDiv}>
            {/* ChapterList List looping */}
            {(props.chapters || []).map((chapter: Chapter, index: number) => (
                <ChapterRow
                    key={index}
                    index={index}
                    chapter={chapter}
                    folderId={props.folderId}
                    onMoreActionButtonClick={() =>
                        onChapterMoreActionPopUpOpen(chapter, () =>
                            handleMoreActionPopUpCallback()
                        )
                    }
                    onToggleEnableButtonClick={() => {
                        onChapterToggleEnablePopUpOpen(chapter, props.onRefresh)
                    }}
                />
            ))}
        </div>
    )
}

export default ChapterList
