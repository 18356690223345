import { ChapterRowProps } from './types'

import { useNavigate } from 'react-router'
import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { FSwitchButton, FText } from '@fantaskticedtechlimited/fui-complib'
import { FIcon, FIconNames } from '@fantaskticedtechlimited/fui-iconlib'
import FListCard from '../../../../../Components/FListCard'
import { Fragment } from 'react'
import { ImageAsset } from '../../../../../assets/images'
import { FFontTypes } from '@fantaskticedtechlimited/fui-fontlib'

function ChapterRow(props: ChapterRowProps) {
    const { chapter: chapterData } = props
    const navigate = useNavigate()

    return (
        <FListCard
            index={props.index}
            isDisableToolBar={props.isDisableToolbar}
            icon={
                <img
                    alt="icon"
                    src={props.chapter.icon?.fileURL ?? ImageAsset.noImage}
                    style={{
                        width: '100px',
                        height: '100px',
                    }}
                />
            }
            onClick={() => {
                if (!props.disableCardOnClick) {
                    if (props.folderId && props.folderId.length > 0)
                        navigate(
                            `/questionBankContent/folder/${props.folderId}/chapter/${chapterData.id}`
                        )
                    else
                        navigate(
                            `/questionBankContent/chapter/${chapterData.id}`
                        )
                }
            }}
            content={
                <Fragment>
                    {/* data in middle top */}
                    <FText maxRows={2} font={FFontTypes.Title()}>
                        {chapterData.name}
                    </FText>

                    {/* data in middle bottom */}
                    {/* description */}
                    <FText
                        font={FFontTypes.Text()}
                        color={FLegacyColorTypes.PRIMARY_GREY}
                        maxRows={2}
                    >
                        {chapterData.description ?? ''}
                    </FText>
                </Fragment>
            }
            toolBar={
                <Fragment>
                    {/* Button on Right side */}
                    <FSwitchButton
                        checked={props.chapter.isEnable}
                        // update with open flag later
                        onClick={() => {
                            props.onToggleEnableButtonClick &&
                                props.onToggleEnableButtonClick()
                        }}
                    />

                    {/* More Action button */}
                    <FIcon
                        name={FIconNames.MORE}
                        size="large"
                        color={() => FLegacyColorTypes.PRIMARY_GREY}
                        onClick={() => {
                            props.onMoreActionButtonClick &&
                                props.onMoreActionButtonClick()
                        }}
                    />
                </Fragment>
            }
        />
    )
}

export default ChapterRow
