import { FForm, FInputField, FText } from '@fantaskticedtechlimited/fui-complib'
import { Fragment, useState } from 'react'
import useQBAPIService from '../../../../../apiService/questionBank'
import {
    ExamSystem,
    QuestionBank,
    QuestionBankCreateInput,
    Subject,
} from '../../../../../assets/type/backend.type'
import FCreateSelect from '../../../../../Components/FCreateSelect'

import { QuestionBankCreateFormProps } from './types'

function QuestionBankCreateForm(props: QuestionBankCreateFormProps) {
    const qbAPIService = useQBAPIService()
    let defaultValue: QuestionBankCreateInput = {
        name: 'Name',
        examSystem: ExamSystem.HKDSE,
        subject: Subject.MATHEMATICS,
    }
    const [questionBankCreateInput, setQuestionBankCreateInput] =
        useState<QuestionBankCreateInput>({
            ...defaultValue,
        })

    const handleQuestionBankCreate = async (
        questionBankCreateInput: QuestionBankCreateInput
    ) => {
        try {
            // let questionBankUpdateInput = removeTypename(_questionBankUpdateInput)
            const result = await qbAPIService.qbQuestionBankCreateMutation({
                variables: {
                    questionBankCreateInput,
                },
            })
            // console.log("result", result);
            if (result.data?.qbQuestionBankCreate.isSuccess) {
                props.onSubmitCallback &&
                    (await props.onSubmitCallback(
                        result.data.qbQuestionBankCreate.data as QuestionBank
                    ))
                props.onClose && (await props.onClose())
            } else {
                console.error(
                    `QuestionBank Create failed, Reason. ${JSON.stringify(
                        result
                    )}`
                )
                alert(
                    `QuestionBank Create failed, Reason.  ${result.data?.qbQuestionBankCreate.errorMessage}`
                )
            }
        } catch (error) {
            console.log('error', error)
            alert(`QuestionBank Create failed, Error: ${JSON.stringify(error)}`)
        }
    }

    const examSystemOptions = Object.values(ExamSystem).map((e) => {
        return {
            label: e,
            value: e,
        }
    })
    const subjectOptions = Object.values(Subject).map((e) => {
        return {
            label: e,
            value: e,
        }
    })

    return (
        <FForm
            onClose={props.onClose}
            onSubmit={() => handleQuestionBankCreate(questionBankCreateInput)}
            style={{ overflowY: 'unset' }}
            contentContainerStyle={{ overflowY: 'unset' }}
        >
            <FInputField
                autoFocus
                label="QuestionBank Name"
                value={questionBankCreateInput.name}
                wordCount={100}
                onInput={(v) =>
                    setQuestionBankCreateInput({
                        ...questionBankCreateInput,
                        name: v,
                    })
                }
            />

            {/* Exam System */}
            <FText style={{ alignSelf: 'flex-start' }}>Exam System</FText>
            <FCreateSelect
                isMulti={false}
                isDisableCreate
                isClearable={false}
                options={examSystemOptions}
                value={{
                    label: questionBankCreateInput.examSystem,
                    value: questionBankCreateInput.examSystem,
                }}
                placeholder="Select Exam System"
                getOptionLabel={(data) => data.label}
                getOptionKey={(data) => data.value}
                onSelect={(data) =>
                    // console.log("data select", data)
                    setQuestionBankCreateInput({
                        ...questionBankCreateInput,
                        examSystem: data.value,
                    })
                }
                onCompareInputValue={(data, input) =>
                    data.value.includes(input)
                }
            />

            {/* Subject */}
            <FText style={{ alignSelf: 'flex-start' }}>Subject</FText>
            <FCreateSelect
                isMulti={false}
                isDisableCreate
                isClearable={false}
                options={subjectOptions}
                value={{
                    label: questionBankCreateInput.subject,
                    value: questionBankCreateInput.subject,
                }}
                placeholder="Select Subject"
                getOptionLabel={(data) => data.label}
                getOptionKey={(data) => data.value}
                onSelect={(data) =>
                    // console.log("data select", data)
                    setQuestionBankCreateInput({
                        ...questionBankCreateInput,
                        subject: data.value,
                    })
                }
                onCompareInputValue={(data, input) =>
                    data.value.includes(input)
                }
            />
        </FForm>
    )
}

export default QuestionBankCreateForm
