import { FForm, FInputField } from '@fantaskticedtechlimited/fui-complib'
import { Fragment, useState } from 'react'
import useQBAPIService from '../../../../../apiService/questionBank'
import { QuestionGroupUpdateInput } from '../../../../../assets/type/backend.type'
import { QuestionGroupUpdateFormProps } from './types'

export const QuestionGroupUpdateForm = (
    props: QuestionGroupUpdateFormProps
) => {
    const qbAPIService = useQBAPIService()
    let defaultValue: QuestionGroupUpdateInput = {
        questionGroupId: props.questionGroup.id,
        name: props.questionGroup.name ?? 'Name',
    }
    const [questionGroupUpdateInput, setQuestionGroupUpdateInput] =
        useState<QuestionGroupUpdateInput>({
            ...defaultValue,
        })

    const handleQuestionGroupUpdate = async (
        questionGroupUpdateInput: QuestionGroupUpdateInput
    ) => {
        try {
            const result = await qbAPIService.qbQuestionGroupUpdateByIdMutation(
                {
                    variables: {
                        questionGroupUpdateInput: questionGroupUpdateInput,
                    },
                }
            )
            if (
                result.data?.qbQuestionGroupUpdateById.isSuccess &&
                result.data.qbQuestionGroupUpdateById.data
            ) {
                props.onSubmitCallback &&
                    (await props.onSubmitCallback(
                        result.data.qbQuestionGroupUpdateById.data
                    ))
                props.onClose && (await props.onClose())
            } else {
                console.error(
                    `QuestionGroup Update failed, Reason. ${JSON.stringify(
                        result
                    )}`
                )
                alert(
                    `QuestionGroup Update failed, Reason.  ${result.data?.qbQuestionGroupUpdateById.errorMessage}`
                )
            }
        } catch (error) {
            console.log('error', error)
            alert(
                `QuestionGroup Update failed, Error: ${JSON.stringify(error)}`
            )
        }
    }

    return (
        <FForm
            onClose={props.onClose}
            onSubmit={() =>
                handleQuestionGroupUpdate({
                    ...questionGroupUpdateInput,
                })
            }
        >
            <FInputField
                autoFocus
                label="QuestionGroup Name"
                value={questionGroupUpdateInput.name ?? 'Name'}
                wordCount={100}
                onInput={(v) =>
                    setQuestionGroupUpdateInput({
                        ...questionGroupUpdateInput,
                        name: v,
                    })
                }
            />
        </FForm>
    )
}
