import { Fragment, useEffect, useState } from 'react'
import useQBAPIService from '../../../../apiService/questionBank'
import { ConceptCard } from '../../../../assets/type/backend.type'
import ConceptCardList from '../../components/ConceptCard/ConceptCardList'
import { ConceptCardsContainerProps } from './types'
import * as styles from './styles'
import { useLoadingPageWrapperContext } from '../../../../layout/WithLoadingPageWrapper'
import { PageContainer } from '../../../../layout/PageContainer'
import {
    FButton,
    FNoData,
    FScrollableContentWrapper,
} from '@fantaskticedtechlimited/fui-complib'

import { useConceptCardPopUpContext } from '../../components/popUp/WithConceptCardPopUpWrapper'

function ConceptCardsContainer(props: ConceptCardsContainerProps) {
    const { setLoadingStart, setLoadingEnd } = useLoadingPageWrapperContext()
    const qbAPIService = useQBAPIService()
    const [conceptCards, setConceptCards] = useState<ConceptCard[]>([])
    const totalConceptCards = conceptCards !== null ? conceptCards.length : 0
    const handleGetConceptCardsByConceptCardId = async (
        chapterId: string,
        withLoadingScreen?: boolean
    ) => {
        withLoadingScreen && setLoadingStart()
        try {
            const result = await qbAPIService.getConceptCardsDetailByFilter({
                conceptCardFilterInput: {
                    chapterId,
                },
            })
            const { conceptCardsGet } = result.data
            setConceptCards(
                conceptCardsGet.isSuccess ? conceptCardsGet.data || [] : []
            )
        } catch (error) {
            console.log('error', error)
            setConceptCards([])
        }
        withLoadingScreen && setLoadingEnd()
    }

    const { onConceptCardCreatePopUpOpen } = useConceptCardPopUpContext()

    useEffect(() => {
        handleGetConceptCardsByConceptCardId(props.chapter.id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.chapter])

    return (
        <PageContainer
            header={
                <div className={styles.toolbarContainer}>
                    {/* New Concept Card button */}
                    <FButton
                        type="Primary"
                        onClick={() =>
                            onConceptCardCreatePopUpOpen(props.chapter, () =>
                                handleGetConceptCardsByConceptCardId(
                                    props?.chapter.id
                                )
                            )
                        }
                        label="New Concept Card"
                    />

                    {/* refresh button */}
                    <FButton
                        type="Primary"
                        onClick={async () =>
                            await handleGetConceptCardsByConceptCardId(
                                props.chapter.id,
                                true
                            )
                        }
                        label="Refresh"
                    />
                </div>
            }
            body={
                <FScrollableContentWrapper>
                    {totalConceptCards === 0 ? (
                        <FNoData />
                    ) : (
                        <Fragment>
                            <ConceptCardList
                                conceptCards={conceptCards || []}
                                onRefresh={() =>
                                    handleGetConceptCardsByConceptCardId(
                                        props.chapter.id
                                    )
                                }
                            />
                        </Fragment>
                    )}
                </FScrollableContentWrapper>
            }
        />
    )
}

export default ConceptCardsContainer
