import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { style } from 'typestyle'

export const DefaultButtonUploader_Icon_Div = style({
    padding: '2px 3.5px !important',
    $nest: {
        '&:hover': {
            $nest: {
                'svg path': {
                    stroke: FLegacyColorTypes.PRIMARY_WHITE,
                },
            },
        },
    },
})
