import { UploaderComponentProps } from '../types'

export interface UploadRuleCheckOutput {
    isPass: boolean
    errorMessage: string
    checkedFiles: File[]
}
export const UploadRuleCheck = (
    files: File[] | FileList | null,
    props: UploaderComponentProps
): UploadRuleCheckOutput => {
    let defaultRes: UploadRuleCheckOutput = {
        isPass: false,
        errorMessage: '',
        checkedFiles: [],
    }

    if (files === null) {
        defaultRes.errorMessage = 'Files should not be Null'
        return defaultRes
    }

    const checkedFiles = Array.from(files)
    defaultRes.checkedFiles = checkedFiles

    if (props.maxNumberOfFiles < 1) {
        defaultRes.errorMessage = 'Files upload limit less than 0'
        return defaultRes
    }

    if (checkedFiles.length > props.maxNumberOfFiles) {
        defaultRes.errorMessage = `You can upload max ${props.maxNumberOfFiles} files at most at a time.`
        return defaultRes
    }

    defaultRes.isPass = true
    return defaultRes
}
