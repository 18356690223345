import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import {
    FButton,
    FInputField,
    FScrollableContentWrapper,
    FSelect,
    FTag,
    FText,
} from '@fantaskticedtechlimited/fui-complib'
import { FFontTypes } from '@fantaskticedtechlimited/fui-fontlib'

import { FIcon, FIconNames } from '@fantaskticedtechlimited/fui-iconlib'
import { Tag } from 'antd'
import Table, { ColumnsType } from 'antd/lib/table'
import { Fragment, useState } from 'react'
import useUserManagementAPIService from '../../apiService/user-management'
import {
    UMFirebaseEmailPasswordUserCreateInput,
    UserArrayFilterInput,
    UserRole,
    UserSafe,
    UserStatus,
} from '../../assets/type/backend.type'
import FDateDisplayer from '../../Components/DateDisplayer'
import { PageContainer } from '../../layout/PageContainer'
import {
    useLoadingPageWrapperContext,
    WithLoadingPageWrapper,
} from '../../layout/WithLoadingPageWrapper'
import useMount from '../../utils/useMount'
import * as styles from './styles'
function AdminUserManagement() {
    const umAPISevice = useUserManagementAPIService()
    const { setLoadingEnd, setLoadingStart } = useLoadingPageWrapperContext()
    const [users, setUsers] = useState<UserSafe[]>([])
    const [filter, setFilter] = useState<UserArrayFilterInput>({})
    const defaultUserCreateInput: UMFirebaseEmailPasswordUserCreateInput = {
        email: '',
        password: '',
        emailVerified: true,
        roles: [UserRole.STUDENT],
        status: UserStatus.ACTIVE,
    }
    const [
        umFirebaseEmailPasswordUserCreateInput,
        setUMFirebaseEmailPasswordUserCreateInput,
    ] = useState<UMFirebaseEmailPasswordUserCreateInput>(defaultUserCreateInput)
    const [isCreatingUser, setIsCreatingUser] = useState(false)
    const handleGetUsers = async (
        userArrayFilterInput: UserArrayFilterInput,
        withLoading = false
    ) => {
        withLoading && setLoadingStart()
        try {
            const result = await umAPISevice.getUsersDetailByFilter({
                userArrayFilterInput,
            })
            const { umUserGets } = result.data
            setUsers(umUserGets.isSuccess ? umUserGets.data || [] : [])
            console.log('data', umUserGets)
        } catch (error) {
            alert(JSON.stringify(error))
            setUsers([])
        }
        withLoading && setLoadingEnd()
    }

    const handleCreatetUsers = async (
        umFirebaseEmailPasswordUserCreateInput: UMFirebaseEmailPasswordUserCreateInput
    ) => {
        try {
            setIsCreatingUser(true)
            const result = await umAPISevice.umFirebaseEmailPasswordUserCreate({
                variables: {
                    umFirebaseEmailPasswordUserCreateInput,
                },
            })
            if (result.data?.umFirebaseEmailPasswordUserCreate.isSuccess) {
                setUMFirebaseEmailPasswordUserCreateInput({
                    ...defaultUserCreateInput,
                    roles: umFirebaseEmailPasswordUserCreateInput.roles,
                })
                await onRefresh()
            } else {
                console.error(
                    `umFirebaseEmailPasswordUserCreate, Reason. ${JSON.stringify(
                        result
                    )}`
                )
                alert(
                    `umFirebaseEmailPasswordUserCreate, Reason.  ${result.data?.umFirebaseEmailPasswordUserCreate.errorMessage}`
                )
            }
        } catch (error) {
            alert(JSON.stringify(error))
        }
        setIsCreatingUser(false)
    }

    const handleDeleteUser = async (user: UserSafe) => {
        try {
            const isDelete = window.confirm(
                `Do you want to delete user ${user.email}`
            )
            if (!isDelete) return
            const result = await umAPISevice.umUserDeleteById({
                variables: {
                    userId: user.id,
                },
            })
            if (result.data?.umUserDeleteById.isSuccess) {
                await onRefresh()
            } else {
                console.error(
                    `umUserDeleteById, Reason. ${JSON.stringify(result)}`
                )
                alert(
                    `umUserDeleteById, Reason.  ${result.data?.umUserDeleteById.errorMessage}`
                )
            }
        } catch (error) {
            alert(JSON.stringify(error))
        }
    }

    const onRefresh = async (withLoading: boolean = false) => {
        await handleGetUsers(filter, withLoading)
    }

    const columns: ColumnsType<UserSafe> = [
        {
            title: 'UserId',
            dataIndex: 'id',
        },
        {
            title: 'email',
            dataIndex: 'email',
        },
        {
            title: 'nickname',
            dataIndex: ['userProfile', 'nickname'],
            render: (value) => {
                if (!value || value === null) {
                    return 'N/A'
                }
                return value
            },
        },
        {
            title: 'firebaseUID',
            dataIndex: 'firebaseUID',
        },
        {
            title: 'roles',
            dataIndex: 'roles',
            render: (_, user) => {
                return (
                    <div>
                        {user.roles.map((e, i) => {
                            if (e === UserRole.ADMIN) {
                                return (
                                    <Tag color="gold" key={i}>
                                        {e}
                                    </Tag>
                                )
                            }
                            if (e === UserRole.PUBLISHER) {
                                return (
                                    <Tag color="orange" key={i}>
                                        {e}
                                    </Tag>
                                )
                            }
                            if (e === UserRole.TEACHER) {
                                return (
                                    <Tag color="blue" key={i}>
                                        {e}
                                    </Tag>
                                )
                            }
                            return (
                                <Tag color="green" key={i}>
                                    {e}
                                </Tag>
                            )
                        })}
                    </div>
                )
            },
        },
        {
            title: 'status',
            dataIndex: 'status',
        },
        {
            title: 'Last Edited',
            dataIndex: 'updateAt',
            render: (_, user) => {
                return <FDateDisplayer date={user.updateAt} />
            },
        },
        {
            title: 'Last Profile Edited',
            render: (_, user) => {
                return <FDateDisplayer date={user.userProfile?.updateAt} />
            },
        },
        {
            title: 'options',
            render: (_, user) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            columnGap: '6px',
                        }}
                    >
                        {/* More Action button */}
                        <FIcon
                            name={FIconNames.DELETE}
                            size="large"
                            color={() => FLegacyColorTypes.PRIMARY_GREY}
                            onClick={async () => {
                                await handleDeleteUser(user)
                            }}
                        />
                    </div>
                )
            },
        },
    ]
    useMount(() => {
        handleGetUsers({}, true)
    })
    return (
        <PageContainer
            header={
                <FText
                    font={FFontTypes.Large_Text()}
                    children="Admin User Management"
                />
            }
            body={
                <Fragment>
                    <div
                        className={styles.adminUserManagement_toolbarContainer}
                        style={{
                            zIndex: 9999,
                        }}
                    >
                        <FInputField
                            style={{ maxWidth: '300px' }}
                            label="Email"
                            value={umFirebaseEmailPasswordUserCreateInput.email}
                            onInput={(e) =>
                                setUMFirebaseEmailPasswordUserCreateInput({
                                    ...umFirebaseEmailPasswordUserCreateInput,
                                    email: e,
                                })
                            }
                        />
                        <FInputField
                            style={{ maxWidth: '200px' }}
                            label="Password"
                            value={
                                umFirebaseEmailPasswordUserCreateInput.password
                            }
                            onInput={(e) =>
                                setUMFirebaseEmailPasswordUserCreateInput({
                                    ...umFirebaseEmailPasswordUserCreateInput,
                                    password: e,
                                })
                            }
                        />
                        <FSelect
                            contentDivStyle={{
                                width: '100px',
                            }}
                            label="Role"
                            options={[
                                UserRole.TEACHER,
                                UserRole.STUDENT,
                                UserRole.ADMIN,
                                UserRole.PUBLISHER,
                            ]}
                            onSelect={(e) => {
                                setUMFirebaseEmailPasswordUserCreateInput({
                                    ...umFirebaseEmailPasswordUserCreateInput,
                                    roles: [e],
                                })
                            }}
                            selectedOptions={
                                umFirebaseEmailPasswordUserCreateInput.roles[0]
                            }
                        />
                        <FButton
                            type="Primary"
                            onClick={async () =>
                                await handleCreatetUsers({
                                    ...umFirebaseEmailPasswordUserCreateInput,
                                })
                            }
                            label="Create User"
                        />
                        <FButton
                            type="Secondary"
                            onClick={async () =>
                                setUMFirebaseEmailPasswordUserCreateInput(
                                    defaultUserCreateInput
                                )
                            }
                            label="Reset"
                        />
                        {isCreatingUser && <FText>Loading...</FText>}
                        <div style={{ flex: 1 }} />

                        {/* refresh button */}
                        <FButton
                            type="Primary"
                            onClick={async () => await onRefresh(true)}
                            label="Refresh"
                        />
                    </div>
                    <FScrollableContentWrapper>
                        <Table
                            bordered
                            rowKey={(e) => e.id}
                            columns={columns}
                            dataSource={users}
                            pagination={false}
                            onRow={(user, i) => {
                                return {
                                    // onDoubleClick: () => {
                                    //     navigate(
                                    //         QBAssetLibraryURLGenerator({
                                    //             viewMode: QBDirectoryViewMode.QUESTIONGROUP,
                                    //             userId: user.id,
                                    //             questionBankId:
                                    //                 questionType.questionBankId!,
                                    //         }),
                                    //     )
                                    // },
                                }
                            }}
                        />
                    </FScrollableContentWrapper>
                </Fragment>
            }
        />
    )
}

export default WithLoadingPageWrapper(AdminUserManagement)
