import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { style } from 'typestyle'

export const previewContainer = style({
    display: 'flex',
    flexDirection: 'column',
    rowGap: '24px',
    width: '100%',
    flexGrow: 1,
    boxSizing: 'border-box',
    overflowY: 'auto',
    overflowX: 'hidden',
    border: '2px solid ' + FLegacyColorTypes.PRIMARY_LIGHT,
    borderRadius: 8,
    padding: '12px 16px',
})

export const QuestionBasic_Difficulty = style({
    display: 'flex',
    justifyContent: 'space-between',
    border: '1px solid #E8E8E8',
    padding: '12px',
    alignItems: 'center',
})

export const QuestionBasic_Container = style({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '8px',
    paddingBottom: '16px',
    borderBottom: '2px solid ' + FLegacyColorTypes.PRIMARY_GREY,
})

export const QuestionBasic_Container_Row_Div = style({
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    columnGap: '8px',
    rowGap: '4px',
})

export const MCQuestionContentPreview_Container = style({
    display: 'flex',
    flexDirection: 'column',
    rowGap: '24px',
})

export const MCQuestionContentPreview_Container_Section_Div = style({
    display: 'flex',
    flexDirection: 'column',
    rowGap: '8px',
})

export const MCQuestionContentPreview_AnswerChoice_Div = (
    optionCorrect: boolean
) =>
    style({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: '8px',
        border:
            '2px solid ' +
            (optionCorrect
                ? FLegacyColorTypes.SECONDARY_GREEN
                : FLegacyColorTypes.PRIMARY_LIGHT),
        borderRadius: 4,
        boxSizing: 'border-box',
        padding: '12px',
        width: '100%',
    })
