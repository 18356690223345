import { useCallback, useMemo, useState } from 'react'
type dispatch<T> = (v: T) => void
type undo = () => void
type redo = () => void
type reset = () => void
/**
 * Wrapper around react's `useState` hook.
 * Use this hook to control, undo and redo react state.
 *
 * @param initialValue initial state value
 */
export const useUndoableState = <T>(
    initialValue: T,
    maxStates?: number
): [T, dispatch<T>, undo, redo, reset] => {
    const maxStoreStates = Number(maxStates) > 0 ? Number(maxStates) : 100
    const [stateIndex, setStateIndex] = useState(0)
    const [states, setStates] = useState<T[]>([initialValue])
    const state = useMemo(() => {
        // console.log("useMemo")
        // console.log("states", states);
        // console.log("stateIndex", stateIndex);

        return states[stateIndex]
    }, [states, stateIndex])

    const setValue: dispatch<T> = useCallback(
        (s: T) => {
            let newIndex = stateIndex + 1
            let newIndexAfterUpdate = newIndex
            let startCopyIndex = 0
            let storageRemain = maxStoreStates - newIndex
            if (storageRemain <= 0) {
                startCopyIndex = Math.abs(storageRemain)
                newIndexAfterUpdate -= startCopyIndex
            }

            // console.log("startCopyIndex", startCopyIndex)
            let slicedStates = states.slice(startCopyIndex, newIndex)
            // console.log("oldStates", states)
            // console.log("slicedStates", slicedStates)
            slicedStates.push(s)
            // console.log("newStates", slicedStates)
            setStates([...slicedStates])
            setStateIndex(newIndexAfterUpdate)
        },
        [setStates, setStateIndex, stateIndex, states, maxStoreStates]
    )

    const undo: undo = useCallback(() => {
        // console.log("undo")
        let newIndex = stateIndex - 1
        if (newIndex >= 0) {
            setStateIndex(newIndex)
        }
    }, [setStateIndex, stateIndex])

    const redo: redo = useCallback(() => {
        // console.log("redo")
        let newIndex = stateIndex + 1
        if (newIndex >= 0 && states.length > newIndex) {
            setStateIndex(newIndex)
        }
    }, [setStateIndex, stateIndex, states])

    const reset: reset = useCallback(() => {
        setStates([initialValue])
        setStateIndex(0)
    }, [setStateIndex, setStates, initialValue])
    return [state, setValue, undo, redo, reset]
}
