import { stylesheet } from 'typestyle'

export const styles = stylesheet({
    QBDirectoryView_ListWrapper: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        rowGap: '12px',
        overflowY: 'auto',
    },
})
