import { gql } from '@apollo/client'

export const GET_CONCEPT_CARD_DETAIL_BY_ID_QUERY = gql`
    query getConceptCardDetailById($conceptCardId: String!) {
        conceptCardGet(id: $conceptCardId) {
            isSuccess
            code
            errorMessage
            data {
                id
                name
                chapterId
                chapter {
                    id
                    name
                }
                questionBankId
                subject
                orderSequence
                tags {
                    id
                    name
                }
                createAt
                updateAt
            }
        }
    }
`
