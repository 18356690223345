import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { style } from 'typestyle'

export const selectionButtonDiv = (width: string) =>
    style({
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        columnGap: '0.5rem',
        boxSizing: 'border-box',
        padding: '0.75rem 1rem',
        backgroundColor: FLegacyColorTypes.DEFAULT_MAIN_THEME,
        boxShadow: '0 0 0.25rem rgba(72, 63, 226, 0.08)',
        borderRadius: '3rem',
        cursor: 'pointer',
        width: width ? width : '100%',
        $nest: {
            '&:hover': {
                opacity: 0.67,
            },
        },
    })
