import { gql } from '@apollo/client'
import { PHOTO_DETAIL_FIELDS } from '../fragments/photoDetail.gql'

export const GET_CONCEPT_CARDS_DETAIL_BY_FILTER = gql`
    query getConceptCardsDetailByFilter(
        $conceptCardFilterInput: ConceptCardFilterInput
    ) {
        conceptCardsGet(conceptCardFilterInput: $conceptCardFilterInput) {
            isSuccess
            errorMessage
            code
            data {
                id
                name
                chapterId
                questionBankId
                subject
                orderSequence
                photo {
                    ...photoDetail
                }
                tags {
                    id
                    name
                }
                createAt
                updateAt
            }
        }
    }

    ${PHOTO_DETAIL_FIELDS}
`
