import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
    useQBAssetLibraryContext,
    WithQBAssetLibraryContext,
} from '../../../contexts/qbAssetLibraryContext'
import { QBDirectoryViewMode } from '../../../contexts/qbAssetLibraryContext/types'
import { useUserSettingContext } from '../../../contexts/userSettingContext'
import { PageContainer } from '../../../layout/PageContainer'
import {
    useLoadingPageWrapperContext,
    WithLoadingPageWrapper,
} from '../../../layout/WithLoadingPageWrapper'
//
import { WithFolderPopUpWrapper } from '../../QuestionBankContent/components/popUp/WithFolderPopUpWrapper'
import { WithQuestionGroupPopUpWrapper } from '../../QuestionBankContent/components/popUp/WithQuestionGroupPopUpWrapper'
import { WithQuestionPopUpWrapper } from '../../QuestionBankContent/components/popUp/WithQuestionPopUpWrapper'
import { WithQuestionTypePopUpWrapper } from '../../QuestionBankContent/components/popUp/WithQuestionTypePopUpWrapper'
import { WithChapterPopUpWrapper } from '../../QuestionBankContent/components/popUp/WithChapterPopUpWrapper'
import QBAssetLibraryURLGenerator from '../components/QBAssetLibraryURLGenerator'
import { PageContainerProps } from '../../../layout/PageContainer/types'
import { QBDirectoryViewFoldersHeader } from './header/Folders'
import { QBDirectoryViewFoldersBody } from './body/Folders'
import { QBDirectoryViewQuestionTypesHeader } from './header/QuestionTypes'
import { QBDirectoryViewQuestionGroupsHeader } from './header/QuestionGroups'
import { QBDirectoryViewQuestionsHeader } from './header/Questions'
import { QBDirectoryViewChaptersBody } from './body/Chapters'
import { QBDirectoryViewQuestionGroupsBody } from './body/QuestionGroups'
import { QBDirectoryViewChaptersHeader } from './header/Chapters'
import { QBDirectoryViewQuestionTypesBody } from './body/QuestionTypes'
import { QBDirectoryViewQuestionsBody } from './body/Questions'

function QBAssetLibraryDirectoryView() {
    const navigate = useNavigate()
    const { setLoadingEnd, setLoadingStart } = useLoadingPageWrapperContext()
    const { currentUserSetting } = useUserSettingContext()
    const {
        isQBAssetLoading,
        viewMode,
        onViewModeChange,
        questionBank,
        folder,
        chapter,
        questionType,
        questionGroup,
    } = useQBAssetLibraryContext()

    const [searchParams] = useSearchParams()
    const _viewMode = searchParams.get('viewMode')
    const questionBankId = searchParams.get('questionBankId')
    const folderId = searchParams.get('folderId')
    const chapterId = searchParams.get('chapterId')
    const questionTypeId = searchParams.get('questionTypeId')
    const questionGroupId = searchParams.get('questionGroupId')
    const questionId = searchParams.get('questionId')
    const noLoading = searchParams.get('noLoading') === 'true' ? true : false

    useEffect(() => {
        if (
            _viewMode &&
            currentUserSetting.questionBank?.id &&
            currentUserSetting.questionBank?.id === questionBankId
        ) {
            onViewModeChange(
                {
                    viewMode: _viewMode as QBDirectoryViewMode,
                    questionBankId: questionBankId!,
                    folderId: folderId,
                    chapterId: chapterId,
                    questionTypeId: questionTypeId,
                    questionGroupId: questionGroupId,
                    questionId: questionId,
                },
                true
            )
        } else {
            navigate(
                QBAssetLibraryURLGenerator({
                    viewMode: QBDirectoryViewMode.FOLDERS,
                    questionBankId: currentUserSetting.questionBank?.id!,
                })
            )
        }
    }, [
        currentUserSetting.questionBank?.id,
        _viewMode,
        questionBankId,
        folderId,
        chapterId,
        questionTypeId,
        questionGroupId,
        questionId,
    ])

    useEffect(() => {
        if (isQBAssetLoading && !noLoading) {
            setLoadingStart()
        } else {
            setLoadingEnd()
        }
    }, [isQBAssetLoading, noLoading])

    const FinalViewRender: () => PageContainerProps = () => {
        if (
            viewMode === QBDirectoryViewMode.FOLDERS &&
            questionBank !== null &&
            questionBankId
        ) {
            return {
                header: <QBDirectoryViewFoldersHeader />,
                body: <QBDirectoryViewFoldersBody />,
            }
        } else if (
            viewMode === QBDirectoryViewMode.CHAPTERS &&
            folder !== null &&
            questionBankId
        ) {
            return {
                header: <QBDirectoryViewChaptersHeader />,
                body: <QBDirectoryViewChaptersBody />,
            }
        } else if (
            viewMode === QBDirectoryViewMode.QUESTION_TYPES &&
            chapter !== null
        ) {
            return {
                header: <QBDirectoryViewQuestionTypesHeader />,
                body: <QBDirectoryViewQuestionTypesBody />,
            }
        } else if (
            viewMode === QBDirectoryViewMode.ROOT_CHAPTER &&
            chapter !== null
        ) {
            return {
                header: <QBDirectoryViewQuestionTypesHeader />,
                body: <QBDirectoryViewQuestionTypesBody />,
            }
        } else if (
            viewMode === QBDirectoryViewMode.QUESTION_GROUPS &&
            questionType !== null
        ) {
            return {
                header: <QBDirectoryViewQuestionGroupsHeader />,
                body: <QBDirectoryViewQuestionGroupsBody />,
            }
        } else if (
            viewMode === QBDirectoryViewMode.QUESTIONS &&
            questionGroup !== null
        ) {
            return {
                header: <QBDirectoryViewQuestionsHeader />,
                body: <QBDirectoryViewQuestionsBody />,
            }
        } else
            return {
                header: <></>,
                body: <div>Loading {`${viewMode}`}...</div>,
            }
    }

    return <PageContainer {...FinalViewRender()} />
}

export default WithLoadingPageWrapper(
    WithChapterPopUpWrapper(
        WithFolderPopUpWrapper(
            WithQuestionTypePopUpWrapper(
                WithQuestionGroupPopUpWrapper(
                    WithQuestionPopUpWrapper(
                        WithQBAssetLibraryContext(QBAssetLibraryDirectoryView)
                    )
                )
            )
        )
    )
)
