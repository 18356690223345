import { stylesheet } from 'typestyle'

export const styles = stylesheet({
    FolderCreateForm_IconRow_Div: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: '8px',
    },
    FolderCreateForm_IconDisplay_Div: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: '24px',
    },
})
