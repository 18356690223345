import QBDirectoryViewBreadcrumb from '../../../Pages/QBAssetLibrary/components/QBDirectoryViewBreadcrumb'
import { PageHeader_Container, styles } from './styles'
import { PageHeaderProps } from './types'

export const HeaderDiv = (props: PageHeaderProps) => {
    return (
        <div
            className={
                PageHeader_Container(
                    props.showBreadcrumb,
                    props.disableBottomBorder
                ) +
                ' ' +
                props.className
            }
            style={props.style}
        >
            {props.showBreadcrumb &&
                (props.customBreadcrumb ?? <QBDirectoryViewBreadcrumb />)}
            <div className={styles.PageHeader_ChildrenDiv}>
                {props.children}
            </div>
        </div>
    )
}
