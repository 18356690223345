import { FOnClickFunction } from '@fantaskticedtechlimited/fui-complib'
import { useEffect, useMemo } from 'react'
import { useStateSafe } from '../useStateSafe'
export interface UseCountDownProps {
    time?: number
    defaultStart?: boolean
    onCallback?: FOnClickFunction
}

function useCountDown(props: UseCountDownProps) {
    const countDownProps: UseCountDownProps = useMemo(() => {
        return {
            time: 10,
            defaultStart: true,
            ...props,
        }
    }, [props])
    const [currentTime, setCurrentTime] = useStateSafe<number>(
        countDownProps.time!
    )
    const [isEnable, setIsEnable] = useStateSafe(countDownProps.defaultStart)
    const [intervalId, setIntervalId] = useStateSafe<NodeJS.Timer | null>(null)
    const enableCountDown = () => {
        setIsEnable(true)
    }
    const disableCountDown = () => {
        pauseCountDown()
        setCurrentTime(countDownProps.time!)
    }
    const pauseCountDown = () => {
        setIsEnable(false)
        intervalId && clearInterval(intervalId)
    }
    useEffect(() => {
        if (isEnable) {
            const id = setInterval(async () => {
                await setCurrentTime((currentTime) => currentTime - 1)
            }, 1000)
            setIntervalId(id)
            return () => {
                clearInterval(id)
            }
        }
    }, [isEnable, setCurrentTime, setIntervalId])
    useEffect(() => {
        if (currentTime <= 0) {
            countDownProps.onCallback && countDownProps.onCallback()
        }
    }, [currentTime, countDownProps])
    return {
        currentTime,
        enableCountDown,
        disableCountDown,
        pauseCountDown,
    }
}

export default useCountDown
