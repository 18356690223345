import { FButton } from '@fantaskticedtechlimited/fui-complib'
import { CTAButtonLabel_Div, CTAButton_Container } from './styles'
import { CTAButtonProps } from './types'

export const CTAButton = (props: CTAButtonProps) => {
    return (
        <FButton
            type="Outline"
            label={props.label}
            className={(isHover) => CTAButton_Container(isHover)}
            labelClassName={(isHover) => CTAButtonLabel_Div(isHover)}
            onClick={props.onClick}
        />
    )
}
