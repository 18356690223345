import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { FIconNames, FIcon } from '@fantaskticedtechlimited/fui-iconlib'
import { FOnClickFunction } from '@fantaskticedtechlimited/fui-complib'
import { useEffect, useState } from 'react'
import { Folder } from '../../../../../assets/type/backend.type'

import { useFolderPopUpWrapperContext } from '../../../../QuestionBankContent/components/popUp/WithFolderPopUpWrapper'
import { useChapterPopUpWrapperContext } from '../../../../QuestionBankContent/components/popUp/WithChapterPopUpWrapper'
import { ArchiChapterCard } from '../ArchiChapterCard'
import { ArrowOpenCloseIcon } from '../ArrowOpenCloseIcon'

interface ArchiFolderCardProps {
    questionBankId: string
    folder: Folder
    isRootOpen?: boolean
    onGlobalUpdate?: FOnClickFunction
}
export const ArchiFolderCard = (props: ArchiFolderCardProps) => {
    const { onChapterCreatePopUpOpen } = useChapterPopUpWrapperContext()
    const { onFolderMoreActionPopUpOpen } = useFolderPopUpWrapperContext()

    const { folder, isRootOpen, questionBankId } = props
    const [isOpenFolder, setIsOpenFolder] = useState(false)
    useEffect(() => {
        if (!isRootOpen) {
            setIsOpenFolder(false)
        } else {
            setIsOpenFolder(true)
        }
    }, [isRootOpen])

    return (
        <div>
            {/* folder title */}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    columnGap: '10px',
                }}
            >
                <ArrowOpenCloseIcon
                    isOpen={isOpenFolder}
                    onOpen={() => {
                        console.log('open arrow')
                        setIsOpenFolder(true)
                    }}
                    onClose={() => {
                        console.log('close arrow')
                        setIsOpenFolder(false)
                    }}
                />
                {`Folder: `}
                {folder.name}
                {!isOpenFolder && `	(${folder.chapters.length})`}
                <button
                    onClick={() =>
                        onChapterCreatePopUpOpen(
                            {
                                questionBankId: props.questionBankId,
                                presetFolderId: folder.id,
                            },
                            async () =>
                                // handleGetQuestionTypesByChapterId(props?.chapter.id)
                                {
                                    props.onGlobalUpdate &&
                                        (await props.onGlobalUpdate())
                                }
                        )
                    }
                >
                    +
                </button>
                {/* More Action button */}
                <FIcon
                    name={FIconNames.MORE}
                    size="small"
                    color={() => FLegacyColorTypes.PRIMARY_GREY}
                    onClick={() => {
                        onFolderMoreActionPopUpOpen(folder, async () => {
                            props.onGlobalUpdate &&
                                (await props.onGlobalUpdate())
                        })
                    }}
                />
            </div>

            {/* Chapters */}
            {isOpenFolder && (
                <div style={{ marginLeft: '24px' }}>
                    {folder.chapters.map((chapter, i) => {
                        return (
                            <ArchiChapterCard
                                questionBankId={questionBankId}
                                isRootOpen={isRootOpen}
                                chapter={chapter}
                                key={i}
                                onGlobalUpdate={props.onGlobalUpdate}
                            />
                        )
                    })}
                </div>
            )}
        </div>
    )
}
