import { useEffect, useState } from 'react'
import useQBAPIService from '../../../../../apiService/questionBank'
import {
    ConceptCard,
    QuestionGroup,
    MCContentCreateInput,
    QuestionBank,
    QuestionCreateInput,
    QuestionInputTypes,
    QuestionType,
    Tag,
    Chapter,
} from '../../../../../assets/type/backend.type'

import {
    FButton,
    FForm,
    FScrollableContentWrapper,
    FScrollBarStyle,
} from '@fantaskticedtechlimited/fui-complib'
import * as styles from './styles'
import { QuestionCreateFormProps } from './types'
import FCreateSelect from '../../../../../Components/FCreateSelect'
import ConceptCardRow from '../../ConceptCard/ConceptCardRow'
import QuestionPreview from '../../Question/QuestionPreview'
import { MCContentInputPanel } from '../../Question/MCContentInputPanel'
import { useStateSafe } from '../../../../../utils/useStateSafe'
import TagCreateSelector from '../../TagCreateSelector'

import { LoadingAnimation } from '../../../../../layout/WithLoadingPageWrapper'
function QuestionCreateForm(props: QuestionCreateFormProps) {
    //
    const qbAPIService = useQBAPIService()

    const [selectedQuestionBank, setSelectedQuestionBank] =
        useState<QuestionBank | null>(null)
    const handleGetQuestionBankContentById = async (id: string) => {
        try {
            const result =
                await qbAPIService.getQuestionCreate_QuestionBankContentDetailById(
                    {
                        id,
                    }
                )
            const { questionBankGet } = result.data
            if (!questionBankGet.isSuccess) {
                setSelectedQuestionBank(null)
                return alert('Fail to load QB')
            }

            setSelectedQuestionBank(questionBankGet.data! || null)
            setTags(questionBankGet.data?.tags ?? [])

            // find and set preset Chapter
            if (props.chapterId) {
                const foundChapter = questionBankGet.data?.chapters.find(
                    (e) => e.id === props.chapterId
                )
                if (!foundChapter) {
                    throw Error('chapterId not found')
                }
                setSelectedChapter(foundChapter)
                setQuestionTypes(foundChapter.questionTypes ?? [])
                // find and set preset QuestionType
                if (props.questionTypeId) {
                    const foundQuestionType = foundChapter.questionTypes.find(
                        (e) => e.id === props.questionTypeId
                    )
                    if (!foundQuestionType) {
                        throw Error('questionTypeId not found')
                    }
                    setSelectedQuestionType(foundQuestionType)

                    // find and set preset QuestionGroup
                    if (props.questionGroupId) {
                        const foundQuestionGroup =
                            foundQuestionType.questionGroups.find(
                                (e) => e.id === props.questionGroupId
                            )
                        if (!foundQuestionGroup) {
                            throw Error('QuestionGroupId not found')
                        }
                        setSelectedQuestionGroup(foundQuestionGroup)
                    }
                }
            }
        } catch (error) {
            console.log('error', error)
            setSelectedQuestionBank(null)
        }
    }

    // chapter
    const [selectedChapter, setSelectedChapter] = useStateSafe<Chapter | null>(
        null
    )

    // chapter question Type
    const [questionTypes, setQuestionTypes] = useState<QuestionType[]>([])
    const [selectedQuestionType, setSelectedQuestionType] =
        useState<QuestionType | null>(null)

    // QuestionGroups
    const [selectedQuestionGroup, setSelectedQuestionGroup] =
        useState<QuestionGroup | null>(null)

    // difficulties
    const levelOptions = [
        {
            label: 'Easy',
            value: 1,
        },
        {
            label: 'Normal',
            value: 2,
        },
        {
            label: 'Hard',
            value: 3,
        },
    ]
    const [selectedLevelOption, setSelectedLevelOption] = useState(
        levelOptions[0]
    )

    // concept Card
    const [selectedConceptCards, setSelectedConceptCards] = useStateSafe<
        ConceptCard[]
    >([])
    const handleConceptCardSelect = (newData: ConceptCard) => {
        const found = selectedConceptCards.find((e) => e.id === newData.id)
        if (!found) {
            setSelectedConceptCards([...selectedConceptCards, newData])
        }
    }
    const handleConceptCardRemove = (newData: ConceptCard) => {
        const newSelectedConceptCards = selectedConceptCards.filter(
            (e) => e.id !== newData.id
        )
        setSelectedConceptCards(newSelectedConceptCards)
    }

    const [tags, setTags] = useStateSafe<Tag[]>([])
    const [selectedTags, setSelectedTags] = useStateSafe<Tag[]>([])

    // Quesion Content
    const { isAutoCloseAfterComplete = true } = props
    const [questionInputType] = useStateSafe<QuestionInputTypes>(
        QuestionInputTypes.MULTIPLE_CHOICE
    )
    const defaultMCContent: MCContentCreateInput = {
        body: '',
        answers: [
            {
                isCorrect: true,
                body: '',
            },
            {
                isCorrect: false,
                body: '',
            },
            {
                isCorrect: false,
                body: '',
            },
            {
                isCorrect: false,
                body: '',
            },
        ],
        solution: '',
    }
    const [mcContentCreateInput, setMcContentCreateInput] =
        useStateSafe<MCContentCreateInput>({
            ...defaultMCContent,
            answers: [...defaultMCContent.answers],
        })

    const handleQuestionCreate = async () => {
        try {
            let questionCreateInput: QuestionCreateInput = {
                level: selectedLevelOption.value,
                questionBankId: props.questionBankId,
                questionGroupId: selectedQuestionGroup?.id ?? null,
                questionTypeId: selectedQuestionType?.id!,
                chapterId: selectedChapter?.id!,
                isFlaged: true,
                inputType: questionInputType,
                contentMC: mcContentCreateInput,
                // contentShortQuestion?: Nullable<ShortQuestionContentCreateInput>;
                // contentLongQuestion?: Nullable<LongQuestionContentCreateInput>;
                // contentGraph?: Nullable<GraphContentCreateInput>;
                tagIds: selectedTags.map((e) => e.id) || [],
                conceptCardIds: selectedConceptCards.map((e) => e.id) || [],
                photoAttachments: [],
            }
            const result = await qbAPIService.qbQuestionCreateMutation({
                variables: {
                    questionCreateInput,
                },
            })
            // console.log("result", result);
            if (
                result.data?.qbQuestionCreate.isSuccess &&
                result.data.qbQuestionCreate.data
            ) {
                props.onSubmitCallback &&
                    (await props.onSubmitCallback(
                        result.data.qbQuestionCreate.data
                    ))
                isAutoCloseAfterComplete &&
                    props.onClose &&
                    (await props.onClose())
                !isAutoCloseAfterComplete && alert('Question created')
            } else {
                console.error(
                    `Question Create failed, Reason. ${JSON.stringify(result)}`
                )
                alert(
                    `Question Create failed, Reason.  ${result.data?.qbQuestionCreate.errorMessage}`
                )
            }
        } catch (error) {
            console.log('error', error)
            alert(`Question Create failed, Error: ${JSON.stringify(error)}`)
        }
    }

    const handleReset = () => {
        setSelectedConceptCards([])
        setSelectedTags([])
        setSelectedLevelOption(levelOptions[0])
        setMcContentCreateInput({
            ...defaultMCContent,
            answers: [...defaultMCContent.answers],
        })
    }

    useEffect(() => {
        props.questionBankId &&
            handleGetQuestionBankContentById(props.questionBankId)
    }, [props.questionBankId])

    if (selectedQuestionBank === null) return <LoadingAnimation />

    return (
        <FForm onClose={props.onClose} onSubmit={handleQuestionCreate}>
            <div
                className={
                    styles.FormContent_Container + ' ' + FScrollBarStyle()
                }
            >
                <FScrollableContentWrapper style={{ width: '50%' }}>
                    <div
                        className={
                            styles.FormContent_Input_Container +
                            ' ' +
                            FScrollBarStyle()
                        }
                    >
                        <FButton
                            type="Primary"
                            onClick={() => {
                                if (
                                    window.confirm(
                                        'Do you want to reset the form ?'
                                    )
                                ) {
                                    handleReset()
                                }
                            }}
                            label="Reset"
                        />
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                columnGap: '12px',
                            }}
                        >
                            <FButton
                                type={
                                    selectedLevelOption.value ===
                                    levelOptions[0].value
                                        ? 'Primary'
                                        : 'Secondary'
                                }
                                label="Easy"
                                onClick={() => {
                                    setSelectedLevelOption(levelOptions[0])
                                }}
                            />
                            <FButton
                                type={
                                    selectedLevelOption.value ===
                                    levelOptions[1].value
                                        ? 'Primary'
                                        : 'Secondary'
                                }
                                label="Medium"
                                onClick={() => {
                                    setSelectedLevelOption(levelOptions[1])
                                }}
                            />
                            <FButton
                                type={
                                    selectedLevelOption.value ===
                                    levelOptions[2].value
                                        ? 'Primary'
                                        : 'Secondary'
                                }
                                label="Hard"
                                onClick={() => {
                                    setSelectedLevelOption(levelOptions[2])
                                }}
                            />
                        </div>
                        {/* Question Types */}
                        {props.questionTypeId === undefined && (
                            <FCreateSelect
                                isDisableCreate
                                isClearable={false}
                                options={questionTypes || []}
                                value={selectedQuestionType}
                                placeholder="Select Question Types"
                                getOptionLabel={(data) => data?.name}
                                getOptionKey={(data) => data?.id!}
                                onSelect={(data) => {
                                    setSelectedQuestionType(data)
                                    setSelectedQuestionGroup(null)
                                }}
                                onCompareInputValue={(data, input) => {
                                    return data!.name
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }}
                            />
                        )}

                        {/* QuestionGroups */}
                        {props.questionGroupId === undefined && (
                            <FCreateSelect
                                isDisableCreate
                                isClearable={false}
                                options={
                                    selectedQuestionType?.questionGroups || []
                                }
                                value={selectedQuestionGroup}
                                placeholder="Select Question Groups"
                                getOptionLabel={(data) => data?.name}
                                getOptionKey={(data) => data?.id!}
                                onSelect={(data) =>
                                    setSelectedQuestionGroup(data)
                                }
                                onCompareInputValue={(data, input) =>
                                    data?.name.includes(input) ?? false
                                }
                            />
                        )}

                        {/* Concept Cards */}
                        {/* <FCreateSelect
						isDisableCreate
						options={selectedQuestionBank.conceptCards}
						value={selectedConceptCards}
						placeholder="Select Concept Cards..."
						isMulti
						getOptionLabel={(data) => {
							// return ls.getLocaleString(data.name)
							return (
								<ConceptCardRow
									conceptCard={data}
									isDisableToolbar
								/>
							);
						}}
						getOptionKey={(data) => data.id}
						onClear={() => setSelectedConceptCards([])}
						onSelect={(data) => handleConceptCardSelect(data)}
						onRemove={(data) => handleConceptCardRemove(data)}
						onCompareInputValue={(data, input) =>
							ls.matchLocaleString(data.name, input)
						}
					/> */}

                        {/* TagInput */}
                        {/* <TagCreateSelector
						questionBankId={props.questionBankId}
						tags={tags}
						onTagsChange={setTags}
						selectedTags={selectedTags}
						onSelectedTagsChange={setSelectedTags}
					/> */}

                        {/* Basic Setting End ------------------------------------------------------- */}

                        {/* MC Content ------------------------------------------------------- */}
                        <MCContentInputPanel
                            content={mcContentCreateInput}
                            onContentChange={(newData) =>
                                setMcContentCreateInput(newData)
                            }
                        />
                        {/* MC Content End------------------------------------------------------- */}
                    </div>
                </FScrollableContentWrapper>
                <FScrollableContentWrapper style={{ width: '50%' }}>
                    <div className={styles.FormContent_Preview_Container}>
                        <QuestionPreview
                            question={{
                                id: 'Preview',
                                level: selectedLevelOption.value,
                                questionBankId:
                                    selectedQuestionBank.id ??
                                    'No selected Question Bank',
                                chapter: selectedChapter ?? null,
                                chapterId: selectedChapter
                                    ? selectedChapter.id
                                    : '',
                                questionType: selectedQuestionType ?? null,
                                questionGroup: selectedQuestionGroup ?? null,
                                isFlaged: true,
                                inputType: questionInputType,
                                content: {
                                    ...mcContentCreateInput,
                                    inputType: questionInputType,
                                },
                                tags: selectedTags,
                                tagIds: [],
                                conceptCards: selectedConceptCards,
                                conceptCardIds: [],
                                photoAttachments: [],
                            }}
                            isEdit
                        />
                    </div>
                </FScrollableContentWrapper>
            </div>
        </FForm>
    )
}

export default QuestionCreateForm
