import { gql } from '@apollo/client'

export const QB_QUESTIONTYPES_REARRANGE_BY_IDS_MUTATION = gql`
    mutation qbQuestionTypesRearrangeByIds(
        $questionTypesRearrangeInput: QuestionTypesRearrangeInput!
    ) {
        qbQuestionTypesRearrangeByIds(
            questionTypesRearrangeInput: $questionTypesRearrangeInput
        ) {
            isSuccess
            code
            errorMessage
            data {
                id
                orderSequence
            }
        }
    }
`
