import {
    QBAssetLibraryDirectoryViewParams,
    QBDirectoryViewMode,
} from '../../../../contexts/qbAssetLibraryContext/types'

const QBAssetLibraryURLGenerator = (
    params: QBAssetLibraryDirectoryViewParams
): string => {
    let url = `?viewMode=${QBDirectoryViewMode.FOLDERS}&questionBankId=${params.questionBankId}`
    switch (params.viewMode) {
        case QBDirectoryViewMode.CHAPTERS:
            url = `?viewMode=${QBDirectoryViewMode.CHAPTERS}&folderId=${params.folderId}&questionBankId=${params.questionBankId}`
            break
        case QBDirectoryViewMode.QUESTION_TYPES:
            url = `?viewMode=${QBDirectoryViewMode.QUESTION_TYPES}&chapterId=${params.chapterId}&questionBankId=${params.questionBankId}`
            break
        case QBDirectoryViewMode.QUESTION:
            url = `?viewMode=${QBDirectoryViewMode.QUESTION}&questionId=${params.questionId}&questionBankId=${params.questionBankId}`
            break
        case QBDirectoryViewMode.QUESTIONS:
            url = `?viewMode=${QBDirectoryViewMode.QUESTIONS}&questionGroupId=${params.questionGroupId}&questionBankId=${params.questionBankId}`
            if (params.questionId) {
                url += `&questionId=${params.questionId}`
            }
            break
        case QBDirectoryViewMode.QUESTION_GROUPS:
            url = `?viewMode=${QBDirectoryViewMode.QUESTION_GROUPS}&questionTypeId=${params.questionTypeId}&questionBankId=${params.questionBankId}`
            break
        case QBDirectoryViewMode.ROOT_CHAPTER:
            url = `?viewMode=${QBDirectoryViewMode.ROOT_CHAPTER}&chapterId=${params.chapterId}&questionBankId=${params.questionBankId}`
            break
        case QBDirectoryViewMode.FOLDERS:
        default:
            break
    }
    if (params.noLoading) {
        url += '&noLoading=true'
    }
    return url
}

export default QBAssetLibraryURLGenerator
