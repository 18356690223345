// https://stackedit.io/app#
// https://github.com/benweet/stackedit
import {
    Fragment,
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
} from 'react'
import { useUndoableState } from '../../../utils/useUndoableState'
import {
    FMarkdownEditorCoreProps,
    FMarkdownEditorProps,
    handleSymbolInsertProps,
    handleTextReplaceProps,
} from './types'
import * as styles from './styles'
import { FButton, FUseTheme } from '@fantaskticedtechlimited/fui-complib'
import { UploadedFile } from '../../../utils/useFirebaseFirestoreService/type'

import { FFontTypes } from '@fantaskticedtechlimited/fui-fontlib'
import FirebaseFileUploader from '../../FirebaseFileUploader'
import firebaseAppWithConfig from '../../../config/firebaseConfig'
import NormalFileUploader from '../../FirebaseFileUploader/normal_uploader'

const handleTextReplace = async (props: handleTextReplaceProps) => {
    const { mdRef, text, startIndex, endIndex, onUpdateMdValue } = props
    if (mdRef.current) {
        let leftMdValue = mdRef.current.value.slice(0, startIndex)
        let rightMdValue = mdRef.current.value.slice(endIndex)

        let newStr = [leftMdValue, text, rightMdValue].join('')

        // update text
        onUpdateMdValue(newStr)

        // focus back to textarea
        await mdRef.current.focus()
    }
}

const handleSymbolInsert = async (props: handleSymbolInsertProps) => {
    const {
        mdRef,
        symbolCutOffset = 0,
        symbol = '',
        placeHolder = 'input',
        isIncludeSelected = true,
        onUpdateMdValue,
    } = props
    if (mdRef.current) {
        let selectedText = ''
        let lastSelectionStart = mdRef.current.selectionStart
        let lastSelectionEnd = mdRef.current.selectionEnd

        // get selected Text
        if (lastSelectionStart !== lastSelectionEnd) {
            selectedText = mdRef.current.value.slice(
                lastSelectionStart,
                lastSelectionEnd
            )
        } else {
            selectedText = placeHolder
        }

        // join original text
        let leftSymbol = symbol.slice(0, symbolCutOffset)
        let rightSymbol = symbol.slice(symbolCutOffset)
        let leftMdValue = mdRef.current.value.slice(0, lastSelectionStart)
        let rightMdValue = mdRef.current.value.slice(lastSelectionEnd)

        let newStr = [
            leftMdValue,
            leftSymbol,
            selectedText,
            rightSymbol,
            rightMdValue,
        ].join('')

        // update text
        onUpdateMdValue(newStr)

        // focus back to textarea
        await mdRef.current.focus()

        // relocate focus point by cached position
        let newSelectionPosition = lastSelectionStart + symbolCutOffset
        let lastSelectionPosition = isIncludeSelected
            ? newSelectionPosition + selectedText.length
            : newSelectionPosition
        mdRef.current.setSelectionRange(
            newSelectionPosition,
            lastSelectionPosition
        )
    }
}

function FMarkdownEditorCore(props: FMarkdownEditorCoreProps) {
    const { theme } = FUseTheme()
    const mdRef = useRef<HTMLTextAreaElement>(null)
    const { onRedo, onUndo } = props

    const handleKeyDownEffect = useCallback(
        async (e: KeyboardEvent) => {
            if (e.ctrlKey && e.key === 'z') {
                e.preventDefault()
                await onUndo()
            } else if (
                (e.ctrlKey && e.key === 'y') ||
                (e.ctrlKey && e.shiftKey && e.key === 'Z')
            ) {
                e.preventDefault()
                await onRedo()
            }

            // smart quote
            if (mdRef.current) {
                let lastSelectionStart = mdRef.current.selectionStart
                let lastSelectionEnd = mdRef.current.selectionEnd

                if (lastSelectionStart === lastSelectionEnd) {
                    return
                }
                if (e.key === '(') {
                    e.preventDefault()
                    await handleSymbolInsert({
                        symbol: '()',
                        symbolCutOffset: 1,
                        mdRef: mdRef,

                        onUpdateMdValue: props.onChange,
                    })
                } else if (e.key === `"`) {
                    e.preventDefault()
                    await handleSymbolInsert({
                        symbol: '""',
                        symbolCutOffset: 1,
                        mdRef: mdRef,

                        onUpdateMdValue: props.onChange,
                    })
                } else if (e.key === `{`) {
                    e.preventDefault()
                    await handleSymbolInsert({
                        symbol: '{}',
                        symbolCutOffset: 1,
                        mdRef: mdRef,

                        onUpdateMdValue: props.onChange,
                    })
                } else if (e.key === `[`) {
                    e.preventDefault()
                    await handleSymbolInsert({
                        symbol: '[]',
                        symbolCutOffset: 1,
                        mdRef: mdRef,

                        onUpdateMdValue: props.onChange,
                    })
                } else if (e.key === `<`) {
                    e.preventDefault()
                    await handleSymbolInsert({
                        symbol: '<>',
                        symbolCutOffset: 1,
                        mdRef: mdRef,

                        onUpdateMdValue: props.onChange,
                    })
                }
            }
        },
        [onRedo, onUndo, props.onChange]
    )

    const addUploadHits = async (files: File[]) => {
        let inputs = files.map((e) => `![Uploading ${e.name}…]()`).join(`\n\n`)
        if (files.length > 0) {
            inputs = '\n' + inputs + '\n'
        }
        // const input = `![Uploading ${file.name}…]()`
        await handleSymbolInsert({
            placeHolder: '',
            isIncludeSelected: false,
            symbol: inputs,
            symbolCutOffset: inputs.length,
            mdRef: mdRef,
            onUpdateMdValue: props.onChange,
        })
    }

    const replaceUploadHitsWithURL = async (uploadFiles: UploadedFile[]) => {
        for (let i = 0; i < uploadFiles.length; i++) {
            const e = uploadFiles[i]
            if (mdRef.current === null) {
                return
            }
            const findTarget = `![Uploading ${e.originalFileName}…]()`
            const insertIndex = mdRef.current.value.indexOf(findTarget)
            if (insertIndex !== -1) {
                const replaceText = `![${e.originalFileName}](${e.fileURL})`
                console.log('replaceText', replaceText)
                await handleTextReplace({
                    text: replaceText,
                    startIndex: insertIndex,
                    endIndex: insertIndex + findTarget.length,
                    mdRef,
                    onUpdateMdValue: props.onChange,
                })
            }
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDownEffect)
        return () => {
            window.removeEventListener('keydown', handleKeyDownEffect)
        }
    }, [props.mdValue, handleKeyDownEffect])

    const handleTextareaHeight = () => {
        if (mdRef && mdRef.current) {
            mdRef.current.style.height = 'inherit'
            const computed = window.getComputedStyle(mdRef.current!)

            let height =
                parseInt(computed.getPropertyValue('border-top-width')) * 2 +
                mdRef.current.scrollHeight +
                parseInt(computed.getPropertyValue('border-bottom-width')) * 2

            if (
                props.maxRows &&
                mdRef.current.scrollHeight >
                    parseInt(computed.getPropertyValue('line-height')) *
                        (props.maxRows + 1)
            ) {
                height =
                    parseInt(computed.getPropertyValue('line-height')) *
                    (props.maxRows + 1)
            }
            mdRef.current.style.height = `${height}px`
            // console.log(mdRef.current.scrollHeight)
            // console.log(computed.getPropertyValue('height'))
        }
    }

    useLayoutEffect(() => {
        if (props.mdValue && props.mdValue.length > 0) {
            handleTextareaHeight()
        }
    }, [props.mdValue])

    return (
        <div
            style={props.wrapperStyle}
            className={
                styles.FMarkdownEditorCore_Wrapper +
                ' ' +
                props.wrapperClassName
            }
        >
            <div
                style={props.toolBarStyle}
                className={
                    styles.FMarkdownEditorCore_ToolBar_Container +
                    ' ' +
                    props.toolBarClassName
                }
            >
                {/* <Fragment>
					<FButton
						type="Outline"
						label="B"
						onClick={async () => {
							await handleSymbolInsert({
								symbol: "****",
								symbolCutOffset: 2,
								mdRef: mdRef,

								onUpdateMdValue: props.onChange,
							});
						}}
						labelProps={{
							font: FFontTypes.Small_Title({
								fontFamily: undefined,
								fontWeight: 800,
							}),
						}}
						style={{ padding: "2px 10px" }}
					/>
					<FButton
						type="Outline"
						label="I"
						onClick={async () => {
							await handleSymbolInsert({
								symbol: "**",
								symbolCutOffset: 1,
								mdRef: mdRef,

								onUpdateMdValue: props.onChange,
							});
						}}
						labelProps={{
							font: "unset",
							style: { fontStyle: "italic" },
						}}
						style={{ padding: "4.75px 12px" }}
					/>
					<FButton
						type="Outline"
						label="Math"
						onClick={async () => {
							await handleSymbolInsert({
								symbol: "$$",
								symbolCutOffset: 1,
								mdRef: mdRef,

								onUpdateMdValue: props.onChange,
							});
						}}
						labelProps={{
							font: FFontTypes.Small_Title({
								fontWeight: 300,
							}),
						}}
						style={{ padding: "2px 10px" }}
					/>
					<FButton
						type="Outline"
						label="Power"
						onClick={async () => {
							await handleSymbolInsert({
								symbol: "{}^{input2}",
								symbolCutOffset: 1,
								mdRef: mdRef,

								onUpdateMdValue: props.onChange,
							});
						}}
						labelProps={{
							font: FFontTypes.Small_Title({
								fontWeight: 300,
							}),
						}}
						style={{ padding: "2px 10px" }}
					/>
					<FButton
						type="Outline"
						label="√"
						onClick={async () => {
							await handleSymbolInsert({
								symbol: "\\sqrt[input2]{}",
								symbolCutOffset: 14,
								mdRef: mdRef,

								onUpdateMdValue: props.onChange,
							});
						}}
						labelStyle={{ marginTop: "2.5px" }}
						style={{ padding: "3.75px 12px" }}
					/>
					<FButton
						type="Outline"
						label="∑"
						onClick={async () => {
							await handleSymbolInsert({
								symbol: "\\sum_{}^{input2}",
								symbolCutOffset: 6,
								mdRef: mdRef,

								onUpdateMdValue: props.onChange,
							});
						}}
						labelStyle={{ marginBottom: "2px" }}
						style={{ padding: "4px 11px" }}
					/>
					<FButton
						type="Outline"
						label="∏"
						onClick={async () => {
							await handleSymbolInsert({
								symbol: "\\prod_{}^{input2}",
								symbolCutOffset: 7,
								mdRef: mdRef,

								onUpdateMdValue: props.onChange,
							});
						}}
						style={{ padding: "5px 10px" }}
					/>
					<FButton
						type="Outline"
						label="∫"
						onClick={async () => {
							await handleSymbolInsert({
								symbol: "\\int_{}^{input2}",
								symbolCutOffset: 6,
								mdRef: mdRef,

								onUpdateMdValue: props.onChange,
							});
						}}
						style={{ padding: "5px 13px" }}
					/>
				</Fragment> */}
                <NormalFileUploader
                    multiple={true}
                    maxNumberOfFiles={99}
                    accept=".jpg,.jpeg,.png,.gif"
                    onBeforeUpload={(files) => {
                        addUploadHits(files)
                    }}
                    onUploadedFiles={(files) => {
                        // console.log("files", files)
                        replaceUploadHitsWithURL(files)
                    }}
                    // firebaseApp={firebaseAppWithConfig}
                    // fileSavePath={'question/photos'}
                    // withDND
                    isButtonUploader
                    // withStatus
                />
            </div>
            <textarea
                ref={mdRef}
                className={styles.FMarkdownEditorCore_InputField_Container(
                    theme
                )}
                rows={props.minRows}
                placeholder="Input something"
                value={props.mdValue}
                onChange={(e) => {
                    props.onChange(e.currentTarget.value)
                    handleTextareaHeight()
                }}
                onBlur={(e) => {
                    // console.log("onBlur")
                    handleTextareaHeight()
                    window.removeEventListener('keydown', handleKeyDownEffect)
                }}
                onFocus={(e) => {
                    // console.log("onFocus")
                    handleTextareaHeight()
                    window.addEventListener('keydown', handleKeyDownEffect)
                }}
            />
        </div>
    )
}

function FMarkdownEditor(props: FMarkdownEditorProps) {
    const [mdValue, setValue, undo, redo, reset] = useUndoableState<string>(
        props.mdValue
    )

    useEffect(() => {
        if (mdValue !== props.mdValue) {
            props.onChange(mdValue)
        }
    }, [mdValue]) // eslint-disable-line react-hooks/exhaustive-deps

    // useEffect(() => {
    // 	if (mdValue !== props.mdValue) {
    // 		setValue(props.mdValue);
    // 	}
    // }, [props.mdValue]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <FMarkdownEditorCore
            mdValue={mdValue}
            onChange={setValue}
            onUndo={undo}
            onRedo={redo}
            onReset={reset}
            minRows={props.minRows}
            maxRows={props.maxRows}
            // {...props}
        />
    )
}

export default FMarkdownEditor
