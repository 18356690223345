import { ElementType, useEffect } from 'react'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import firebaseAppWithConfig from '../../config/firebaseConfig'

export const WithFirebaseMessagingContext = (
    Component: ElementType,
    isShowFirebaseMessageToken?: boolean
) => {
    const initFirebaseMessaging = async () => {
        try {
            const messaging = getMessaging(firebaseAppWithConfig)
            const token = await getToken(messaging, {
                vapidKey: process.env.REACT_APP_FIREBASE_MESSAGE_VAPID_KEY,
            })
            if (isShowFirebaseMessageToken) {
                console.log('token', token)
            }
            onMessage(messaging, (payload) => {
                console.log('Message received. ', payload)

                const dataMessageTitle = payload.data?.title
                    ? `FE: ${payload.data?.title}`
                    : 'Notification'
                const dataMessageOptions = {
                    body: payload.data?.body ?? '',
                    icon: payload.data?.icon ?? undefined,
                    // image: payload.data.image,
                    vibrate: [300, 100, 400],
                    requireInteraction: true,
                    timestamp: new Date().getTime(),
                }
                navigator.serviceWorker.ready.then((r) => {
                    r.showNotification(dataMessageTitle, dataMessageOptions)
                })
                // ...
            })
        } catch (error) {
            console.error('testModuleFirebase error', error)
        }
    }

    return function WithFirebaseMessagingContext(props: any) {
        useEffect(() => {
            initFirebaseMessaging()
        }, [])
        return <Component {...props} />
    }
}
