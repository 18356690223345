import { FLegacyColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import { FIcon, FIconNames } from '@fantaskticedtechlimited/fui-iconlib'
import { ImageAsset } from '../../../../../assets/images'

interface HoverableIconProps {
    isHover?: boolean
}
export function HBEditIcon(props: HoverableIconProps) {
    const { isHover } = props
    return (
        <img
            src={isHover ? ImageAsset.EditIconHover : ImageAsset.EditIcon}
            alt="Edit"
        />
    )
}

export function HBClearFlagIcon(props: HoverableIconProps) {
    const { isHover } = props
    return (
        <FIcon
            name={FIconNames.FLAG}
            size="small"
            color={() =>
                isHover
                    ? FLegacyColorTypes.PRIMARY_BLACK
                    : FLegacyColorTypes.PRIMARY_GREY
            }
        />
    )
}

export function HBDeleteIcon(props: HoverableIconProps) {
    const { isHover } = props
    return (
        <FIcon
            name={FIconNames.DELETE}
            size="small"
            color={() =>
                isHover
                    ? FLegacyColorTypes.PRIMARY_BLACK
                    : FLegacyColorTypes.PRIMARY_GREY
            }
        />
    )
}

export function HBMoveIcon(props: HoverableIconProps) {
    const { isHover } = props
    return (
        <img
            src={isHover ? ImageAsset.MoveIconHover : ImageAsset.MoveIcon}
            alt="Move"
        />
    )
}

export function HBDuplicateIcon(props: HoverableIconProps) {
    const { isHover } = props
    return (
        <img
            src={
                isHover
                    ? ImageAsset.DuplicateIconHover
                    : ImageAsset.DuplicateIcon
            }
            alt="Duplicate"
        />
    )
}
